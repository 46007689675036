import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiIncidentClient } from '../infrastructure/HengyiClient/Incidents';
import { UserLink } from '../Users/UserLink';
import InnerImageZoom from 'react-inner-image-zoom'
import { GetAssetDomain } from '../infrastructure/Helper';

export class IncidentsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.sync = this.sync.bind(this);
    }

    async downloadFile(id, name) {

        var link = document.createElement('a');
        link.href = await GetAssetDomain() + "/file/" + id;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

    }


    async sync() {
        this.setState({ syncLoading: true });
        await HengyiIncidentClient.Sync(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
        this.setState({ syncLoading: false });
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
    });
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/incidents" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <h5>
                                <Link to="/incidents" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                {this.state.data && <span>Incident</span>}
                                {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                            </h5>
                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <><Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Reference:</td>
                                                <td>
                                                    {this.state.data.reference && this.state.data.reference}
                                                    {!this.state.data.reference && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Title:</td>
                                                <td>
                                                    {this.state.data.title && this.state.data.title}
                                                    {!this.state.data.title && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Location:</td>
                                                <td>
                                                    {this.state.data.location && this.state.data.location}
                                                    {!this.state.data.location && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Cause:</td>
                                                <td>
                                                    {this.state.data.cause && this.state.data.cause}
                                                    {!this.state.data.cause && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Person affected:</td>
                                                <td>
                                                    {this.state.data.personAffected && this.state.data.personAffected}
                                                    {!this.state.data.personAffected && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Person type:</td>
                                                <td>
                                                    {this.state.data.personType && this.state.data.personType}
                                                    {!this.state.data.personType && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>
                                                    {this.state.data.type && this.state.data.type}
                                                    {!this.state.data.type && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Outcome:</td>
                                                <td>
                                                    {this.state.data.outcome && this.state.data.outcome}
                                                    {!this.state.data.outcome && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Was someone harmed?:</td>
                                                <td>
                                                    {this.state.data.wasSomeoneHarmed && "Yes"}
                                                    {!this.state.data.wasSomeoneHarmed && "No"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Type of harm:</td>
                                                <td>
                                                    {this.state.data.harmType && this.state.data.harmType}
                                                    {!this.state.data.harmType && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Reported By:</td>
                                                <td>
                                                    <UserLink user={this.state.data.reportedBy} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Building:</td>
                                                <td>
                                                    {this.state.data.building && <Link key={this.state.data.building.id + "-view"} to={"/buildings/" + this.state.data.building.id}>{this.state.data.building.name}</Link>}
                                                    {!this.state.data.building && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Provider:</td>
                                                <td>
                                                    {this.state.data.provider === "none" && <Badge color="secondary">Not synced</Badge>}
                                                    {this.state.data.provider === "donesafe" && <Badge color="primary">DoneSafe</Badge>}
                                                    {!this.state.data.provider && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "open" && <Badge color="primary">Open</Badge>}
                                                    {this.state.data.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                                    {!this.state.data.status && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.details && <span>{this.state.data.details}</span>}
                                                    {!this.state.data.details && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created:</td>
                                                <td>
                                                    {this.state.data.created && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.created}</Moment>}
                                                    {!this.state.data.created && <i>Never</i>}
                                                </td>
                                            </tr>



                                        </tbody>
                                            </table>

                                        </Col>
                                        <Col md="3">
                                            <h6>External System</h6>
                                            <hr />
                                            {this.state.data && this.state.data.provider === "none" &&
                                                <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                                    <Row>
                                                        <Col>
                                                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Not synced to external system</strong></p>
                                                            <p>This incident has not been synced to an external health and safety system</p>
                                                            <Button to="/notices" onClick={this.sync} className="btn mr-2 mt-2 mb-2 btn-info">
                                                                {this.state.syncLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                                {!this.state.syncLoading && "Sync to DoneSafe"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {this.state.data && this.state.data.provider === "doneSafe" &&
                                                <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                    <Row>
                                                        <Col>
                                                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Sync'd to DoneSafe</strong></p>
                                                            <p>DoneSafe IncidentId: <strong>{this.state.data.externalId}</strong></p>
                                                            <p>This incident has been successfully published to the DoneSafe system</p>
                                                            <a className="btn btn-sml btn-success ml-1" href={"https://vlobby.donesafe.com/module_records/" + this.state.data.externalId} target="_blank" rel="noopener noreferrer">View in DoneSafe</a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                                <Card>
                                    <CardBody>
                                        <h6>Images</h6>
                                        <hr />

                                        <Row>
                                            {this.state.data && this.state.data.images && this.state.data.images.length > 0 && this.state.data.images.map(item =>
                                                <Col style={{ maxWidth: "300px" }}>
                                                    <InnerImageZoom src={this.state.assetUrl + "/image/" + item + ".jpg"} />
                                                </Col>
                                            )}
                                        </Row>
                                        {(!this.state.data || !this.state.data.images || !this.state.data.images.length > 0) &&
                                            <h4 className="text-muted text-center mt-3"><i>No images uploaded</i></h4>
                                        }

                                    </CardBody>
                                </Card>

                                {this.state.data && this.state.data.files && this.state.data.files.length > 0 && <Card>
                                    <CardBody>
                                        <h6>Other files</h6>
                                        <hr />
                                        {this.state.data.files.map(file => <Row style={{ paddingTop: "10px" }}>
                                            <Col>
                                                <h5>
                                                    {file.fileName}
                                                </h5>
                                            </Col>
                                            <Col style={{
                                                textAlign: "right"
                                            }}>
                                                <button onClick={() => { this.downloadFile(file.id, file.fileName); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                                            </Col>
                                        </Row>)}
                                    </CardBody>
                                </Card>}
                                
                                </>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Incident activity" description="List all incident activity" entityId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiIncidentClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
