import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { EVChargingTable } from '../EVCharging/EVChargingTable';
import { ActivityTable } from '../Activity/ActivityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class VehiclesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.archive = this.archive.bind(this);
        this.restore = this.restore.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/vehicles" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/vehicles" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Vehicle</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.licensePlate}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/vehicles/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity</NavLink>
                                </NavItem>
                                {this.state.data && (this.state.data.fuelType === "ev" || this.state.data.fuelType === "hybrid") && <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        EV Charging</NavLink>
                                </NavItem>}
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Make:</td>
                                                <td>
                                                    {this.state.data.make && this.state.data.make}
                                                    {!this.state.data.make && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Model:</td>
                                                <td>
                                                    {this.state.data.model && this.state.data.model}
                                                    {!this.state.data.model && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">License plate:</td>
                                                <td>
                                                    {this.state.data.licensePlate && this.state.data.licensePlate}
                                                    {!this.state.data.licensePlate && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">WoF expires:</td>
                                                <td>
                                                    {this.state.data.wofExpiry && <Moment utc local format="ddd DD MMM YY">{this.state.data.wofExpiry}</Moment>}
                                                    {!this.state.data.wofExpiry && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Registration expires:</td>
                                                <td>
                                                    {this.state.data.registrationExpiry && <Moment utc local format="ddd DD MMM YY">{this.state.data.registrationExpiry}</Moment>}
                                                    {!this.state.data.registrationExpiry && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Colour:</td>
                                                <td>
                                                    {this.state.data.colour && this.state.data.colour}
                                                    {!this.state.data.colour && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Space:</td>
                                                <td>
                                                    {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                    {!this.state.data.apartment && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Primary contact:</td>
                                                <td>
                                                    {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/" + this.state.data.user.id}>{this.state.data.user.name} {this.state.data.user.surname}</Link>}
                                                    {!this.state.data.user && <i>Anyone at apartment</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Fuel Type:</td>
                                                <td>
                                                    {this.state.data.fuelType && this.state.data.fuelType === "ev" && <Badge color="primary">Electric</Badge>}
                                                    {this.state.data.fuelType && this.state.data.fuelType === "petrol" && <Badge color="info">Petrol</Badge>}
                                                    {this.state.data.fuelType && this.state.data.fuelType === "diesel" && <Badge color="info">Diesel</Badge>}
                                                    {this.state.data.fuelType && this.state.data.fuelType === "hybrid" && <Badge color="info">Hybrid</Badge>}
                                                    {!this.state.data.fuelType && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "active" && <Badge color="primary">Active</Badge>}
                                                    {this.state.data.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                                </td>
                                            </tr>

                                            {this.state.data && this.state.data.provider === "swiftPark" &&
                                            <tr>
                                                <td className="pr-2">Integration:</td>
                                                    <td><a href={"https://swiftpark.codemagic.app/vehicles/details/" + this.state.data.valetExternalId}>View in SwiftPark</a></td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Vehicle activity" description="List all vehicle activity" entityId={this.props.match.params.id} />}
                        </TabPane>


                        {this.state.data && (this.state.data.fuelType === "ev" || this.state.data.fuelType === "hybrid") && <TabPane tabId="3">
                            {this.state.activeTab === '3' && <EVChargingTable title="Vehicle charging sessions" description={"Charging sessions for " + this.state.data.licensePlate} vehicleId={this.props.match.params.id} />}
                        </TabPane>}

                    </TabContent>



                    {!this.state.loading && this.state.data && this.state.data.status === "active" &&
                        <div className="alert alert-danger">
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Vehicle no longer in use?</strong> Archive it to hide in the CRM</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/vehicles" onClick={this.archive} className="btn mr-2 mt-2 mb-2 btn-danger">Archive vehicle</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "archived" &&
                        <div className="alert alert-info">
                            <Row>
                                <Col>
                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Vehicle still in use?</strong> Restore it to allow it to be used again</p>
                                </Col>
                                <Col md="2">
                                <Button style={{ float: "right" }} to="/vehicles" onClick={this.restore} className="btn mr-2 mt-2 mb-2 btn-info">Restore vehicle</Button>
                                </Col>
                            </Row>
                        </div>}

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiVehicleClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }


    async archive() {
        await HengyiVehicleClient.Archive(this.props.match.params.id);
        this.setState({ next: true });
    }

    async restore() {
        await HengyiVehicleClient.Restore(this.props.match.params.id);
        this.setState({ next: true });
    }

}
