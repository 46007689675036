import React, { Component } from 'react';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { TableHeader } from '../infrastructure/TableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWheelchair } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver';
import { userContext } from '../../userContext';

export class Apartments extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowAccessibleOnly = this.handleShowAccessibleOnly.bind(this);

        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "name", direction: "asc" };
    }

    async clear() {

        this.setState({
            search: "",
            skip: 0
        }, () => this.update());

    }

    handleShowAccessibleOnly = (evt) => {
        this.setState({
            showAccessibleOnly: evt.target.checked
        }, () => this.update());
    }


    async exportToCSV() {

        this.setState({ exporting: true });

        var blob = await HengyiApartmentClient.Export(new ApartmentQueryParams()
            .WithAccessibility(this.state.showAccessibleOnly ? true : null)
            .Search(this.state.search)
            .Paginate(0, this.state.total));

        FileSaver.saveAs(blob, "tickets.csv");

        this.setState({ exporting: false });
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        this.setState({
            showAccessibleOnly: urlParams.get("hasAccessibilityRequirements") ? urlParams.get("hasAccessibilityRequirements") : false
        }, () => {
            this.populateData(this.state.skip, this.state.take);
        });
    }

    renderTable(data) {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">

                    <TableHeader
                        sort={this.state.sort}
                        direction={this.state.direction}
                        columns={[
                            { name: "", sort: "", static: true },
                            { name: "Name", sort: "name" },
                            { name: "Type", sort: "type.name" },
                            { name: "Floor", sort: "floor.name" },
                            { name: "Settled", sort: "settled" },
                            { name: "Property Title", sort: "propertyTitleNumber" },
                            { name: "Owners", sort: "", static: true },
                            { name: "Parks", sort: "", static: true },
                            { name: "", sort: "", static: true }]}
                        onUpdate={(sort, direction) => this.setState({
                            sort: sort,
                            direction: direction
                        }, () => this.update())}
                    />
                    <tbody>
                        {data.map(apartment =>
                            <tr key={apartment.id}>
                                <td>
                                    {apartment.hasAccessibilityRequirements && <FontAwesomeIcon icon={faWheelchair} />}
                                </td>
                                <td>{apartment.name}</td>
                                <td>
                                    {apartment.type && apartment.type.name}
                                    {!apartment.type && <i>Not specified</i>}
                                </td>
                                <td>{apartment.floor.name}</td>
                                <td>
                                    {apartment.settled && "Yes"}
                                    {!apartment.settled && "No"}
                                </td>
                                <td>
                                    {apartment.propertyTitleNumber && apartment.propertyTitleNumber}
                                    {!apartment.propertyTitleNumber && <i>Not specified</i>}
                                </td>
                                <td>{apartment.owners && apartment.owners.length > 0 && apartment.owners.map((owner, index) =>
                                    <Link key={apartment.id + owner.id + "-view"} to={"/users/" + owner.id}>{owner.name} {owner.surname}{(index < (apartment.owners.length - 1)) && <span>, </span>}</Link> 
                                )}{(!apartment.owners || !apartment.owners.length > 0) && <i>Unregistered</i>}</td>
                                <td>
                                    {apartment.carParks}
                                </td>
                                <td>
                                    <Link key={apartment.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/apartments/" + apartment.id}>Details</Link>
                                    <Link key={apartment.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/apartments/edit/" + apartment.id}>Edit</Link>
                                </td>
                            </tr>
                        )}

                        {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No spaces to display</i></h4></td></tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <userContext.Consumer>
                        {({ user, logoutUser }) => {
                            return (<Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Spaces ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of spaces in the building.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>


                                                {user.permissions && user.permissions.includes("apartment.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2" >

                                            {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                            {this.state.exporting && <Spinner animation="border" />}

                                        </button>}

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                            </Card>);
                        }}
                    </userContext.Consumer>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                            <CardHeader>
                                <Row>
                                    <Col></Col>
                                    <Col md="4">
                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="showAccessibleOnly"
                                                            type="checkbox"
                                                            checked={this.state.showAccessibleOnly}
                                                            onChange={this.handleShowAccessibleOnly} />  Show accessible only
                                                </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiApartmentClient.List(new ApartmentQueryParams()
                .WithAccessibility(this.state.showAccessibleOnly ? true : null)
                .Search(this.state.search)
                .Paginate(skip, take)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
