import { HengyiFloorClient } from '../infrastructure/HengyiClient/Floors';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class FloorsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/floors" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/floors" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Floor</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Floor details</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/floors/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h6>Floor details</h6>
                                    <hr />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    <span>{this.state.data.name}</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="pr-2">Ordinal number:</td>
                                                <td>
                                                    <span>{this.state.data.ordinal}</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="pr-2">Building:</td>
                                                <td>
                                                    <span>
                                                        {this.state.data.building && <Link key={this.state.data.building.id + "-view"} to={"/buildings/" + this.state.data.building.id}>{this.state.data.building.name}</Link>}
                                                        {!this.state.data.building && <i>Not specified</i>}
                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </Col>

                                <hr />

                            </Row>
                        </CardHeader>
                    </Card>

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiFloorClient.Get(id);
            console.log(response);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
