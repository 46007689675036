import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, InputGroup, ButtonGroup, Input } from 'reactstrap';
import { HengyiMessageThreadClient, MessageThreadQueryParams } from '../infrastructure/HengyiClient/MessageThreads';
import Moment from 'react-moment';
import { Formik, Field, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HengyiMessageClient, MessageQueryParams } from '../infrastructure/HengyiClient/Messages';
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';
import { store, view } from 'react-easy-state';

const persistedState = store({
    currentThreadId: "",
    isUnread: "",
    userId: "",
    apartmentId: "",
    statusFilter: "",
    search: ""
});

export class Conversations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: true, data: [], loading: false, total: 0, skip: 0, take: 25, authenticated: true,
            currentThreadId: "",
            currentThread: "",
            messages: []
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.updateCurrentThread = this.updateCurrentThread.bind(this);
        this.handleIsUnreadChange = this.handleIsUnreadChange.bind(this);

        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
        this.markAsUnread = this.markAsUnread.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    handleUserIdChange(name, value) {

        persistedState.userId = value;
        persistedState.currentThreadId = null;
        this.update();
    }

    handleApartmentIdChange(name, value) {
        persistedState.apartmentId = value;
        persistedState.currentThreadId = null;
        this.update();
    }

    handleStatusChange(evt) {
        persistedState.statusFilter = evt.target.value;
        persistedState.currentThreadId = null;
        this.update();
    }

    handleSearchChange(evt) {

        persistedState.search = evt.target.value;
        persistedState.currentThreadId = null;
        this.update();
    }

    handleIsUnreadChange(evt) {
        persistedState.isUnread = evt.target.value;
        persistedState.currentThreadId = null;
        this.update();
    }

    async markAsUnread(threadId) {

        await HengyiMessageThreadClient.MarkAsUnread(threadId);
        await this.updateCurrentThread(null);
    }

    async componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("unread") === "true") {
            persistedState.isUnread = "toConcierge";
        }

        if (urlParams.get("currentThreadId")) {
            await this.populateData(this.state.skip, this.state.take);
            await this.updateCurrentThread(urlParams.get("currentThreadId"));
        } else {
            await this.populateData(this.state.skip, this.state.take);
        }

        this.timer = setInterval(() => this.populateData(this.state.skip, this.state.take), 5000);
    }

    async clear() {

        this.setState({
            skip: 0
        }, async () => {
            persistedState.currentThreadId = null;
            persistedState.isUnread = "";
            persistedState.userId = "";
            persistedState.apartmentId = "";
            persistedState.statusFilter = "";
            persistedState.search = "";
            await this.update();
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    async updateCurrentThread(threadId) {
        if (threadId) {
            await HengyiMessageThreadClient.MarkAsSeen(threadId);
            persistedState.currentThreadId = threadId;
            this.setState({ currentThread: this.state.data.filter(item => item.id === threadId)[0] }, () => this.populateData(this.state.skip, this.state.take, true));
        } else {
            persistedState.currentThreadId = null;
            this.setState({ currentThread: null }, () => this.populateData(this.state.skip, this.state.take, true));
        }
        setTimeout(() => {
            if (document.getElementById("message")) {
                document.getElementById("message").focus();
            }
        }, 100);
    }

    async update(scroll) {
        if (persistedState.currentThreadId) {
            this.setState({ currentThread: this.state.data.filter(item => item.id === persistedState.currentThreadId)[0] });
        } else {
            this.setState({ currentThread: null });
        }
        await this.populateData(this.state.skip, this.state.take, scroll);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table table-linked' aria-labelledby="tabelLabel">
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td style={{ width: "70px" }} className="clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} >
                                <div className={persistedState.currentThreadId === item.id && "selected-div"} key={item.id + "-location-view"} to={"/conversations/" + item.id}>
                                    <div class="i-block">
                                        {item.messageThreadType === "general" && <i class="feather icon-message-square"></i>}
                                        {item.messageThreadType === "campaign" && <i class="feather icon-volume-1"></i>}
                                        {item.messageThreadType === "booking" && <i class="feather icon-calendar"></i>}
                                        {item.messageThreadType === "offer" && <i class="feather icon-tag"></i>}
                                        {item.messageThreadType === "vehicle" && <FontAwesomeIcon size="2x" icon={faCar} />}
                                    </div>
                                </div>
                            </td>
                            <td className="clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} >
                                <div className={item.conciergeStatus === "new" ? "bold" : ""} key={item.id + "-location-view"} to={"/conversations/" + item.id}>
                                    <p style={{ marginBottom: 0, marginTop: "10px" }}>{item.user.name} {item.user.surname}</p>
                                    {item.latestMessage && <p style={{ maxHeight: "25px", overflow: "hidden", color: "#a2a2a2", width: "100%", whiteSpace: "normal", wordBreak: "normal", padding: "0", margin: "0" }}>{item.latestMessage.content}</p>}
                                    {persistedState.currentThreadId && <small style={{ color: "#a2a2a2" }}>{item.latestMessage && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.latestMessage.created}</Moment>}{item.user.apartments && item.user.apartments[0] && <span> - {item.user.apartments[0].apartment.name}</span>}</small>}
                                </div>
                            </td>
                            {!persistedState.currentThreadId && <td className="text-right clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} >
                                <div className={item.conciergeStatus === "new" ? "bold" : ""} key={item.id + "-location-view"} to={"/conversations/" + item.id}>
                                    <small style={{ marginRight: "15px", marginTop: "30px", display: "block" }}>{item.latestMessage && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.latestMessage.created}</Moment>}{item.user.apartments && item.user.apartments[0] && <span> - {item.user.apartments[0].apartment.name}</span>}</small>
                                </div>
                            </td>}
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="2"><h4 className="text-muted text-center mt-3"><i>No conversations to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <div id="filterSection">

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Conversations ({this.state.unreadCount})
                                     {this.state.init && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">List of conversations with residents</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                            <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                            <Link style={{ float: "right" }} to="/conversations/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                        </div>
                                    </Col>
                                </Row>

                            </CardHeader>
                        </Card>

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5>Filter</h5>
                                    </Col>

                                    <Col>
                                        <UserSelector
                                            placeholder="Resident"
                                            value={persistedState.userId}
                                            initialUserId={persistedState.userId ? persistedState.userId.value : null}
                                            onChange={this.handleUserIdChange}
                                            hideApartmentSuffix={true}
                                            hideLabel={true} />
                                    </Col>
                                    <Col>
                                        <ApartmentSelector
                                            placeholder="Space"
                                            value={persistedState.apartmentId}
                                            initialApartmentId={persistedState.apartmentId ? persistedState.apartmentId.value : null}
                                            onChange={this.handleApartmentIdChange}
                                            hideLabel={true} />
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <Input type="select" name="select" value={persistedState.isUnread} onChange={this.handleIsUnreadChange} >
                                                <option value="">Read status</option>
                                                <option value="toConcierge">Unread</option>
                                                <option value="toResident">Resident has not read</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(persistedState.search || persistedState.isUnread || persistedState.userId || persistedState.apartmentId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        {!this.state.currentThread &&
                            <Card>
                                <CardBody>
                                    <i className="feather icon-star"></i> Select a conversation below to get started
                                </CardBody>
                            </Card>
                        }

                        {this.state.currentThread && this.state.currentThread.messageThreadType === "general" &&
                            <Card>
                                <CardBody>
                                <i className="feather icon-message-square"></i> Conversation between <strong><Link to={"/users/" + this.state.currentThread.user.id}>{this.state.currentThread.user.name} {this.state.currentThread.user.surname}</Link></strong> and concierge

                                <button style={{ float: "right" }} onClick={() => { this.markAsUnread(this.state.currentThread.id); }} disabled={this.state.loading} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Mark as unread</button>

                            </CardBody>
                            </Card>
                        }

                        {this.state.currentThread && this.state.currentThread.messageThreadType === "campaign" &&
                            <Card>
                                <CardBody>
                                    <i className="feather icon-volume-1"></i>  Reply to the '<strong><Link to={"/campaigns/" + this.state.currentThread.campaign.id}>{this.state.currentThread.campaign.title}</Link></strong>' camapign from <strong><Link to={"/users/" + this.state.currentThread.user.id}>{this.state.currentThread.user.name} {this.state.currentThread.user.surname}</Link></strong>

                                <button style={{ float: "right" }} onClick={() => { this.markAsUnread(this.state.currentThread.id); }} disabled={this.state.loading} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Mark as unread</button>

                            </CardBody>
                            </Card>
                        }

                        {this.state.currentThread && this.state.currentThread.messageThreadType === "vehicle" &&
                            <Card>
                                <CardBody>
                                    <FontAwesomeIcon icon={faCar} /> Conversation about the vehicle '<strong><Link to={"/vehicles/" + this.state.currentThread.vehicle.id}>{this.state.currentThread.vehicle.make} {this.state.currentThread.vehicle.model} - {this.state.currentThread.vehicle.licensePlate}</Link></strong>' with <strong><Link to={"/users/" + this.state.currentThread.user.id}>{this.state.currentThread.user.name} {this.state.currentThread.user.surname}</Link></strong>

                                <button style={{ float: "right" }} onClick={() => { this.markAsUnread(this.state.currentThread.id); }} disabled={this.state.loading} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Mark as unread</button>

                            </CardBody>
                            </Card>
                        }

                        {this.state.currentThread && this.state.currentThread.messageThreadType === "booking" &&
                            <Card>
                                <CardBody>
                                    <i className="feather feather icon-calendar"></i> Conversation about booking on
                                    <strong> <Link to={"/bookings/" + this.state.currentThread.booking.id}><Moment utc local format="ddd, DD/MM" date={this.state.currentThread.booking.start} />: <Moment utc local format="h:mma" date={this.state.currentThread.booking.start} /> - <Moment utc local format="h:mma" date={this.state.currentThread.booking.end} /></Link></strong> with
                                    <strong> <Link to={"/users/" + this.state.currentThread.user.id}>{this.state.currentThread.user.name} {this.state.currentThread.user.surname}</Link></strong>


                                    <button style={{ float: "right" }} onClick={() => { this.markAsUnread(this.state.currentThread.id); }} disabled={this.state.loading} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Mark as unread</button>

                                </CardBody>
                            </Card>
                        }

                        {this.state.currentThread && this.state.currentThread.messageThreadType === "offer" &&
                            <Card>
                                <CardBody>
                                    <i className="feather feather icon-tag"></i> Conversation about the offer '<strong><Link to={"/partner-offers/" + this.state.currentThread.partnerOffer.id}>{this.state.currentThread.partnerOffer.name}</Link></strong>' with <strong><Link to={"/users/" + this.state.currentThread.user.id}>{this.state.currentThread.user.name} {this.state.currentThread.user.surname}</Link></strong>

                                    <button style={{ float: "right" }} onClick={() => { this.markAsUnread(this.state.currentThread.id); }} disabled={this.state.loading} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Mark as unread</button>

                                </CardBody>
                            </Card>
                        }

                    </div>

                    <Row>
                        <Col className={persistedState.currentThreadId && "col-md-3"} style={{ minHeight: "300px", height: "calc(100vh - 450px)" }}>
                            <Card style={{ height: "100%", overflowY: "scroll" }}>
                                <CardBody className="p-0">
                                    {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                                </CardBody>
                            </Card>
                        </Col>
                        {persistedState.currentThreadId && <Col>
                            <div id="convo-card" className="card" style={{ minHeight: "300px", height: "calc(100vh - 450px)" }}>
                                <div id="chat-container" className="card-body" style={{ height: "100%", overflowY: "scroll" }}>
                                    <div className="h-list-body">
                                        {this.state.messages && this.state.messages.map(message =>
                                            <div className={message.direction}>
                                                <p className={"chat-message " + message.direction} style={{ whiteSpace: "pre-wrap" }}>
                                                    {message.content}
                                                    <br />
                                                    <small><Moment utc local format="ddd DD MMM YY, h:mm a">{message.created}</Moment></small>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Formik
                                    initialValues={{
                                        message: "",
                                    }}
                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                        if (this.state.message) {
                                            var message = this.state.message;

                                            this.setState({
                                                message: ""
                                            });

                                            document.getElementById("message").value = "";

                                            var newMessage = await HengyiMessageClient.Create(message, persistedState.currentThreadId);
                                            var existingMessages = this.state.messages;
                                            existingMessages.push(newMessage.data);

                                            await this.update(true);
                                        }
                                    }}
                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <InputGroup>
                                                <textarea id="message" name="message" component="textarea" className="form-control" style={{ borderRadius: "0" }} rows="3" onChange={this.handleChange} ></textarea>
                                            <ButtonGroup className="input-group-append" style={{ borderRadius: "0" }}>
                                                <button type="submit" className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-mail"></i> Send
                                                    </span>
                                                </button>
                                            </ButtonGroup>
                                        </InputGroup>
                                    </Form>
                                )}</Formik>
                            </div>
                        </Col>}
                    </Row>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take, scroll) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var unreadCount = await HengyiMessageThreadClient.List(new MessageThreadQueryParams()
                .WithUnread("toConcierge")
                .Paginate(0, 1));

            this.setState({
                unreadCount: unreadCount.data.total
            });

            var response = await HengyiMessageThreadClient.List(new MessageThreadQueryParams()
                .Descending()
                .Sort("last-message")
                .Search(persistedState.search)
                .WithApartment(persistedState.apartmentId ? persistedState.apartmentId.value : null)
                .WithUser(persistedState.userId ? persistedState.userId.value : null)
                .WithUnread(persistedState.isUnread)
                .ExcludeOutboundCampaignsWithNoReply()
                .Paginate(skip, take));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total, init: false });
            }

            if (persistedState.currentThreadId) {
                var messages = await HengyiMessageClient.List(new MessageQueryParams()
                    .WithThread(persistedState.currentThreadId)
                    .Ascending()
                    .Sort("created")
                    .Paginate(0, 1000));

                if (this.state.messages.length !== messages.data.data.length)
                    scroll = true;

                this.setState({
                    messages: messages.data.data
                }, () => {
                    //if (scroll) {
                    //    var objDiv = document.getElementById("chat-container");
                    //    objDiv.scrollTop = objDiv.scrollHeight;
                    //}
                });
            }

        }
    }
}
