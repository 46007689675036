import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';
import { HengyiParcelClient } from '../infrastructure/HengyiClient/Parcels';
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class ParcelEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, name: "", description: "", loading: true, authenticated: true, next: false };


        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    apartmentId: "",
                    userId: "",
                    location: this.state.location,
                    numberOfPackages: this.state.numberOfPackages,
                    requestedHandoverDate: this.state.requestedHandoverDate,
                    parcelType: this.state.parcelType,
                    status: this.state.status,
                    requestedHandoverDeliverySlot: this.state.requestedHandoverDeliverySlot
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.apartmentId) {
                        setFieldError("apartmentId", "Please select the apartment this parcel is being delivered to");
                        return;
                    }

                    if (!fields.userId) {
                        setFieldError("userId", "Please select a contact to bill/ notify about this parcel");
                        return;
                    }

                    var updates = [{
                        "op": "replace",
                        "path": "residentNote",
                        "value": this.state.residentNote
                    }, {
                        "op": "replace",
                        "path": "location",
                        "value": fields.location
                    }, {
                        "op": "replace",
                        "path": "description",
                        "value": this.state.description
                    }, {
                        "op": "replace",
                        "path": "parcelType",
                        "value": fields.parcelType
                    }, {
                        "op": "replace",
                        "path": "numberOfPackages",
                        "value": fields.numberOfPackages
                    }];

                    if (fields.apartmentId != null) {
                        if (fields.apartmentId.value) {
                            updates.push({
                                "op": "replace",
                                "path": "apartmentId",
                                "value": fields.apartmentId.value
                            });
                        }
                    }
                    if (fields.userId != null) {
                        if (fields.userId.value) {
                            updates.push({
                                "op": "replace",
                                "path": "userId",
                                "value": fields.userId.value
                            });
                        }
                    } else {
                        updates.push({
                            "op": "replace",
                            "path": "userId",
                            "value": ""
                        });
                    }

                    const response = await fetch((await GetAPIDomain()) + "/parcels/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify(updates)
                    });

                    if (this.state.data.status === "ready" ||
                        this.state.data.status === "requestedDropOff" ||
                        this.state.data.status === "requestedPickUp" ||
                        this.state.data.status === "inTransit") {

                        //Set handover dates + status
                        await HengyiParcelClient.SetExpectedHandover(this.props.match.params.id, fields.requestedHandoverDate, fields.requestedHandoverDeliverySlot, fields.status);
                    }


                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <ApartmentSelector
                                label="Space*"
                                value={values.apartmentId}
                                initialApartmentId={this.state.apartmentId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId}
                                filterUser={values.userId ? values.userId.value : null} />
                        </Col>
                        <Col>
                            <UserSelector
                                label="Primary contact*"
                                value={values.userId}
                                initialUserId={this.state.userId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userId}
                                error={errors.userId}
                                filterApartment={values.apartmentId ? values.apartmentId.value : null} />

                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="location">Location*</label>
                                <select type="select" name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                                    <option value="Front Desk">Front Desk</option>
                                    <option value="Storage">Storage</option>
                                </select>
                                <ErrorMessage name="location" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="parcelType">Type</label>
                                <select type="select" name="parcelType"
                                    value={values.parcelType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.parcelType && touched.parcelType ? ' is-invalid' : '')}>
                                    <option value="parcel">Parcel</option>
                                    <option value="mail">Mail</option>
                                </select>
                                <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <input id="description" name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="residentNote">Note to display to the resident</label>
                                    <input id="residentNote" name="residentNote" type="text" className={'form-control' + (errors.residentNote && touched.residentNote ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="residentNote" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="numberOfPackages">Number of packages</label>
                                <Field name="numberOfPackages" type="number" className={'form-control' + (errors.numberOfPackages && touched.numberOfPackages ? ' is-invalid' : '')} />
                                <ErrorMessage name="numberOfPackages" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    {(this.state.data.status === "ready" ||
                        this.state.data.status === "requestedDropOff" ||
                        this.state.data.status === "requestedPickUp" ||
                        this.state.data.status === "inTransit") && <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="type">Delivery status</label>
                                    <select type="select" name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
                                        <option value="ready">No user preference stated</option>
                                        <option value="requestedDropOff">Take up to my space</option>
                                        <option value="requestedPickUp">Resident will pick up</option>
                                        <option value="inTransit">In transit</option>
                                    </select>
                                    <ErrorMessage name="status" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="requestedHandoverDate">Delivery date</label>

                                        <DatePicker id="requestedHandoverDate"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.requestedHandoverDate}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            setFieldValue("requestedHandoverDate", v);
                                        }} />

                                    {touched.requestedHandoverDate && errors.requestedHandoverDate ? <p className="error-text">{errors.requestedHandoverDate}</p> : null}
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="type">Delivery slot</label>
                                    <select type="select" name="requestedHandoverDeliverySlot"
                                        value={values.requestedHandoverDeliverySlot}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.requestedHandoverDeliverySlot && touched.requestedHandoverDeliverySlot ? ' is-invalid' : '')}>
                                        <option value="">Any delivery slot</option>
                                        <option value="morning">Morning (before 12)</option>
                                        <option value="afternoon">Afternoon (12-5)</option>
                                        <option value="evening">Evening (after 5)</option>
                                    </select>
                                    <ErrorMessage name="requestedHandoverDeliverySlot" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>}

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/parcels/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update parcel
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update basic parcel information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/parcels/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiParcelClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.apartment)
                    this.setState({ apartmentId: response.data.apartment.id });

                if (response.data.user)
                    this.setState({ userId: response.data.user.id });

                if (response.data.requestedHandoverDate) {
                    this.setState({ requestedHandoverDate: new Date(response.data.requestedHandoverDate) });
                }

                this.setState({
                    loading: false,
                    data: response.data,
                    residentNote: response.data.residentNote,
                    location: response.data.location,
                    description: response.data.description,
                    parcelType: response.data.parcelType,
                    numberOfPackages: response.data.numberOfPackages,
                    status: response.data.status,
                    requestedHandoverDeliverySlot: response.data.requestedHandoverDeliverySlot
                }, () => {
                    document.getElementById("description").value = response.data.description;
                    document.getElementById("residentNote").value = response.data.residentNote;
                });
            }
        }
    }
}
