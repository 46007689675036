import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiChargeClient } from '../infrastructure/HengyiClient/Charges';
import { HengyiBillingCodeClient, BillingCodeQueryParams } from '../infrastructure/HengyiClient/BillingCodes';

export class ChargesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialBillingCode = this.state.billingCodeOptions.filter(type => type.value === this.state.billingCodeId)[0];

            return (<Formik
                initialValues={{
                    description: this.state.description,
                    amount: this.state.amount,
                    billingCodeId: initialBillingCode
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.description) {
                        setFieldError("description", "Please enter a description so the resident knows what this charge is for");
                        return;
                    }

                    var amount = 0;
                    if (this.state.amount)
                        amount = parseFloat(this.state.amount);

                    const response = await HengyiChargeClient.Update(
                        this.props.match.params.id,
                        Math.round(amount * 100),
                        this.state.description,
                        fields.billingCodeId ? fields.billingCodeId.value : null
                    );

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="amount">Amount to charge</label>
                                    <input name="amount" id="amount" type="text" className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="amount" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <input name="description" id="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <SelectField
                                    id="billingCodeId"
                                    name="billingCodeId"
                                    label="Billing code"
                                    placeholder="Select user"
                                    options={this.state.billingCodeOptions}
                                    value={values.billingCodeId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.billingCodeId}
                                    error={errors.billingCodeId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>
                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/charges/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update charge
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the amount or description</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/charges/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiChargeClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    description: response.data.description,
                    amount: response.data.amount / 100,
                    billingCodeId: response.data.billingCode ? response.data.billingCode.id : null,
                    billingCodeOptions: (await HengyiBillingCodeClient.List(new BillingCodeQueryParams().Paginate(0, 10000))).data.data.map(function (item) { return { value: item.id, label: item.name + (item.description ? " (" + item.description + ")" : "") }; }),
                }, () => {
                    document.getElementById("amount").value = response.data.amount ? response.data.amount / 100 : 0;
                    document.getElementById("description").value = response.data.description ? response.data.description : "";
                });
            }
        }
    }
}
