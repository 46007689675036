import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiParkingBankClient, ParkingBankQueryParams } from '../infrastructure/HengyiClient/ParkingBanks';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiCarParkClient } from '../infrastructure/HengyiClient/CarParks';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { CarParkTypeSelector } from '../infrastructure/CarParkTypeSelector';

export class CarParksEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", parkingBankId: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialParkingBank = this.state.parkingBankOptions.filter(type => type.value === this.state.parkingBankId)[0];
            var initialApartment = this.state.apartmentOptions.filter(item => item.value === this.state.apartmentId)[0];
            var initialApartmentLeasedTo = this.state.apartmentOptions.filter(item => item.value === this.state.apartmentLeasedToId)[0];

            return (<Formik
                initialValues={{
                    parkNumber: this.state.parkNumber,
                    description: this.state.description,
                    parkingBankId: initialParkingBank,
                    apartmentId: initialApartment,
                    apartmentLeasedToId: initialApartmentLeasedTo,
                    typeId: this.state.typeId
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    console.log(fields.typeId);

                    var response = await HengyiCarParkClient.Update(this.props.match.params.id,
                        this.state.description, fields.parkingBankId.value, "available", fields.apartmentId ? fields.apartmentId.value : null, fields.apartmentLeasedToId ? fields.apartmentLeasedToId.value : null, this.state.parkNumber, fields.typeId ? fields.typeId.value : null);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="parkNumber">Park Number</label>
                                    <input name="parkNumber" id="parkNumber" type="text" className={'form-control' + (errors.parkNumber && touched.parkNumber ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="parkNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                    <textarea name="description" id="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                        <div className="form-group mt-3">
                            <Row>
                                <Col>

                    <SelectField
                        id="parkingBankId"
                        name="parkingBankId"
                        label="Parking bank"
                        placeholder="Select parking bank"
                        options={this.state.parkingBankOptions}
                        value={values.parkingBankId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.parkingBankId}
                        error={errors.parkingBankId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />

                                </Col>
                                <Col>
                                    <CarParkTypeSelector
                                        label="Type"
                                        value={values.typeId}
                                        initialId={this.state.typeId}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.typeId}
                                        error={errors.typeId} />
                                </Col>
                            </Row>
                        </div>

                    <div className="form-group mt-3">
                        <Row>
                            <Col>
                                <SelectField
                                    id="apartmentId"
                                    name="apartmentId"
                                    label="Space"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentId}
                                    error={errors.apartmentId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                            <Col>
                                <SelectField
                                    id="apartmentLeasedToId"
                                    name="apartmentLeasedToId"
                                    label="Space leased to"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentLeasedToId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentLeasedToId}
                                    error={errors.apartmentLeasedToId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/car-parks/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update car park
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/car-parks/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.parkingBankOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiCarParkClient.Get(this.props.match.params.id);
            var banks = (await HengyiParkingBankClient.List(new ParkingBankQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.parkingBank)
                    this.setState({ parkingBankId: response.data.parkingBank.id });

                var apartments = (await HengyiApartmentClient.List(new ApartmentQueryParams().Paginate(0, 10000))).data.data;

                if (response.data.apartmentOwnedBy) {
                    this.setState({ apartmentId: response.data.apartmentOwnedBy.id });
                }

                if (response.data.apartmentLeasedBy) {
                    this.setState({ apartmentLeasedToId: response.data.apartmentLeasedBy.id });
                }

                this.setState({
                    loading: false,
                    parkingBankOptions: banks.map(function (item) { return { value: item.id, label: item.name }; }),
                    apartmentOptions: apartments.map(function (item) { return { value: item.id, label: item.name }; }),
                    parkNumber: response.data.parkNumber,
                    description: response.data.description,
                    typeId: response.data.type ? response.data.type.id : null
                }, () => {
                    document.getElementById("parkNumber").value = response.data.parkNumber ? response.data.parkNumber : "";
                    document.getElementById("description").value = response.data.description ? response.data.description : "";
                });
            }
        }
    }
}
