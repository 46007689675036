import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class BillingCodeQueryParams extends ListQueryParams {

    WithShowArchived() {
        this.WithParam("showArchivedCodes", true);
        return this;
    }
}

//******************************
// Billing codes
//******************************
export class HengyiBillingCodeClient {

    static async List(params: BillingCodeQueryParams) {

        var query = (await GetAPIDomain()) + "/billing-codes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/billing-codes/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Archive(id: string) {

        var query = (await GetAPIDomain()) + "/billing-codes/" + id + "/archive";

        return await ApiResponse.Create(await fetch(query, {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Restore(id: string) {

        var query = (await GetAPIDomain()) + "/billing-codes/" + id + "/restore";

        return await ApiResponse.Create(await fetch(query, {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Create(name: string, description?: string, externalReference?: string) {

        var request: any = {
            name: name,
            description: description,
            externalReference: externalReference
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/billing-codes/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, name: string, description?: string, externalReference?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/billing-codes/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                    "op": "replace",
                    "path": "description",
                    "value": description
                }, {
                    "op": "replace",
                    "path": "externalReference",
                    "value": externalReference
                }])
        }));
    }
}