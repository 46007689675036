import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class WorkOrderAssignmentQueryParams extends ListQueryParams {

    WithWorkOrder(value: string) {
        this.WithParam("workOrderId", value);
        return this;
    }

    WithContractor(value: string) {
        this.WithParam("contractorId", value);
        return this;
    }
}

export class HengyiWorkOrderAssignmentClient {

    static async List(params: WorkOrderAssignmentQueryParams) {

        var query = (await GetAPIDomain()) + "/work-order-assignments" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-order-assignments/" + id, {
            headers: await GetAPIHeaders()
        }));

    }
}