import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class VehiclesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                make: "",
                model: "",
                licensePlate: "",
                userId: "",
                apartmentId: "",
                colour: "",
                fuelType: "ev",
                wofExpiry: "",
                registrationExpiry: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.apartmentId || !fields.apartmentId.value) {
                    setFieldError("apartmentId", "Please select an apartment");
                    return;
                }

                const response = await HengyiVehicleClient.Create(
                    this.state.colour,
                    fields.fuelType,
                    this.state.licensePlate,
                    this.state.make,
                    this.state.model,
                    fields.wofExpiry,
                    fields.userId ? fields.userId.value : null,
                    fields.apartmentId ? fields.apartmentId.value : null,
                    fields.registrationExpiry);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="make">Make</label>
                                <input name="make" type="text" className={'form-control' + (errors.make && touched.make ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="make" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="model">Model</label>
                                <input name="model" type="text" className={'form-control' + (errors.model && touched.model ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="model" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="licensePlate">License Plate</label>
                                <input name="licensePlate" type="text" className={'form-control' + (errors.licensePlate && touched.licensePlate ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="licensePlate" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="colour">Colour</label>
                                <input name="colour" type="text" className={'form-control' + (errors.colour && touched.colour ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="colour" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="fuelType">Fuel Type</label>
                                <select name="fuelType"
                                    value={values.fuelType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.fuelType && touched.fuelType ? ' is-invalid' : '')}>
                                    <option value="ev" label="Electric" />
                                    <option value="petrol" label="Petrol" />
                                    <option value="diesel" label="Diesel" />
                                    <option value="hybrid" label="Hybrid" />
                                </select>
                                <ErrorMessage name="fuelType" component="div" className="invalid-feedback" />
                            </div>

                            </Col>
                    </Row>

                    <Row>
                        <Col>
                            <ApartmentSelector
                                label="Space*"
                                value={values.apartmentId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId}
                                filterUser={values.userId ? values.userId.value : null} />
                        </Col>
                        <Col>
                            <UserSelector
                                label="Primary contact from the space"
                                value={values.userId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userId}
                                error={errors.userId}
                                filterApartment={values.apartmentId ? values.apartmentId.value : null} />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="wofExpiry">WoF Expiry</label>

                                <DatePicker id="wofExpiry"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.wofExpiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("wofExpiry", v);
                                    }} />
                            </div>
                        </Col> <Col>
                            <div className="form-group">
                                <label htmlFor="registrationExpiry">Registration Expiry</label>

                                <DatePicker id="registrationExpiry"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.registrationExpiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("registrationExpiry", v);
                                    }} />
                            </div>
                        </Col>
                        </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/vehicles/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New vehicle
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a vehicle in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/vehicles" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
