import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, TabContent, TabPane, Spinner, Card, CardHeader, CardFooter, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { GetAssetDomain } from '../infrastructure/Helper';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'react-moment';
import { ActivityTable } from '../Activity/ActivityTable';
import { HengyiTicketClient } from '../infrastructure/HengyiClient/Tickets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { UserLink } from '../Users/UserLink';
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { WorkOrderTable } from '../WorkOrders/WorkOrderTable';
import { userContext } from '../../userContext';

export class TicketDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    async downloadFile(id, name) {

        var link = document.createElement('a');
        link.href = await GetAssetDomain() + "/file/" + id;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        this.update();
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
        this.setState({
            assetUrl: await GetAssetDomain()
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async update() {
        await this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/tickets" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/tickets" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Ticket details</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.location}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/tickets/" + this.state.data.id + "/email"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Email ticket</Link>}
                                        {this.state.data && this.state.data.status === "open" && <Link style={{ float: "right" }} to={"/tickets/" + this.state.data.id + "/assign"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Assign</Link>}
                                        {this.state.data && this.state.data.status === "open" && <Link style={{ float: "right" }} to={"/tickets/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    {!this.state.loading && this.state.data && this.state.data.status === "open" &&
                        <div>
                            <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Has this ticket been resolved?</strong> Mark this ticket as resolved</p>
                                    </Col>
                                    <Col md="2">
                                        <Link style={{ float: "right" }} to={"/tickets/" + this.state.data.id + "/resolve"} className="btn mr-2 mt-2 mb-2 btn-info">Ticket resolved</Link>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        </div>
                    }

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}>
                                        Work Orders
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <div>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <h6>Details</h6>
                                                    <hr />
                                                    <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Type:</td>
                                                            <td>
                                                                {this.state.data.ticketType === "ticket" && <Badge color="primary">Ticket</Badge>}
                                                                {this.state.data.ticketType === "hazard" && <Badge color="danger">Hazard</Badge>}
                                                                {this.state.data.ticketType === "incident" && <Badge color="warning">Incident</Badge>}
                                                            </td>
                                                        </tr>
                                                            <tr>
                                                                <td className="pr-2">Status:</td>
                                                                <td>
                                                                    {this.state.data.status === "open" && <Badge color="primary">Open</Badge>}
                                                                    {this.state.data.status === "resolved" && <Badge color="success">Resolved</Badge>}
                                                                    {this.state.data.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                                    {!this.state.data.status && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Location:</td>
                                                                <td>
                                                                    {this.state.data.location && <span>{this.state.data.location}</span>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Space:</td>
                                                                <td>
                                                                    {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                                    {!this.state.data.apartment && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Floor:</td>
                                                                <td>
                                                                    {this.state.data.floor && <Link key={this.state.data.floor.id + "-view"} to={"/floors/" + this.state.data.floor.id}>{this.state.data.floor.name}</Link>}
                                                                    {!this.state.data.floor && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Type:</td>
                                                                <td>
                                                                    {this.state.data.type && <span>{this.state.data.type.name}</span>}
                                                                    {!this.state.data.type && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Category:</td>
                                                                <td>
                                                                    {this.state.data.category && <span>{this.state.data.category.name}</span>}
                                                                    {!this.state.data.category && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Created by:</td>
                                                                <td>
                                                                    {this.state.data.submittedBy && <UserLink user={this.state.data.submittedBy} />}
                                                                    {!this.state.data.submittedBy && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.submittedBy && <tr>
                                                                <td className="pr-2">Email:</td>
                                                                <td>
                                                                    {this.state.data.submittedBy.email && this.state.data.submittedBy.email}
                                                                    {!this.state.data.submittedBy.email && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            {this.state.data.submittedBy && <tr>
                                                                <td className="pr-2">Mobile:</td>
                                                                <td>
                                                                    {this.state.data.submittedBy.mobile && this.state.data.submittedBy.mobile}
                                                                    {!this.state.data.submittedBy.mobile && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            <tr>
                                                                <td className="pr-2">Assigned to:</td>
                                                                <td>
                                                                    {this.state.data.assignedTo && <span>{this.state.data.assignedTo.name} {this.state.data.assignedTo.surname}</span>}
                                                                    {!this.state.data.assignedTo && <i>Nobody</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Resolved by:</td>
                                                                <td>
                                                                    {this.state.data.resolvedBy && <span>{this.state.data.resolvedBy.name}</span>}
                                                                    {!this.state.data.resolvedBy && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.resolvedBy && <tr>
                                                                <td className="pr-2">Resolution:</td>
                                                                <td>
                                                                    {this.state.data.resolution && <span>{this.state.data.resolution}</span>}
                                                                    {!this.state.data.resolution && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            <tr>
                                                                <td className="pr-2">Description:</td>
                                                                <td>
                                                                    {this.state.data.description && <span>{this.state.data.description}</span>}
                                                                    {!this.state.data.description && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                                <userContext.Consumer>
                                                    {({ user, logoutUser, features }) => {

                                                        return (<>

                                                            {features && (features.includes("hazards") || features.includes("incidents")) && <Col md="4">
                                                                <h6>Health & Safety</h6>
                                                                <hr />

                                                                {features && (features.includes("hazards") || features.includes("incidents")) && !this.state.data.hazard && !this.state.data.incident &&
                                                                    <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Not linked to H&S</strong></p>
                                                                                <p>This ticket has not been converted into a Health & Safety item</p>
                                                                                <div>
                                                                                    <Link style={{ float: "left" }} to={"/tickets/" + this.state.data.id + "/convert-to/hazard"} className="btn mr-2 mt-2 mb-2 btn-info">Convert to Hazard</Link>
                                                                                    <Link style={{ float: "left" }} to={"/tickets/" + this.state.data.id + "/convert-to/incident"} className="btn mr-2 mt-2 mb-2 btn-info">Convert to Incident</Link>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }


                                                                {features && features.includes("hazards") && this.state.data.hazard &&
                                                                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Converted to a Hazard</strong></p>
                                                                                <p>This ticket has been converted into a hazard</p>
                                                                                <div>
                                                                                    <Link style={{ float: "left" }} to={"/hazards/" + this.state.data.hazard.id} className="btn mr-2 mt-2 mb-2 btn-success">View Hazard</Link>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }


                                                                {features && features.includes("incidents") && this.state.data.incident &&
                                                                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Converted to an Incident</strong></p>
                                                                                <p>This ticket has been converted into a incident</p>
                                                                                <div>
                                                                                    <Link style={{ float: "left" }} to={"/incidents/" + this.state.data.incident.id} className="btn mr-2 mt-2 mb-2 btn-success">View Incident</Link>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }

                                                            </Col>}</>)
                                                    }}
                                                </userContext.Consumer>
                                            </Row>
                                        </CardBody>
                                    </Card>


                                    <Card>
                                        <CardBody>
                                            <h6>Images</h6>
                                            <hr />

                                            <Row>
                                                {this.state.data && this.state.data.images && this.state.data.images.length > 0 && this.state.data.images.map(item =>
                                                    <Col style={{ maxWidth: "300px" }}>
                                                        <InnerImageZoom src={this.state.assetUrl + "/image/" + item + ".jpg"} />
                                                    </Col>
                                                )}
                                            </Row>
                                            {(!this.state.data || !this.state.data.images || !this.state.data.images.length > 0) &&
                                                <h4 className="text-muted text-center mt-3"><i>No images uploaded</i></h4>
                                            }

                                        </CardBody>
                                    </Card>

                                    {this.state.data && this.state.data.files && this.state.data.files.length > 0 && <Card>
                                        <CardBody>
                                            <h6>Other files</h6>
                                            <hr />
                                            {this.state.data.files.map(file => <Row style={{ paddingTop: "10px" }}>
                                                <Col>
                                                    <h5>
                                                        {file.fileName}
                                                    </h5>
                                                </Col>
                                                <Col style={{
                                                    textAlign: "right"
                                                }}>
                                                    <button onClick={() => { this.downloadFile(file.id, file.fileName); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                                                </Col>
                                            </Row>)}
                                        </CardBody>
                                    </Card>}

                                </div>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>
                                <Row>
                                    <Col md="4">
                                        <Card>
                                            <CardHeader>
                                                <h5>Send a note</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row className="mt-2">
                                                    <Col>

                                                        <Formik
                                                            initialValues={{
                                                                message: ""
                                                            }}
                                                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {


                                                                var message = document.getElementById("message").value;

                                                                if (!message) {
                                                                    setFieldError("message", "Please specify the message to send");
                                                                    return;
                                                                }



                                                                var response = await HengyiTicketClient.AddTicketMessage(this.props.match.params.id, message);

                                                                if (!response.successful) {
                                                                    response.data.validationErrors.map(error => {
                                                                        setFieldError(error.key, error.message);
                                                                        return {
                                                                            [error.key]: error.message
                                                                        };
                                                                    });

                                                                } else {
                                                                    setFieldValue("message", "");
                                                                    await this.update();
                                                                }
                                                            }}
                                                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                            <Form>
                                                                <div className="form-group">
                                                                    <label htmlFor="message">Note</label>
                                                                        <textarea rows="4" component="textarea" name="message" id="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                                </div>

                                                                <div className="mt-4">
                                                                    <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                        {!isSubmitting && "Add note"}
                                                                        {isSubmitting && <Spinner animation="border" />}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        )}</Formik>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        {this.state.data && this.state.data.messages &&
                                            <>
                                                {this.state.data.messages.map(item =>
                                                    <Card key={item.id}>
                                                        <CardHeader><h5>Note from {item.user.name} {item.user.surname}</h5></CardHeader>
                                                        <CardBody style={{ whiteSpace: "pre-wrap" }}>{item.message}</CardBody>
                                                        <CardFooter>
                                                            <small>Sent on <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></small>
                                                        </CardFooter>
                                                    </Card>
                                                )}
                                            </>}
                                    </Col>
                                </Row>
                            </div>}
                        </TabPane>

                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <ActivityTable title="Ticket activity" description="List all activity for this ticket" entityId={this.props.match.params.id} />}
                        </TabPane>

                        <TabPane tabId="4">
                            {this.state.activeTab === '4' && <WorkOrderTable title="Work orders" description="Work orders created from this ticket" includeNewButton="true" ticketId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>

                    {!this.state.loading && this.state.data && this.state.data.status === "open" &&
                        <div className="alert alert-info" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Ticket invalid?</strong> Cancel this ticket if it was submitted in error.</p>
                                </Col>
                                <Col md="2">
                                    <Link style={{ float: "right" }} to={"/tickets/" + this.props.match.params.id + "/cancel"} className="btn mr-2 mt-2 mb-2 btn-info">Cancel ticket</Link>
                                </Col>
                            </Row>

                        </div>}
                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiTicketClient.Get(id);
            console.log(response);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
