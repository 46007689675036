import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class VehiclesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, licensePlate: "", colour: "", userId: "", fuelType: "", wofExpiry: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    make: this.state.make,
                    model: this.state.model,
                    licensePlate: this.state.licensePlate,
                    colour: this.state.colour,
                    fuelType: this.state.fuelType,
                    wofExpiry: this.state.wofExpiry,
                    registrationExpiry: this.state.registrationExpiry,
                    userId: "",
                    apartmentId: "",
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.apartmentId || !fields.apartmentId.value) {
                        setFieldError("apartmentId", "Please select an apartment");
                        return;
                    }

                    const response = await HengyiVehicleClient.Update(this.props.match.params.id, this.state.colour, fields.fuelType, this.state.licensePlate, this.state.make, this.state.model, fields.wofExpiry, fields.userId ? fields.userId.value : null, fields.apartmentId ? fields.apartmentId.value : null, fields.registrationExpiry);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="make">Make</label>
                                    <input name="make" id="make" type="text" className={'form-control' + (errors.make && touched.make ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="make" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="model">Model</label>
                                    <input name="model" id="model" type="text" className={'form-control' + (errors.model && touched.model ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="model" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="licensePlate">License Plate</label>
                                    <input name="licensePlate" id="licensePlate" type="text" className={'form-control' + (errors.licensePlate && touched.licensePlate ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="licensePlate" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="colour">Colour</label>
                                    <input name="colour" id="colour" type="text" className={'form-control' + (errors.colour && touched.colour ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="colour" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="fuelType">Fuel Type</label>
                                <select name="fuelType"
                                    value={values.fuelType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.fuelType && touched.fuelType ? ' is-invalid' : '')}>
                                    <option value="ev" label="Electric" />
                                    <option value="petrol" label="Petrol" />
                                    <option value="diesel" label="Diesel" />
                                    <option value="hybrid" label="Hybrid" />
                                </select>
                                <ErrorMessage name="fuelType" component="div" className="invalid-feedback" />
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <ApartmentSelector
                                label="Space*"
                                value={values.apartmentId}
                                initialApartmentId={this.state.apartmentId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId}
                                filterUser={values.userId ? values.userId.value : null} />
                        </Col>
                        <Col>
                            <UserSelector
                                label="Primary contact from the space"
                                value={values.userId}
                                initialUserId={this.state.userId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userId}
                                error={errors.userId}
                                filterApartment={values.apartmentId ? values.apartmentId.value : null} />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="wofExpiry">WoF Expiry</label>

                                    <DatePicker id="wofExpiry"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.wofExpiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("wofExpiry", v);
                                    }} />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="registrationExpiry">Registration Expiry</label>

                                    <DatePicker id="registrationExpiry"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.registrationExpiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("registrationExpiry", v);
                                    }} />
                            </div>
                        </Col>
                    </Row>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/vehicles/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update vehicle
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update vehicle information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/vehicles/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiVehicleClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.user)
                    this.setState({ userId: response.data.user.id });

                if (response.data.apartment) {
                    this.setState({ apartmentId: response.data.apartment.id });
                }

                if (response.data.wofExpiry) {
                    this.setState({ wofExpiry: new Date(response.data.wofExpiry) });
                }

                if (response.data.registrationExpiry) {
                    this.setState({ registrationExpiry: new Date(response.data.registrationExpiry) });
                }

                this.setState({
                    loading: false,
                    make: response.data.make,
                    model: response.data.model,
                    licensePlate: response.data.licensePlate,
                    colour: response.data.colour,
                    fuelType: response.data.fuelType
                }, () => {
                    document.getElementById("make").value = response.data.make ? response.data.make : "";
                    document.getElementById("model").value = response.data.model ? response.data.model : "";
                    document.getElementById("licensePlate").value = response.data.licensePlate ? response.data.licensePlate : "";
                    document.getElementById("colour").value = response.data.colour ? response.data.colour : "";
                });
            }
        }
    }
}
