import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class CarParkQueryParams extends ListQueryParams {

    WithParkingBank(value: string) {
        this.WithParam("parkingBankId", value);
        return this;
    }

    WithBuilding(value: string) {
        this.WithParam("buildingId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    IsInUse(value: Boolean) {
        this.WithParam("inUse", value);
        return this;
    }

}

//******************************
// CAR PARKS
//******************************
export class HengyiCarParkClient {

    static async List(params: CarParkQueryParams) {

        var query = (await GetAPIDomain()) + "/car-parks" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-parks/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(parkingBankId: string, description: string, apartmentId?: string, apartmentLeasedToId?: string, parkNumber?: number, typeId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-parks/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "description": description,
                "parkingBankId": parkingBankId,
                "apartmentId": apartmentId,
                "typeId": typeId,
                "apartmentLeasedToId": apartmentLeasedToId,
                "parkNumber": parseInt(parkNumber+"")
            })
        }));
    }

    static async Update(id: string, description: string, parkingBankId: string, status: string, apartmentId?: string, apartmentLeasedToId?: string, parkNumber?: number, typeId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-parks/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "parkNumber",
                "value": parkNumber
            }, {
                "op": "replace",
                "path": "parkingBankId",
                "value": parkingBankId
            }, {
                "op": "replace",
                "path": "status",
                "value": status
            }, {
                "op": "replace",
                "path": "apartmentId",
                "value": apartmentId
                },
                {
                    "op": "replace",
                    "path": "apartmentLeasedToId",
                    "value": apartmentLeasedToId
                },
                {
                    "op": "replace",
                    "path": "typeId",
                    "value": typeId
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/car-parks/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}