import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class InvitationQueryParams extends ListQueryParams {

    WithApartment(value: string): InvitationQueryParams {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithUser(value: string): InvitationQueryParams {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string): InvitationQueryParams {
        this.WithParam("status", value);
        return this;
    }
}

export class HengyiInvitationClient {

    static async List(params: InvitationQueryParams) {

        var query = (await GetAPIDomain()) + "/resident-invitations" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/resident-invitations/" + id, {
            headers: await GetAPIHeaders()
        }));
    }

    static async DeleteInvitation(id: string) {

        var query = (await GetAPIDomain()) + "/resident-invitations/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    //COMPLETE INVITATION
    static async CompleteInvitation(invitationCode: string, email: string, password: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/resident-invitations/complete", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": invitationCode,
                "email": email,
                "password": password
            })
        }));
    }

    //CHECK INVITATION
    static async CheckInvitation(invitationCode: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/resident-invitations/validate", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": invitationCode
            })
        }));
    }

    //CREATE INVITATION
    static async Create(name: string, surname: string, email: string, mobile: string, trust: string, apartmentIds: string[], ApartmentLivingIn: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/resident-invitations", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "email": email,
                "mobile": mobile,
                "trust": trust,
                "apartmentsOwned": apartmentIds ? apartmentIds : [],
                "ApartmentLivingIn": ApartmentLivingIn
            })
        }));
    }

}