import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';

export class TicketCategoriesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, name: "", status: "", description: "", loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    status: this.state.status
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    fields.name = this.state.name;

                    const response = await fetch((await GetAPIDomain()) + "/ticket-categories/" + this.props.match.params.cid, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "name",
                            "value": fields.name
                        }, {
                            "op": "replace",
                                "path": "status",
                                "value": fields.status
                        }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values }) => (
                <Form>

                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                            <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>

                        <div className="form-group">
                            <label htmlFor="type">Ticket Type</label>
                            <input className="form-control" disabled value={this.state.data && this.state.data.name} />
                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                        </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                            <select name="status"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.status && touched.surname ? ' is-invalid' : '')}>
                                <option value="available" label="Active" />
                                <option value="archived" label="Archived" />
                            </select>
                            <ErrorMessage name="status" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/ticket-types/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update ticket category
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the name or status</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/ticket-types/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var responseType = await HengyiApi.GetTicketType(this.props.match.params.id);
            var responseCategory = await HengyiApi.GetTicketCategory(this.props.match.params.cid);

            if (!responseType.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    name: responseCategory.data.name,
                    status: responseCategory.data.status,
                    data: responseType.data
                }, () => {
                    document.getElementById("name").value = responseCategory.data.name ? responseCategory.data.name : "";
                });
            }
        }
    }
}
