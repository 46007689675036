import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import Moment from 'react-moment';
import { HengyiTicketClient, TicketQueryParams } from '../infrastructure/HengyiClient/Tickets';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import FileSaver from 'file-saver';
import { store } from 'react-easy-state';
import { userContext } from '../../userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { UserSelector } from '../infrastructure/UserSelector';
import { TableHeader } from '../infrastructure/TableHeader';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

const persistedState = store({
    search: "", status: "", emailed: "", ticketCategoryId: "", ticketTypeId: "", isAssigned: "", apartmentId: "",
    hideCancelled: true, hideResolved: true, sort: "created", direction: "desc", assignedToId: "",
    skip: 0, take: 50
});

export class Tickets extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);

        this.handleTicketTypeIdChange = this.handleTicketTypeIdChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleEmailedChange = this.handleEmailedChange.bind(this);
        this.handleIsAssignedChange = this.handleIsAssignedChange.bind(this);
        this.handleTicketCategoryIdChange = this.handleTicketCategoryIdChange.bind(this);

        this.handleHideResolved = this.handleHideResolved.bind(this);
        this.handleHideCancelled = this.handleHideCancelled.bind(this);

        this.state = {
            data: [], exporting: false, loading: false, total: 0, skip: 0, take: 50,
            authenticated: true, filterParamsInit: false, apartmentInit: false, userInit: false
        };
    }

    handleHideResolved = (evt) => {
        persistedState.hideResolved = evt.target.checked;
        this.update();
    }

    handleHideCancelled = (evt) => {
        persistedState.hideCancelled = evt.target.checked;
        this.update();
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("reset"))
            this.clear(false);

        persistedState.search = urlParams.get("q") ? urlParams.get("q") : "";
        persistedState.status = urlParams.get("status");
        persistedState.isAssigned = urlParams.get("isAssigned");
        if (urlParams.get("assignedToId")) {
            persistedState.initialAssignedToId = urlParams.get("assignedToId");
        } else if (persistedState.assignedToId) {
            persistedState.initialAssignedToId = persistedState.assignedToId.value;
        } else {

        }
        persistedState.status = urlParams.get("status");

        await this.populateFiterOptions();

        this.setState({
            filterParamsInit: true,
        }, () => this.update());

    }

    handleStatusChange(evt) {
        persistedState.status = evt.target.value;
        this.update();
    }

    handleEmailedChange(evt) {
        persistedState.emailed = evt.target.value;
        this.update();
    }

    handleIsAssignedChange(evt) {
        persistedState.isAssigned = evt.target.value;
        this.update();
    }

    async handleUserIdChange(name, value) {

        persistedState.assignedToId = value;
        if (value) {
            persistedState.initialAssignedToId = value.value;
        } else {
            persistedState.initialAssignedToId = null;
        }

        if (this.state.userInit)
            this.update();
    }

    handleApartmentIdChange(name, value) {

        persistedState.apartmentId = value;

        if (this.state.apartmentInit)
            this.update();

    }

    handleTicketTypeIdChange(name, value) {

        persistedState.ticketTypeId = value;
        this.update();
    }

    handleTicketCategoryIdChange(name, value) {

        persistedState.ticketCategoryId = value;
        this.update();
    }

    renderTable(data) {
        return (
            <div className="table-responsive">
                <table className='table table-linked' aria-labelledby="tabelLabel">

                    <TableHeader
                        sort={persistedState.sort}
                        direction={persistedState.direction}
                        columns={[
                            { name: "", sort: "", static: true },
                            { name: "", sort: "", static: true },
                            { name: "#", sort: "ticketNumber" },
                            { name: "Type", sort: "type.name" },
                            { name: "Category", sort: "category.name" },
                            { name: "Space", sort: "apartment.name" },
                            { name: "Created by", sort: "submittedBy.name" },
                            { name: "Created", sort: "created" },
                            { name: "Assigned to", sort: "assignedTo.name" },
                            { name: "Status", sort: "status" }]}
                        onUpdate={(sort, direction) => {
                            persistedState.sort = sort;
                            persistedState.direction = direction;
                            this.update();
                        }}
                    />
                    <tbody>
                        {data.map(item =>
                            <tr key={item.id}>
                                <td style={{ padding: "5px!important" }} className="text-center">
                                    {item.emailed && <span style={{ position: "relative", top: "12px" }}><FontAwesomeIcon icon={faEnvelope} /></span>}
                                </td>
                                <td>
                                    {item.ticketType === "ticket" && <Badge style={{ position: "relative", top: "12px" }} color="primary">Ticket</Badge>}
                                    {item.ticketType === "hazard" && <Badge style={{ position: "relative", top: "12px" }} color="danger">Hazard</Badge>}
                                    {item.ticketType === "incident" && <Badge style={{ position: "relative", top: "12px" }} color="warning">Incident</Badge>}
                                </td>
                                <td>
                                    <Link key={item.id + "-ticketnumber-view"} to={"/tickets/" + item.id}>
                                        #{item.ticketNumber}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-type-view"} to={"/tickets/" + item.id}>
                                        {item.type && item.type.name}
                                        {!item.type && <i>Not specified</i>}

                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-category-view"} to={"/tickets/" + item.id}>
                                        {item.category && item.category.name}
                                        {!item.category && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-apartment-view"} to={"/tickets/" + item.id}>
                                        {item.apartment && item.apartment.name}
                                        {!item.apartment && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-created-view"} to={"/tickets/" + item.id}>
                                        {item.submittedBy && item.submittedBy.name + " " + item.submittedBy.surname}
                                        {!item.submittedBy && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-created-view"} to={"/tickets/" + item.id}>
                                        {item.created && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>}
                                        {!item.created && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-assignedto-view"} to={"/tickets/" + item.id}>
                                        {item.assignedTo && item.assignedTo.name + " " + item.assignedTo.surname}
                                        {!item.assignedTo && <i>Nobody</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-status-view"} to={"/tickets/" + item.id}>
                                        {item.status === "open" && <Badge color="primary">Open</Badge>}
                                        {item.status === "resolved" && <Badge color="success">Resolved</Badge>}
                                        {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                        {!item.status && <i>Not specified</i>}
                                    </Link>
                                </td>
                            </tr>
                        )}

                        {this.state.data.length === 0 && <tr><td colSpan="10"><h4 className="text-muted text-center mt-3"><i>No tickets to display</i></h4></td></tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <userContext.Consumer>
                        {({ user, logoutUser }) => {
                            return (<Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h5>Tickets ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                            </h5>
                                            <span className="d-block m-t-5 text-muted">List of tickets/ requests submitted.</span>
                                        </Col>
                                        <Col>
                                            <div style={{ paddingRight: "10px" }}>
                                                <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (persistedState.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (persistedState.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                                {user.permissions && user.permissions.includes("tickets.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2" >

                                                    {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                                    {this.state.exporting && <Spinner animation="border" />}

                                                </button>}

                                                {user.permissions && user.permissions.includes("tickets.modify") && <Link style={{ float: "right" }} to="/tickets/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Lodge ticket</Link>}

                                            </div>
                                        </Col>
                                    </Row>

                                </CardHeader>
                            </Card>);
                        }}
                    </userContext.Consumer>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>

                                <Col>
                                    {this.state.filterParamsInit && <UserSelector
                                        placeholder="Assigned to"
                                        value={persistedState.assignedToId}
                                        initialUserId={persistedState.initialAssignedToId}
                                        initComplete={() => {
                                            this.setState({
                                                userInit: true
                                            }, () => this.update())
                                        }}
                                        onChange={this.handleUserIdChange}
                                        filterPermission="tickets.assignable"
                                        hideApartmentSuffix={true}
                                        hideLabel={true} />}
                                </Col>
                                <Col>
                                    <SelectField
                                        id="ticketTypeId"
                                        name="ticketTypeId"
                                        placeholder="Type"
                                        options={this.state.ticketTypeOptions}
                                        value={persistedState.ticketTypeId}
                                        isMulti={false}
                                        onChange={this.handleTicketTypeIdChange}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />
                                </Col>
                                <Col>
                                    <SelectField
                                        id="ticketCategoryId"
                                        name="ticketCategoryId"
                                        placeholder="Category"
                                        options={this.state.ticketCategoryOptions}
                                        value={persistedState.ticketCategoryId}
                                        isMulti={false}
                                        onChange={this.handleTicketCategoryIdChange}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />
                                </Col>

                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="open">Open</option>
                                            <option value="resolved">Resolved</option>
                                            <option value="cancelled">Cancelled</option>
                                        </Input>
                                    </InputGroup>
                                </Col>

                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.emailed} onChange={this.handleEmailedChange} >
                                            <option value="">Emailed status</option>
                                            <option value="true">Emailed</option>
                                            <option value="false">Not emailed</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col></Col>
                                <Col>
                                    <ApartmentSelector
                                        placeholder="Space"
                                        value={persistedState.apartmentId}
                                        initComplete={() => {
                                            this.setState({
                                                apartmentInit: true
                                            }, () => this.update())
                                        }}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <InputGroup>

                                        <Input type="select" name="select" value={persistedState.isAssigned} onChange={this.handleIsAssignedChange} >
                                            <option value="">Assignment status</option>
                                            <option value="true">Assigned</option>
                                            <option value="false">Not assigned</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <DatePicker id="start"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="Start date"
                                        selected={persistedState.startFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            persistedState.startFilter = v;
                                            this.update();
                                        }} />
                                </Col>
                                <Col>
                                    <DatePicker id="end"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="End date"
                                        selected={persistedState.endFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            persistedState.endFilter = v;
                                            this.update();
                                        }} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(persistedState.search || persistedState.emailed || persistedState.status || persistedState.apartmentId || persistedState.assignedToId || persistedState.ticketTypeId || persistedState.ticketCategoryId || persistedState.startFilter || persistedState.endFilter) &&
                                                <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardHeader>

                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideResolved"
                                                type="checkbox"
                                                checked={persistedState.hideResolved}
                                                onChange={this.handleHideResolved} />  Hide resolved tickets
                                        </label>
                                    </li>   <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideCancelled"
                                                type="checkbox"
                                                checked={persistedState.hideCancelled}
                                                onChange={this.handleHideCancelled} />  Hide cancelled tickets
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        persistedState.search = evt.target.value;
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(persistedState.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(persistedState.skip + this.state.take, this.state.take);
    }

    async exportToCSV() {

        this.setState({ exporting: true });

        var assignedToId = "";
        if (persistedState.assignedToId)
            assignedToId = persistedState.assignedToId.value;

        var apartmentId = "";
        if (persistedState.apartmentId)
            apartmentId = persistedState.apartmentId.value;

        var ticketTypeId = "";
        if (persistedState.ticketTypeId)
            ticketTypeId = persistedState.ticketTypeId.value;

        var ticketCategoryId = "";
        if (persistedState.ticketCategoryId)
            ticketCategoryId = persistedState.ticketCategoryId.value;

        var blob = await HengyiTicketClient.Export(new TicketQueryParams()
            .WithStatus(persistedState.status)
            .WithStart(persistedState.startFilter)
            .WithEnd(persistedState.endFilter)
            .WithAssignedTo(assignedToId)
            .WithApartment(apartmentId)
            .WithHideResolved(persistedState.hideResolved ? "true" : "false")
            .WithHideCancelled(persistedState.hideCancelled ? "true" : "false")
            .WithType(ticketTypeId)
            .WithCategory(ticketCategoryId)
            .WithEmailed(persistedState.emailed)
            .WithIsAssigned(persistedState.isAssigned)
            .Paginate(0, this.state.total)
            .Search(persistedState.search));

        FileSaver.saveAs(blob, "tickets.csv");

        this.setState({ exporting: false });
    }

    async clear() {

        persistedState.skip = 0;
        persistedState.status = "";
        persistedState.search = "";
        persistedState.assignedToId = null;
        persistedState.emailed = "";
        persistedState.ticketTypeId = null;
        persistedState.ticketCategoryId = null;
        persistedState.startFilter = "";
        persistedState.endFilter = "";
        persistedState.apartmentId = "";
        persistedState.initialAssignedToId = "";

        this.setState({
            search: ""
        });

        await this.update();

    }

    async update() {

            await this.populateData(persistedState.skip, this.state.take);
    }

    async populateFiterOptions() {

        var ticketTypes = (await HengyiApi.GetTicketTypes(new ListQueryParams().Paginate(0, 10000)))
            .data.data;
        var ticketCategories = (await HengyiApi.GetTicketCategories(new ListQueryParams().Paginate(0, 10000)))
            .data.data;

        this.setState({
            ticketTypeOptions: ticketTypes.map(function (item) { return { value: item.id, label: item.name }; }),
            ticketCategoryOptions: ticketCategories.map(function (item) { return { value: item.id, label: item.name }; }),
        });

    }

    async populateData(skip, take) {
        if (!this.state.loading && this.state.userInit && this.state.apartmentInit) {

            persistedState.skip = skip;
            this.setState({ loading: true, take: take });

            var assignedToId = "";
            if (persistedState.assignedToId)
                assignedToId = persistedState.assignedToId.value;

            var ticketTypeId = "";
            if (persistedState.ticketTypeId)
                ticketTypeId = persistedState.ticketTypeId.value;

            var ticketCategoryId = "";
            if (persistedState.ticketCategoryId)
                ticketCategoryId = persistedState.ticketCategoryId.value;

            var apartmentId = "";
            if (persistedState.apartmentId)
                apartmentId = persistedState.apartmentId.value;

            var response = await HengyiTicketClient.List(new TicketQueryParams()
                .WithStatus(persistedState.status)
                .WithStart(persistedState.startFilter)
                .WithEnd(persistedState.endFilter)
                .WithHideResolved(persistedState.hideResolved ? "true" : "false")
                .WithHideCancelled(persistedState.hideCancelled ? "true" : "false")
                .WithType(ticketTypeId)
                .WithCategory(ticketCategoryId)
                .WithApartment(apartmentId)
                .WithAssignedTo(assignedToId)
                .WithEmailed(persistedState.emailed)
                .WithIsAssigned(persistedState.isAssigned)
                .Sort(persistedState.sort, persistedState.direction)
                .Paginate(skip, take)
                .Search(persistedState.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }
        }
    }

}

Tickets.contextType = userContext;



