import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiCampaignClient } from '../infrastructure/HengyiClient/Campaigns';
import DatePicker from 'react-datepicker';
import { AudienceSelector } from '../Audience/AudienceSelector';
import { PartnerOfferSelector } from '../infrastructure/PartnerOfferSelector';


export class CampaignsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", audiences: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                scheduledSend: "",
                partnerOfferId: "",
                campaignType: "general"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (fields.campaignType === "offer" && !fields.partnerOfferId) {
                    setFieldError("campaignType", "Please select the offer this notification is about");
                    return;
                }

                var response = await HengyiCampaignClient.Create(
                    this.state.title,
                    this.state.content,
                    fields.scheduledSend,
                    this.state.audiences,
                    fields.campaignType === "offer" && fields.partnerOfferId ? fields.partnerOfferId.value : null
                );

                if (fields.sendBehaviour === "now") {
                    await HengyiCampaignClient.Schedule(response.data.id, (new Date()).toISOString());
                } else if (fields.sendBehaviour === "schedule") {
                    await HengyiCampaignClient.Schedule(response.data.id, fields.scheduledSend);
                }

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Card>
                    <CardBody>
                            <h6>Details</h6>
                            <hr />
                            <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                        <input name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="title" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                        <label htmlFor="content">Content</label>
                                        <textarea name="content" type="text" component="textarea" className={'form-control' + (errors.content && touched.content ? ' is-invalid' : '')} onChange={this.handleChange}  ></textarea>
                                    <ErrorMessage name="content" component="div" className="invalid-feedback" />
                                </div>
                                </Col>

                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="campaignType">Type</label>
                                        <Field as="select" name="campaignType"
                                            className={'form-control' + (errors.campaignType && touched.campaignType ? ' is-invalid' : '')}>
                                            <option value="general" label="General" />
                                            <option value="offer" label="Offer" />
                                        </Field>
                                        <ErrorMessage name="campaignType" component="div" className="invalid-feedback" />
                                    </div>

                                    {values.campaignType === "offer" && <div className="form-group">
                                        <PartnerOfferSelector
                                            value={values.partnerOfferId}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.partnerOfferId}
                                            error={errors.partnerOfferId}
                                            filterStatus="published" />
                                    </div>}

                                </Col>
                        </Row>

                    </CardBody>
                </Card>
                    <Card>
                        <CardBody>

                            <div className="form-group">
                                <h6>Audience</h6>
                                <hr />

                                <Row className="mb-2">
                                    <Col>Type</Col>
                                    <Col>Entity</Col>
                                    <Col md="1"></Col>
                                </Row>
                                <hr />
                                {(!this.state.audiences || this.state.audiences.length === 0) && <i>No audiences added yet</i>}
                                {this.state.audiences && this.state.audiences.length > 0 &&
                                    <div>

                                        {this.state.audiences.map((audience, index) =>
                                            <Row>
                                                <Col style={{ textTransform: 'capitalize' }}>
                                                    {audience.type}
                                                </Col>
                                                <Col style={{ textTransform: 'capitalize' }}>
                                                    {audience.entityName && <span>{audience.entityName}</span>}
                                                    {!audience.entityName && <i>Everything</i>}
                                                </Col>
                                                <Col md="1">
                                                    <button className="btn mr-2 btn-outline-dark" onClick={() => {
                                                        console.log("Remove index: " + index);

                                                        this.state.audiences.splice(index, 1);
                                                        this.setState({
                                                            audiences: this.state.audiences
                                                        });

                                                        return false;
                                                    }}>REMOVE</button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>}

                                <hr />

                                <AudienceSelector hideLabels={true} hideUserType={true} initialValues={{
                                    audience: "all",
                                    userType: "any"
                                }} onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    var audiences = this.state.audiences;

                                    var audience = {
                                        type: fields.audience
                                    };

                                    if (fields.userType && fields.userType !== "any")
                                        audience.userType = fields.userType;


                                    if (fields.entityId) {
                                        audience.entityId = fields.entityId.value;
                                        audience.entityName = fields.entityId.label;
                                    }

                                    audiences.push(audience);

                                    this.setState({
                                        audiences: audiences
                                    });

                                }} />

                            </div>

                        </CardBody>
                    </Card>
                <Card>
                    <CardBody>
                        <h6>Scheduling</h6>
                        <hr />

                        <Row>
                            <Col>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="sendBehaviour" id="draft" value="draft" />
                                    <label class="form-check-label" for="draft">Save as draft</label>
                                </div>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="sendBehaviour" id="now" value="now" />
                                    <label class="form-check-label" for="now">Send right away</label>
                                </div>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="sendBehaviour" id="schedule" value="schedule" />
                                    <label class="form-check-label" for="schedule">Schedule a time to send</label>
                                </div>
                            </Col>
                        </Row>
                        {values.sendBehaviour && values.sendBehaviour === "schedule" && <Row className="mt-2">
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="scheduledSend">Scheduled for</label>

                                        <DatePicker id="scheduledSend"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.scheduledSend}
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy hh:mm a'
                                        onChange={(v, f) => {
                                            setFieldValue("scheduledSend", v);
                                        }} />
                                </div>
                            </Col>
                        </Row>}
                    </CardBody>
                </Card>

                <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && <span>
                                {!values.sendBehaviour && "Save"}
                                {values.sendBehaviour && values.sendBehaviour === "draft" && "Save"}
                                {values.sendBehaviour && values.sendBehaviour === "now" && "Send"}
                                {values.sendBehaviour && values.sendBehaviour === "schedule" && "Schedule"}
                            </span>}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/campaigns/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New campaign
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new campaign to send</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/campaigns" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>

                    {this.state.data && this.renderForm()}

                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
