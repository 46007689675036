import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiSchedulesClient } from '../infrastructure/HengyiClient/Schedules';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';

export class ScheduleNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: false,
            loading: true,
            authenticated: true,
            next: false,
            daysOfWeek: [],
            openDate: null,
            closeDate: null,
            weekdays:  ["Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday"]
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    componentDidMount() {
        this.setState({
            loading: false,
            init: true,
            dates: [],
            timeslot: 30,
            capacity: 1
        }, () => {
            document.getElementById("timeslot").value = 30;
            document.getElementById("capacity").value = 1;
        });

    }

    renderForm() {

        const {
            facilityId,
            eventId
        } = this.props;

        return (<Formik
            initialValues={{
                open: "",
                close: "",
                daysOfWeek: "",
                reminderEnabled: false,
                reminderLeadTime: 30,
                specificDates: "false"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!this.state.openDate) {
                    setFieldError("open", "Please specify an opening time");
                    return;
                }

                if (!this.state.closeDate) {
                    setFieldError("close", "Please specify a closing time");
                    return;
                }

                var response = await HengyiSchedulesClient.Create(
                    this.state.name,
                    parseInt(this.state.timeslot) || 30,
                    this.state.daysOfWeek,
                    moment(this.state.openDate).format("HH:mm:ss"),
                    moment(this.state.closeDate).format("HH:mm:ss"),
                    fields.reminderEnabled,
                    this.state.reminderText,
                    fields.reminderLeadTime,
                    parseInt(this.state.capacity) || 1,
                    facilityId,
                    eventId);

                if (fields.specificDates === "true") {
                    await HengyiSchedulesClient.SetSpecificDates(response.data.id, this.state.dates.map(item => {
                        return item.getFullYear() + "-" + (item.getMonth() + 1) + "-" + item.getDate();
                    }));
                }

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, handleChange, setFieldTouched }) => (
                <Form>
                    <h6>Details</h6>
                    <hr />
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="timeslot">Timeslot length (minutes)</label>
                                <Field id="timeslot" name="timeslot" type="number" className={'form-control' + (errors.timeslot && touched.timeslot ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="timeslot" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="capacity">Capacity</label>
                                <Field id="capacity" name="capacity" type="number" className={'form-control' + (errors.capacity && touched.capacity ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="capacity" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={'form-group' + (errors.open && touched.open ? ' is-invalid' : '')}>
                                <label htmlFor="open">Start time</label>
                                <DatePicker
                                    id="open"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    selected={this.state.openDate}
                                    onChange={this.setOpenDate}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="form-control" />
                                <ErrorMessage name="open" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className={'form-group' + (errors.close && touched.close ? ' is-invalid' : '')}>
                                <label htmlFor="close">End time</label>
                                <DatePicker
                                    id="close"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    disable={this.state.openDate == null}
                                    selected={this.state.closeDate}
                                    onChange={this.setCloseDate}
                                    filterTime={this.disableHoursBeforeOpenTime}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="form-control" />
                                <ErrorMessage name="close" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <h6>Dates</h6>
                    <hr />

                    <Row>
                        <Col>
                            <div class="form-check">
                                <Field class="form-check-input" type="radio" name="specificDates" id="specificDatesFalse" value="false" />
                                <label class="form-check-label" for="specificDatesFalse">Any date</label>
                            </div>
                            <div class="form-check">
                                <Field class="form-check-input" type="radio" name="specificDates" id="specificDatesTrue" value="true" />
                                <label class="form-check-label" for="specificDatesTrue">Specific dates</label>
                            </div>
                        </Col>
                    </Row>

                    {values.specificDates === "false" && <Row className="mt-3">
                        <Col>
                            <h6>Days this schedule is valid for</h6>
                            <div className={'form-group' + (errors.daysOfWeek && touched.daysOfWeek ? ' is-invalid' : '')}>
                                <ul className="list-unstyled list-inline">
                                    {this.state.weekdays.map(day =>
                                        <li className="list-inline-item">
                                            <label>
                                                <input name="dayOfWeek"
                                                    type="checkbox"
                                                    value={day}
                                                    onChange={this.updateDayOfWeek} /> {day}
                                            </label>
                                        </li>)}

                                </ul>
                            </div>
                        </Col>
                    </Row>}


                    {values.specificDates === "true" && <Row className="mt-3">
                        <Col>
                            <h6>Select a date to add it</h6>

                            <DatePicker id="scheduleDate"
                                onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                autoComplete="off"
                                className="form-control"
                                dateFormat='dd/MM/yyyy'
                                onChange={(v, f) => {
                                    var dates = this.state.dates;
                                    dates.push(v);
                                    dates.sort((a, b) => {
                                        return a - b;
                                    });
                                    this.setState({
                                        dates: dates
                                    });
                                }} />

                            <h6 className="mt-2">Dates this schedule is valid for</h6>
                            <div className="mt-2">
                                <ul>
                                    {this.state.dates.length === 0 && <i>No dates added</i>}
                                    {this.state.dates.map((date, index) =>
                                        <li>
                                            <label>
                                                <Moment utc local format="ddd DD MMM YY">{date}</Moment> <small className="clickable" onClick={() => {
                                                    
                                                    this.state.dates.splice(index, 1);
                                                    this.setState({
                                                        dates: this.state.dates
                                                    });

                                                    return false;
                                                }}>[remove]</small>
                                            </label>
                                        </li>)}

                                </ul>
                            </div>
                        </Col>
                    </Row>}

                    <h6 className="mt-3">Reminder settings</h6>
                    <hr />
                    <Row>
                        <Col>
                            <div className="form-group">
                                <ul className="list-unstyled">
                                    <li>
                                        <label>
                                            <Field name="reminderEnabled" type="checkbox" /> Reminder enabled
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {values.reminderEnabled && <Row>

                        <Col>
                            <div className="form-group">
                                <label htmlFor="reminderText">Reminder text</label>
                                <Field name="reminderText" type="text" className={'form-control' + (errors.reminderText && touched.reminderText ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="reminderText" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="reminderLeadTime">Reminder lead time (minutes)</label>
                                <Field name="reminderLeadTime" type="number" className={'form-control' + (errors.reminderLeadTime && touched.reminderLeadTime ? ' is-invalid' : '')} />
                                <ErrorMessage name="reminderLeadTime" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>}

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
        )}</Formik>);
    }

    render() {

        const {
            facilityId,
            eventId
        } = this.props;

        if (this.state.next === true) {
            return (<div>
                {facilityId && <Redirect  to={"/facilities/" + facilityId} />}
                {eventId && <Redirect to={"/events/" + eventId} />}
            </div>);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New schedule
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new schedule</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {facilityId && <Link style={{ float: "right" }} to={"/facilities/" + facilityId} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>}
                                        {eventId && <Link style={{ float: "right" }} to={"/events/" + eventId} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    updateDayOfWeek = e => {
        if (e.target.checked) {
            this.state.daysOfWeek.push(e.target.value);
        }
        else {
            var index = this.state.daysOfWeek.findIndex(i => i === e.target.value);
            this.state.daysOfWeek.splice(index, 1);
        }
    }

    setOpenDate = date => {
        this.setState({
            openDate: date,
            closeTime: null,
        });
    }

    setCloseDate = date => {
        this.setState({
            closeDate: date
        });
    }

    disableHoursBeforeOpenTime = time => {
        return moment(time).isAfter(this.state.openDate, "minutes");
    }
}
