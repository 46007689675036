import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiBillingCodeClient } from '../infrastructure/HengyiClient/BillingCodes';

export class BillingCodesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: false, loading: true, authenticated: true, next: false, errors: {}, isSubmitting: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    async handleSubmit(event) {

        this.setState({
            errors: {},
            isSubmitting: true
        });

        HengyiBillingCodeClient.Update(this.props.match.params.id, this.state.name, this.state.description, this.state.externalReference).then((response) => {

            if (!response.successful) {

                var errors = {};

                response.data.validationErrors.map(error => {
                    errors[error.key] = error.message;
                    return {
                        [error.key]: error.message
                    };
                });

                this.setState({
                    errors: errors
                });

            } else {
                this.setState({ next: true, id: response.data.id });
            }


            this.setState({
                isSubmitting: false
            });

        });

        return false;
    }


    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<form onSubmit={this.handleSubmit}>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input id="name" name="name" type="text" className={'form-control' + (this.state.errors.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                            {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.name}</div>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="externalReference">External Reference</label>
                            <input id="externalReference" name="externalReference" type="text" className="form-control" onChange={this.handleChange} />
                            {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.externalReference}</div>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input id="description" name="description" type="text" component="textarea" className={'form-control' + (this.state.errors.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                            {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.description}</div>}
                        </div>
                    </Col>
                </Row>

                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={this.state.isSubmitting} onClick={this.handleSubmit}>
                        {!this.state.isSubmitting && "SAVE"}
                        {this.state.isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </form>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/billing-codes/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update billing code details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details of this billing code</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/billing-codes/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var entity = await HengyiBillingCodeClient.Get(this.props.match.params.id);

            if (!entity.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

               

                this.setState({
                    data: entity.data,
                    loading: false,
                    init: true
                }, () => {
                     document.getElementById("name").value = entity.data.name;
                    document.getElementById("externalReference").value = entity.data.externalReference;
                    document.getElementById("description").value = entity.data.description;
                });

            }
        }
    }
}
