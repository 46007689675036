import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';

export class ContractorsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, name: "", surname: "", loading: true, authenticated: true, next: false, email: "", mobile: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    surname: this.state.surname,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    company: this.state.company
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    const response = await HengyiContractorClient.Update(this.props.match.params.id, this.state.name, this.state.surname, this.state.email, this.state.mobile, this.state.company);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>

                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="surname">Surname</label>
                                            <input name="surname" id="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input name="company" id="company" type="text" className={'form-control' + (errors.company && touched.company ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="company" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input name="email" id="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile</label>
                                    <input name="mobile" id="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/contractors/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update contractor
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update contractor information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/contractors/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiContractorClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    name: response.data.name,
                    surname: response.data.surname,
                    mobile: response.data.mobile,
                    email: response.data.email,
                    company: response.data.company
                }, () => {
                    document.getElementById("name").value = response.data.name ? response.data.name : "";
                    document.getElementById("surname").value = response.data.surname ? response.data.surname : "";
                    document.getElementById("mobile").value = response.data.mobile ? response.data.mobile : "";
                    document.getElementById("email").value = response.data.email ? response.data.email : "";
                    document.getElementById("company").value = response.data.company ? response.data.company : "";
                });
            }
        }
    }
}
