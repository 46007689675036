import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class InvoiceQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithShowVoided(value: Boolean) {
        this.WithParam("showVoided", value);
        return this;
    }

    WithShowDeleted(value: Boolean) {
        this.WithParam("showDeleted", value);
        return this;
    }
}

//******************************
// INVOICES
//******************************
export class HengyiInvoiceClient {

    static async List(params: InvoiceQueryParams) {

        var query = (await GetAPIDomain()) + "/invoices" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Export(params: InvoiceQueryParams) {

        var query = (await GetAPIDomain()) + "/invoices/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async GetLinks(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/" + id + "/links", {
            headers: await GetAPIHeaders()
        }));

    }

    static async IssueForUser(userId: string, chargeIds: string[]) {

        var request: any = {
            "userId": userId,
            "chargeIds": chargeIds
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/invoices/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/invoices/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/invoices/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}