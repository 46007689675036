import React, { Component } from 'react';
import { HengyiSchedulesClient } from '../infrastructure/HengyiClient/Schedules';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class ScheduleDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            authenticated: true
        };
        this.delete = this.delete.bind(this);
    }

    async delete() {

        const {
            scheduleId
        } = this.props;

        var success = await HengyiSchedulesClient.Delete(scheduleId);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async componentDidMount() {

        const {
            scheduleId
        } = this.props;

        this.populateData(scheduleId);
    }

    render() {

        const {
            scheduleId,
            facilityId,
            eventId
        } = this.props;
        
        if (this.state.next) {
            return (<div>
                {facilityId && <Redirect to={"/facilities/" + facilityId} />}
                {eventId && <Redirect to={"/events/" + eventId} />}
            </div>);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        {facilityId && <Link to={"/facilities/" + facilityId} className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>}
                                        {eventId && <Link to={"/events/" + eventId} className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>}
                                        {!facilityId && !eventId && <Link to="/campaigns" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>}
                                        {this.state.data && <span>{this.state.data.name}</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Schedule details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {facilityId && this.state.data && <Link style={{ float: "right" }} to={"/facilities/" + facilityId + "/schedules/edit/" + scheduleId} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                        {eventId && this.state.data && <Link style={{ float: "right" }} to={"/events/" + eventId + "/schedules/edit/" + scheduleId} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Schedule Details</h6>
                                    <hr />
                                    {this.state.data &&
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Capacity:</td>
                                                    <td>
                                                        {this.state.data.capacity}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Timeslot:</td>
                                                    <td>
                                                        {this.state.data.timeslot}
                                                    </td>
                                            </tr>
                                            {!this.state.data.specificDates && <tr>
                                                <td className="pr-2">Days:</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    {this.state.data.daysOfWeek.length === 0 && <i>None</i>}
                                                    {this.state.data.daysOfWeek.map((item, index) =>
                                                        <span style={{ textTransform: 'capitalize' }}>{item}{index < (this.state.data.daysOfWeek.length - 1) && <span>, </span>}</span>)}
                                                </td>
                                            </tr>}
                                                <tr>
                                                    <td className="pr-2">Open:</td>
                                                    <td>
                                                        {this.state.data.open}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Close:</td>
                                                    <td>
                                                        {this.state.data.close}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Reminder Enabled:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.reminderEnabled.toString()}
                                                    </td>
                                                </tr>
                                                {this.state.data.reminderEnabled && <tr>
                                                    <td className="pr-2">Reminder content:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.reminderText}
                                                    </td>
                                                </tr>}
                                                {this.state.data.reminderEnabled && <tr>
                                                    <td className="pr-2">Reminder lead time:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.reminderLeadTime} minutes
                                                </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    }
                                </Col>

                                {this.state.data && this.state.data.specificDates && <Col>

                                    <h6>Schedule Dates</h6>
                                    <hr />

                                    {(!this.state.data.dates || this.state.data.dates.length === 0) && <i>None</i>}
                                    {(this.state.data.dates && this.state.data.dates.length > 0) && <ul>
                                        {this.state.data.dates.map(item => <li> <Moment utc local format="ddd DD MMM YY">{item}</Moment></li>)}
                                    </ul>}

                                </Col>}


                                {facilityId && <Col>
                                    <h6>Facility Details</h6>
                                    <hr />
                                    {this.state.data &&
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.facility.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Description:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.facility.description}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.facility.status}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Type:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.facility.type}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </Col>}
                                {eventId && <Col>
                                    <h6>Event Details</h6>
                                    <hr />
                                    {this.state.data &&
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {this.state.data.event.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Description:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                    {this.state.data.event.description}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                    {this.state.data.event.status}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </Col>}
                            </Row>
                        </CardBody>
                    </Card>


                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this schedule, all bookings on this schedule will be cancelled.</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/schedules" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete schedule</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This schedule cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiSchedulesClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false,
                });
            }
        }
    }
}
