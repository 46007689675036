import React, { Component } from "react";
import { Spinner, Row, Col } from 'reactstrap';
import { SelectField } from "../infrastructure/SelectField";
import { Formik, Form, ErrorMessage } from 'formik';
import { HengyiFloorClient } from "../infrastructure/HengyiClient/Floors";
import { HengyiApartmentClient } from "../infrastructure/HengyiClient/Apartments";
import { HengyiBuildingClient } from "../infrastructure/HengyiClient/Buildings";
import { HengyiApi, ListQueryParams } from "../infrastructure/fetchHengy";
import { UserSelector } from "../infrastructure/UserSelector";

class AudienceSelector extends Component {

    async componentDidMount() {

        var floors = (await HengyiFloorClient.List(new ListQueryParams().Sort("ordinal", "asc").Paginate(0, 10000))).data.data;
        var apartments = (await HengyiApartmentClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;
        var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;
        var apartmentTypes = (await HengyiApi.GetApartmentTypes(new ListQueryParams().Paginate(0, 10000))).data.data;

        this.setState({
            floorOptions: floors.map(function (item) { return { value: item.id, label: item.name }; }),
            buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
            apartmentOptions: apartments.map(function (item) { return { value: item.id, label: item.name }; }),
            apartmentTypeOptions: apartmentTypes.map(function (item) { return { value: item.id, label: item.name }; })
        });
    }

    render() {
        const {
            onSubmit,
            initialValues,
            hideLabels,
            hideUserType
        } = this.props;

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                    {!hideLabels && <label htmlFor="audience">Audience</label>}
                                <select name="audience"
                                    value={values.audience}
                                        onChange={(target) => {
                                            handleChange(target);
                                            setFieldValue("entityId", null);
                                        }}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.audience && touched.audience ? ' is-invalid' : '')}>
                                    <option value="all" label="All" />
                                    <option value="apartment" label="Space" />
                                    <option value="apartmentType" label="Space type" />
                                    <option value="building" label="Building" />
                                    <option value="floor" label="Floor" />
                                    <option value="user" label="User" />
                                </select>
                                <ErrorMessage name="audience" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                    {values.audience === "all" && <div>
                                        {!hideLabels && <label htmlFor="audience">Subject</label>}
                                        <select name="entityId" disabled="disabled" className="form-control">
                                            <option value="">Everyone</option>
                                        </select>
                                    </div>}

                                    {values.audience === "apartment" && <SelectField
                                        id="entityId"
                                        name="entityId"
                                        placeholder="Select apartment"
                                        options={this.state.apartmentOptions}
                                        value={values.entityId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.entityId}
                                        error={errors.entityId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />}

                                    {values.audience === "apartmentType" && <SelectField
                                        id="entityId"
                                        name="entityId"
                                        placeholder="Select apartment type"
                                        options={this.state.apartmentTypeOptions}
                                        value={values.entityId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.entityId}
                                        error={errors.entityId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />}

                                    {values.audience === "building" && <SelectField
                                        id="entityId"
                                        name="entityId"
                                        placeholder="Select building"
                                        options={this.state.buildingOptions}
                                        value={values.entityId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.entityId}
                                        error={errors.entityId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />}

                                    {values.audience === "floor" && <SelectField
                                        id="entityId"
                                        name="entityId"
                                        placeholder="Select floor"
                                        options={this.state.floorOptions}
                                        value={values.entityId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.entityId}
                                        error={errors.entityId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />}

                                    {values.audience === "user" && <UserSelector
                                        id="entityId"
                                        name="entityId"
                                        placeholder="Select user"
                                        hideLabel={true}
                                        value={values.entityId}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.entityId}
                                        error={errors.entityId} />}

                            </div>
                        </Col>
                            {!hideUserType && <Col>
                                <div className="form-group">
                                    {!hideLabels && <label htmlFor="userType">User type</label>}
                                    <select name="userType"
                                        value={values.userType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.userType && touched.userType ? ' is-invalid' : '')}>
                                        <option value="any" label="Any user type" />
                                        <option value="resident" label="Resident" />
                                        <option value="owner" label="Owner" />
                                    </select>
                                    <ErrorMessage name="userType" component="div" className="invalid-feedback" />
                                </div>
                            </Col>}
                        <Col md="1">
                            <button type="submit" className="btn mr-2 btn-outline-dark" disabled={isSubmitting}>
                                {!isSubmitting && "ADD"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </Col>
                    </Row>
                </Form>
            )}</Formik>
        );
    }

}

export { AudienceSelector };
