import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class TicketQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }
    WithAssignedTo(value: string) {
        this.WithParam("assignedToId", value);
        return this;
    }

    WithType(value: string) {
        this.WithParam("ticketTypeId", value);
        return this;
    }

    WithCategory(value: string) {
        this.WithParam("ticketCategoryId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithEmailed(value: string) {
        this.WithParam("emailed", value);
        return this;
    }

    WithIsAssigned(value: string) {
        this.WithParam("isAssigned", value);
        return this;
    }

    WithHideResolved(value: Boolean) {
        this.WithParam("hideResolved", value);
        return this;
    }

    WithApartment(value: Boolean) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithHideCancelled(value: Boolean) {
        this.WithParam("hideCancelled", value);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiTicketClient {

    static async List(params: TicketQueryParams) {

        var query = (await GetAPIDomain()) + "/tickets" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Export(params: TicketQueryParams) {

        var query = (await GetAPIDomain()) + "/tickets/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }


    static async Update(id: string, ticketTypeId: string, ticketCategoryId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "ticketTypeId",
                "value": ticketTypeId
            }, {
                "op": "replace",
                    "path": "ticketCategoryId",
                    "value": ticketCategoryId
            }])
        }));
    }


    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    //ADD NEW TICKET MESSAGE
    static async AddTicketMessage(ticketId: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + ticketId + "/messages", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        }));
    }

    static async AssignTicket(id: string, userId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id + "/assign", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId
            })
        }));
    }


    static async SendToEmail(id: string, email: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id + "/send-as-email", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "email": email
            })
        }));
    }

    static async SetAsCancelled(id: string, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": notes
            })
        }));
    }

    static async SetAsResolved(id: string, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tickets/" + id + "/resolve", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "resolution": notes
            })
        }));
    }
}