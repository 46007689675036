import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class BookingSlotsQueryParams extends ListQueryParams {
    WithBookingDate(value: string) {
        this.WithParam("bookingDate", value);
        return this;
    }

    WithScheduleId(value: boolean) {
        this.WithParam("scheduleId", value);
        return this;
    }
}

export class HengyiBookingSlotsClient {

    static async List(params: BookingSlotsQueryParams) {

        var query = (await GetAPIDomain()) + "/booking-slots" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }
}