import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';

import { HengyiContractorClient, ContractorQueryParams } from '../infrastructure/HengyiClient/Contractors';
import { TableHeader } from '../infrastructure/TableHeader';

export class Contractors extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", status: "", provider: "", sort: "name", direction: "asc" };
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleProviderChange(evt) {
        this.setState({
            provider: evt.target.value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Name", sort: "name" },
                        { name: "Email", sort: "email" },
                        { name: "Mobile", sort: "mobile" },
                        { name: "Company", sort: "company" },
                        { name: "Provider", sort: "provider" },
                        { name: "Status", sort: "status" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>{item.name} {item.surname}</td>
                            <td>
                                {item.email && item.email}
                                {!item.email && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.mobile && item.mobile}
                                {!item.mobile && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.company && item.company}
                                {!item.company && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.provider === "none" && <Badge color="primary">None</Badge>}
                                {item.provider === "iviva" && <Badge color="info">IVIVA</Badge>}
                            </td>
                            <td>
                                {item.status === "pending" && <Badge color="secondary">Pending</Badge>}
                                {item.status === "approved" && <Badge color="primary">Approved</Badge>}
                                {item.status === "cancelled" && <Badge color="info">Cancelled</Badge>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/contractors/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/contractors/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No contractors to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Contractors ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of contractors in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/contractors/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="cancelled">Cancelled</option>
                                        </Input>
                                    </InputGroup>
                                </Col><Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.provider} onChange={this.handleProviderChange} >
                                            <option value="">Any provider</option>
                                            <option value="none">None</option>
                                            <option value="iviva">IVIVA</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>

                                            <ButtonGroup>
                                                {(this.state.search || this.state.status || this.state.provider) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async clear() {
        this.setState({
            skip: 0,
            status: "",
            search: "",
            provider: ""
        }, async () => {
            await this.update();
        });
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiContractorClient.List(new ContractorQueryParams()
                .WithStatus(this.state.status)
                .WithProvider(this.state.provider)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
