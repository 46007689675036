import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { UserLink } from "../Users/UserLink";
import { UserSelector } from "../infrastructure/UserSelector";
import { TableHeader } from "../infrastructure/TableHeader";
import { GetAssetDomain } from '../infrastructure/Helper';
import { InductionQueryParams, HengyiInductionClient } from "../infrastructure/HengyiClient/Inductions";
import { HengyiApi } from "../infrastructure/fetchHengy";

class InductionsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, showVoided: false,
            search: "",
            status: "", sort: "created", direction: "desc",
            userId: props.userId
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.delete = this.delete.bind(this);
        this.completeInduction = this.completeInduction.bind(this);
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            skip: 0,
            startFilter: "",
            endFilter: "",
            userFilterId: ""
        }, () => this.update());

    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.state.skip, this.state.take);
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleUserIdChange(name, value) {

        this.setState({
            userFilterId: value
        }, () => this.update());
    }

    async delete(userId, documentId) {
        await HengyiInductionClient.Delete(userId, documentId);
        this.update();
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            userId,
            invoiceId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var userFilterId = "";
            if (this.state.userFilterId)
                userFilterId = this.state.userFilterId.value;

            var params = new InductionQueryParams()
                .WithUser(userFilterId)
                .Sort(this.state.sort, this.state.direction);

            if (userId)
                params = params.WithUser(userId);

            if (invoiceId)
                params = params.WithInvoice(invoiceId);

            var response = await HengyiInductionClient.List(params.Paginate(skip, take)
                .Search(this.state.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }

            if (userId) {
                var actions = await HengyiApi.GetUserActions(userId);
                this.setState({
                    actions: actions.data
                });
            }
        }
    }

    async completeInduction() {

        const {
            userId
        } = this.props;

        await HengyiInductionClient.Create(userId);
        this.update();
    }

    render() {
        const {
            title,
            description,
            userId,
            enabledDelete
        } = this.props;

        var columns = [
            { name: "Document", sort: "document.name" },
            { name: "Signed", sort: "created" },
            { name: "Signature", static: true, sort: "created" }];

        if (!userId)
            columns.unshift({ name: "User", sort: "user.name" });

        if (enabledDelete)
            columns.push({ name: "", sort: "", static: true });

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Signed Induction Documents"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List induction documents signed"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                {userId && this.state.actions && <div>

                    {this.state.actions.inductionStatus === "notComplete" &&
                        <div className="alert alert-warning">
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Induction not complete:</strong> This user has not read and agreed to the induction document</p>
                                </Col>
                                <Col md="3">
                                <button style={{ float: "right" }} to="/users" onClick={this.completeInduction} className="btn mr-2 mt-2 mb-2 btn-warning">Mark induction as complete</button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {this.state.actions.inductionStatus === "outOfDate" &&
                        <div className="alert alert-info">
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Induction lapsed:</strong> This user has agreed to an older induction document</p>
                                </Col>
                                <Col md="3">
                                <button style={{ float: "right" }} to="/users" onClick={this.completeInduction} className="btn mr-2 mt-2 mb-2 btn-info">Mark induction as complete</button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {this.state.actions.inductionStatus === "complete" &&
                        <div className="alert alert-success">
                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Induction complete:</strong> This user has read and agreed to the induction document</p>
                        </div>
                    }


                </div>}

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            <Col></Col>

                            {!userId && <Col>
                                <UserSelector
                                    id="userFilterId"
                                    name="userFilterId"
                                    placeholder="Signed by"
                                    value={this.state.userFilterId}
                                    onChange={this.handleUserIdChange}
                                    hideLabel={true} />
                            </Col>}

                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.userFilterId || this.state.startFilter || this.state.endFilter) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>

                        </Row>
                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={columns}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />

                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.user.id + "" + item.document.id}>
                                            {!userId && <td>
                                                {item.user && <UserLink user={item.user} />}
                                                {!item.user && <i>Not specified</i>}
                                            </td>}
                                            <td>
                                                {item.document && item.document.name}
                                                {!item.document && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                <Moment utc local format="ddd, DD/MM h:mma" date={item.created} />
                                            </td>
                                            <td>
                                                {!item.signatureImageId && <i>No image</i>}
                                                {item.signatureImageId && <img alt="Uploaded by submitted" className="rounded-circle" src={this.state.assetUrl + "/image/" + item.signatureImageId + ".png"} style={{ height: "50px", objectFit: "cover" }} />}
                                            </td>
                                            {enabledDelete && <td>
                                                <button style={{ float: "right" }} onClick={() => { this.delete(item.user.id, item.document.id); }} className="btn mr-2 btn-outline-danger mt-2 mb-2">Delete</button>
                                            </td>}
                                        </tr>
                                    )}
                                    {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No induction document agreements to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { InductionsTable };
