import React, { Component } from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { Home } from './components/Home';
import { userContext } from './userContext';
import { SignIn } from './components/SignIn';
import { PrivateRoute } from './components/infrastructure/PrivateRoute';
import { HengyiApi } from './components/infrastructure/fetchHengy';
import { PasswordResetBegin } from './components/pages/PasswordResetBegin';
import { PasswordResetComplete } from './components/pages/PasswordResetComplete';
import { ChangePassword } from './components/pages/ChangePassword';

import { Incidents } from './components/Incidents/Incidents';
import { IncidentsDetails } from './components/Incidents/IncidentsDetails';
import { Hazards } from './components/Hazards/Hazards';
import { HazardsDetails } from './components/Hazards/HazardsDetails';

import { Apartments } from './components/Apartments/Apartments';
import { ApartmentDetails } from './components/Apartments/ApartmentDetails';
import { ApartmentEdit } from './components/Apartments/ApartmentEdit';

import { CarParkTypes } from './components/CarParkTypes/CarParkTypes';
import { CarParkTypesNew } from './components/CarParkTypes/CarParkTypesNew';
import { CarParkTypesEdit } from './components/CarParkTypes/CarParkTypesEdit';
import { CarParkTypesDetails } from './components/CarParkTypes/CarParkTypesDetails';

import { Parcels } from './components/Parcels/Parcels';
import { ParcelNew } from './components/Parcels/ParcelNew';
import { ParcelEdit } from './components/Parcels/ParcelEdit';
import { ParcelDetails } from './components/Parcels/ParcelDetails';
import { ParcelSetCollected } from './components/Parcels/ParcelSetCollected';
import { ParcelSetDroppedOff } from './components/Parcels/ParcelSetDroppedOff';

import { Roles } from './components/Roles/Roles';
import { RolesNew } from './components/Roles/RolesNew';
import { RolesEdit } from './components/Roles/RolesEdit';
import { RoleDetails } from './components/Roles/RoleDetails';

import { Tickets } from './components/Tickets/Tickets';
import { TicketDetails } from './components/Tickets/TicketDetails';
import { TicketCancel } from './components/Tickets/TicketCancel';
import { TicketResolve } from './components/Tickets/TicketResolve';
import { TicketAssign } from './components/Tickets/TicketAssign';
import { TicketEmail } from './components/Tickets/TicketEmail';
import { TicketsEdit } from './components/Tickets/TicketsEdit';
import { TicketsNew } from './components/Tickets/TicketsNew';
import { ConvertTicketToHazard } from './components/Tickets/ConvertTicketToHazard';
import { ConvertTicketToIncident } from './components/Tickets/ConvertTicketToIncident'; 
import { ConvertTicketToWorkRequest } from './components/Tickets/ConvertTicketToWorkRequest'; 

import { Campaigns } from './components/Campaigns/Campaigns';
import { CampaignsDetails } from './components/Campaigns/CampaignsDetails';
import { CampaignsEdit } from './components/Campaigns/CampaignsEdit';
import { CampaignsNew } from './components/Campaigns/CampaignsNew';

import { TicketTypes } from './components/TicketTypes/TicketTypes';
import { TicketTypesNew } from './components/TicketTypes/TicketTypesNew';
import { TicketTypesEdit } from './components/TicketTypes/TicketTypesEdit';
import { TicketTypeDetails } from './components/TicketTypes/TicketTypeDetails';

import { TicketCategoriesNew } from './components/TicketCategories/TicketCategoriesNew';
import { TicketCategoriesEdit } from './components/TicketCategories/TicketCategoriesEdit';

import { Invitations } from './components/Invitation/Invitations';
import { InvitationsNew } from './components/Invitation/InvitationsNew';
import { InvitationDetails } from './components/Invitation/InvitationDetails';
import { InvitationEdit } from './components/Invitation/InvitationEdit';

import { Users } from './components/Users/Users';
import { UsersDetails } from './components/Users/UsersDetails';
import { UsersNew } from './components/Users/UsersNew';
import { UsersEdit } from './components/Users/UsersEdit';

import { Documents } from './components/Documents/Documents';
import { DocumentDetails } from './components/Documents/DocumentDetails';
import { DocumentEdit } from './components/Documents/DocumentEdit';
import { DocumentsNew } from './components/Documents/DocumentsNew';

import { Folders } from './components/Folders/Folders';
import { FolderDetails } from './components/Folders/FolderDetails';
import { FolderEdit } from './components/Folders/FolderEdit';
import { FolderNew } from './components/Folders/FolderNew';

import { ApartmentTypes } from './components/ApartmentTypes/ApartmentTypes';
import { ApartmentTypesDetails } from './components/ApartmentTypes/ApartmentTypesDetails';
import { ApartmentTypesEdit } from './components/ApartmentTypes/ApartmentTypesEdit';
import { ApartmentTypesNew } from './components/ApartmentTypes/ApartmentTypesNew';

import { BillingCodes } from './components/BillingCodes/BillingCodes';
import { BillingCodesDetails } from './components/BillingCodes/BillingCodesDetails';
import { BillingCodesEdit } from './components/BillingCodes/BillingCodesEdit';
import { BillingCodesNew } from './components/BillingCodes/BillingCodesNew';

import { Vehicles } from './components/Vehicles/Vehicles';
import { VehiclesDetails } from './components/Vehicles/VehiclesDetails';
import { VehiclesEdit } from './components/Vehicles/VehiclesEdit';
import { VehiclesNew } from './components/Vehicles/VehiclesNew';

import { Notices } from './components/Notices/Notices';
import { NoticesDetails } from './components/Notices/NoticesDetails';
import { NoticesEdit } from './components/Notices/NoticesEdit';
import { NoticesNew } from './components/Notices/NoticesNew';

import { AutomatedChargeTypes } from './components/AutomatedChargeTypes/AutomatedChargeTypes';
import { AutomatedChargeTypesDetails } from './components/AutomatedChargeTypes/AutomatedChargeTypesDetails';
import { AutomatedChargeTypesEdit } from './components/AutomatedChargeTypes/AutomatedChargeTypesEdit';

import { Contractors } from './components/Contractors/Contractors';
import { ContractorsDetails } from './components/Contractors/ContractorsDetails';
import { ContractorsEdit } from './components/Contractors/ContractorsEdit';
import { ContractorsNew } from './components/Contractors/ContractorsNew';

import { WorkOrders } from './components/WorkOrders/WorkOrders';
import { WorkOrdersDetails } from './components/WorkOrders/WorkOrdersDetails';
import { WorkOrdersEdit } from './components/WorkOrders/WorkOrdersEdit';
import { WorkOrdersNew } from './components/WorkOrders/WorkOrdersNew';
import { WorkOrdersAssign } from './components/WorkOrders/WorkOrdersAssign';

import { HandoverNotes } from './components/HandoverNotes/HandoverNotes';
import { HandoverNotesNew } from './components/HandoverNotes/HandoverNotesNew';
import { HandoverNotesDetails } from './components/HandoverNotes/HandoverNotesDetails';
import { HandoverNotesEdit } from './components/HandoverNotes/HandoverNotesEdit';
import { HandoverNotesAssign } from './components/HandoverNotes/HandoverNotesAssign';

import { Pets } from './components/Pets/Pets';
import { PetsDetails } from './components/Pets/PetsDetails';
import { PetsEdit } from './components/Pets/PetsEdit';
import { PetsNew } from './components/Pets/PetsNew';

import { ParkedVehicles } from './components/ParkedVehicles/ParkedVehicles';
import { ParkedVehiclesDetails } from './components/ParkedVehicles/ParkedVehiclesDetails';
import { ParkedVehiclesNew } from './components/ParkedVehicles/ParkedVehiclesNew';
import { ParkedVehiclesEdit } from './components/ParkedVehicles/ParkedVehiclesEdit';
import { ParkedVehicleCancel } from './components/ParkedVehicles/ParkedVehicleCancel';
import { ParkedVehicleCollected } from './components/ParkedVehicles/ParkedVehicleCollected';

import { ValetRequests } from './components/ValetRequests/ValetRequests';
import { ValetRequestsNew } from './components/ValetRequests/ValetRequestsNew';

import { ParkingBanks } from './components/ParkingBanks/ParkingBanks';
import { ParkingBanksDetails } from './components/ParkingBanks/ParkingBanksDetails';
import { ParkingBanksEdit } from './components/ParkingBanks/ParkingBanksEdit';
import { ParkingBanksNew } from './components/ParkingBanks/ParkingBanksNew';

import { CarParks } from './components/CarParks/CarParks';
import { CarParksDetails } from './components/CarParks/CarParksDetails';
import { CarParksEdit } from './components/CarParks/CarParksEdit';
import { CarParksNew } from './components/CarParks/CarParksNew';

import { StorageUnits } from './components/StorageUnits/StorageUnits';
import { StorageUnitsDetails } from './components/StorageUnits/StorageUnitsDetails';
import { StorageUnitsEdit } from './components/StorageUnits/StorageUnitsEdit';
import { StorageUnitsNew } from './components/StorageUnits/StorageUnitsNew';

import { Buildings } from './components/Buildings/Buildings';
import { BuildingsDetails } from './components/Buildings/BuildingsDetails';
import { BuildingsEdit } from './components/Buildings/BuildingsEdit';

import { Floors } from './components/Floors/Floors';
import { FloorsDetails } from './components/Floors/FloorsDetails';
import { FloorsEdit } from './components/Floors/FloorsEdit';

import { Facilities } from './components/Facilities/Facilities';
import { FacilityNew } from './components/Facilities/FacilityNew';
import { FacilityDetails } from './components/Facilities/FacilityDetails';
import { FacilityEdit } from './components/Facilities/FacilityEdit';

import { FacilityScheduleNew } from './components/Schedules/FacilityScheduleNew';
import { EventScheduleNew } from './components/Schedules/EventScheduleNew';
import { FacilityScheduleDetails } from './components/Schedules/FacilityScheduleDetails';
import { FacilityScheduleEdit } from './components/Schedules/FacilityScheduleEdit';
import { EventScheduleDetails } from './components/Schedules/EventScheduleDetails';
import { EventScheduleEdit } from './components/Schedules/EventScheduleEdit';

import { Conversations } from './components/Conversations/Conversations';
import { ConversationsNew } from './components/Conversations/ConversationsNew';
import { ConversationsBookingsNew } from './components/Conversations/ConversationsBookingsNew';

import { Events } from './components/Events/Events';
import { EventsNew } from './components/Events/EventsNew';
import { EventsEdit } from './components/Events/EventsEdit';
import { EventsDetails } from './components/Events/EventsDetails';

import { SwipeCards } from './components/SwipeCards/SwipeCards';
import { SwipeCardsNew } from './components/SwipeCards/SwipeCardsNew';
import { SwipeCardsDetails } from './components/SwipeCards/SwipeCardsDetails';
import { SwipeCardsEdit } from './components/SwipeCards/SwipeCardsEdit';

import { Charges } from './components/Charges/Charges';
import { ChargesNew } from './components/Charges/ChargesNew';
import { ChargesEdit } from './components/Charges/ChargesEdit';
import { ChargesDetails } from './components/Charges/ChargesDetails';

import { Invoices } from './components/Invoices/Invoices';
import { InvoicesNew } from './components/Invoices/InvoicesNew';
import { InvoicesEdit } from './components/Invoices/InvoicesEdit';
import { InvoicesDetails } from './components/Invoices/InvoicesDetails';

import { Inductions } from './components/Induction/Inductions';

import { EVChargingSessions } from './components/EVCharging/EVChargingSessions';

import { Bookings } from './components/Bookings/Bookings';
import { BookingNew } from './components/Bookings/BookingNew';
import { BookingsEdit } from './components/Bookings/BookingsEdit';
import { BookingDetails } from './components/Bookings/BookingDetails';


import { PartnerOfferCategories } from './components/PartnerOfferCategories/PartnerOfferCategories';
import { PartnerOfferCategoriesNew } from './components/PartnerOfferCategories/PartnerOfferCategoriesNew';
import { PartnerOfferCategoriesEdit } from './components/PartnerOfferCategories/PartnerOfferCategoriesEdit';
import { PartnerOfferCategoriesDetails } from './components/PartnerOfferCategories/PartnerOfferCategoriesDetails';


import { PartnerOffers } from './components/PartnerOffers/PartnerOffers';
import { PartnerOffersNew } from './components/PartnerOffers/PartnerOffersNew';
import { PartnerOfferEdit } from './components/PartnerOffers/PartnerOfferEdit';
import { PartnerOffersDetails } from './components/PartnerOffers/PartnerOffersDetails';

import { Features } from './components/Features/Features';
import { Tenants } from './components/Tenants/Tenants';
import { TenantsEdit } from './components/Tenants/TenantsEdit';
import { BrandingImageUpload } from './components/Tenants/BrandingImageUpload';
import { BrandingImageDetails } from './components/Tenants/BrandingImageDetails';
import { BrandingImageEdit } from './components/Tenants/BrandingImageEdit';

import './custom.css'
import { HengyiFeatureClient, FeatureQueryParams } from './components/infrastructure/HengyiClient/Features';
import { HengyiTenantClient } from './components/infrastructure/HengyiClient/Tenants';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            user: {
                authenticated: false,
                permissions: []
            },
            features: []
        };
        this.logout = this.logout.bind(this);
        this.refreshApplicationState = this.refreshApplicationState.bind(this);
    }

    logout() {
        this.setState({
            user: {
                authenticated: false,
                permissions: []
            },
            features: []
        });
        HengyiApi.LogOut();
    }

    async refreshApplicationState() {

        var user = await HengyiApi.GetCurrentUser();
        var permissions = await HengyiApi.GetPermissionsForCurrentUser();
        var features = await HengyiFeatureClient.List(new FeatureQueryParams());
        var tenant = await HengyiTenantClient.Get();

        if (user && permissions.authenticated) {
            this.setState({
                user: {
                    id: user.id,
                    name: user.name,
                    surname: user.surname,
                    mobile: user.mobile,
                    email: user.email,
                    authenticated: true,
                    permissions: permissions.data.data.map(p => p.id)
                },
                features: features.data.data.filter(f => f.enabled).map(p => p.id),
                tenant: tenant.data
            });
        } else {
            this.setState({
                user: {
                    authenticated: false,
                    permissions: []
                },
                features: [],
                tenant: null
            });
        }
    }

    async componentDidMount() {
        this.refreshApplicationState();
        document.title = "Portal"
    }

    render() {

        const value = {
            user: this.state.user,
            logoutUser: this.logout,
            refresh: this.refreshApplicationState,
            features: this.state.features,
            tenant: this.state.tenant
        }

        return (
            <userContext.Provider value={value}>
                <Layout>
                    <Switch>
                        <PrivateRoute exact path='/' component={Home} />

                        <PrivateRoute exact path='/incidents' component={Incidents} />
                        <PrivateRoute exact path='/incidents/:id' component={IncidentsDetails} />
                        <PrivateRoute exact path='/hazards' component={Hazards} />
                        <PrivateRoute exact path='/hazards/:id' component={HazardsDetails} />

                        <PrivateRoute exact path='/apartments' component={Apartments} />
                        <PrivateRoute exact path='/apartments/edit/:id' component={ApartmentEdit} />
                        <PrivateRoute exact path='/apartments/:id' component={ApartmentDetails} />

                        <PrivateRoute exact path='/roles' component={Roles} />
                        <PrivateRoute exact path='/roles/new' component={RolesNew} />
                        <PrivateRoute exact path='/roles/edit/:id' component={RolesEdit} />
                        <PrivateRoute exact path='/roles/:id' component={RoleDetails} />

                        <PrivateRoute exact path='/vehicles' component={Vehicles} />
                        <PrivateRoute exact path='/vehicles/new' component={VehiclesNew} />
                        <PrivateRoute exact path='/vehicles/edit/:id' component={VehiclesEdit} />
                        <PrivateRoute exact path='/vehicles/:id' component={VehiclesDetails} />

                        <PrivateRoute exact path='/contractors' component={Contractors} />
                        <PrivateRoute exact path='/contractors/new' component={ContractorsNew} />
                        <PrivateRoute exact path='/contractors/edit/:id' component={ContractorsEdit} />
                        <PrivateRoute exact path='/contractors/:id' component={ContractorsDetails} />

                        <PrivateRoute exact path='/work-orders' component={WorkOrders} />
                        <PrivateRoute exact path='/work-orders/new' component={WorkOrdersNew} />
                        <PrivateRoute exact path='/work-orders/edit/:id' component={WorkOrdersEdit} />
                        <PrivateRoute exact path='/work-orders/:id' component={WorkOrdersDetails} />
                        <PrivateRoute exact path='/work-orders/:id/assign' component={WorkOrdersAssign} />

                        <PrivateRoute exact path='/pets' component={Pets} />
                        <PrivateRoute exact path='/pets/new' component={PetsNew} />
                        <PrivateRoute exact path='/pets/edit/:id' component={PetsEdit} />
                        <PrivateRoute exact path='/pets/:id' component={PetsDetails} />

                        <PrivateRoute exact path='/parked-vehicles' component={ParkedVehicles} />
                        <PrivateRoute exact path='/parked-vehicles/new' component={ParkedVehiclesNew} />
                        <PrivateRoute exact path='/parked-vehicles/edit/:id' component={ParkedVehiclesEdit} />
                        <PrivateRoute exact path='/parked-vehicles/:id' component={ParkedVehiclesDetails} />
                        <PrivateRoute exact path='/parked-vehicles/:id/cancel' component={ParkedVehicleCancel} />
                        <PrivateRoute exact path='/parked-vehicles/:id/collected' component={ParkedVehicleCollected} />

                        <PrivateRoute exact path='/valet-requests' component={ValetRequests} />
                        <PrivateRoute exact path='/valet-requests/new' component={ValetRequestsNew} />

                        <PrivateRoute exact path='/parcels' component={Parcels} />
                        <PrivateRoute exact path='/parcels/new' component={ParcelNew} />
                        <PrivateRoute exact path='/parcels/edit/:id' component={ParcelEdit} />
                        <PrivateRoute exact path='/parcels/:id' component={ParcelDetails} />
                        <PrivateRoute exact path='/parcels/:id/set-collected' component={ParcelSetCollected} />
                        <PrivateRoute exact path='/parcels/:id/set-dropped-off' component={ParcelSetDroppedOff} />

                        <PrivateRoute exact path='/invitations' component={Invitations} />
                        <PrivateRoute exact path='/invitations/new' component={InvitationsNew} />
                        <PrivateRoute exact path='/invitations/edit/:id' component={InvitationEdit} />
                        <PrivateRoute exact path='/invitations/:id' component={InvitationDetails} />

                        <PrivateRoute exact path='/users' component={Users} />
                        <PrivateRoute exact path='/users/new' component={UsersNew} />
                        <PrivateRoute exact path='/users/edit/:id' component={UsersEdit} />
                        <PrivateRoute exact path='/users/:id' component={UsersDetails} />

                        <PrivateRoute exact path='/notices' component={Notices} />
                        <PrivateRoute exact path='/notices/new' component={NoticesNew} />
                        <PrivateRoute exact path='/notices/edit/:id' component={NoticesEdit} />
                        <PrivateRoute exact path='/notices/:id' component={NoticesDetails} />

                        <PrivateRoute exact path='/documents' component={Documents} />
                        <PrivateRoute exact path='/documents/new' component={DocumentsNew} />
                        <PrivateRoute exact path='/documents/edit/:id' component={DocumentEdit} />
                        <PrivateRoute exact path='/documents/:id' component={DocumentDetails} />

                        <PrivateRoute exact path='/folders' component={Folders} />
                        <PrivateRoute exact path='/folders/new' component={FolderNew} />
                        <PrivateRoute exact path='/folders/edit/:id' component={FolderEdit} />
                        <PrivateRoute exact path='/folders/:id' component={FolderDetails} />

                        <PrivateRoute exact path='/handover-notes' component={HandoverNotes} />
                        <PrivateRoute exact path='/handover-notes/new' component={HandoverNotesNew} />
                        <PrivateRoute exact path='/handover-notes/edit/:id' component={HandoverNotesEdit} />
                        <PrivateRoute exact path='/handover-notes/:id' component={HandoverNotesDetails} />
                        <PrivateRoute exact path='/handover-notes/:id/assign' component={HandoverNotesAssign} />

                        <PrivateRoute exact path='/tickets' component={Tickets} />
                        <PrivateRoute exact path='/tickets/new' component={TicketsNew} />
                        <PrivateRoute exact path='/tickets/edit/:id' component={TicketsEdit} />
                        <PrivateRoute exact path='/tickets/:id' component={TicketDetails} />
                        <PrivateRoute exact path='/tickets/:id/cancel' component={TicketCancel} />
                        <PrivateRoute exact path='/tickets/:id/resolve' component={TicketResolve} />
                        <PrivateRoute exact path='/tickets/:id/assign' component={TicketAssign} />
                        <PrivateRoute exact path='/tickets/:id/email' component={TicketEmail} />
                        <PrivateRoute exact path='/tickets/:id/convert-to/hazard' component={ConvertTicketToHazard} />
                        <PrivateRoute exact path='/tickets/:id/convert-to/incident' component={ConvertTicketToIncident} />
                        <PrivateRoute exact path='/tickets/:id/convert-to/work-order' component={ConvertTicketToWorkRequest} />

                        <PrivateRoute exact path='/ticket-types' component={TicketTypes} />
                        <PrivateRoute exact path='/ticket-types/new' component={TicketTypesNew} />
                        <PrivateRoute exact path='/ticket-types/edit/:id' component={TicketTypesEdit} />
                        <PrivateRoute exact path='/ticket-types/:id' component={TicketTypeDetails} />

                        <PrivateRoute exact path='/ticket-types/:id/categories/new' component={TicketCategoriesNew} />
                        <PrivateRoute exact path='/ticket-types/:id/categories/edit/:cid' component={TicketCategoriesEdit} />

                        <PrivateRoute exact path='/apartment-types' component={ApartmentTypes} />
                        <PrivateRoute exact path='/apartment-types/new/' component={ApartmentTypesNew} />
                        <PrivateRoute exact path='/apartment-types/edit/:id' component={ApartmentTypesEdit} />
                        <PrivateRoute exact path='/apartment-types/:id' component={ApartmentTypesDetails} />

                        <PrivateRoute exact path='/billing-codes' component={BillingCodes} />
                        <PrivateRoute exact path='/billing-codes/new/' component={BillingCodesNew} />
                        <PrivateRoute exact path='/billing-codes/edit/:id' component={BillingCodesEdit} />
                        <PrivateRoute exact path='/billing-codes/:id' component={BillingCodesDetails} />

                        <PrivateRoute exact path='/parking-banks' component={ParkingBanks} />
                        <PrivateRoute exact path='/parking-banks/new/' component={ParkingBanksNew} />
                        <PrivateRoute exact path='/parking-banks/edit/:id' component={ParkingBanksEdit} />
                        <PrivateRoute exact path='/parking-banks/:id' component={ParkingBanksDetails} />

                        <PrivateRoute exact path='/campaigns' component={Campaigns} />
                        <PrivateRoute exact path='/campaigns/new/' component={CampaignsNew} />
                        <PrivateRoute exact path='/campaigns/edit/:id' component={CampaignsEdit} />
                        <PrivateRoute exact path='/campaigns/:id' component={CampaignsDetails} />

                        <PrivateRoute exact path='/car-parks' component={CarParks} />
                        <PrivateRoute exact path='/car-parks/new/' component={CarParksNew} />
                        <PrivateRoute exact path='/car-parks/edit/:id' component={CarParksEdit} />
                        <PrivateRoute exact path='/car-parks/:id' component={CarParksDetails} />

                        <PrivateRoute exact path='/car-park-types' component={CarParkTypes} />
                        <PrivateRoute exact path='/car-park-types/new/' component={CarParkTypesNew} />
                        <PrivateRoute exact path='/car-park-types/edit/:id' component={CarParkTypesEdit} />
                        <PrivateRoute exact path='/car-park-types/:id' component={CarParkTypesDetails} />

                        <PrivateRoute exact path='/storage-units' component={StorageUnits} />
                        <PrivateRoute exact path='/storage-units/new/' component={StorageUnitsNew} />
                        <PrivateRoute exact path='/storage-units/edit/:id' component={StorageUnitsEdit} />
                        <PrivateRoute exact path='/storage-units/:id' component={StorageUnitsDetails} />

                        <PrivateRoute exact path='/buildings' component={Buildings} />
                        <PrivateRoute exact path='/buildings/edit/:id' component={BuildingsEdit} />
                        <PrivateRoute exact path='/buildings/:id' component={BuildingsDetails} />

                        <PrivateRoute exact path='/floors' component={Floors} />
                        <PrivateRoute exact path='/floors/edit/:id' component={FloorsEdit} />
                        <PrivateRoute exact path='/floors/:id' component={FloorsDetails} />

                        <PrivateRoute exact path='/facilities' component={Facilities} />
                        <PrivateRoute exact path='/facilities/new/' component={FacilityNew} />
                        <PrivateRoute exact path='/facilities/edit/:id' component={FacilityEdit} />
                        <PrivateRoute exact path='/facilities/:id' component={FacilityDetails} />

                        <PrivateRoute exact path='/facilities/:id/schedules/new/' component={FacilityScheduleNew} />
                        <PrivateRoute exact path='/facilities/:id/schedules/edit/:sid' component={FacilityScheduleEdit} />
                        <PrivateRoute exact path='/facilities/:id/schedules/:sid' component={FacilityScheduleDetails} />

                        <PrivateRoute exact path='/events' component={Events} />
                        <PrivateRoute exact path='/events/new' component={EventsNew} />
                        <PrivateRoute exact path='/events/edit/:id' component={EventsEdit} />
                        <PrivateRoute exact path='/events/:id' component={EventsDetails} />

                        <PrivateRoute exact path='/events/:id/schedules/new/' component={EventScheduleNew} />
                        <PrivateRoute exact path='/events/:id/schedules/edit/:sid' component={EventScheduleEdit} />
                        <PrivateRoute exact path='/events/:id/schedules/:sid' component={EventScheduleDetails} />

                        <PrivateRoute exact path='/bookings' component={Bookings} />
                        <PrivateRoute exact path='/bookings/new/' component={BookingNew} />
                        <PrivateRoute exact path='/bookings/edit/:id' component={BookingsEdit} />
                        <PrivateRoute exact path='/bookings/:id' component={BookingDetails} />
                        <PrivateRoute exact path='/bookings/:id/message' component={ConversationsBookingsNew} />

                        <PrivateRoute exact path='/conversations' component={Conversations} />
                        <PrivateRoute exact path='/conversations/new' component={ConversationsNew} />

                        <PrivateRoute exact path='/swipe-cards' component={SwipeCards} />
                        <PrivateRoute exact path='/swipe-cards/new/' component={SwipeCardsNew} />
                        <PrivateRoute exact path='/swipe-cards/edit/:id' component={SwipeCardsEdit} />
                        <PrivateRoute exact path='/swipe-cards/:id' component={SwipeCardsDetails} />

                        <PrivateRoute exact path='/charges' component={Charges} />
                        <PrivateRoute exact path='/charges/new/' component={ChargesNew} />
                        <PrivateRoute exact path='/charges/edit/:id' component={ChargesEdit} />
                        <PrivateRoute exact path='/charges/:id' component={ChargesDetails} />

                        <PrivateRoute exact path='/invoices' component={Invoices} />
                        <PrivateRoute exact path='/invoices/new/' component={InvoicesNew} />
                        <PrivateRoute exact path='/invoices/edit/:id' component={InvoicesEdit} />
                        <PrivateRoute exact path='/invoices/:id' component={InvoicesDetails} />

                        <PrivateRoute exact path='/partner-offer-categories' component={PartnerOfferCategories} />
                        <PrivateRoute exact path='/partner-offer-categories/new/' component={PartnerOfferCategoriesNew} />
                        <PrivateRoute exact path='/partner-offer-categories/edit/:id' component={PartnerOfferCategoriesEdit} />
                        <PrivateRoute exact path='/partner-offer-categories/:id' component={PartnerOfferCategoriesDetails} />

                        <PrivateRoute exact path='/partner-offers' component={PartnerOffers} />
                        <PrivateRoute exact path='/partner-offers/new' component={PartnerOffersNew} />
                        <PrivateRoute exact path='/partner-offers/:id' component={PartnerOffersDetails} />
                        <PrivateRoute exact path='/partner-offers/edit/:id' component={PartnerOfferEdit} />

                        <PrivateRoute exact path='/automated-charge-types' component={AutomatedChargeTypes} />
                        <PrivateRoute exact path='/automated-charge-types/edit/:id' component={AutomatedChargeTypesEdit} />
                        <PrivateRoute exact path='/automated-charge-types/:id' component={AutomatedChargeTypesDetails} />

                        <PrivateRoute exact path='/ev-charge-sessions' component={EVChargingSessions} />
                        <PrivateRoute exact path='/inductions' component={Inductions} />
                        <PrivateRoute exact path='/features' component={Features} />
                        <PrivateRoute exact path='/branding' component={Tenants} />
                        <PrivateRoute exact path='/branding/edit' component={TenantsEdit} />
                        <PrivateRoute exact path='/branding/images/upload' component={BrandingImageUpload} />
                        <PrivateRoute exact path='/branding/images/:id' component={BrandingImageDetails} />
                        <PrivateRoute exact path='/branding/images/edit/:id' component={BrandingImageEdit} />

                        <Route exact path='/sign-in' component={SignIn} />
                        <Route exact path='/password-reset/begin' component={PasswordResetBegin} />
                        <Route exact path='/password-reset/complete/:code' component={PasswordResetComplete} />
                        <PrivateRoute exact path='/change-password' component={ChangePassword} />
                    </Switch>
                </Layout>
            </userContext.Provider>
        );
    }
}
