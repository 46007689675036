import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class FacilityQueryParams extends ListQueryParams {
    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }
}

export class HengyiFacilityClient {

    static async List(params: FacilityQueryParams) {

        var query = (await GetAPIDomain()) + "/facilities" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/facilities/" + id, {
            headers: await GetAPIHeaders()
        }));
    }


    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/facilities/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    static async Create(name: string,
        description: string,
        type: string,
        buildingId: string,
         imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/facilities/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "status": "Available",
                "type": type,
                "buildingId": buildingId,
                "backgroundImageId": imageId
            })
        }));
    }

    static async Update(id: string,
        name: string,
        description: string,
        status: string,
        type: string,
        buildingId: string,
    imageId?:string) {

        var updates = [{
                "op": "replace",
                "path": "name",
                "value": name
        }, {
                "op": "replace",
            "path": "buildingId",
            "value": buildingId
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "status",
                "value": status
            }, {
                "op": "replace",
                "path": "type",
                "value": type
            }, {
                "op": "replace",
                "path": "backgroundImageId",
                "value": imageId
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/facilities/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }
}