import React, { Component } from 'react';
import { ScheduleNew } from './ScheduleNew';

export class FacilityScheduleNew extends Component {

    render() {
        return (
            <ScheduleNew facilityId={this.props.match.params.id} />
            );
    }

}
