import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { GetAssetDomain } from '../infrastructure/Helper';
import { HengyiWorkOrderClient } from '../infrastructure/HengyiClient/WorkOrders';
import { WorkOrderAssignmentTable } from '../WorkOrderAssignments/WorkOrderAssignmentTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import InnerImageZoom from 'react-inner-image-zoom'

export class WorkOrdersDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.close = this.close.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async downloadFile(id, name) {

        var link = document.createElement('a');
        link.href = await GetAssetDomain() + "/file/" + id;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        this.update();
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id); this.setState({
            assetUrl: await GetAssetDomain()
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/work-orders" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/work-orders" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Work order</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.orderNumber}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/work-orders/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                        {this.state.data && this.state.data.status === "open" && <Link style={{ float: "right" }} to={"/work-orders/" + this.state.data.id + "/assign"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Assign</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        History
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <Row><Col>
                                        <h6>Details</h6>
                                        <hr />

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Order number:</td>
                                                    <td>
                                                        {this.state.data.orderNumber && this.state.data.orderNumber}
                                                        {!this.state.data.orderNumber && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">IVIVA Reference:</td>
                                                    <td>
                                                        {this.state.data.externalTicketNumber && this.state.data.externalTicketNumber}
                                                        {!this.state.data.externalTicketNumber && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Subject:</td>
                                                    <td>
                                                        {this.state.data.subject && this.state.data.subject}
                                                        {!this.state.data.subject && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Description:</td>
                                                    <td>
                                                        {this.state.data.description && this.state.data.description}
                                                        {!this.state.data.description && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Contractor:</td>
                                                    <td>
                                                        {this.state.data.assignedTo && <Link key={this.state.data.assignedTo.id + "-view"} to={"/contractors/" + this.state.data.assignedTo.id}>{this.state.data.assignedTo.name} ({this.state.data.assignedTo.surname})</Link>}
                                                        {!this.state.data.assignedTo && <i>Not assigned</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Provider:</td>
                                                    <td>
                                                        {this.state.data.provider === "none" && <Badge color="primary">None</Badge>}
                                                        {this.state.data.provider === "iviva" && <Badge color="info">IVIVA</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td>
                                                        {this.state.data.status === "open" && <Badge color="primary">Open</Badge>}
                                                        {this.state.data.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="pr-2">Problem Type:</td>
                                                    <td>
                                                        {this.state.data.problemType && this.state.data.problemType}
                                                        {!this.state.data.problemType && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Service Category:</td>
                                                    <td>
                                                        {this.state.data.serviceCategoryName && this.state.data.serviceCategoryName}
                                                        {!this.state.data.serviceCategoryName && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Priority:</td>
                                                    <td>
                                                        {this.state.data.priorityName && this.state.data.priorityName}
                                                        {!this.state.data.priorityName && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Location:</td>
                                                    <td>
                                                        {this.state.data.locationFullName && this.state.data.locationFullName}
                                                        {!this.state.data.locationFullName && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                        {this.state.data.ticket && <Col>

                                            <h6>Linked ticket</h6>
                                            <hr />

                                            <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Linked ticket</strong></p>
                                                        <p>This work request was created from a ticket</p>
                                                        <div>
                                                            <Link style={{ float: "left" }} to={"/tickets/" + this.state.data.ticket.id} className="btn mr-2 mt-2 mb-2 btn-success">View Ticket</Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Col>}

                                        {this.state.data.externalId && <Col>

                                            <h6>IVIVA Reference</h6>
                                            <hr />

                                            <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>IVIVA Ticket</strong></p>
                                                        <p>This ticket is managed by the IVIVA system, please update the status there</p>
                                                        <div>
                                                            <a target="_blank" style={{ float: "left" }} href={"https://pacificaobsi.com:2087/Apps/WorkRequest/view?key=" + this.state.data.externalId} className="btn mr-2 mt-2 mb-2 btn-info">View in IVIVA</a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Col>}

                                    </Row>
                                </CardBody>
                            </Card>}

                            {this.state.activeTab === '1' && this.state.data && this.state.data.ticket &&

                                <div>
                                    <Card>
                                        <CardBody>
                                            <h6>Linked ticket details</h6>
                                            <hr />
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td className="pr-2">Status:</td>
                                                        <td>
                                                            {this.state.data.ticket.status === "open" && <Badge color="primary">Open</Badge>}
                                                            {this.state.data.ticket.status === "resolved" && <Badge color="success">Resolved</Badge>}
                                                            {this.state.data.ticket.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                            {!this.state.data.ticket.status && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Location:</td>
                                                        <td>
                                                            {this.state.data.ticket.location && <span>{this.state.data.ticket.location}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Space:</td>
                                                        <td>
                                                            {this.state.data.ticket.apartment && <Link key={this.state.data.ticket.apartment.id + "-view"} to={"/apartments/" + this.state.data.ticket.apartment.id}>{this.state.data.ticket.apartment.name}</Link>}
                                                            {!this.state.data.ticket.apartment && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Floor:</td>
                                                        <td>
                                                            {this.state.data.ticket.floor && <Link key={this.state.data.ticket.floor.id + "-view"} to={"/floors/" + this.state.data.ticket.floor.id}>{this.state.data.ticket.floor.name}</Link>}
                                                            {!this.state.data.ticket.floor && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Description:</td>
                                                        <td>
                                                            {this.state.data.ticket.description && <span>{this.state.data.ticket.description}</span>}
                                                            {!this.state.data.ticket.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </CardBody>
                                    </Card>


                                    <Card>
                                        <CardBody>
                                            <h6>Images</h6>
                                            <hr />

                                            <Row>
                                                {this.state.data && this.state.data.ticket.images && this.state.data.ticket.images.length > 0 && this.state.data.ticket.images.map(item =>
                                                    <Col style={{ maxWidth: "300px" }}>
                                                        <InnerImageZoom src={this.state.assetUrl + "/image/" + item + ".jpg"} />
                                                    </Col>
                                                )}
                                            </Row>
                                            {(!this.state.data.ticket || !this.state.data.ticket.images || !this.state.data.ticket.images.length > 0) &&
                                                <h4 className="text-muted text-center mt-3"><i>No images uploaded</i></h4>
                                            }

                                        </CardBody>
                                    </Card>

                                    {this.state.data && this.state.data.ticket.files && this.state.data.ticket.files.length > 0 && <Card>
                                        <CardBody>
                                            <h6>Other files</h6>
                                            <hr />
                                            {this.state.data.ticket.files.map(file => <Row style={{ paddingTop: "10px" }}>
                                                <Col>
                                                    <h5>
                                                        {file.fileName}
                                                    </h5>
                                                </Col>
                                                <Col style={{
                                                    textAlign: "right"
                                                }}>
                                                    <button onClick={() => { this.downloadFile(file.id, file.fileName); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                                                </Col>
                                            </Row>)}
                                        </CardBody>
                                    </Card>}

                                </div>

                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <WorkOrderAssignmentTable workOrderId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>


                    {!this.state.loading && this.state.data && this.state.data.status === "open" && this.state.data.provider === "none" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Close work order:</strong> This work order is not linked to IVIVA, you must manually close it once the contractor reports that it is complete</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/work-orders" onClick={this.close} className="btn mr-2 mt-2 mb-2 btn-danger">Close work order</Button>
                                </Col>
                            </Row>
                        </div>}

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiWorkOrderClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async close() {
        await HengyiWorkOrderClient.Close(this.props.match.params.id);
        this.setState({ next: true });
    }
}
