import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class FloorQueryParams extends ListQueryParams {

    WithBuilding(value: string) {
        this.WithParam("buildingId", value);
        return this;
    }

}

export class HengyiFloorClient {

    static async List(params: FloorQueryParams) {

        var query = (await GetAPIDomain()) + "/floors" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/floors/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/floors/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name
            })
        }));
    }

    static async Update(id: string, name: string, ordinal: number) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/floors/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
                {
                    "op": "replace",
                    "path": "ordinal",
                    "value": ordinal
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/floors/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}