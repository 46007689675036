import React, { Component } from 'react';
import { ChargesTable } from './ChargesTable';

export class Charges extends Component {

    render() {
        return (
            <ChargesTable enableEditControls={true} />
        );
    }
}
