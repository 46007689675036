import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { GetAssetDomain, GetResidentDomain } from '../infrastructure/Helper';
import classnames from 'classnames';
import { UserLink } from '../Users/UserLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { PartnerOfferClient } from '../infrastructure/HengyiClient/PartnerOffers';
import { PartnerOfferEventClient } from '../infrastructure/HengyiClient/PartnerOfferEvents';
import { PartnerOfferEventQueryParams } from '../infrastructure/HengyiClient/PartnerOfferEvents';
import { HengyiStatisticsClient } from '../infrastructure/HengyiClient/Statistics';

export class PartnerOffersDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            eventData: [], eventLoading: false, eventTotal: 0, eventSkip: 0, eventTake: 50, eventSearch: "",
            activeTab: '1'
        };

        this.delete = this.delete.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);

        this.handleEventSearchChange = this.handleEventSearchChange.bind(this);

        this.toggle = this.toggle.bind(this);

    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
        await this.updateEventData();
        this.setState({
            residentDomain: await GetResidentDomain()
        });
    }

    async approve() {
        await PartnerOfferClient.Approve(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async reject() {
        await PartnerOfferClient.Reject(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/partner-offers" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/partner-offers" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Partner offers</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/partner-offers/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Row>
                        {this.state.statsData && this.state.statsData.length > 0 && this.state.statsData.map(stat => <Col md="4" key={stat.title}>
                            <Card>
                                <div className="media friendlist-box">
                                    <CardBody>
                                        <h5>{stat.title}</h5>
                                        <h2>{stat.value}</h2>
                                        <span className="d-block text-muted" style={{ height: "56px" }}>{stat.description}</span>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>)}
                    </Row>

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Events
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                <CardBody>
                                    <Row><Col>
                                        <h6>Partner offer details</h6>
                                        <hr />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td>
                                                        {this.state.data.name && <span>{this.state.data.name}</span>}</td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "draft" && <Badge color="primary">Draft</Badge>}
                                                    {this.state.data.status === "published" && <Badge color="success">Published</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Uploaded by:</td>
                                                <td>
                                                    {this.state.data.createdBy && <UserLink user={this.state.data.createdBy} />}
                                                    {!this.state.data.createdBy && <i>Not specified</i>}
                                                </td>
                                            </tr>

                                            
                                                <tr>
                                                    <td className="pr-2">Category:</td>
                                                    <td>
                                                    {this.state.data.partnerOfferCategory && <span>{this.state.data.partnerOfferCategory.name}</span>}
                                                    {!this.state.data.partnerOfferCategory && <i>Not specified</i>}
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Expiry:</td>
                                                        <td>
                                                            {this.state.data.expiry && <Moment utc local format="ddd, DD/MM h:mma" date={this.state.data.expiry} />}
                                                            {!this.state.data.expiry && <i>Never</i>}
                                                        </td>
                                                    </tr>
                                                <tr>
                                                    <td className="pr-2">File uploaded:</td>
                                                    <td>
                                                        {this.state.data.fileUploaded && <span>Yes</span>}
                                                    {!this.state.data.fileUploaded && <i>No</i>}
                                                    </td>
                                                </tr>
                                               
                                            {this.state.data.fileUploaded && <tr>
                                                <td colSpan="2" className="pt-4"><strong>File</strong></td></tr>}

                                            {this.state.data.fileUploaded && <tr>
                                                <td className="pr-2">File type:</td>
                                                <td>
                                                    {this.state.data.fileType && <span>{this.state.data.fileType}</span>}
                                                    {!this.state.data.fileType && <i>Not specified</i>}
                                                </td>
                                            </tr>}

                                            {this.state.data.fileUploaded && <tr>
                                                <td className="pr-2">File name:</td>
                                                <td>
                                                    {this.state.data.fileName && <span>{this.state.data.fileName}</span>}
                                                    {!this.state.data.fileName && <i>Not specified</i>}
                                                </td>
                                            </tr>}

                                            {this.state.data.fileUploaded && <tr>
                                                <td className="pr-2">File size:</td>
                                                <td>
                                                    {this.state.data.fileSize && this.state.data.fileSize <= 1024 && <span>{(this.state.data.fileSize).toFixed(0)} Bytes</span>}
                                                    {this.state.data.fileSize && this.state.data.fileSize > 1024 && this.state.data.fileSize <= (1024 * 1024) && <span>{(this.state.data.fileSize / 1024).toFixed(0)} Kilobytes</span>}
                                                    {this.state.data.fileSize && this.state.data.fileSize > (1024 * 1024 * 1024) && <span>{(this.state.data.fileSize / 1024 / 1024).toFixed(0)} Megabytes</span>}
                                                    {this.state.data.fileSize && this.state.data.fileSize > (1024 * 1024 * 1024) && <span>{(this.state.data.fileSize / 1024 / 1024 / 1024).toFixed(0)} Gigabytes</span>}
                                                    {!this.state.data.fileSize && <i>Not specified</i>}
                                                </td>
                                            </tr>}

                                            {this.state.data.fileUploaded && <tr>
                                                <td colSpan="2">
                                                    <button style={{ float: "left" }} onClick={() => { this.downloadFile(this.state.data.id, this.state.data.name + "." + this.state.data.fileType); return false; }} className="btn mr-2 btn-outline-dark mt-2 mb-2" ><span className="pcoded-micon"><i className="feather icon-download"></i> Download file</span></button>
                                                </td>
                                            </tr>}

                                            </tbody>
                                        </table>
                                        </Col>

                                    <Col md="3" className="text-center">
                                        <h6 className="text-left">Thumbnail</h6>
                                                <hr />
                                                {!this.state.data.thumbnailImage && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                                {this.state.data.thumbnailImage && this.state.data.thumbnailImage === "/img/icons/folder.svg" && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                                {this.state.data.thumbnailImage && this.state.data.thumbnailImage !== "/img/icons/folder.svg"&& <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.thumbnailImage + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}
                                    </Col>
                                            </Row>
                                    </CardBody>
                                </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Partner offer events ({this.state.eventTotal})
                                     {this.state.eventLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">List of events for this offer</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>
                                                    <button style={{ float: "right" }} onClick={() => { this.eventNext(); }} disabled={this.state.eventLoading || (this.state.eventSkip + this.state.eventTake >= this.state.eventTotal)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                    <button style={{ float: "right" }} onClick={() => { this.eventPrevious(); }} disabled={this.state.eventLoading || (this.state.eventSkip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Filter</h5>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <InputGroup>
                                                        <Input type="text" placeholder="Search" value={this.state.eventSearch} onChange={this.handleEventSearchChange} onKeyPress={e => e.key === 'Enter' && this.updateEventData()} />
                                                        <ButtonGroup className="input-group-append">
                                                            <button onClick={() => { this.updateEventData(); }} disabled={this.state.eventLoading} className="btn btn-outline-dark" >
                                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                            </button>
                                                        </ButtonGroup>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody className="p-0">
                                        {this.state.eventData &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>Date</th>
                                                        <th style={{ borderTop: "none" }}>User</th>
                                                        <th style={{ borderTop: "none" }}>Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.eventData.map(item =>
                                                        <tr key={item.id}>
                                                            <td><Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></td>
                                                            <td>
                                                                <Link key={item.id + item.user.id + "-view"} to={"/users/" + item.user.id}>{item.user.name} {item.user.surname}</Link>
                                                            </td>
                                                            {item.type !== "offerDocumentDownloaded" && item.type !== "offerViewed" && item.type !== "linkClicked" && <td style={{ textTransform: 'capitalize' }}>{item.type}</td>}
                                                            {item.type === "offerViewed" && <td style={{ textTransform: 'capitalize' }}>Offer Viewed</td>}
                                                            {item.type === "linkClicked" && <td style={{ textTransform: 'capitalize' }}>Link Clicked</td>}
                                                            {item.type === "offerDocumentDownloaded" && <td style={{ textTransform: 'capitalize' }}>Document Downloaded</td>}
                                                        </tr>
                                                    )}
                                                    {this.state.eventData.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No events to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }

                                    </CardBody>
                                </Card>

                            </div>}
                        </TabPane>
                    </TabContent>


                    {!this.state.loading && this.state.data && this.state.data.status === "draft" &&
                        <div className="alert alert-primary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Pending:</strong> Approve this offer to make it visible to eligible non-admins</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/partner-offers" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-seconday">Approve</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "published" &&
                        <div className="alert alert-success" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Approved:</strong> Hide this offer to mark is as draft and make it temporarily unavailable.</p>
                                </Col>
                                <Col md="2">
                                <Button style={{ float: "right" }} to="/partner-offers" onClick={this.reject} className="btn mr-2 mt-2 mb-2 btn-seconday">Hide</Button>
                                </Col>
                            </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.status === "draft" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this offer, doing so will permanently remove it and all associated events, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                <Button style={{ float: "right" }} to="/partner-offers" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete partner offer</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This offer is protected and cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var success = await PartnerOfferClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }


    async downloadFile(id, name) {

        var data = await PartnerOfferClient.GetDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        await this.populateData(id);

    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await PartnerOfferClient.Get(id);
            var statsResponse = await HengyiStatisticsClient.PartnerOffer(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, statsData: statsResponse.data, loading: false });
            }
        }
    }


    handleEventSearchChange(evt) {
        this.setState({
            eventSearch: evt.target.value
        });
    }

    async eventPrevious() {
        await this.populateEventData(this.state.eventSkip - this.state.eventTake, this.state.eventTake);
    }

    async eventNext() {
        await this.populateEventData(this.state.eventSkip + this.state.eventTake, this.state.eventTake);
    }

    async updateEventData() {
        await this.populateEventData(this.state.eventSkip, this.state.eventTake);
    }

    async populateEventData(skip, take) {

        if (!this.state.eventLoading) {
            this.setState({ eventLoading: true, eventSkip: skip, eventTake: take });

            var response = await PartnerOfferEventClient.List(new PartnerOfferEventQueryParams()
                .WithPartnerOffer(this.props.match.params.id)
                .Search(this.state.eventSearch)
                .Paginate(skip, take)
                .Sort("created")
                .Descending());

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ eventData: response.data.data, eventLoading: false, eventTotal: response.data.total });
            }
        }
    }
}
