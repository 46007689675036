import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { AudienceSelector } from '../Audience/AudienceSelector';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';

export class DocumentEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialFolderOption = this.state.folderOptions.filter(type => type.value === this.state.parentId)[0];

            return (<Formik
                initialValues={{
                    description: this.state.description,
                    canRequestInfo: this.state.canRequestInfo,
                    parentId: initialFolderOption,
                    requestInfoPartner: this.state.requestInfoPartner,
                    requestInfoEmail: this.state.requestInfoEmail,
                    requestInfoCode: this.state.requestInfoCode,
                    name: this.state.data.name,
                    fileType: this.state.data.fileType,
                    visibility: this.state.data.visibility,
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    const response = await fetch((await GetAPIDomain()) + "/documents/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "description",
                            "value": fields.description
                        }, {
                                "op": "replace",
                                "path": "parentId",
                                "value": fields.parentId.value
                            }, {
                                "op": "replace",
                                "path": "audiences",
                                "value": this.state.audiences
                            }, {
                                "op": "replace",
                                "path": "visibility",
                                "value": fields.visibility
                            }, {
                            "op": "replace",
                            "path": "requestInfoPartner",
                            "value": fields.canRequestInfo ? fields.requestInfoPartner : null
                        }, {
                            "op": "replace",
                            "path": "requestInfoEmail",
                            "value": fields.canRequestInfo ? fields.requestInfoEmail : null
                        }, {
                            "op": "replace",
                            "path": "requestInfoCode",
                            "value": fields.canRequestInfo ? fields.requestInfoCode : null
                        }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" disabled className="form-control" />
                            </div></Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="fileType">File type</label>
                                <Field name="fileType" type="text" disabled className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="parentId"
                                    name="parentId"
                                    label="Folder"
                                    placeholder="Select folder"
                                    options={this.state.folderOptions}
                                    value={values.parentId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.parentId}
                                    error={errors.parentId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                    </Row>

                    <h6 className="mt-2">Visibility</h6>
                    <hr />
                    <Row>
                        <Col>
                            <div class="form-check">
                                <Field class="form-check-input" type="radio" name="visibility" id="public" value="public" />
                                <label class="form-check-label" for="public">Public</label>
                            </div>
                            <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="visibility" id="restricted" value="restricted" />
                                    <label class="form-check-label" for="restricted">Restricted</label>
                            </div>
                        </Col>
                    </Row>

                        {values.visibility === "restricted" && <div className="form-group">
                            <h6 className="mt-2">Audience</h6>
                        <hr />

                        <Row className="mb-2">
                            <Col>Type</Col>
                            <Col>Entity</Col>
                            <Col>User Type</Col>
                            <Col md="1"></Col>
                        </Row>
                        <hr />
                        {(!this.state.audiences || this.state.audiences.length === 0) && <i>No audiences added yet</i>}
                        {this.state.audiences && this.state.audiences.length > 0 &&
                            <div>

                                {this.state.audiences.map((audience, index) =>
                                    <Row>
                                        <Col style={{ textTransform: 'capitalize' }}>
                                            {audience.type}
                                        </Col>
                                        <Col style={{ textTransform: 'capitalize' }}>
                                            {audience.entityName && <span>{audience.entityName}</span>}
                                            {!audience.entityName && <i>Everything</i>}
                                        </Col>
                                        <Col md="1">
                                            <button className="btn mr-2 btn-outline-dark" onClick={() => {
                                                console.log("Remove index: " + index);

                                                this.state.audiences.splice(index, 1);
                                                this.setState({
                                                    audiences: this.state.audiences
                                                });

                                                return false;
                                            }}>REMOVE</button>
                                        </Col>
                                    </Row>
                                )}
                            </div>}

                        <hr />

                            <AudienceSelector hideUserType={true} hideLabels={true} initialValues={{
                            audience: "all",
                            userType: "any"
                        }} onSubmit={async (fields, { setErrors, setFieldError }) => {

                            var audiences = this.state.audiences;

                            var audience = {
                                type: fields.audience
                            };

                            if (fields.userType && fields.userType !== "any")
                                audience.userType = fields.userType;


                            if (fields.entityId) {
                                audience.entityId = fields.entityId.value;
                                audience.entityName = fields.entityId.label;
                            }

                            audiences.push(audience);

                            this.setState({
                                audiences: audiences
                            });

                        }} />

                    </div>}


                    <h6 className="mt-2">Request info</h6>
                    <hr />
                    <Row>
                        <Col>
                            <div className="form-group" style={{ paddingLeft: "20px" }}>
                                <label className="form-check-label">
                                    <Field type="checkbox" name="canRequestInfo" className="form-check-input" checked={values.canRequestInfo} />
                                    Can request info
                                    </label>
                            </div>
                        </Col>
                    </Row>
                    {values.canRequestInfo &&
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="requestInfoPartner">Partner</label>
                                    <Field name="requestInfoPartner" type="text" className={'form-control' + (errors.requestInfoPartner && touched.requestInfoPartner ? ' is-invalid' : '')} />
                                    <ErrorMessage name="requestInfoPartner" component="div" className="invalid-feedback" />
                                </div></Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="requestInfoEmail">Email</label>
                                    <Field name="requestInfoEmail" type="text" className={'form-control' + (errors.requestInfoEmail && touched.requestInfoEmail ? ' is-invalid' : '')} />
                                    <ErrorMessage name="requestInfoEmail" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="requestInfoCode">Code</label>
                                    <Field name="requestInfoCode" type="text" className={'form-control' + (errors.requestInfoCode && touched.requestInfoCode ? ' is-invalid' : '')} />
                                    <ErrorMessage name="requestInfoCode" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>}

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/documents/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update document details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details this document</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/documents/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var document = await HengyiDocumentClient.GetDocument(this.props.match.params.id);

            if (!document.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                var audiences = [];

                if (document.data && document.data.audiences) {
                    audiences = document.data.audiences.map(item => {
                        if (item.apartment)
                            return {
                                entityName: item.apartment.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.apartment.id
                            };
                        if (item.apartmentType)
                            return {
                                entityName: item.apartmentType.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.apartmentType.id
                            };
                        if (item.building)
                            return {
                                entityName: item.building.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.building.id
                            };
                        if (item.floor)
                            return {
                                entityName: item.floor.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.floor.id
                            };

                        if (item.user)
                            return {
                                entityName: item.user.name + " " + item.user.surname,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.user.id
                            };


                        if (item.type === "all")
                            return {
                                entityName: "Everyone",
                                type: item.type,
                                userType: item.userType,
                                entityId: null
                            };

                        return {
                            entityName: "Unknown type: " + item.type,
                            type: item.type,
                            userType: item.userType,
                            entityId: ""
                        };
                    });
                }
                this.setState({
                    data: document.data,
                    description: document.data.description,
                    canRequestInfo: document.data.canRequestInfo,
                    parentId: document.data.parentId,
                    requestInfoPartner: document.data.requestInfoPartner,
                    requestInfoEmail: document.data.requestInfoEmail,
                    requestInfoCode: document.data.requestInfoCode,
                    visibility: document.data.visibility,
                    loading: false,
                    init: true,
                    audiences: audiences,
                    folderOptions: (await HengyiApi.GetDocuments()).data.data.map(function (item) { return { value: item.id, label: item.name }; })
                });

            }
        }
    }
}
