import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class PartnerOfferEventQueryParams extends ListQueryParams {

    WithType(value: string) {
        this.WithParam("partnerOfferEventType", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithPartnerOffer(value: string) {
        this.WithParam("partnerOfferId", value);
        return this;
    }
}

//******************************
// Partner offer categories
//******************************
export class PartnerOfferEventClient {

    static async List(params: PartnerOfferEventQueryParams) {

        var query = (await GetAPIDomain()) + "/partner-offer-events" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}