import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { PartnerOfferCategoryClient } from '../infrastructure/HengyiClient/PartnerOfferCategories';

export class PartnerOfferCategoriesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/partner-offer-categories" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/partner-offer-categories" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Partner Offer</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/partner-offer-categories/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h6>{this.state.data.name}</h6>
                                    <hr />
                                    <p>{this.state.data.description}</p>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger">
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this category, doing so will permanently remove it, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                <Button style={{ float: "right" }} to="/partner-offer-categories" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete category</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This partner offer category is protected and cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var success = await PartnerOfferCategoryClient.Remove(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await PartnerOfferCategoryClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
