import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class EVChargingSessionQueryParams extends ListQueryParams {

    WithBuilding(value: string) {
        this.WithParam("buildingId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithVehicle(value: string) {
        this.WithParam("vehicleId", value);
        return this;
    }

    WithSwipeCard(value: string) {
        this.WithParam("swipeCardId", value);
        return this;
    }

    HasUserAssigned(value: Boolean) {
        this.WithParam("hasUser", value);
        return this;
    }

    HasVehicleAssigned(value: Boolean) {
        this.WithParam("hasVehicle", value);
        return this;
    }

    WithHideCancelled(value: Boolean) {
        this.WithParam("hideCancelled", value);
        return this;
    }

    WithHideBelowThreshold(value: Boolean) {
        this.WithParam("hideBelowThreshold", value);
        return this;
    }

    WithHideCompleted(value: Boolean) {
        this.WithParam("hideCompleted", value);
        return this;
    }

    WithDate(value: string) {
        this.WithParam("date", value);
        return this;
    }

}

export class HengyiEVChargingSessionClient {

    static async List(params: EVChargingSessionQueryParams) {

        var query = (await GetAPIDomain()) + "/ev-charging-sessions" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Start(swipeCardCode: string, vehicleId: string, buildingId: string, start?:string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/start", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "swipeCardCode": swipeCardCode,
                "vehicleId": vehicleId,
                "buildingId": buildingId,
                "start": start
            })
        }));
    }

    static async Complete(id: string, amountToBill: number, energyUsed: number, end?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/" + id + "/complete", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "amountToBill": amountToBill,
                "energyUsed": energyUsed,
                "end": end
            })
        }));
    }

    static async Accept(id: string, message?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/" + id + "/accept", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        }));
    }

    static async Cancel(id: string, message?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        }));
    }

    static async AssignToUser(id: string, userId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/ev-charging-sessions/" + id + "/assign-user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId
            })
        }));
    }
}