import React, { Component } from 'react';
import { HengyiBookingsClient} from '../infrastructure/HengyiClient/Bookings';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Badge, Button, } from 'reactstrap';
import Moment from 'react-moment';
import { userContext } from '../../userContext';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendar, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { UserLink } from '../Users/UserLink';
import { Formik, Field, Form, ErrorMessage } from 'formik';

export class BookingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            approvedBy: null,
            loading: false,
            authenticated: true,
            next: false,
            showForm: "none",
            activeTab: '1',
            showAddNote: false, includeDeleted: false
        };

        this.approve = this.approve.bind(this);
        this.cancel = this.cancel.bind(this);
        this.toggle = this.toggle.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiBookingsClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async approve() {
        await HengyiBookingsClient.Approve(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async cancel() {
        await HengyiBookingsClient.Cancel(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }
    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/bookings" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/bookings" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        Booking
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Booking details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/bookings/edit/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Change date</Link>
                                        <Link style={{ float: "right" }} to={"/bookings/" + this.props.match.params.id + "/message"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Send message</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <userContext.Consumer>
                                    {({ user, logoutUser, features }) => {
                                        return (<CardBody>
                                    <Row>
                                        <Col>
                                            <h6>Details</h6>
                                            <hr />

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Booking:</td>
                                                        <td>
                                                            {this.state.data.schedule.facility && <span><FontAwesomeIcon icon={faBuilding} /> {this.state.data.schedule.facility.name}</span>}
                                                            {this.state.data.schedule.event && <span><FontAwesomeIcon icon={faCalendar} /> {this.state.data.schedule.event.name}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Time:</td>
                                                        <td>
                                                            <Moment utc local format="ddd, DD/MM" date={this.state.data.start} />: <Moment utc local format="h:mma" date={this.state.data.start} /> - <Moment utc local format="h:mma" date={this.state.data.end} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Notes:</td>
                                                        <td>
                                                            {this.state.data.notes && this.state.data.notes}
                                                            {!this.state.data.notes && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Status:</td>
                                                        <td>
                                                            {this.state.data.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                            {this.state.data.status === "confirmed" && <Badge color="success">Confirmed</Badge>}
                                                            {this.state.data.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Created: </td>
                                                        <td>
                                                            <Moment utc local format="ddd, DD/MM h:mma" date={this.state.data.created} />
                                                        </td>
                                                    </tr>
                                                    {this.state.data.status === "cancelled" && <tr>
                                                        <td className="pr-2">Reason cancelled:</td>
                                                        <td>
                                                            {this.state.data.reasonCancelled && this.state.data.reasonCancelled}
                                                            {!this.state.data.reasonCancelled && <i>Not specified</i>}
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col><h6>User</h6>
                                            <hr />

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">User:</td>
                                                        <td>
                                                            <UserLink user={this.state.data.user} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Contact Name:</td>
                                                        <td>
                                                            {this.state.data.contactName}
                                                            {!this.state.data.contactName && this.state.data.user.name + " " + this.state.data.user.surname}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Contact Number:</td>
                                                        <td>
                                                            {this.state.data.contactNumber}
                                                            {!this.state.data.contactNumber && this.state.data.user.mobile}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                                </Col>

                                                {features && features.includes("exivo") && <Col><h6>Exivo</h6>
                                                    <hr />

                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pr-2">Enabled:</td>
                                                                <td>
                                                                    {!this.state.data.exivoEnabled && "No"}
                                                                    {this.state.data.exivoEnabled && "Yes"}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.exivoEnabled && <tr>
                                                                <td className="pr-2">Code:</td>
                                                                <td>
                                                                    {this.state.data.exivoBookingCode}
                                                                </td>
                                                            </tr>}
                                                            {this.state.data.exivoEnabled && <tr>
                                                                <td className="pr-2">Link:</td>
                                                                <td>
                                                                    <a href={'https://picker.exivo.io/unlockdoor/' + this.state.data.externalId}>Unlock door</a>
                                                                </td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </Col>}

                                    </Row>
                                    
                                        </CardBody>)
                                    }}
                                </userContext.Consumer>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Booking activity" description="List all booking activity" entityId={this.props.match.params.id} />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this booking</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        if (!fields.message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiBookingsClient.AddNote(this.props.match.params.id, fields.message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                            <Field as="textarea" name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("booking.modify") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                    </TabContent>

                    {!this.state.loading && this.state.data && (this.state.data.status === "pending") &&
                        <div className="alert alert-primary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This booking is <strong>Pending.</strong> Approve or cancel this booking. The resident will be notified.</p>
                                </Col>
                            <Col md="2">
                                <Button style={{ float: "right" }} to="/bookings" onClick={this.cancel} className="btn mr-2 mt-2 mb-2 btn-seconday">Cancel</Button>
                                <Button style={{ float: "right" }} to="/bookings" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-seconday">Approve</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "confirmed" &&
                        <div className="alert alert-success" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This booking is <strong>Confirmed</strong>. If this booking is no longer valid for any reason cancel it. The resident will be notified.</p>
                                </Col>
                                <Col md="2">
                                <Button style={{ float: "right" }} to="/bookings" onClick={() => { this.setState({ showForm: "cancel-booking" }); }} className="btn mr-2 mt-2 mb-2 btn-seconday">Specify reason & cancel</Button>
                                </Col>
                            </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.status === "cancelled" &&
                        <div className="alert alert-secondary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This booking is <strong>Cancelled.</strong> Booking still valid? You can restore it to make it visible to the resident again.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/bookings" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-seconday">Restore</Button>
                                </Col>
                            </Row>
                        </div>}



                    {this.state.showForm === "cancel-booking" && <Card>
                        <CardBody>
                            <Formik
                                initialValues={{

                                }}
                                onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                    var response = await HengyiBookingsClient.Cancel(this.props.match.params.id, this.state.reasonCancelled);

                                    if (!response.successful) {
                                        response.data.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });

                                    } else {
                                        this.setState({ showForm: "none", reasonCancelled: "" });
                                        setFieldValue("reasonCancelled", "");
                                        await this.update();
                                    }
                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                    <Row><Col>
                                        <div className="form-group">
                                            <label htmlFor="reasonCancelled">Reason</label>
                                                <Field name="reasonCancelled" type="text" className={'form-control' + (errors.reasonCancelled && touched.reasonCancelled ? ' is-invalid' : '')} onChange={this.handleChange}/>
                                            <ErrorMessage name="reasonCancelled" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>

                                        <Col md="2">
                                            <div className="mt-4">
                                                <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                                <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                    {!isSubmitting && "Cancel booking"}
                                                    {isSubmitting && <Spinner animation="border" />}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}</Formik>
                        </CardBody>
                    </Card>}

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiBookingsClient.Get(id);
            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiBookingsClient.ListNotes(id, includeDeleted)).data;
          
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false,
                    notes: notes.data
                });
            }
        }
    }
}
