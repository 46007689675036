import React, { Component } from "react";
import { SelectField } from "./SelectField";
import { FacilityQueryParams, HengyiFacilityClient } from "./HengyiClient/Facilities";

class FacilitySelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {

        const {
            initialFacilityId,
            displayFormat,
            name,
            initComplete
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new FacilityQueryParams();

        var facilities = (await HengyiFacilityClient.List(query.Paginate(0, 10000))).data;

        if (facilities) {
            this.setState({
                facilityOptions: facilities.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                    if (initialFacilityId && !this.state.init) {
                        this.handleChange(name ? name : "facilityId", this.state.facilityOptions.filter(i => i.value === initialFacilityId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }


        if (initComplete)
            initComplete();
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : "facilityId"}
            name={name ? name : "facilityId"}
            label={label ? label : hideLabel ? null : "Facility"}
            placeholder={placeholder ? placeholder : "Select facility"}
            options={this.state.facilityOptions}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}

export { FacilitySelector };
