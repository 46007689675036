import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiFacilityClient } from '../infrastructure/HengyiClient/Facilities';
import { SelectField } from "../infrastructure/SelectField";
import { GetAPIDomain } from '../infrastructure/Helper';

export class FacilityNew extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false, id: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                description: "",
                type: "",
                buildingId: this.state.buildingOptions[0]
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.buildingId) {
                    setFieldError("buildingId", "Please select a building");
                    return;
                }

                console.log("Submitting");

                if (!fields.type) {
                    setFieldError("type", "Please select a type");
                    return;
                }

                var imageId = null;

                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await HengyiFacilityClient.Create(
                    this.state.name,
                    this.state.description,
                    fields.type.value,
                    fields.buildingId.value,
                    imageId);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className={'form-group' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                    <SelectField
                                        id="type"
                                        name="type"
                                        label="Type"
                                        placeholder="Select Type"
                                        options={this.state.facilityTypeOptions}
                                        value={values.type}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.status}
                                        error={errors.type}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />

                                    <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                </div>
                                </Col>
                                <Col>
                                    <SelectField
                                        id="buildingId"
                                        name="buildingId"
                                        label="Building"
                                        placeholder="Select building"
                                        options={this.state.buildingOptions}
                                        value={values.buildingId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.buildingId}
                                        error={errors.buildingId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />
                                </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <input name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3" className="text-center">
                        <div className="form-group">
                            <h6 className="text-left">Background image</h6>

                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                        </div>
                    </Col>
                </Row>
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/facilities/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New facility
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new facility</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/facilities" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.buildingOptions && this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {

            const facilityTypes = [{ value: 'general', label: 'General' }, { value: 'evCharger', label: 'EVCharger' }, { value: 'lift', label: 'Lift' }];
            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                loading: false,
                init: true,
                buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                type: "",
                facilityTypeOptions: facilityTypes
            });
        }
    }
}
