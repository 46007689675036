import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { HengyiNoticeClient, NoticeQueryParams } from '../infrastructure/HengyiClient/Notices';
import { UserLink } from '../Users/UserLink';
import Moment from 'react-moment';
import { TableHeader } from '../infrastructure/TableHeader';

export class Notices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "",
            hideArchived: true, hideExpired: true, sort: "title", direction: "asc"
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleHideArchived = this.handleHideArchived.bind(this);
        this.handleHideExipred = this.handleHideExipred.bind(this);
    }

    handleHideArchived = (evt) => {
        this.setState({
            hideArchived: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    handleHideExipred = (evt) => {
        this.setState({
            hideExpired: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            skip: 0
        }, () => this.update());

    }

    componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);

        this.setState({
            search: urlParams.get("q") ? urlParams.get("q") : "",
            status: urlParams.get("status"),
            skip: 0
        }, () => this.update());
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Type", sort: "type" },
                        { name: "Title", sort: "title" },
                        { name: "Status", sort: "status" },
                        { name: "Expiry", sort: "expiry" },
                        { name: "Created By", sort: "createdBy.name" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.type === "info" && <Badge color="info">Information</Badge>}
                                {item.type === "warning" && <Badge color="warning">Warning</Badge>}
                                {item.type === "alert" && <Badge color="danger">Alert</Badge>}
                            </td>
                            <td>
                                {item.title && item.title}
                                {!item.title && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                {item.status === "approved" && <Badge color="success">Approved</Badge>}
                                {item.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                {!item.status && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.expiry && <Moment utc local format="ddd, DD/MM" date={item.expiry} />}
                                {!item.expiry && <i>Never</i>}
                            </td>
                            <td>
                                <UserLink user={item.createdBy} />
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/notices/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/notices/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No notices to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Notices ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of notices posted for the building</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/notices/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Approved</option>
                                            <option value="archived">Archived</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.status) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardHeader>
                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideCollected"
                                                type="checkbox"
                                                checked={this.state.hideArchived}
                                                onChange={this.handleHideArchived} />  Hide archived notices
                                        </label>
                                    </li>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideExpired"
                                                type="checkbox"
                                                checked={this.state.hideExpired}
                                                onChange={this.handleHideExipred} />  Hide expired notices
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiNoticeClient.List(new NoticeQueryParams()
                .WithHideArchived(this.state.hideArchived ? "true" : "false")
                .WithHideExpired(this.state.hideExpired ? "true" : "false")
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithStatus(this.state.status)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
