import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiEventClient } from '../infrastructure/HengyiClient/Events';
import { GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';

export class EventsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", buildingId: ""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialBuilding = this.state.buildingOptions.filter(type => type.value === this.state.buildingId)[0];

            return (<Formik
                initialValues={{
                    buildingId: initialBuilding,
                    startTime: this.state.startTime,
                    endTime: this.state.endTime
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var imageId = this.state.backgroundImageId;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var response = await HengyiEventClient.Update(this.props.match.params.id,
                        this.state.name,
                        this.state.description,
                        this.state.location,
                        fields.buildingId.value,
                        fields.startTime,
                        fields.endTime,
                        imageId);

                    if (!response.successful) {
                        response.data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true, id: response.data.id });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                            <input id="name" name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange}/>
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                            <label htmlFor="description">Description</label>
                                            <textarea id="description" name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} ></textarea>
                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="location">Location</label>
                                            <input id="location" name="location" type="text" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                                <Col>
                                    <SelectField
                                        id="buildingId"
                                        name="buildingId"
                                        label="Building"
                                        placeholder="Select building"
                                        options={this.state.buildingOptions}
                                        value={values.buildingId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.buildingId}
                                        error={errors.buildingId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="startTime">Start time</label>

                                            <DatePicker id="startTime"
                                                onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                            autoComplete="off"
                                            className="form-control"
                                            selected={values.startTime}
                                            showTimeSelect
                                            dateFormat='dd/MM/yyyy hh:mm a'
                                            onChange={(v, f) => {
                                                setFieldValue("startTime", v);
                                                }} />


                                            {errors.startTime && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.startTime}</div>}

                                    </div>
                                </Col>

                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="endTime">End time</label>

                                            <DatePicker id="endTime"
                                                onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                            autoComplete="off"
                                            className="form-control"
                                            selected={values.endTime}
                                            showTimeSelect
                                            dateFormat='dd/MM/yyyy hh:mm a'
                                            onChange={(v, f) => {
                                                setFieldValue("endTime", v);
                                                }} />

                                            {errors.endTime && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.endTime}</div>}

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Background image</h6>

                                {this.state.file && <div>
                                    <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                    this.setState({
                                        file: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />

                            </div>
                        </Col>
                    </Row>
                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/events/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update event
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/events/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.buildingOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiEventClient.Get(this.props.match.params.id);
            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.backgroundImageId) {
                    this.setState({
                        file: (await GetAssetDomain()) + "/image/" + response.data.backgroundImageId + ".jpg"
                    });
                }
                if (response.data.building)
                    this.setState({ buildingId: response.data.building.id });

                if (response.data.startTime) {
                    this.setState({ startTime: new Date(response.data.startTime) });
                }

                if (response.data.endTime) {
                    this.setState({ endTime: new Date(response.data.endTime) });
                }

                this.setState({
                    loading: false,
                    buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                    name: response.data.name,
                    backgroundImageId: response.data.backgroundImageId,
                    description: response.data.description,
                    location: response.data.location
                }, () => {
                    document.getElementById("name").value = response.data.name;
                    document.getElementById("description").value = response.data.description;
                    document.getElementById("location").value = response.data.location;
                });
            }
        }
    }
}
