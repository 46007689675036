import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ContractorQueryParams extends ListQueryParams {

    WithProvider(value: string) {
        this.WithParam("provider", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

}

//******************************
// CONTRACTORS
//******************************
export class HengyiContractorClient {

    static async List(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/contractors" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contractors/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, surname: string, email: string, mobile: string, company: string) {

        var request: any = {
            name: name,
            surname: surname,
            email: email,
            mobile: mobile,
            company: company
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contractors/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, name: string, surname: string, email: string, mobile: string, company: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contractors/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                    "op": "replace",
                    "path": "surname",
                    "value": surname
                }, {
                    "op": "replace",
                    "path": "email",
                    "value": email
                }, {
                    "op": "replace",
                    "path": "mobile",
                    "value": mobile
                }, {
                    "op": "replace",
                    "path": "company",
                    "value": company
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/contractors/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contractors/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Cancel(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contractors/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

}