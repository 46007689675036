import React from 'react';
import { Route } from 'react-router-dom';
import { userContext } from '../../userContext';
import { SignIn } from '../SignIn';
import { HengyiApi } from './fetchHengy';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <userContext.Consumer>
            {({ user, logoutUser }) => {

                if (HengyiApi.IsAuthenticated()) {

                    return (
                        <Route {...rest} render={props => (
                            <Component {...props} />
                        )} />);
                } else {
                    console.log("RENDER SIGN IN");
                    return (
                        <Route {...rest} render={() => (
                                <SignIn />
                        )} />);
                }
            }}
        </userContext.Consumer>
    );
};