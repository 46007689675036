import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, InputGroup, Input, ButtonGroup, Badge } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GetResidentDomain } from '../infrastructure/Helper';
import { HengyiInvitationClient, InvitationQueryParams } from '../infrastructure/HengyiClient/Invitations';
import { TableHeader } from '../infrastructure/TableHeader';

export class Invitations extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", inviteStatus: "", sort: "name", direction: "asc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.notifySuccessCopy = this.notifySuccessCopy.bind(this);
    }

    async clear() {

        this.setState({
            search: "",
            inviteStatus: "",
            skip: 0
        }, () => this.update());

    }

    async componentDidMount() {
        this.setState({
            residentDomain: await GetResidentDomain()
        });
        this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Details", sort: "name" },
                        { name: "User", sort: "user.name" },
                        { name: "Status", sort: "status", static: true },
                        { name: "Space", sort: "apartment.name", static: true },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(invite =>
                        <tr key={invite.id}>
                            <td>
                                {invite.name} {invite.surname}
                                <p className="muted">{invite.email}</p>
                            </td>
                            <td>
                                {invite.user && <Link key={invite.user.id + "-view"} to={"/users/" + invite.user.id}>{invite.user.name} {invite.user.surname}</Link>}
                                {!invite.user && <i>Invite not used</i>}
                            </td>
                            <td>

                                {invite.status === "used" && <Badge color="secondary">Used</Badge>}
                                {invite.status === "active" && <Badge color="success">Active</Badge>}
                                {invite.status === "expired" && <Badge color="secondary">Expired</Badge>}
                            </td>
                            <td>
                                {invite.apartments && invite.apartments.length > 0 &&
                                    <ul className="list-unstyled">
                                        {invite.apartments.map(apartment =>
                                            <li key={invite.id + "-" + apartment.id}>
                                                <Link key={apartment.id + "-view"} to={"/apartments/" + apartment.id}>{apartment.name}</Link>
                                            </li>
                                        )}
                                    </ul>
                                }
                                {invite.apartments.length === 0 && <i>None</i>}
                            </td>
                            <td>
                                <Link key={invite.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invitations/" + invite.id}>Details</Link>
                                {invite.status === "active" && <Link key={invite.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invitations/edit/" + invite.id}>Edit</Link>}
                                {invite.status === "active" &&

                                    <CopyToClipboard
                                        onCopy={this.notifySuccessCopy}
                                        text={this.state.residentDomain + "/invitation/accept/" + invite.code}>

                                        <div style={{ float: "right" }} className="mr-2 mt-2" >
                                            <button data-tip="React-tooltip" onClick={this.onClick} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-copy"></i></span>
                                            </button>
                                            <ReactTooltip place="top" type="dark" effect="solid"><span>Copy invite link</span></ReactTooltip>
                                        </div>
                                    </CopyToClipboard>
                                }
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No invitations to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invitations ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Invitations sent to owners and residents</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/invitations/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Send invitation</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Row>
                                            <Col>
                                                <InputGroup>
                                                    <Input type="select" name="select" value={this.state.inviteStatus} onChange={this.handleStatusChange} >
                                                        <option value="">All statuses</option>
                                                        <option value="active">Active</option>
                                                        <option value="used">Used</option>
                                                        <option value="expired">Expired</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup>
                                                    <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                                    <ButtonGroup className="input-group-append">
                                                        <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                            <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                        </button>
                                                    </ButtonGroup>
                                                    <ButtonGroup>
                                                        {(this.state.search || this.state.inviteStatus) &&
                                                            <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                                <span className="pcoded-micon">Clear</span>
                                                            </button>}
                                                    </ButtonGroup>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleStatusChange(evt) {
        this.setState({
            inviteStatus: evt.target.value
        }, () => this.update());
    }

    notifySuccessCopy() {
        toast("📋 Invitation link copied to clipboard");
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiInvitationClient.List(new InvitationQueryParams()
                .WithStatus(this.state.inviteStatus)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            console.log(response);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
