import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { EVChargingSessionQueryParams, HengyiEVChargingSessionClient } from "../infrastructure/HengyiClient/EVChargingSessions";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import { HengyiBuildingClient } from "../infrastructure/HengyiClient/Buildings";
import { ListQueryParams } from "../infrastructure/fetchHengy";
import { UserLink } from "../Users/UserLink";
import NumberFormat from "react-number-format";
import { HengyiVehicleClient, VehicleQueryParams } from "../infrastructure/HengyiClient/Vehicles";
import { UserSelector } from "../infrastructure/UserSelector";
import { TableHeader } from "../infrastructure/TableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faInfo } from "@fortawesome/free-solid-svg-icons";

class EVChargingTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: "",
            showForm: "none",
            buildingOptions: [], hideCancelled: true, hideCompleted: true, hideBelowThreshold: true, sort: "start", direction: "desc"
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleUserAssignedChange = this.handleUserAssignedChange.bind(this);
        this.handleVehicleAssignedChange = this.handleVehicleAssignedChange.bind(this);
        this.handleFilterByDateChange = this.handleFilterByDateChange.bind(this);

        this.handleHideCancelledChange = this.handleHideCancelledChange.bind(this);
        this.handleHideCompletedChange = this.handleHideCompletedChange.bind(this);
        this.handleHideBelowThresholdChange = this.handleHideBelowThresholdChange.bind(this);

        this.updateCurrentSession = this.updateCurrentSession.bind(this);
    }

    handleFilterByDateChange = (date) => {
        this.setState({
            filterDate: date
        }, () => this.update());
    }

    handleHideCancelledChange = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked
        }, () => this.update());
    }

    handleHideBelowThresholdChange = (evt) => {
        this.setState({
            hideBelowThreshold: evt.target.checked
        }, () => this.update());
    }

    handleHideCompletedChange = (evt) => {
        this.setState({
            hideCompleted: evt.target.checked
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            userAssignedFilter: "",
            vehicleAssignedFilter: "",
            skip: 0,
            filterDate: ""
        }, () => this.update());

    }

    async componentDidMount() {
        await this.update();
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleUserAssignedChange(evt) {
        this.setState({
            userAssignedFilter: evt.target.value
        }, () => this.update());
    }

    handleVehicleAssignedChange(evt) {
        this.setState({
            vehicleAssignedFilter: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        var date = this.state.filterDate ? this.state.filterDate.toISOString() : null;

        const {
            vehicleId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new EVChargingSessionQueryParams()
                .WithVehicle(vehicleId)
                .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
                .WithHideCompleted(this.state.hideCompleted ? "true" : "false")
                .WithHideBelowThreshold(this.state.hideBelowThreshold ? "true" : "false")
                .WithDate(date)
                .WithStatus(this.state.status);

            if (this.state.vehicleAssignedFilter)
                params = params.HasVehicleAssigned(this.state.vehicleAssignedFilter);

            if (this.state.userAssignedFilter)
                params = params.HasUserAssigned(this.state.userAssignedFilter);

            var response = await HengyiEVChargingSessionClient.List(params
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total,
                    buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; })
                });
            }

            if (!vehicleId) {
                var vehicles = (await HengyiVehicleClient.List(new VehicleQueryParams().WithFuelType("ev", "hybrid").Paginate(0, 10000))).data.data;
                this.setState({
                    vehicleOptions: vehicles.map(function (item) { return { value: item.id, label: item.licensePlate + " (" + item.colour + " " + item.make + " " + item.model + ")" }; }),
                });
            }
        }
    }

    async updateCurrentSession(sessionId) {
        this.setState({
            activeChargingSessionId: sessionId
        });

        var session = await HengyiEVChargingSessionClient.Get(sessionId);

        if (session.successful && session.data && session.data.vehicle) {
            this.setState({
                vehicleId: session.data.vehicle.id
            });
        } else {
            this.setState({
                vehicleId: null
            });
        }
    }

    render() {
        const {
            title,
            description,
            vehicleId
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "EV Charging"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all activity"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                    {this.state.showForm !== "add-session" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "add-session" }); }} className="btn btn-outline-dark mt-2">Add</button>}
                                    {this.state.showForm === "add-session" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Cancel</button>}

                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                {this.state.showForm === "add-session" && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                buildingId: this.state.buildingOptions[0],
                                start: "",
                                swipeCardCode: ""
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                var scc = document.getElementById("swipeCardCode").value;

                                var vid = vehicleId;
                                if (!vid && fields.vehicleId) {
                                    vid = fields.vehicleId.value;
                                }

                                var response = await HengyiEVChargingSessionClient.Start(
                                    scc,
                                    vid,
                                    fields.buildingId.value,
                                    fields.start)


                                if (!response.successful) {
                                    response.data.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ showForm: "none" });
                                    setFieldValue("start", "");
                                    setFieldValue("swipeCardCode", "");
                                    document.getElementById("swipeCardCode").value = "";
                                    await this.update();
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="swipeCardCode">Swipe card code</label>
                                                <input name="swipeCardCode" id="swipeCardCode" type="text" className={'form-control' + (errors.swipeCardCode && touched.swipeCardCode ? ' is-invalid' : '')} />
                                            <ErrorMessage name="swipeCardCode" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="start">Start time</label>

                                                <DatePicker id="start"
                                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                                autoComplete="off"
                                                className="form-control"
                                                selected={values.start}
                                                showTimeSelect
                                                dateFormat='dd/MM/yyyy hh:mm a'
                                                onChange={(v, f) => {
                                                    setFieldValue("start", v);
                                                }} />
                                        </div>
                                    </Col>
                                    {!vehicleId &&
                                        <Col>
                                            <SelectField
                                                id="vehicleId"
                                                name="vehicleId"
                                                label="Vehicle"
                                                placeholder="Select vehicle"
                                                options={this.state.vehicleOptions}
                                                value={values.vehicleId}
                                                isMulti={false}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                touched={touched.vehicleId}
                                                error={errors.vehicleId}
                                                isClearable={true}
                                                backspaceRemovesValue={true}
                                            />
                                            <ErrorMessage name="vehicleId" component="div" className="invalid-feedback" />
                                        </Col>
                                    }
                                    <Col>
                                        <SelectField
                                            id="buildingId"
                                            name="buildingId"
                                            label="Building"
                                            placeholder="Select building"
                                            options={this.state.buildingOptions}
                                            value={values.buildingId}
                                            isMulti={false}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.buildingId}
                                            error={errors.buildingId}
                                            isClearable={true}
                                            backspaceRemovesValue={true}
                                        />
                                        <ErrorMessage name="buildingId" component="div" className="invalid-feedback" />
                                    </Col>

                                    <Col md="1">
                                        <div className="mt-4">
                                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "START"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                {this.state.showForm === "complete-session" && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                amountToBill: "",
                                energyUsed: ""
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                var amountToBill = parseFloat(document.getElementById("amountToBill").value ? document.getElementById("amountToBill").value : 0);
                                var energyUsed = parseFloat(document.getElementById("energyUsed").value ? document.getElementById("energyUsed").value : 0);

                                var response = await HengyiEVChargingSessionClient.Complete(
                                    this.state.activeChargingSessionId,
                                    Math.round(amountToBill * 100),
                                    energyUsed * 1000);


                                if (!response.successful) {
                                    response.data.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ showForm: "none" });
                                    setFieldValue("amountToBill", "");
                                    setFieldValue("energyUsed", "");
                                    document.getElementById("amountToBill").value = "";
                                    document.getElementById("energyUsed").value = "";
                                    await this.update();
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="amountToBill">Amount to bill</label>
                                                <input name="amountToBill" id="amountToBill" type="text" className={'form-control' + (errors.amountToBill && touched.amountToBill ? ' is-invalid' : '')} />
                                            <ErrorMessage name="amountToBill" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col><Col>
                                        <div className="form-group">
                                            <label htmlFor="energyUsed">Energy used (kW)</label>
                                                <input name="energyUsed" id="energyUsed" type="text" className={'form-control' + (errors.energyUsed && touched.energyUsed ? ' is-invalid' : '')} />
                                            <ErrorMessage name="energyUsed" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>

                                    <Col md="2">
                                        <div className="mt-4">
                                            <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none", activeChargingSessionId: "" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "Complete"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                {this.state.showForm === "assign-user" && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                userId: "",
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                var response = await HengyiEVChargingSessionClient.AssignToUser(
                                    this.state.activeChargingSessionId, fields.userId.value);

                                if (!response.successful) {
                                    response.data.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ showForm: "none" });
                                    setFieldValue("userId", "");
                                    await this.update();
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div className="form-group">

                                            <UserSelector
                                                value={values.userId}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                touched={touched.userId}
                                                error={errors.userId}
                                                filterVehicle={this.state.vehicleId} />

                                            <ErrorMessage name="amountToBill" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="mt-4">
                                            <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none", activeChargingSessionId: "" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "Save"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                {this.state.showForm === "accept-session" && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                message: ""
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                var response = await HengyiEVChargingSessionClient.Accept(this.state.activeChargingSessionId, fields.message);

                                if (!response.successful) {
                                    response.data.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ showForm: "none" });
                                    setFieldValue("message", "");
                                    await this.update();
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row><Col>
                                    <div className="form-group">
                                        <label htmlFor="message">Note</label>
                                        <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>

                                    <Col md="2">
                                        <div className="mt-4">
                                            <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none", activeChargingSessionId: "" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "Accept"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                {this.state.showForm === "cancel-session" && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                message: ""
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                var response = await HengyiEVChargingSessionClient.Cancel(this.state.activeChargingSessionId, fields.message);

                                if (!response.successful) {
                                    response.data.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ showForm: "none" });
                                    setFieldValue("message", "");
                                    await this.update();
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row><Col>
                                    <div className="form-group">
                                        <label htmlFor="message">Note</label>
                                        <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>

                                    <Col md="2">
                                        <div className="mt-4">
                                            <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none", activeChargingSessionId: "" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "Cancel"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="requested">Requested</option>
                                        <option value="charging">Charging</option>
                                        <option value="complete">Complete</option>
                                        <option value="cancelled">Cancelled</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.userAssignedFilter} onChange={this.handleUserAssignedChange} >
                                        <option value="">User status</option>
                                        <option value="True">Assigned</option>
                                        <option value="False">Not assigned</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.vehicleAssignedFilter} onChange={this.handleVehicleAssignedChange} >
                                        <option value="">Vehicle status</option>
                                        <option value="True">Assigned</option>
                                        <option value="False">Not assigned</option>
                                    </Input>
                                </InputGroup>
                            </Col>

                            <Col>
                                <div className="form-group">
                                    <InputGroup>
                                        <DatePicker
                                            id="date"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                            placeholderText="Filter to a date"
                                            selected={this.state.filterDate}
                                            onChange={this.handleFilterByDateChange}
                                            dateFormat='dd/MM/yyyy'
                                            className="form-control"
                                            autoComplete="off" />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.filterDate || this.state.status || this.state.userAssignedFilter || this.state.vehicleAssignedFilter) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>

                    <CardHeader>

                        <div className="form-group">
                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideCancellations"
                                            type="checkbox"
                                            checked={this.state.hideCancelled}
                                            onChange={this.handleHideCancelledChange} />  Hide cancelled sessions
                                        </label>
                                </li>   <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="futureOnly"
                                            type="checkbox"
                                            checked={this.state.hideCompleted}
                                            onChange={this.handleHideCompletedChange} />  Hide completed sessions
                                    </label>
                                </li>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideBelowThreshold"
                                            type="checkbox"
                                            checked={this.state.hideBelowThreshold}
                                            onChange={this.handleHideBelowThresholdChange} />  Hide below billing threshold
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "", sort: "", static: true },
                                        { name: "Card", sort: "card.name" },
                                        { name: "Vehicle", sort: "vehicle.licensePlate" },
                                        { name: "User", sort: "user.name" },
                                        { name: "Amount", sort: "amountToBill" },
                                        { name: "Energy (kW)", sort: "energyUsed" },
                                        { name: "Start", sort: "start" },
                                        { name: "End", sort: "end" },
                                        { name: "Status", sort: "status" },
                                        { name: "", sort: "", static: true }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />
                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.belowThreshold && <FontAwesomeIcon icon={faExclamationTriangle} />}
                                            </td>
                                            <td>
                                                {item.card && item.card.name}
                                                {!item.card && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {item.vehicle && <Link key={item.vehicle.id + "-view"} to={"/vehicles/" + item.vehicle.id}>{item.vehicle.make} {item.vehicle.model} - {item.vehicle.licensePlate}</Link>}
                                                {!item.vehicle && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                <UserLink user={item.user} />
                                            </td>
                                            <td>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amountToBill / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                            </td>
                                            <td>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.energyUsed / 1000)} displayType={'text'} thousandSeparator={true} suffix={' kW'} renderText={value => value} />
                                            </td>
                                            <td>
                                                {item.start && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.start}</Moment>}
                                                {!item.start && <i>Not started</i>}
                                            </td>
                                            <td>
                                                {item.end && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.end}</Moment>}
                                                {!item.end && <i>Not ended</i>}
                                            </td>
                                            <td>
                                                {item.status === "charging" && <Badge color="primary">Charging</Badge>}
                                                {item.status === "complete" && <Badge color="success">Complete</Badge>}
                                                {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                {item.status === "requested" && <Badge color="info">Requested</Badge>}
                                            </td>
                                            <td>
                                                {!item.user && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "assign-user" }); this.updateCurrentSession(item.id); }} className="btn btn-outline-dark">Assign</button>}
                                                {item.status === "charging" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "complete-session" }); this.updateCurrentSession(item.id); }} className="btn btn-outline-dark">Complete</button>}

                                                {item.status === "requested" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "cancel-session" }); this.updateCurrentSession(item.id); }} className="btn btn-outline-dark">Cancel</button>}
                                                {item.status === "requested" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "accept-session" }); this.updateCurrentSession(item.id); }} className="btn btn-outline-dark">Accept</button>}
                                            </td>
                                        </tr>
                                    )}

                                    {this.state.data.length === 0 && <tr><td colSpan="9"><h4 className="text-muted text-center mt-3"><i>No sessions to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { EVChargingTable };
