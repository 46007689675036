import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class PetQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithPetType(value: string) {
        this.WithParam("petType", value);
        return this;
    }
}

//******************************
// PETS
//******************************
export class HengyiPetClient {

    static async List(params: PetQueryParams) {

        var query = (await GetAPIDomain()) + "/pets" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/pets/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, petType: string, type?: string, breed?: string, description?: string, userId?: string, imageId?: string, apartmentId?: string) {

        var request: any = {
            "name": name,
            "petType": petType,
            "type": type,
            "breed": breed,
            "description": description,
            "userId": userId,
            "apartmentId": apartmentId
        };

        if (imageId)
            request.profileImageId = imageId;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/pets/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, name: string, petType: string, type?: string, breed?: string, description?: string, userId?: string, imageId?: string, apartmentId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/pets/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "petType",
                "value": petType
            }, {
                "op": "replace",
                "path": "type",
                "value": type
            }, {
                "op": "replace",
                "path": "breed",
                "value": breed
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "userId",
                "value": userId
            }, {
                "op": "replace",
                "path": "apartmentId",
                "value": apartmentId
            }, {
                "op": "replace",
                "path": "profileImageId",
                "value": imageId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/pets/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }




    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/pets/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/pets/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }
    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/pets/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}