import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { InputGroup, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { SelectField } from '../infrastructure/SelectField';
import { userContext } from '../../userContext';
import { SwiftParkCarParkTypeSelector } from '../infrastructure/SwiftParkCarParkTypeSelector';

export class BuildingsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {

        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,

                    parcelEnabled: this.state.parcelEnabled,
                    parcelDeliveryCost: this.state.parcelDeliveryCost,
                    parkingEnabled: this.state.parkingEnabled,
                    parcelHoldingLength: this.state.parcelHoldingLength,

                    exivoEnabled: this.state.exivoEnabled,
                    exivoSiteId: this.state.exivoSiteId,
                    exivoApiKey: this.state.exivoApiKey,
                    exivoApiSecret: this.state.exivoApiSecret,

                    valetProvider: this.state.valetProvider,
                    valetRequestItemCost: this.state.valetRequestItemCost,
                    valetParkCarCost: this.state.valetParkCarCost,
                    valetProviderAPIKey: this.state.valetProviderAPIKey,
                    valetPassword: this.state.valetPassword,
                    valetUserName: this.state.valetUserName,

                    inductionEnabled: this.state.inductionEnabled,
                    inductionDocumentId: this.state.documentOptions.filter(item => item.value === this.state.inductionDocumentId)[0],

                    evChargerEnabled: this.state.evChargerEnabled,
                    evChargeProvider: this.state.evChargeProvider,
                    fixedChargeFee: this.state.fixedChargeFee,
                    evPowerCostPerUnit: this.state.evPowerCostPerUnit
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (fields.inductionEnabled && !fields.inductionDocumentId) {
                        setFieldError("inductionDocumentId", "Please select the induction document that needs to be viewed");
                        return;
                    }

                    var response = await HengyiBuildingClient.Update(this.props.match.params.id, this.state.name, fields.parcelEnabled, Math.trunc(this.state.parcelDeliveryCost * 100),
                        fields.parkingEnabled, fields.valetProvider, (fields.valetProvider === "oValet") ? this.state.valetProviderAPIKey : "", Math.trunc(this.state.valetRequestItemCost * 100), Math.trunc(this.state.valetParkCarCost * 100), this.state.parcelHoldingLength);

                    await HengyiBuildingClient.UpdateInductionDocument(this.props.match.params.id, fields.inductionEnabled, fields.inductionDocumentId ? fields.inductionDocumentId.value : null);

                    await HengyiBuildingClient.UpdateEVChargeSettings(this.props.match.params.id, fields.evChargerEnabled, fields.evChargeProvider, Math.trunc(this.state.fixedChargeFee * 100), this.state.evPowerCostPerUnit * 100 / 1000);

                    await HengyiBuildingClient.UpdateExivoSettings(this.props.match.params.id, fields.exivoEnabled, this.state.exivoSiteId, this.state.exivoApiKey, this.state.exivoApiSecret);

                    await HengyiBuildingClient.UpdateValetProviderSettings(this.props.match.params.id, fields.valetProvider, this.state.valetProviderAPIKey, this.state.valetPassword, this.state.valetUserName, fields.valetDefaultParkTypeId ? fields.valetDefaultParkTypeId.value : null, fields.valetDefaultParkTypeId ? fields.valetDefaultParkTypeId.label : null);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                console.log(error);
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }
                }}
            >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <userContext.Consumer>
                        {({ features }) => {
                            return (<Form>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Update building details
                                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Update building settings and details</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>
                                                    <Link style={{ float: "right" }} to={"/buildings/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <input id="name" name="name" type="text" className="form-control" onChange={this.handleChange} />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody></Card>

                                {features && features.includes("parcels") && <Card>

                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Parcel settings
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Configuration for building parcel delivery</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                                                    <div className="form-check" style={{ textAlign: "right" }}>
                                                        <Field id="parcelEnabled" name="parcelEnabled" type="checkbox" className={'form-check-input' + (errors.parcelEnabled && touched.parcelEnabled ? ' is-invalid' : '')} />
                                                        <label className="form-check-label" htmlFor="parcelEnabled">Parcel enabled</label>
                                                        <ErrorMessage name="parcelEnabled" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    {values && values.parcelEnabled && <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="parcelDeliveryCost">Parcel Delivery Cost</label>
                                                    <InputGroup>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input id="parcelDeliveryCost" name="parcelDeliveryCost" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    </InputGroup>
                                                    <ErrorMessage name="parcelDeliveryCost" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="parcelHoldingLength">How long to hold the parcel for (hours)</label>
                                                    <input name="parcelHoldingLength" id="parcelHoldingLength" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    <ErrorMessage name="parcelHoldingLength" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>}
                                </Card>}

                                {features && features.includes("valet") && <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Valet settings
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Configuration for building valet services</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                                                    <div className="form-check" style={{ textAlign: "right" }}>
                                                        <Field id="parkingEnabled" name="parkingEnabled" type="checkbox" className={'form-check-input' + (errors.parkingEnabled && touched.parkingEnabled ? ' is-invalid' : '')} />
                                                        <label className="form-check-label" htmlFor="parkingEnabled">Valet enabled</label>
                                                        <ErrorMessage name="parkingEnabled" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    {values && values.parkingEnabled && <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetProvider">Valet Integration</label>
                                                    <select name="valetProvider"
                                                        value={values.valetProvider}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={'form-control' + (errors.valetProvider && touched.valetProvider ? ' is-invalid' : '')}>
                                                        <option value="none" label="None" />
                                                        <option value="oValet" label="O-Valet" />
                                                        <option value="swiftPark" label="SwiftPark" />
                                                    </select>
                                                    <ErrorMessage name="valetProvider" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            {values.valetProvider == "oValuet" && <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetProviderAPIKey">API Key</label>
                                                    <input id="valetProviderAPIKey" name="valetProviderAPIKey" type="text" className="form-control" disabled={!(values.valetProvider === "oValet")} onChange={this.handleChange} />
                                                    <ErrorMessage name="valetProviderAPIKey" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>}
                                            {values.valetProvider == "swiftPark" && <Col>
                                                <div className="form-group">
                                                    <SwiftParkCarParkTypeSelector
                                                        id="valetDefaultParkTypeId"
                                                        name="valetDefaultParkTypeId"
                                                        buildingId={this.props.match.params.id}
                                                        label="Default car park type"
                                                        initialId={this.state.valetDefaultParkTypeId}
                                                        value={values.valetDefaultParkTypeId}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.valetDefaultParkTypeId}
                                                        error={errors.valetDefaultParkTypeId} />

                                                </div>
                                            </Col>}
                                            {values.valetProvider == "swiftPark" && <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetUserName">SwiftPark Username</label>
                                                    <input id="valetUserName" name="valetUserName" type="text" className="form-control" disabled={!(values.valetProvider === "swiftPark")} onChange={this.handleChange} />
                                                    <ErrorMessage name="valetUserName" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>}
                                            {values.valetProvider == "swiftPark" && <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetPassword">SwiftPark Password</label>
                                                    <input id="valetPassword" name="valetPassword" type="text" className="form-control" disabled={!(values.valetProvider === "swiftPark")} onChange={this.handleChange} />
                                                    <ErrorMessage name="valetPassword" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>}
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetParkCarCost">Valet cost</label>
                                                    <InputGroup>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input name="valetParkCarCost" id="valetParkCarCost" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    </InputGroup>
                                                    <ErrorMessage name="valetParkCarCost" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="valetRequestItemCost">Request item cost</label>
                                                    <InputGroup>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input name="valetRequestItemCost" id="valetRequestItemCost" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    </InputGroup>
                                                    <ErrorMessage name="valetRequestItemCost" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>}
                                </Card>}

                                {features && features.includes("exivo") && <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Exivo Access Control
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Configuration for Exivo access control</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                                                    <div className="form-check" style={{ textAlign: "right" }}>
                                                        <Field id="exivoEnabled" name="exivoEnabled" type="checkbox" className={'form-check-input' + (errors.exivoEnabled && touched.exivoEnabled ? ' is-invalid' : '')} />
                                                        <label className="form-check-label" htmlFor="exivoEnabled">Exivo enabled</label>
                                                        <ErrorMessage name="exivoEnabled" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    {values && values.exivoEnabled && <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="exivoApiKey">API Key</label>
                                                    <input name="exivoApiKey" id="exivoApiKey" type="text" className="form-control" onChange={this.handleChange} />
                                                    <ErrorMessage name="exivoApiKey" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="exivoApiSecret">API Secret</label>
                                                    <input name="exivoApiSecret" id="exivoApiSecret" type="text" className="form-control" onChange={this.handleChange} />
                                                    <ErrorMessage name="exivoApiSecret" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="exivoSiteId">Site Id</label>
                                                    <input name="exivoSiteId" id="exivoSiteId" type="text" className="form-control" onChange={this.handleChange} />
                                                    <ErrorMessage name="exivoSiteId" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>}
                                </Card>}

                                {features && features.includes("ev-charging") && <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> EV Charging settings
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Configuration for building EV charging services</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                                                    <div className="form-check" style={{ textAlign: "right" }}>
                                                        <Field id="evChargerEnabled" name="evChargerEnabled" type="checkbox" className={'form-check-input' + (errors.evChargerEnabled && touched.evChargerEnabled ? ' is-invalid' : '')} />
                                                        <label className="form-check-label" htmlFor="evChargerEnabled">EV Charging enabled</label>
                                                        <ErrorMessage name="evChargerEnabled" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    {values && values.evChargerEnabled && <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="evChargeProvider">EV Charging Integration</label>
                                                    <select name="evChargeProvider"
                                                        value={values.evChargeProvider}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={'form-control' + (errors.evChargeProvider && touched.evChargeProvider ? ' is-invalid' : '')}>
                                                        <option value="none" label="None" />
                                                        <option value="jetCharge" label="JetCharge" />
                                                    </select>
                                                    <ErrorMessage name="evChargeProvider" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="fixedChargeFee">Fixed charge fee</label>
                                                    <InputGroup>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input name="fixedChargeFee" id="fixedChargeFee" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    </InputGroup>
                                                    <ErrorMessage name="fixedChargeFee" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="evPowerCostPerUnit">Power cost per kW</label>
                                                    <InputGroup>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input name="evPowerCostPerUnit" id="evPowerCostPerUnit" type="number" step="any" className="form-control" onChange={this.handleChange} />
                                                    </InputGroup>
                                                    <ErrorMessage name="evPowerCostPerUnit" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>}
                                </Card>}

                                {features && features.includes("inductions") && <Card>

                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Induction settings
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Configuration for building induction for residents</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                                                    <div className="form-check" style={{ textAlign: "right" }}>
                                                        <Field id="inductionEnabled" name="inductionEnabled" type="checkbox" className={'form-check-input' + (errors.inductionEnabled && touched.inductionEnabled ? ' is-invalid' : '')} />
                                                        <label className="form-check-label" htmlFor="inductionEnabled">Resident induction enabled</label>
                                                        <ErrorMessage name="inductionEnabled" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    {values && values.inductionEnabled && <CardBody>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <SelectField
                                                        id="inductionDocumentId"
                                                        name="inductionDocumentId"
                                                        label="Induction document"
                                                        placeholder="Select a document"
                                                        options={this.state.documentOptions}
                                                        value={values.inductionDocumentId}
                                                        isMulti={false}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.inductionDocumentId}
                                                        error={errors.inductionDocumentId}
                                                        isClearable={true}
                                                        backspaceRemovesValue={true}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>}
                                </Card>}

                                <div className="form-group">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "SAVE"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>)
                        }}
                    </userContext.Consumer>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/buildings/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    {this.state.data && this.renderForm(this.state.data)}

                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var item = await HengyiBuildingClient.Get(this.props.match.params.id);
            var documentOptions = await HengyiApi.GetListFiles(new ListQueryParams().Paginate(0, 10000));

            console.log(item);

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    data: item.data,
                    name: item.data.name,
                    parcelEnabled: item.data.parcelEnabled,
                    parcelDeliveryCost: item.data.parcelDeliveryCost / 100,
                    parcelHoldingLength: item.data.parcelHoldingLength,
                    parkingEnabled: item.data.parkingEnabled,
                    valetProvider: item.data.valetProvider,
                    valetRequestItemCost: item.data.valetRequestItemCost / 100,
                    valetParkCarCost: item.data.valetParkCarCost / 100,
                    valetProviderAPIKey: item.data.valetProviderAPIKey,
                    valetPassword: item.data.valetPassword,
                    valetUserName: item.data.valetUserName,

                    valetDefaultParkTypeId: item.data.valetDefaultParkTypeId,

                    evChargerEnabled: item.data.evChargerEnabled,
                    evChargeProvider: item.data.evChargeProvider,
                    fixedChargeFee: item.data.fixedChargeFee / 100,
                    evPowerCostPerUnit: +(Math.round((item.data.evPowerCostPerWatt / 100 * 1000) + "e+2") + "e-2"),

                    exivoEnabled: item.data.exivoEnabled,
                    exivoSiteId: item.data.exivoSiteId,
                    exivoApiKey: item.data.exivoApiKey,
                    exivoApiSecret: item.data.exivoApiSecret,

                    loading: false,
                    init: true,
                    inductionEnabled: item.data.inductionEnabled,
                    inductionDocumentId: item.data.inductionDocument ? item.data.inductionDocument.id : null,
                    documentOptions: documentOptions.data.data.map(function (item) {
                        return {
                            value: item.id,
                            label: item.name
                        };
                    })
                }, () => {
                    document.getElementById("name").value = item.data.name;

                    if (document.getElementById("parcelDeliveryCost"))
                        document.getElementById("parcelDeliveryCost").value = item.data.parcelDeliveryCost / 100;
                    if (document.getElementById("parcelHoldingLength"))
                        document.getElementById("parcelHoldingLength").value = item.data.parcelHoldingLength;

                    if (document.getElementById("valetProviderAPIKey"))
                        document.getElementById("valetProviderAPIKey").value = item.data.valetProviderAPIKey;
                    if (document.getElementById("valetParkCarCost"))
                        document.getElementById("valetParkCarCost").value = item.data.valetParkCarCost / 100;
                    if (document.getElementById("valetRequestItemCost"))
                        document.getElementById("valetRequestItemCost").value = item.data.valetRequestItemCost / 100;

                    if (document.getElementById("valetPassword"))
                        document.getElementById("valetPassword").value = item.data.valetPassword;
                    if (document.getElementById("valetUserName"))
                        document.getElementById("valetUserName").value = item.data.valetUserName;

                    if (document.getElementById("exivoSiteId"))
                        document.getElementById("exivoSiteId").value = item.data.exivoSiteId;
                    if (document.getElementById("exivoApiKey"))
                        document.getElementById("exivoApiKey").value = item.data.exivoApiKey;
                    if (document.getElementById("exivoApiSecret"))
                        document.getElementById("exivoApiSecret").value = item.data.exivoApiSecret;

                    if (document.getElementById("fixedChargeFee"))
                        document.getElementById("fixedChargeFee").value = item.data.fixedChargeFee / 100;
                    if (document.getElementById("evPowerCostPerUnit"))
                        document.getElementById("evPowerCostPerUnit").value = +(Math.round((item.data.evPowerCostPerWatt / 100 * 1000) + "e+2") + "e-2");
                });

            }
        }
    }
}
