import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { UserSelector } from '../infrastructure/UserSelector';
import { HengyiTicketClient } from '../infrastructure/HengyiClient/Tickets';
import InnerImageZoom from 'react-inner-image-zoom'
import { GetAssetDomain } from '../infrastructure/Helper';

function required(value) {
    console.log(value);
    if (!value) {
        console.log("Error: " + value);
        return 'This is a required field';
    }
}

export class ConvertTicketToIncident extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], categoryOptions: [], apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };

    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var ticketDetails = await HengyiTicketClient.Get(this.props.match.params.id);

            if (ticketDetails.data.submittedBy)
                this.setState({ userId: ticketDetails.data.submittedBy.id });

            this.setState({
                init: true,
                location: "",
                data: ticketDetails.data,
                loading: false
            });
        }
    }

    renderForm() {

        var title = "";
        if (this.state.data.type)
            title = title + this.state.data.type.name;
        if (this.state.data.category) {
            if (this.state.data.type)
                title = title + " - ";
            title = title + this.state.data.category.name;
        }


        return (<Formik
            initialValues={{
                title: title,
                location: this.state.data.location,
                details: this.state.data.description,
                cause: "",
                type: "Incident",
                outcome: "",
                harmType: "",

                someoneHarmed: false,
                personAffected: "",
                personType: "",
                userId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.title) {
                    setFieldError("title", "Please set the title");
                    return;
                }

                if (!fields.details) {
                    setFieldError("details", "Please list the incident details");
                    return;
                }

                if (!fields.location) {
                    setFieldError("location", "Please specify where this occurred");
                    return;
                }

                if (!fields.cause) {
                    setFieldError("cause", "Please specify cause of the incident");
                    return;
                }

                if (!fields.type) {
                    setFieldError("type", "Please specify type of incident");
                    return;
                }

                if (!fields.outcome) {
                    setFieldError("outcome", "What was the outcome?");
                    return;
                }

                if (fields.someoneHarmed && !fields.harmType) {
                    setFieldError("harmType", "Please choose an option");
                    return;
                }

                if (!fields.personAffected) {
                    setFieldError("personAffected", "Please list who was affected");
                    return;
                }

                if (!fields.personType) {
                    setFieldError("personType", "Please specify what type of person was affected");
                    return;
                }

                const params = {
                    title: fields.title,
                    details: fields.details,
                    location: fields.location,
                    cause: fields.cause,
                    type: fields.type,
                    outcome: fields.outcome,
                    wasSomeoneHarmed: fields.someoneHarmed,
                    harmType: fields.harmType,
                    personAffected: fields.personAffected,
                    personType: fields.personType
                };

                if (fields.userId && fields.userId.value)
                    params.reportedById = fields.userId.value;

                const response = await fetch((await GetAPIDomain()) + "/tickets/" + this.props.match.params.id + "/convert-to/incident", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {


                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <Field validate={required} name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                            <ErrorMessage name="title" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="location">Location</label>
                            <Field validate={required} name="location" type="text" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} />
                            <ErrorMessage name="location" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>


                <div className="form-group">
                    <label htmlFor="details">Details</label>
                    <Field name="details" type="text" component="textarea" className={'form-control' + (errors.details && touched.details ? ' is-invalid' : '')} />
                    <ErrorMessage name="details" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                        <label htmlFor="cause">Cause</label>
                        <Field name="cause" type="text" component="textarea" className={'form-control' + (errors.cause && touched.cause ? ' is-invalid' : '')} />
                        <ErrorMessage name="cause" component="div" className="invalid-feedback" />
                </div>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="type">Type</label>
                            <select type="select" name="type"
                                placeholder="Please choose type"
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                <option value="">Please choose type</option>
                                <option value="Incident">Incident</option>
                                <option value="Accident">Accident</option>
                            </select>
                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="outcome">Outcome</label>
                            <select type="select" name="outcome"
                                placeholder="Please choose outcome"
                                value={values.outcome}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.outcome && touched.outcome ? ' is-invalid' : '')}>
                                <option value="">Please choose outcome</option>
                                <option value="Minor">Minor</option>
                                <option value="Serious">Serious</option>
                            </select>
                            <ErrorMessage name="outcome" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <div style={{ paddingRight: "10px", paddingTop: "13px" }}>
                    <div className="form-check">
                        <Field id="someoneHarmed" name="someoneHarmed" type="checkbox" className={'form-check-input' + (errors.someoneHarmed && touched.someoneHarmed ? ' is-invalid' : '')} />
                        <label className="form-check-label" htmlFor="someoneHarmed">Someone was harmed</label>
                        <ErrorMessage name="someoneHarmed" component="div" className="invalid-feedback" />
                    </div>
                </div>
                {values.someoneHarmed &&
                    <div className="form-group" style={{ paddingTop: "13px" }}>
                        <label htmlFor="harmType">Injury or Illness?</label>
                        <select type="select" name="harmType"
                            placeholder="Type of harm"
                            value={values.harmType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={'form-control' + (errors.harmType ? ' is-invalid' : '')}>
                            <option value="">Type of harm</option>
                            <option value="Injury">Injury</option>
                            <option value="Illness">Illness</option>
                        </select>
                        <ErrorMessage name="harmType" component="div" className="invalid-feedback" />
                    </div>}
                <Row>

                    <Col>
                        <div className="form-group" style={{ paddingTop: "13px" }}>
                            <label htmlFor="personAffected">Person Affected</label>
                            <Field validate={required} name="personAffected" type="text" className={'form-control' + (errors.personAffected && touched.personAffected ? ' is-invalid' : '')} />
                            <ErrorMessage name="personAffected" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>

                        <div className="form-group" style={{ paddingTop: "13px" }}>
                            <label htmlFor="personType">Type of person</label>
                            <select type="select" name="personType"
                                placeholder="Type of person"
                                value={values.harmType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.personType && touched.personType ? ' is-invalid' : '')}>
                                <option value="">Type of person</option>
                                <option value="Employee">Employee</option>
                                <option value="Contractor">Contractor</option>
                                <option value="Visitor">Visitor</option>
                                <option value="Resident">Resident</option>
                                <option value="Volunteer">Volunteer</option>
                                <option value="Other">Other</option>
                            </select>
                            <ErrorMessage name="personType" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group" style={{ paddingTop: "13px" }}>
                            <UserSelector
                                label="Reported by"
                                value={values.userId}
                                initialUserId={this.state.userId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userId}
                                error={errors.userId} />
                        </div>
                    </Col>
                </Row>
                <hr />

                <h6>Images</h6>
                <hr />

                <Row>
                    {this.state.data && this.state.data.images && this.state.data.images.length > 0 && this.state.data.images.map(item =>
                        <Col style={{ maxWidth: "300px" }}>
                            <InnerImageZoom src={this.state.assetUrl + "/image/" + item + ".jpg"} />
                        </Col>
                    )}
                </Row>
                {(!this.state.data || !this.state.data.images || !this.state.data.images.length > 0) &&
                    <h4 className="text-muted text-center mt-3"><i>No images uploaded</i></h4>
                }

                <hr />

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "CONVERT TICKET"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/tickets/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Convert to an incident
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Convert this ticket into an incident</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/tickets/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}
