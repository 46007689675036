import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { UserLink } from '../Users/UserLink';
import { HengyiEventClient, EventQueryParams } from '../infrastructure/HengyiClient/Events';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import { TableHeader } from '../infrastructure/TableHeader';

export class Events extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "",
            showCancelled: false, status: "", sort: "name", direction: "asc"
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleShowCancelled = this.handleShowCancelled.bind(this);
    }

    handleShowCancelled = (evt) => {
        this.setState({
            showCancelled: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            skip: 0,
            startFilter: "",
            endFilter: ""
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Name", sort: "name" },
                        { name: "Location", sort: "location" },
                        { name: "Status", sort: "status" },
                        { name: "Created By", sort: "createdBy.name" },
                        { name: "Date/time", sort: "startTime" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.name && item.name}
                                {!item.name && <i>Not specified</i>}</td>
                            <td>
                                {item.location && item.location}
                                {!item.location && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                {item.status === "approved" && <Badge color="success">Active</Badge>}
                                {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                {!item.status && <i>Not specified</i>}
                            </td>
                            <td>
                                <UserLink user={item.createdBy} />
                            </td>
                            <td>
                                {item.isBookable && <i>Bookable event, see scheduled dates</i>}
                                {!item.isBookable && <div>
                                    {!item.startTime && <i>No start</i>}
                                    {item.startTime && <Moment utc local format="ddd DD MMM YY, hh:mm a">{item.startTime}</Moment>}
                                    <br />
                                    {!item.endTime && <i>No end</i>}
                                    {item.endTime && <Moment utc local format="ddd DD MMM YY, hh:mm a">{item.endTime}</Moment>}
                                </div>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/events/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/events/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="7"><h4 className="text-muted text-center mt-3"><i>No events to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Events ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of events for the building</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/events/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="approved">Active</option>
                                            <option value="pending">Pending</option>
                                            {this.state.showCancelled && <option value="cancelled">Cancelled</option>}
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <DatePicker id="start"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="Start date"
                                        selected={this.state.startFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                startFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <DatePicker id="end"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="End date"
                                        selected={this.state.endFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                endFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.startFilter || this.state.endFilter) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>


                        <CardHeader>
                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="showCancelled"
                                                type="checkbox"
                                                checked={this.state.showCancelled}
                                                onChange={this.handleShowCancelled} />  Show cancelled
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiEventClient.List(new EventQueryParams()
                .WithStart(this.state.startFilter)
                .WithEnd(this.state.endFilter)
                .WithShowCancelled(this.state.showCancelled ? "true" : "false")
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithStatus(this.state.status)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
