import React, { Component } from 'react';
import { HengyiStatisticsClient } from './infrastructure/HengyiClient/Statistics';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Badge, Button, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faDollarSign, faQuestionCircle, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { userContext } from '../userContext';

export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            authenticated: true
        };
    }

    async componentDidMount() {
        this.populateData();

        this.timer = setInterval(() => this.populateData(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiStatisticsClient.Dashboard();

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }


        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Row>
                            {this.state.data && this.state.data.length > 0 && this.state.data.map(stat => <Col md="4" key={stat.title}>
                                <Card>
                                    <Link to={stat.link} className="media friendlist-box">
                                        <CardBody>
                                            <h5>{stat.title}</h5>
                                            <h2>{stat.value}</h2>
                                            <span className="d-block text-muted" style={{ height: "56px" }}>{stat.description}</span>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>)}
                        </Row>
                    </Col>
                    <Col md="4">

                        <userContext.Consumer>
                            {({ features }) => {
                                return (<Card className="note-bar">
                                    <CardHeader><h5>Quick actions</h5></CardHeader>
                                    <CardBody>
                                        {features && features.includes("parcels") && <Link to="/parcels/new?origin=dashboard" className="media friendlist-box">
                                            <div className="mr-4 photo-table">
                                                <i className="feather icon-package"></i>
                                            </div>
                                            <div className="media-body">
                                                <h6>Add parcel</h6>
                                                <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                                <p className="text-muted m-0">Add a new parcel that's been received</p>
                                            </div>
                                        </Link>}

                                        {features && features.includes("conversations") && <Link to="/conversations/new?origin=dashboard" className="media friendlist-box">
                                            <div className="mr-4 photo-table">
                                                <i className="feather icon-message-circle"></i>
                                            </div>
                                            <div className="media-body">
                                                <h6>Message resident</h6>
                                                <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                                <p className="text-muted m-0">Start a new conversation with a resident</p>
                                            </div>
                                        </Link>}

                                        {features && features.includes("billing") && <Link to="/charges/new?origin=dashboard" className="media friendlist-box">
                                            <div className="mr-4 photo-table fa">
                                                <i><FontAwesomeIcon icon={faDollarSign} /></i>
                                            </div>
                                            <div className="media-body">
                                                <h6>Charge resident</h6>
                                                <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                                <p className="text-muted m-0">Add a charge to a resident's account</p>
                                            </div>
                                        </Link>}


                                        <a href="mailto:help@vlobby.co.nz?subject=Portal%20Support%20Request" className="media friendlist-box">
                                            <div className="mr-4 photo-table fa">
                                                <i><FontAwesomeIcon icon={faQuestionCircle} /></i>
                                            </div>
                                            <div className="media-body">
                                                <h6>Need help?</h6>
                                                <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                                <p className="text-muted m-0">Email help@vlobby.co.nz</p>
                                            </div>
                                        </a>

                                        <Link to="/apartments?hasAccessibilityRequirements=true" className="media friendlist-box">
                                            <div className="mr-4 photo-table fa">
                                                <i><FontAwesomeIcon icon={faUniversalAccess} /></i>
                                            </div>
                                            <div className="media-body">
                                                <h6>Accessible spaces</h6>
                                                <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                                <p className="text-muted m-0">Spaces with accessibility requirements</p>
                                            </div>
                                        </Link>

                                    </CardBody>
                                </Card>)
                            }}
                        </userContext.Consumer>
                    </Col>
                </Row>

            </div>
        );
    }
}
