import React, { Component } from 'react';
import { HengyiApi, UserQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { TableHeader } from '../infrastructure/TableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWheelchair, faCreditCard } from '@fortawesome/free-solid-svg-icons'

export class Users extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowSuspended = this.handleShowSuspended.bind(this);
        this.handleShowAccessibleOnly = this.handleShowAccessibleOnly.bind(this);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", userStatus: "",
            roleId: "", showSuspended: false, sort: "name", direction: "asc"
        };
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleShowSuspended = (evt) => {
        this.setState({
            showSuspended: evt.target.checked
        }, () => this.update());
    }

    handleShowAccessibleOnly = (evt) => {
        this.setState({
            showAccessibleOnly: evt.target.checked
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            startFilter: "",
            endFilter: "",
            skip: 0
        }, () => this.update());

    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "", sort: "", static: true },
                        { name: "Name", sort: "name" },
                        { name: "Email", sort: "email" },
                        { name: "Mobile", sort: "mobile" },
                        { name: "Roles", sort: "roles", static: true },
                        { name: "Status", sort: "status" },
                        { name: "Parcel preference", sort: "status", static: true },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.hasAccessibilityRequirements && <span><FontAwesomeIcon icon={faWheelchair} />&nbsp;</span>}
                                {user.hasCreditCardLoaded && <span><FontAwesomeIcon icon={faCreditCard} />&nbsp;</span>}
                            </td>
                            <td>{user.name} {user.surname}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.roles && user.roles.length > 0 && user.roles.map(role =>
                                <Link key={user.id + role.id + "-view"} to={"/roles/" + role.id}>{role.name}</Link>
                            )}{(!user.roles || !user.roles.length > 0) && <i>Resident or owner</i>}</td>
                            <td>
                                {user.status === "active" && <Badge color="primary">Active</Badge>}
                                {user.status === "suspended" && <Badge color="danger">Suspended</Badge>}
                            </td>
                            <td>
                                {user.preferredParcelDeliveryOption === "askEachTime" && <span>Ask each time</span>}
                                {user.preferredParcelDeliveryOption === "bringUp" && <span>Bring up &nbsp;
                                    {!user.preferredParcelDeliverySlot && <Badge color="info">Any time</Badge>}
                                    {user.preferredParcelDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                    {user.preferredParcelDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                    {user.preferredParcelDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}
                                    </span>
                                }
                            </td>
                            <td>
                                <Link key={user.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/users/" + user.id}>Details</Link>
                                <Link key={user.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/users/edit/" + user.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No users to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Users ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of users who can access the system.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/users/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <DatePicker id="start"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="Start date"
                                        selected={this.state.startFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                startFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <DatePicker id="end"
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="End date"
                                        selected={this.state.endFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                endFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.startFilter || this.state.endFilter) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>


                        <CardHeader>
                            <Row>
                                <Col></Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="showSuspended"
                                                        type="checkbox"
                                                        checked={this.state.showSuspended}
                                                        onChange={this.handleShowSuspended} />  Show suspended
                                                </label>
                                            </li>
                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="showAccessibleOnly"
                                                        type="checkbox"
                                                        checked={this.state.showAccessibleOnly}
                                                        onChange={this.handleShowAccessibleOnly} />  Show accessible only
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiApi.GetUsers(new UserQueryParams()
                .WithStatus(this.state.userStatus)
                .WithAccessibility(this.state.showAccessibleOnly ? true : null)
                .WithRole(this.state.roleId)
                .WithShowSuspended(this.state.showSuspended ? "true" : "false")
                .WithStart(this.state.startFilter)
                .WithEnd(this.state.endFilter)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
