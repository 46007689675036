import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiApartmentClient } from '../infrastructure/HengyiClient/Apartments';
import { SelectField } from "../infrastructure/SelectField";
import { UserSelector } from '../infrastructure/UserSelector';

export class ApartmentEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    description: this.state.description,
                    name: this.state.name,
                    propertyTitleNumber: this.state.propertyTitleNumber,
                    settled: this.state.settled,
                    powerMeterNumber: this.state.powerMeterNumber,
                    waterMeterNumber: this.state.waterMeterNumber,
                    lettingAgentName: this.state.lettingAgentName,
                    lettingAgentBusiness: this.state.lettingAgentBusiness,
                    lettingAgentMobile: this.state.lettingAgentMobile,
                    lettingAgentEmail: this.state.lettingAgentEmail,
                    userId: "",
                    apartmentType: this.state.apartmentType ? this.state.apartmentTypeOptions.filter(item => item.value === this.state.apartmentType.id) : null,

                    hasAccessibilityRequirements: this.state.hasAccessibilityRequirements,
                    accessibilityRequirement: this.state.accessibilityRequirement,
                    accessibilityOrientationRequired: this.state.accessibilityOrientationRequired,
                    accessibilityMedicalRequired: this.state.accessibilityMedicalRequired
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    if (fields.hasAccessibilityRequirements && !this.state.accessibilityRequirement) {
                        setFieldError("accessibilityRequirement", "Please enter the accessibility requirements");
                        return;
                    }


                    var response = await HengyiApartmentClient.Update(
                        this.props.match.params.id,
                        this.state.name,
                        this.state.description,
                        fields.apartmentType == null ? null : fields.apartmentType.value,
                        this.state.powerMeterNumber,
                        this.state.waterMeterNumber,
                        this.state.lettingAgentName,
                        this.state.lettingAgentBusiness,
                        this.state.lettingAgentMobile,
                        this.state.lettingAgentEmail,
                        this.state.propertyTitleNumber,
                        fields.settled,
                        fields.hasAccessibilityRequirements,
                        this.state.accessibilityRequirement,
                        fields.accessibilityOrientationRequired,
                        this.state.accessibilityMedicalRequired,
                        fields.userId ? fields.userId.value : null);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input name="name" id="name" type="text" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="propertyTitleNumber">Property title number</label>
                                    <input name="propertyTitleNumber" id="propertyTitleNumber" type="text" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="propertyTitleNumber" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartmentType"
                                    name="apartmentType"
                                    label="Apartment type"
                                    placeholder="Select type"
                                    options={this.state.apartmentTypeOptions}
                                    value={values.apartmentType}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentType}
                                    error={errors.apartmentType}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                            </Col>
                            <Col>
                                <UserSelector
                                    label="Primary contact"
                                    value={values.userId}
                                    initialUserId={this.state.userId}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.userId}
                                    error={errors.userId}
                                    filterApartment={this.props.match.params.id} />

                            </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                    <textarea name="description" id="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>


                            <div className="form-group">
                                <ul className="list-unstyled">
                                    <li>
                                        <label><Field name="settled" type="checkbox" checked={values.settled} /> Space settled</label>
                                    </li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                    <h6>Utilities</h6>
                    <Row>
                        <Col>
                            <div className="form-group">
                                    <label htmlFor="powerMeterNumber">Power meter (ICP):</label>
                                    <input name="powerMeterNumber" id="powerMeterNumber" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="powerMeterNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="waterMeterNumber">Water meter number</label>
                                    <input name="waterMeterNumber" id="waterMeterNumber" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="waterMeterNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <h6>Letting agent</h6>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lettingAgentName">Name</label>
                                    <input name="lettingAgentName" id="lettingAgentName" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="lettingAgentName" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lettingAgentBusiness">Business</label>
                                    <input name="lettingAgentBusiness" id="lettingAgentBusiness" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="lettingAgentBusiness" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lettingAgentMobile">Mobile</label>
                                    <input name="lettingAgentMobile" id="lettingAgentMobile" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="lettingAgentMobile" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lettingAgentEmail">Email</label>
                                    <input name="lettingAgentEmail" id="lettingAgentEmail" type="text" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="lettingAgentEmail" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        </Row>

                        <h6>Accessibility</h6>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <ul className="list-unstyled">
                                        <li>
                                            <label><Field name="hasAccessibilityRequirements" type="checkbox" checked={values.hasAccessibilityRequirements} /> Space has accessibility requirements</label>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        {values.hasAccessibilityRequirements && <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="accessibilityRequirement">What are the accessibility requirements?*</label>
                                    <input name="accessibilityRequirement" id="accessibilityRequirement" type="text" className={'form-control' + (errors.accessibilityRequirement && touched.accessibilityRequirement ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="accessibilityRequirement" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="accessibilityMedicalRequired">Any medical requirements to be mindful of?</label>
                                    <input name="accessibilityMedicalRequired" id="accessibilityMedicalRequired" type="text" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="accessibilityMedicalRequired" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>}

                        {values.hasAccessibilityRequirements && <Row>
                            <Col>
                                <div className="form-group">
                                    <ul className="list-unstyled">
                                        <li>
                                            <label><Field name="accessibilityOrientationRequired" type="checkbox" checked={values.accessibilityOrientationRequired} /> Space users require an accessibility orientation</label>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>}

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/apartments/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update space details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details for this space</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/apartments/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var item = await HengyiApartmentClient.Get(this.props.match.params.id);

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                var apartmentTypes = await HengyiApi.GetApartmentTypes(new ListQueryParams().Paginate(0, 1000));

                if (item.data.primaryContact)
                    this.setState({ userId: item.data.primaryContact.id });

                this.setState({
                    data: item.data,
                    description: item.data.description,
                    name: item.data.name,
                    apartmentType: item.data.type,
                    settled: item.data.settled,
                    propertyTitleNumber: item.data.propertyTitleNumber,
                    waterMeterNumber: item.data.waterMeterNumber,
                    powerMeterNumber: item.data.powerMeterNumber,
                    lettingAgentName: item.data.lettingAgentName,
                    lettingAgentBusiness: item.data.lettingAgentBusiness,
                    lettingAgentMobile: item.data.lettingAgentMobile,
                    lettingAgentEmail: item.data.lettingAgentEmail,

                    accessibilityMedicalRequired: item.data.accessibilityMedicalRequired,
                    accessibilityOrientationRequired: item.data.accessibilityOrientationRequired,
                    accessibilityRequirement: item.data.accessibilityRequirement,
                    hasAccessibilityRequirements: item.data.hasAccessibilityRequirements,

                    loading: false,
                    init: true,
                    apartmentTypeOptions: apartmentTypes.data.data.map(function (item) { return { value: item.id, label: item.name }; })
                }, () => {
                    document.getElementById("name").value = item.data.name ? item.data.name : "";
                    document.getElementById("description").value = item.data.description ? item.data.description : "";
                    document.getElementById("propertyTitleNumber").value = item.data.propertyTitleNumber ? item.data.propertyTitleNumber : "";

                    document.getElementById("waterMeterNumber").value = item.data.waterMeterNumber ? item.data.waterMeterNumber : "";
                    document.getElementById("powerMeterNumber").value = item.data.powerMeterNumber ? item.data.powerMeterNumber : "";

                    document.getElementById("lettingAgentName").value = item.data.lettingAgentName ? item.data.lettingAgentName : "";
                    document.getElementById("lettingAgentBusiness").value = item.data.lettingAgentBusiness ? item.data.lettingAgentBusiness : "";
                    document.getElementById("lettingAgentMobile").value = item.data.lettingAgentMobile ? item.data.lettingAgentMobile : "";
                    document.getElementById("lettingAgentEmail").value = item.data.lettingAgentEmail ? item.data.lettingAgentEmail : "";

                    if (document.getElementById("accessibilityRequirement"))
                        document.getElementById("accessibilityRequirement").value = item.data.accessibilityRequirement ? item.data.accessibilityRequirement : "";

                    if (document.getElementById("accessibilityMedicalRequired"))
                        document.getElementById("accessibilityMedicalRequired").value = item.data.accessibilityMedicalRequired ? item.data.accessibilityMedicalRequired : "";
                });

            }
        }
    }
}
