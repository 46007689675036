import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import FileSaver from 'file-saver';
import { store } from 'react-easy-state';
import { userContext } from '../../userContext';
import { UserSelector } from '../infrastructure/UserSelector';
import { TableHeader } from '../infrastructure/TableHeader';
import { HandoverNoteParams, HengyiHandoverNoteClient } from '../infrastructure/HengyiClient/HandoverNotes';
import { FacilitySelector } from '../infrastructure/FacilitySelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

const persistedState = store({
    search: "", status: "", isAssigned: "", hideClosed: true, myTicketsOnly: false, assignedToId: "", sort: "created", direction: "desc",
    skip: 0, take: 50
});

export class HandoverNotes extends Component {

    constructor(props) {
        super(props);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleIsAssignedChange = this.handleIsAssignedChange.bind(this);

        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
        this.handleFacilityIdChange = this.handleFacilityIdChange.bind(this);

        this.handleMyTicketsOnlyChange = this.handleMyTicketsOnlyChange.bind(this);
        this.handleHideClosedChange = this.handleHideClosedChange.bind(this);

        this.state = {
            data: [], exporting: false, loading: false, total: 0, skip: 0, take: 50,
            authenticated: true, filterParamsInit: false, userInit: false, search: ""
        };
    }

    handleHideClosedChange = (evt) => {
        persistedState.hideClosed = evt.target.checked;
        this.update();
    }

    handleSearchChange(evt) {
        persistedState.search = evt.target.value;
        this.setState({
            search: evt.target.value
        });
    }

    handleStatusChange(evt) {
        persistedState.status = evt.target.value;
        this.update();
    }

    handleMyTicketsOnlyChange(evt) {

        console.log(evt.target.value);

        persistedState.myTicketsOnly = evt.target.value;
        this.update();
    }

    handleIsAssignedChange(evt) {
        persistedState.isAssigned = evt.target.value;
        this.update();
    }

    async handleUserIdChange(name, value) {

        persistedState.assignedToId = value;
        if (value) {
            persistedState.initialAssignedToId = value.value;
        } else {
            persistedState.initialAssignedToId = null;
        }

        if (this.state.userInit)
            this.update();
    }

    async handleApartmentIdChange(name, value) {

        persistedState.apartmentId = value;
        if (value) {
            persistedState.initialApartmentId = value.value;
        } else {
            persistedState.initialApartmentId = null;
        }

        if (this.state.apartmentInit)
            this.update();
    }

    async handleFacilityIdChange(name, value) {

        persistedState.facilityId = value;
        if (value) {
            persistedState.initialFacilityId = value.value;
        } else {
            persistedState.initialFacilityId = null;
        }

        if (this.state.facilityInit)
            this.update();
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("reset"))
            this.clear(false);

        persistedState.search = urlParams.get("q") ? urlParams.get("q") : "";
        persistedState.status = urlParams.get("status");
        persistedState.isAssigned = urlParams.get("isAssigned");
        persistedState.myTicketsOnly = urlParams.get("myTicketsOnly");

        if (urlParams.get("assignedToId")) {
            persistedState.initialAssignedToId = urlParams.get("assignedToId");
        } else if (persistedState.assignedToId) {
            persistedState.initialAssignedToId = persistedState.assignedToId.value;
        } else {

        }
        persistedState.status = urlParams.get("status");

        this.setState({
            filterParamsInit: true,
        }, () => this.update());

    }

    renderTable(data) {
        return (
            <div className="table-responsive">
                <table className='table table-linked' aria-labelledby="tabelLabel">

                    <TableHeader
                        sort={persistedState.sort}
                        direction={persistedState.direction}
                        columns={[
                            { name: "Title", sort: "title" },
                            { name: "Space", sort: "", static: true },
                            { name: "Assigned to", sort: "assignedTo.name" },
                            { name: "Status", sort: "status" },
                            { name: "Created by", sort: "submittedBy.name" },
                            { name: "Created", sort: "created" }]}
                        onUpdate={(sort, direction) => {
                            persistedState.sort = sort;
                            persistedState.direction = direction;
                            this.update();
                        }}
                    />
                    <tbody>
                        {data.map(item =>
                            <tr key={item.id}>
                                <td>
                                    <Link key={item.id + "-title-view"} to={"/handover-notes/" + item.id}>
                                        {item.title}{!item.title && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-space-view"} to={"/handover-notes/" + item.id}>
                                        {item.apartment && <span>{item.apartment.name}{item.facility && ", "}</span>}
                                        {item.facility && <span>{item.facility.name}</span>}
                                        {!item.facility && !item.apartment && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-assignedto-view"} to={"/handover-notes/" + item.id}>
                                        {item.assignedTo && item.assignedTo.name + " " + item.assignedTo.surname}
                                        {!item.assignedTo && <i>Nobody</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-status-view"} to={"/handover-notes/" + item.id}>
                                        {item.status === "open" && <Badge color="primary">Open</Badge>}
                                        {item.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                        {!item.status && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-created-view"} to={"/handover-notes/" + item.id}>
                                        {item.submittedBy && item.submittedBy.name + " " + item.submittedBy.surname}
                                        {!item.submittedBy && <i>Not specified</i>}
                                    </Link>
                                </td>
                                <td>
                                    <Link key={item.id + "-created-view"} to={"/handover-notes/" + item.id}>
                                        {item.created && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>}
                                        {!item.created && <i>Not specified</i>}
                                    </Link>
                                </td>
                            </tr>
                        )}

                        {this.state.data.length === 0 && <tr><td colSpan="10"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <userContext.Consumer>
                        {({ user, logoutUser }) => {
                            return (<Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h5>Handover notes ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                            </h5>
                                            <span className="d-block m-t-5 text-muted">List of handover notes.</span>
                                        </Col>
                                        <Col>
                                            <div style={{ paddingRight: "10px" }}>
                                                <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (persistedState.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (persistedState.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                                {user.permissions && user.permissions.includes("handover.notes.use") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2" >

                                                    {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                                    {this.state.exporting && <Spinner animation="border" />}

                                                </button>}

                                                {user.permissions && user.permissions.includes("handover.notes.use") && <Link style={{ float: "right" }} to="/handover-notes/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Create new note</Link>}

                                            </div>
                                        </Col>
                                    </Row>

                                </CardHeader>
                            </Card>);
                        }}
                    </userContext.Consumer>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="2">
                                    <h5>Filter</h5>
                                </Col>

                                <Col md="2">
                                    {this.state.filterParamsInit && <FacilitySelector
                                        placeholder="Facility"
                                        value={persistedState.facilityId}
                                        initialUserId={persistedState.initialFacilityId}
                                        initComplete={() => {
                                            this.setState({
                                                facilityInit: true
                                            }, () => this.update())
                                        }}
                                        onChange={this.handleFacilityIdChange}
                                        hideLabel={true} />}
                                </Col>

                                <Col md="2">
                                    <InputGroup>

                                        <Input type="select" name="select" value={persistedState.isAssigned} onChange={this.handleIsAssignedChange} >
                                            <option value="">Assignment status</option>
                                            <option value="true">Assigned</option>
                                            <option value="false">Not assigned</option>
                                        </Input>
                                    </InputGroup>
                                </Col>

                                <Col md="2">
                                    {this.state.filterParamsInit && <UserSelector
                                        placeholder="Assigned to"
                                        value={persistedState.assignedToId}
                                        initialUserId={persistedState.initialAssignedToId}
                                        initComplete={() => {
                                            this.setState({
                                                userInit: true
                                            }, () => this.update())
                                        }}
                                        onChange={this.handleUserIdChange}
                                        filterPermission="tickets.assignable"
                                        hideApartmentSuffix={true}
                                        hideLabel={true} />}
                                </Col>

                                <Col md="2">
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="open">Open</option>
                                            {!persistedState.hideClosed && <option value="closed">Closed</option>}
                                        </Input>
                                    </InputGroup>
                                </Col>

                                <Col md="2">
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.myTicketsOnly} onChange={this.handleMyTicketsOnlyChange} >
                                            <option value="">All tickets</option>
                                            <option value="true">My tickets</option>
                                        </Input>
                                    </InputGroup>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col></Col>


                                <Col md="2">
                                    {this.state.filterParamsInit && <ApartmentSelector
                                        placeholder="Space"
                                        value={persistedState.apartmentId}
                                        initialUserId={persistedState.initialApartmentId}
                                        initComplete={() => {
                                            this.setState({
                                                apartmentInit: true
                                            }, () => this.update())
                                        }}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />}
                                </Col>


                                <Col md="2">
                                    <DatePicker id="start"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="Start date"
                                        selected={persistedState.startFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            persistedState.startFilter = v;
                                            this.update();
                                        }} />
                                </Col>
                                <Col md="2">
                                    <DatePicker id="end"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="End date"
                                        selected={persistedState.endFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            persistedState.endFilter = v;
                                            this.update();
                                        }} />
                                </Col>
                                <Col md="4">
                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(persistedState.search || persistedState.emailed || persistedState.status || persistedState.apartmentId || persistedState.assignedToId || persistedState.facilityId || persistedState.startFilter || persistedState.endFilter) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardHeader>

                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideClosed"
                                                type="checkbox"
                                                checked={persistedState.hideClosed}
                                                onChange={this.handleHideClosedChange} />  Hide closed notes
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(persistedState.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(persistedState.skip + this.state.take, this.state.take);
    }

    async exportToCSV() {

        this.setState({ exporting: true });

        var assignedToId = "";
        if (persistedState.assignedToId)
            assignedToId = persistedState.assignedToId.value;

        var params = new HandoverNoteParams()
            .WithStatus(persistedState.status)
            .WithStart(persistedState.startFilter)
            .WithEnd(persistedState.endFilter)
            .WithAssignedTo(assignedToId)
            .WithIsAssigned(persistedState.isAssigned)
            .WithHideClosed(persistedState.hideClosed)
            .Sort(persistedState.sort, persistedState.direction)
            .Paginate(0, this.state.total)
            .Search(persistedState.search);

        if (persistedState.myTicketsOnly)
            params = params.ShowOnlyMyTickets();

        var blob = await HengyiHandoverNoteClient.Export(params);

        FileSaver.saveAs(blob, "handover-notes.csv");

        this.setState({ exporting: false });
    }

    async clear() {

        persistedState.skip = 0;
        persistedState.status = "";
        persistedState.search = "";
        persistedState.assignedToId = null;
        persistedState.isAssigned = "";
        persistedState.hideClosed = true;
        persistedState.myTicketsOnly = false;


        persistedState.startFilter = "";
        persistedState.endFilter = "";
        persistedState.initialAssignedToId = "";

        persistedState.apartmentId = "";
        persistedState.facilityId = "";

        this.setState({
            search: ""
        });

        await this.update();

    }

    async update() {

        await this.populateData(persistedState.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading && this.state.userInit) {

            persistedState.skip = skip;
            this.setState({ loading: true, take: take });

            var assignedToId = "";
            if (persistedState.assignedToId)
                assignedToId = persistedState.assignedToId.value;

            var facilityId = "";
            if (persistedState.facilityId)
                facilityId = persistedState.facilityId.value;

            var apartmentId = "";
            if (persistedState.apartmentId)
                apartmentId = persistedState.apartmentId.value;

            var params = new HandoverNoteParams()
                .WithStatus(persistedState.status)
                .WithStart(persistedState.startFilter)
                .WithEnd(persistedState.endFilter)
                .WithAssignedTo(assignedToId)
                .WithIsAssigned(persistedState.isAssigned)
                .WithFacility(facilityId)
                .WithApartment(apartmentId)
                .WithHideClosed(persistedState.hideClosed)
                .Sort(persistedState.sort, persistedState.direction)
                .Paginate(skip, take)
                .Search(persistedState.search);

            if (persistedState.myTicketsOnly)
                params = params.ShowOnlyMyTickets();

            var response = await HengyiHandoverNoteClient.List(params);


            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }
        }
    }

}

HandoverNotes.contextType = userContext;



