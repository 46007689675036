import React, { Component } from 'react';
import { ScheduleEdit } from './ScheduleEdit';

export class FacilityScheduleEdit extends Component {

    render() {
        return (
            <ScheduleEdit facilityId={this.props.match.params.id} scheduleId={this.props.match.params.sid}/>
            );
    }

}
