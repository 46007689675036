import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class RoleDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: null, loading: false, authenticated: true, next: false, deleteFail: false };
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/roles" />);
        }else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/roles" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>{this.state.data.name}</span>}
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.description}</span>}</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/roles/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody>
                            <Row>
                                {this.state.data && this.state.data.permissionGroups.map(group => {
                                    return (
                                        <Col md="4" key={group.name}>
                                            <h6>{group.name}</h6>
                                            <hr />
                                            <ul className="list-unstyled">
                                                {group.permissions.map(permission => {
                                                    return (<li key={permission.id}>
                                                        { permission.selected && <i className="feather icon-check-circle" style={{ color: "#1de9b6" }}></i> }
                                                        {!permission.selected && <i className="feather icon-circle"></i>} {permission.name}
                                                        <p className="text-muted">{permission.description}</p>
                                                    </li>);
                                                })}
                                            </ul>
                                        </Col>
                                        );
                                })}

                            </Row>
                        </CardBody>
                    </Card>

                    <div className="alert alert-danger">
                        {!this.state.deleteFail &&
                            <Row>
                                <Col>
                                   <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can delete this role, doing so will permanently remove it, please proceed with caution</p></Col>
                                <Col>
                                    <Button style={{ float: "right" }} to="/roles" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete role</Button>
                                </Col>
                            </Row>}

                        {this.state.deleteFail &&
                            <div>
                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed: </strong> This role is currently in use, please make sure no one is using this role</p>
                            </div>}
                    </div>
                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiApi.DeleteRole(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetRole(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }

           
        }
    }
}
