import React, { Component } from 'react';
import { HengyiCarParkClient, CarParkQueryParams } from '../infrastructure/HengyiClient/CarParks';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { TableHeader } from '../infrastructure/TableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { CarParkTypeSelector } from '../infrastructure/CarParkTypeSelector';

export class CarParks extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "parkNumber", direction: "asc"  };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleTypeIdChange = this.handleTypeIdChange.bind(this);
    }

    handleTypeIdChange(name, value) {

        this.setState({
            typeId: value
        }, () => this.update());
    }


    async clear() {

        this.setState({
            search: "",
            skip: 0,
            typeId: null
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "", sort: "", static: true },
                        { name: "Number", sort: "parkNumber" },
                        { name: "Type", sort: "type.name" },
                        { name: "Description", sort: "description" },
                        { name: "Parking Bank", sort: "parkingBank.name" },
                        { name: "Space", sort: "apartment.name" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.apartmentLeasedBy && <span><FontAwesomeIcon icon={faExchangeAlt} title="This car park is leased" />&nbsp;</span>}
                            </td>
                            <td>
                                {item.parkNumber && item.parkNumber}
                                {!item.parkNumber && <i>Not specified</i>}</td>

                            <td>
                                {item.type && <Link key={item.type.id + "-view"} to={"/car-park-types/" + item.type.id}>{item.type.name}</Link>}
                                {!item.type && <i>Not specified</i>}
                            </td>

                            <td>
                                {item.description && item.description}
                                {!item.description && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.parkingBank && <Link key={item.parkingBank.id + "-view"} to={"/parking-banks/" + item.parkingBank.id}>{item.parkingBank.name}</Link>}
                                {!item.parkingBank && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.apartment && <Link key={item.apartment.id + "-view"} to={"/apartments/" + item.apartment.id}>{item.apartment.name}</Link>}
                                {!item.apartment && <i>Not specified</i>}
                                {item.apartmentLeasedBy && <span> (Leased)</span>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/car-parks/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/car-parks/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No car parks to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Car parks ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of car parks in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/car-parks/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <CarParkTypeSelector
                                        placeholder="Car Park Type"
                                        value={this.state.typeId}
                                        onChange={this.handleTypeIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()}/>
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.statusFilter || this.state.inUseFilter || this.state.typeId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var inUser = null;

            if (this.state.inUseFilter)
                inUser = this.state.inUseFilter === "True";


            var query = new CarParkQueryParams()
                .Search(this.state.search)
                .Paginate(skip, take)
                .Sort(this.state.sort, this.state.direction);

            if (this.state.typeId)
                query = query
                    .WithParam("typeId", this.state.typeId.value)

            var response = await HengyiCarParkClient.List(query);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }

        }
    }
}
