import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiApi, UserQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiWorkOrderClient } from '../infrastructure/HengyiClient/WorkOrders';
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';

export class WorkOrdersNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                orderNumber: "",
                description: "",
                userId: "",

                subject: "",
                problemType: "",
                serviceCategoryName: "",
                priorityName: "",
                locationFullName: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {


                if (!this.state.subject) {
                    setFieldError("subject", "Please set the subject");
                    return;
                }

                if (!this.state.description) {
                    setFieldError("description", "Please enter a description");
                    return;
                }

                if (!fields.problemType) {
                    setFieldError("problemType", "Please choose a problem type");
                    return;
                }

                if (!fields.serviceCategoryName) {
                    setFieldError("serviceCategoryName", "Please choose a category");
                    return;
                }

                if (!fields.priorityName) {
                    setFieldError("priorityName", "Please choose a priority");
                    return;
                }

                if (!fields.locationFullName) {
                    setFieldError("locationFullName", "Please select the location");
                    return;
                }

                const params = {
                    subject: this.state.subject,
                    description: this.state.description,
                    locationFullName: fields.locationFullName ? fields.locationFullName.value : null,
                    problemType: fields.problemType ? fields.problemType.value : null,
                    serviceCategoryName: fields.serviceCategoryName ? fields.serviceCategoryName.value : null,
                    priorityName: fields.priorityName,
                    orderNumber: this.state.orderNumber,
                    ticketId: this.props.match.params.id
                };

                if (fields.userId && fields.userId.value)
                    params.assignedToId = fields.userId.value;

                const response = await fetch((await GetAPIDomain()) + "/work-orders", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {
                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {
                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }
                } else {
                    this.setState({ next: true, id: data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="orderNumber">Order number</label>
                                <input name="orderNumber" type="text" className={'form-control' + (errors.orderNumber && touched.orderNumber ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="orderNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="subject">Subject</label>
                                <input name="subject" type="text" className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>   <SelectField
                            id="userId"
                            name="userId"
                            label="Contractor"
                            placeholder="Select contractor"
                            options={this.state.userOptions}
                            value={values.userId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.userId}
                            error={errors.userId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        /></Col>
                    </Row>


                    <Row>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="serviceCategoryName"
                                    name="serviceCategoryName"
                                    label="Service Category"
                                    placeholder="Select service category"
                                    options={this.state.serviceCategoryOptions}
                                    value={values.serviceCategoryName}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.serviceCategoryName}
                                    error={errors.serviceCategoryName}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">

                                <SelectField
                                    id="problemType"
                                    name="problemType"
                                    label="Problem Type"
                                    placeholder="Please choose type"
                                    options={this.state.problemTypeOptions}
                                    value={values.problemType}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.problemType}
                                    error={errors.problemType}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">

                                <SelectField
                                    id="locationFullName"
                                    name="locationFullName"
                                    label="Location"
                                    placeholder="Please choose location"
                                    options={this.state.locationOptions}
                                    value={values.locationFullName}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.locationFullName}
                                    error={errors.locationFullName}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="priorityName">Priority</label>
                                <select type="select" name="priorityName"
                                    placeholder="Please choose priority"
                                    value={values.priorityName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.priorityName && touched.priorityName ? ' is-invalid' : '')}>
                                    <option value="">Please choose priority</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Low">Low</option>
                                    <option value="Urgent">Urgent</option>
                                </select>
                                <ErrorMessage name="type" component="div" className="invalid-feedback" />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/work-orders/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New Work Order
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a work order in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/work-orders" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var users = (await HengyiContractorClient.List(new UserQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                userOptions: users.map(function (item) { return { value: item.id, label: item.name+" "+item.surname }; }),
                loading: false,
                serviceCategoryOptions: (await HengyiWorkOrderClient.GetServiceCategories()).data.data.map(function (item) { return { value: item.value, label: item.display }; }),
                problemTypeOptions: (await HengyiWorkOrderClient.GetProblemTypes()).data.data.map(function (item) { return { value: item.value, label: item.display }; }),
                locationOptions: (await HengyiWorkOrderClient.GetLocations()).data.data.map(function (item) { return { value: item.value, label: item.display }; })
            });
        }
    }
}
