import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class HengyiSwiftParkClient {

    static async ListParkTypes(buildingId: string) {

        var query = (await GetAPIDomain()) + "/integrations/swift-park/park-types?buildingId=" + buildingId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}