import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiUserClient } from '../infrastructure/HengyiClient/Users';
import { GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';

export class UsersEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, licensePlate: "", colour: "", userId: "", fuelType: "", wofExpiry: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    surname: this.state.surname,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    roles: this.state.roles,
                    preferredParcelDeliveryOption: this.state.preferredParcelDeliveryOption,
                    preferredParcelDeliverySlot: this.state.preferredParcelDeliverySlot
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var imageId = this.state.profileImageId;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    const response = await HengyiUserClient.UpdateUser(this.props.match.params.id,
                        this.state.name,
                        this.state.surname,
                        this.state.email,
                        this.state.mobile,
                        fields.roles,
                        imageId,
                        fields.preferredParcelDeliveryOption,
                        fields.preferredParcelDeliverySlot);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                            <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div></Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="surname">Surname</label>
                                            <input name="surname" id="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                            <input name="email" id="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div></Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile</label>
                                            <input name="mobile" id="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="type">Parcel preference</label>
                                            <select type="select" name="preferredParcelDeliveryOption"
                                                value={values.preferredParcelDeliveryOption}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={'form-control' + (errors.preferredParcelDeliveryOption && touched.preferredParcelDeliveryOption ? ' is-invalid' : '')}>
                                                <option value="askEachTime">Ask each time</option>
                                                <option value="bringUp">Bring up to my space (may incur small fee)</option>
                                            </select>
                                            <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    {values.preferredParcelDeliveryOption === "bringUp" && <Col>
                                        <div className="form-group">
                                            <label htmlFor="type">Preferred delivery slot</label>
                                            <select type="select" name="preferredParcelDeliverySlot"
                                                value={values.preferredParcelDeliverySlot}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={'form-control' + (errors.preferredParcelDeliverySlot && touched.preferredParcelDeliverySlot ? ' is-invalid' : '')}>
                                                <option value="">Any delivery slot</option>
                                                <option value="morning">Morning (before 12)</option>
                                                <option value="afternoon">Afternoon (12-5)</option>
                                                <option value="evening">Evening (after 5)</option>
                                            </select>
                                            <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>}
                            </Row>
                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Profile image</h6>

                                {this.state.file && <div>
                                    <img className="rounded-circle" alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                    this.setState({
                                        file: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />

                            </div>
                        </Col>
                    </Row>

                    <div className="form-group">
                        <h5>Roles</h5>
                        <hr />
                        <FieldArray name="roles"
                            render={arrayHelpers => (
                                <ul className="list-unstyled">
                                    {this.state.roleOptions && this.state.roleOptions.map(role => {

                                        return (
                                            <li><label>
                                                <input
                                                    name="roles"
                                                    type="checkbox"
                                                    value={role.id}
                                                    checked={values.roles.includes(role.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) arrayHelpers.push(role.id);
                                                        else {
                                                            const idx = values.roles.indexOf(role.id);
                                                            arrayHelpers.remove(idx);
                                                        }
                                                    }}
                                                />{" "}
                                                {role.name}
                                            </label>
                                                <p className="text-muted">{role.description}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )} />
                        <ErrorMessage name="roles" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/users/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update user information and access</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/users/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiApi.GetUser(this.props.match.params.id);
            var rolesResponse = await HengyiApi.GetRoles(new ListQueryParams().Paginate(0, 50));

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.profileImageId) {
                    this.setState({
                        file: (await GetAssetDomain()) + "/image/" + response.data.profileImageId + ".jpg"
                    });
                }

                this.setState({
                    loading: false,
                    roleOptions: rolesResponse.data.data,
                    data: response.data,
                    profileImageId: response.data.profileImageId,
                    name: response.data.name,
                    surname: response.data.surname,
                    email: response.data.email,
                    mobile: response.data.mobile,
                    roles: response.data.roles.map(p => p.id),
                    preferredParcelDeliverySlot: response.data.preferredParcelDeliverySlot,
                    preferredParcelDeliveryOption: response.data.preferredParcelDeliveryOption
                }, () => {
                    document.getElementById("name").value = response.data.name ? response.data.name : "";
                    document.getElementById("surname").value = response.data.surname ? response.data.surname : "";
                    document.getElementById("email").value = response.data.email ? response.data.email : "";
                    document.getElementById("mobile").value = response.data.mobile ? response.data.mobile : "";
                });
            }
        }
    }
}
