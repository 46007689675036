import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import { PartnerOfferClient } from '../infrastructure/HengyiClient/PartnerOffers';
import { GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';
import { PartnerOfferCategoryClient, PartnerOfferCategoryQueryParams } from '../infrastructure/HengyiClient/PartnerOfferCategories';

export class PartnerOfferEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialCategory = this.state.categoryOptions.filter(type => type.value === this.state.categoryId)[0];

            return (<Formik
                initialValues={{
                    expiry: this.state.expiry,
                    categoryId: initialCategory
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var imageId = this.state.data.imageId;

                    if (fields.thumbnail) {

                        let formData = new FormData();
                        formData.append("file", fields.thumbnail);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var response = await PartnerOfferClient.Update(this.state.data.id, this.state.name, this.state.description, fields.expiry, this.state.weight ? this.state.weight : 0, fields.categoryId ? fields.categoryId.value : null, this.state.weblink, imageId);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {

                        console.log(fields.file);

                        if (fields.file) {
                            let formData = new FormData();
                            formData.append("file", fields.file);

                            response = await fetch((await GetAPIDomain()) + "/partner-offers/" + response.data.id + "/upload-file", {
                                method: 'post',
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                                },
                                body: formData
                            });
                        }

                        this.setState({ id: this.state.data.id, next: true });
                    }


                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange }) => (

                    <Form>
                        <Row>
                            <Col>

                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <Field id="name" name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <Field id="description" name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="expiry">Expiry</label>

                                    <DatePicker id="expiry"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.expiry}
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy hh:mm a'
                                        onChange={(v, f) => {
                                            setFieldValue("expiry", v);
                                        }} />


                                    {errors.expiry && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.expiry}</div>}

                                </div>
                                <div className="form-group">
                                    <label htmlFor="weight">Weight</label>
                                    <Field id="weight" name="weight" type="number" className={'form-control' + (errors.weight && touched.weight ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="weblink">Weblink</label>
                                    <Field id="weblink" name="weblink" type="text" className={'form-control' + (errors.weblink && touched.weblink ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="weblink" component="div" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <SelectField
                                        id="categoryId"
                                        name="categoryId"
                                        label="Category"
                                        placeholder="Select category"
                                        options={this.state.categoryOptions}
                                        value={values.categoryId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.categoryId}
                                        error={errors.categoryId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    />
                                </div>

                                {!this.state.loading && this.state.data && this.state.data.fileUploaded &&
                                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                        <Row>
                                            <Col>
                                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Attched file:</strong> <span>{this.state.data.fileName}</span>.</p>
                                            </Col>
                                            <Col md="2">
                                            <Button style={{ float: "right" }} to="/partner-offers" onClick={() => { this.downloadFile(this.state.data.id, this.state.data.fileName); return false; }} className="btn mr-2 mt-2 mb-2 btn-seconday">Download</Button>
                                            </Col>
                                        </Row>
                                    </div>}

                                <div className="pt-3">
                                    <div className="form-group">
                                        <label htmlFor="file">{this.state.data.fileUploaded ? "Change attachment" : "Attach file"}</label>
                                        <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {
                                            setFieldValue("file", event.currentTarget.files[0]);
                                        }} />
                                    </div>
                                </div>

                            </Col>
                            <Col md="3" className="text-center">
                                <div className="form-group">
                                    <h6 className="text-left">Offer thumbnail</h6>

                                    {this.state.thumbnail && <div>
                                        <img alt="Uploaded supporting file" src={this.state.thumbnail} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                    </div>}

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="thumbnail" name="thumbnail" type="file" onChange={(event) => {

                                        this.setState({
                                            thumbnail: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("thumbnail", event.currentTarget.files[0]);
                                    }} />

                                </div>
                            </Col>
                        </Row>

                        <hr />
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "Save"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>

            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/partner-offers/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update document details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details this document</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/partner-offers/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async downloadFile(id, name) {

        var data = await PartnerOfferClient.GetDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        await this.populateData(id);

    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var partnerOffer = await PartnerOfferClient.Get(this.props.match.params.id);

            if (!partnerOffer.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                if (partnerOffer.data.imageId) {
                    this.setState({
                        thumbnail: (await GetAssetDomain()) + "/image/" + partnerOffer.data.imageId + ".jpg"
                    });
                }

                console.log(partnerOffer);

                var response = await PartnerOfferCategoryClient.List((new PartnerOfferCategoryQueryParams()).Paginate(0, 1000));

                var categoryOptions = response.data.data.map(function (item) { return { value: item.id, label: item.name }; });

                this.setState({
                    data: partnerOffer.data,
                    description: partnerOffer.data.description,
                    name: partnerOffer.data.name,
                    expiry: partnerOffer.data.expiry ? new Date(partnerOffer.data.expiry) : null,
                    weight: partnerOffer.data.weight,
                    weblink: partnerOffer.data.webLink,
                    loading: false,
                    init: true,
                    categoryOptions: categoryOptions,
                    categoryId: partnerOffer.data.partnerOfferCategory ? partnerOffer.data.partnerOfferCategory.id : null
                }, () => {
                    document.getElementById("name").value = partnerOffer.data.name;
                    document.getElementById("description").value = partnerOffer.data.description;
                    document.getElementById("weight").value = partnerOffer.data.weight;
                    document.getElementById("weblink").value = partnerOffer.data.webLink;
                });
            }
        }
    }
}
