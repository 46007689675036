import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiNoticeClient } from '../infrastructure/HengyiClient/Notices';

export class NoticesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name:"", description:""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                buildingId: this.state.buildingOptions[0],
                type: "info",
                description: "",
                expiry: "",
                name:""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.expiry) {
                    setFieldError("expiry", "Please set an expiry date");
                    return;
                }

                var response = await HengyiNoticeClient.Create(this.state.title, this.state.description, fields.buildingId.value, fields.expiry, fields.type);

                console.log(response);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="title">Name</label>
                                <input name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} onChange={this.handleChange} />
                                {touched && errors.title ? <p className="error-text">{errors.title}</p> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="type">Type</label>
                                <select type="select" name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                    <option value="info">Information</option>
                                    <option value="warning">Warning</option>
                                    <option value="alert">Alert</option>
                                </select>
                                <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <SelectField
                                id="buildingId"
                                name="buildingId"
                                label="Building"
                                placeholder="Select building"
                                options={this.state.buildingOptions}
                                value={values.buildingId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.buildingId}
                                error={errors.buildingId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="expiry">Expiry</label>

                                <DatePicker id="expiry"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.expiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("expiry", v);
                                    }} />

                                {touched.expiry && errors.expiry ? <p className="error-text">{errors.expiry}</p> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                {touched.description  && errors.description ? <p className="error-text">{errors.description}</p> : null}
                            </div>
                        </Col>
                    </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/notices/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>New notice
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List a new notice for the building</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/notices" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.buildingOptions &&  this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
