import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { userContext } from '../../userContext';

export class NavMenu extends Component {

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <header>

            <userContext.Consumer>
                {({ tenant, user, logoutUser  }) => {
                    return (<Navbar className="navbar navbar-dark flex-md-nowrap shadow" dark style={{ paddingTop: "10px", paddingBottom: "10px", backgroundColor: (tenant != null ? (tenant.colour ) : "initial") }}>

                        <NavbarBrand style={{ textAlign: "left", backgroundColor: (tenant != null ? (tenant.colour + " !important") : "initial") }} className="navbar-brand col-sm-3 col-md-2" tag={Link} to="/">{tenant && tenant.name}</NavbarBrand>
                        <ul className="navbar-nav px-3">
                            {user.authenticated && <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/" onClick={logoutUser}>Sign out</NavLink>
                            </NavItem>}
                            {!user.authenticated && <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/sign-in">Sign in</NavLink>
                            </NavItem>}
                </ul>

                    </Navbar>);
                }}
            </userContext.Consumer>
      </header>
    );
  }
}
