import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';

export class RolesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, data: [], permissions: [], name: "", description: "", loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    permissions: this.state.permissions
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    fields.name = this.state.name;
                    fields.description = this.state.description;

                    const response = await fetch((await GetAPIDomain()) + "/roles/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "name",
                            "value": fields.name
                        }, {
                            "op": "replace",
                                "path": "description",
                                "value": fields.description
                        }, {
                            "op": "replace",
                                "path": "permissions",
                                "value": fields.permissions
                        }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values }) => (
                <Form>

                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                            <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                            <input name="description" id="description" type="text" className={'form-control' + (errors.description && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                            <h5>Permissions</h5>
                            <hr />
                        <FieldArray name="permissions"
                            render={arrayHelpers => (
                                <div className="row">
                                    {permissionGroups && permissionGroups.map(permissionGroup => {

                                        return (<Col md="4">
                                            <h6>{permissionGroup.name}</h6>
                                            <hr />
                                            <ul className="list-unstyled">
                                                {permissionGroup.permissions && permissionGroup.permissions.map(permission => {

                                                return (
                                                    <li><label>
                                                        <input
                                                            name="permissions"
                                                            type="checkbox"
                                                            value={permission.id}
                                                            checked={values.permissions.includes(permission.id)}
                                                            onChange={e => {
                                                                if (e.target.checked) arrayHelpers.push(permission.id);
                                                                else {
                                                                    const idx = values.permissions.indexOf(permission.id);
                                                                    arrayHelpers.remove(idx);
                                                                }
                                                            }}
                                                        />{" "}
                                                        {permission.name}
                                                    </label>
                                                        <p className="text-muted">{permission.description}</p>
                                                    </li>
                                                )
                                            })}
                                        </ul></Col>)
                                    })}
                                </div>
                            )} />
                        <ErrorMessage name="permissions" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/roles/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update role
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a new role to manage user's permissions</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/roles/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiApi.GetPermissionsGrouped(new ListQueryParams().Paginate(0, 50));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                var role = await HengyiApi.GetRole(this.props.match.params.id);
               
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total,
                    permissions: role.data.permissions
                        .filter(p => p.selected)
                        .map(p => p.id),
                    name: role.data.name,
                    description: role.data.description
                }, () => {
                    document.getElementById("name").value = role.data.name ? role.data.name : "";
                    document.getElementById("description").value = role.data.description ? role.data.description : "";
                });
            }
        }
    }
}
