import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiWorkOrderClient, WorkOrderQueryParams } from "../infrastructure/HengyiClient/WorkOrders";
import { TableHeader } from "../infrastructure/TableHeader";

class WorkOrderTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: "", status: this.props.status, sort: "orderNumber", direction: "asc"
        };

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleProviderChange(evt) {
        this.setState({
            provider: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async clear() {
        this.setState({
            skip: 0,
            status: "",
            search: "",
            provider: ""
        }, async () => {
            await this.update();
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            ticketId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new WorkOrderQueryParams()
                .Paginate(skip, take)
                .WithStatus(this.state.status)
                .WithProvider(this.state.provider)
                .WithTicketId(ticketId)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction);

            if (this.props.contractorId != null)
                params.WithContractor(this.props.contractorId);

            var response = await HengyiWorkOrderClient.List(params);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }

    render() {
        const {
            title,
            description,
            includeNewButton,
            ticketId
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Work orders"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List of work orders in the system"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                    {includeNewButton && <Link style={{ float: "right" }} to={ticketId ? "/tickets/" + ticketId+"/convert-to/work-order" : "/work-orders/new"} className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            {!this.props.status && <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="open">Open</option>
                                        <option value="closed">Closed</option>
                                    </Input>
                                </InputGroup>
                            </Col>}
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.provider} onChange={this.handleProviderChange} >
                                        <option value="">Any provider</option>
                                        <option value="none">None</option>
                                        <option value="iviva">IVIVA</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.provider) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <div className="table-responsive">
                                <table className='table' aria-labelledby="tabelLabel">

                                    <TableHeader
                                        sort={this.state.sort}
                                        direction={this.state.direction}
                                        columns={[
                                            { name: "Order Number", sort: "orderNumber" },
                                            { name: "Assigned to", sort: "assignedTo.name" },
                                            { name: "Provider", sort: "provider" },
                                            { name: "Status", sort: "status" },
                                            { name: "", sort: "", static: true }]}
                                        onUpdate={(sort, direction) => this.setState({
                                            sort: sort,
                                            direction: direction
                                        }, () => this.update())}
                                    />
                                    <tbody>
                                        {this.state.data.map(item =>
                                            <tr key={item.id}>
                                                <td>
                                                    {item.orderNumber && item.orderNumber}
                                                    {!item.orderNumber && <i>Not specified</i>}
                                                </td>
                                                <td>
                                                    {item.assignedTo && <Link key={item.assignedTo.id + "-view"} to={"/contractors/" + item.assignedTo.id}>{item.assignedTo.name} ({item.assignedTo.surname})</Link>}
                                                    {!item.assignedTo && <i>Not assigned</i>}
                                                </td>
                                                <td>
                                                    {item.provider === "none" && <Badge color="primary">None</Badge>}
                                                    {item.provider === "iviva" && <Badge color="info">IVIVA</Badge>}
                                                </td>
                                                <td>
                                                    {item.status === "open" && <Badge color="primary">Open</Badge>}
                                                    {item.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                                </td>

                                                <td>
                                                    <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/work-orders/" + item.id}>Details</Link>
                                                    <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/work-orders/edit/" + item.id}>Edit</Link>
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No work orders to display</i></h4></td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { WorkOrderTable };
