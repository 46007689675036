import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'react-moment';
import { store, view } from 'react-easy-state';
import DatePicker from 'react-datepicker';
import { UserLink } from "../Users/UserLink";
import { HengyiParcelClient, ParcelQueryParams } from '../infrastructure/HengyiClient/Parcels';
import { TableHeader } from '../infrastructure/TableHeader';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';
import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';
import FileSaver from 'file-saver';
import { userContext } from '../../userContext';

const persistedState = store({
    search: "",
    status: "",
    type: "",
    hideCollected: true,
    showInvalidated: false,
    indicatedPreference: null,
    filterDate: null,
    apartmentId: "",
    skip: 0, take: 50, sort: "requestedHandoverDate", direction: "desc"
});

class ParcelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false, total: 0,
            authenticated: true,
            selectedParcels: [],
            selectAll: false,
            panelToDisplay: "none",
            selectedStatuses: [],
            possibleActions: [],
            chargeDefault: "2.40",
            exporting: false,
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleDeliverySlotChange = this.handleDeliverySlotChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleHideCollected = this.handleHideCollected.bind(this);
        this.handleShowInvalidated = this.handleShowInvalidated.bind(this);
        this.handleFilterByDateChange = this.handleFilterByDateChange.bind(this);
        this.handleIndicatedPreferenceChange = this.handleIndicatedPreferenceChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
        this.handleWhenToTakeUpDeliverySlot = this.handleWhenToTakeUpDeliverySlot.bind(this);
        this.applyAction = this.applyAction.bind(this);
        this.handleInvalidateMessageChange = this.handleInvalidateMessageChange.bind(this);
        this.handleCollectedMessageChange = this.handleCollectedMessageChange.bind(this);

        this.handleWhenToTakeUp = this.handleWhenToTakeUp.bind(this);
    }

    async clear(reloadDelayed) {

        persistedState.filterDate = null;
        persistedState.indicatedPreference = null;
        persistedState.type = "";
        persistedState.search = "";
        persistedState.deliverySlot = "";
        persistedState.status = "";
        persistedState.apartmentId = "";
        persistedState.skip = 0;

        if (reloadDelayed) {
        } else {
            this.setState({
                search: ""
            }, () => this.update());
        }
    }

    async componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("reset"))
            this.clear(false);

        if (urlParams.get("status"))
            persistedState.status = urlParams.get("status");

        if (urlParams.get("type"))
            persistedState.type = urlParams.get("type");

        if (urlParams.get("q"))
            persistedState.search = urlParams.get("q");

        if (urlParams.get("requestedDate")) {
            var parts = urlParams.get("requestedDate").split("-");

            persistedState.filterDate = new Date(parts[0], parseInt(parts[1] - 1), parts[2]);
        }

        if (urlParams.get("hasIndicatedPreference"))
            persistedState.indicatedPreference = urlParams.get("hasIndicatedPreference") === "true";

        this.update();
        var result = await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 1));

        this.setState({
            chargeDefault: parseFloat((result.data.data[0].parcelDeliveryCost / 100) + "").toFixed(2)
        });
    }

    handleSelectionUpdate() {
        var selectedStatuses = [];
        var possibleActions = [];

        for (var i = 0; i < this.state.data.length; i++) {

            var item = this.state.data[i];
            if (this.state.selectedParcels.indexOf(item.id) !== -1 && selectedStatuses.indexOf(item.status) === -1) {
                selectedStatuses.push(item.status);
            }

            if (this.state.selectedParcels.indexOf(item.id) !== -1) {
                if (item.status === "ready") {
                    possibleActions.push("Take to apartment", "Resident will collect", "Collected", "Delivered", "Invalidated", "In transit");
                } else if (item.status === "requestedDropOff") {
                    possibleActions.push("Resident will collect", "Collected", "Delivered", "Invalidated", "In transit");
                } else if (item.status === "requestedPickUp") {
                    possibleActions.push("Take to apartment", "Collected", "Delivered", "Invalidated", "In transit");
                } else if (item.status === "inTransit") {
                    possibleActions.push("Take to apartment", "Resident will collect", "Collected", "Delivered", "Invalidated");
                }
                possibleActions.push("Update location");
            }
        }

        possibleActions = possibleActions.reduce((total, value) => {
            total[value] = (total[value] || 0) + 1;
            return total;
        }, {});

        this.setState({
            panelToDisplay: this.state.selectedParcels.length > 0 ? "select-action" : "none",
            selectedStatuses: selectedStatuses,
            possibleActions: Object.keys(possibleActions).map(key => ({ value: key, label: key + " (" + possibleActions[key] + ")" })),
            actionToApply: Object.keys(possibleActions)[0]
        });

        if (selectedStatuses.length > 0) {
            if (selectedStatuses[0] === "inTransit") {
                this.setState({
                    actionToApply: "Delivered"
                });
            } else if (selectedStatuses[0] === "requestedPickUp") {
                this.setState({
                    actionToApply: "Collected"
                });
            } else if (selectedStatuses[0] === "requestedDropOff") {
                this.setState({
                    actionToApply: "In transit"
                });
            } else if (selectedStatuses[0] === "ready") {
                this.setState({
                    actionToApply: "Collected"
                });
            }
        }
    }

    async applyAction() {

        this.setState({
            panelToDisplay: "processing"
        });

        for (var i = 0; i < this.state.data.length; i++) {

            var item = this.state.data[i];

            if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                if (this.state.actionToApply === "In transit") {

                    if (item.status === "ready" || item.status === "requestedDropOff" || item.status === "requestedPickUp") {
                        await HengyiParcelClient.SetParcelAsInTransit(item.id);
                    }

                } else if (this.state.actionToApply === "Take to apartment") {
                    this.setState({
                        panelToDisplay: "please-take-up"
                    });
                } else if (this.state.actionToApply === "Resident will collect") {
                    this.setState({
                        panelToDisplay: "will-collect"
                    });
                } else if (this.state.actionToApply === "Collected") {
                    this.setState({
                        panelToDisplay: "collected"
                    });
                } else if (this.state.actionToApply === "Delivered") {
                    this.setState({
                        panelToDisplay: "drop-off"
                    });
                } else if (this.state.actionToApply === "Invalidated") {
                    this.setState({
                        panelToDisplay: "invalidate"
                    });
                } else if (this.state.actionToApply === "Update location") {
                    this.setState({
                        panelToDisplay: "update-location"
                    });
                }
            }

        }


        if (this.state.actionToApply === "In transit") {
            this.setState({
                selectedParcels: [],
                actionToApply: ""
            }, () => this.handleSelectionUpdate());
            await this.update();
        }
    }

    handleIndicatedPreferenceChange(evt) {
        persistedState.indicatedPreference = evt.target.checked;
        this.update();
    }

    handleShowInvalidated = (evt) => {
        persistedState.showInvalidated = evt.target.checked;
        this.update();
    }

    handleHideCollected = (evt) => {
        persistedState.hideCollected = evt.target.checked;
        this.update();
    }

    handleFilterByDateChange = (date) => {
        persistedState.filterDate = date;
        this.update();
    }


    handleWhenToTakeUp = (date) => {
        this.setState({
            whenToTakeUp: date
        });
    }

    handleWhenToTakeUpDeliverySlot(evt) {
        this.setState({
            whenToTakeUpDeliverySlot: evt.target.value
        });
    }

    handleStatusChange(evt) {
        persistedState.status = evt.target.value;
        this.update();
    }

    handleDeliverySlotChange(evt) {
        persistedState.deliverySlot = evt.target.value;
        this.update();
    }

    handleTypeChange(evt) {
        persistedState.type = evt.target.value;
        this.update();
    }

    handleApartmentIdChange(name, value) {
        persistedState.apartmentId = value;
        this.update();
    }

    handleInvalidateMessageChange(evt) {
        this.setState({
            invalidateMessage: evt.target.value
        });
    }

    handleCollectedMessageChange(evt) {
        this.setState({
            collectedMessage: evt.target.value
        });
    }

    handleSearchChange(evt) {
        persistedState.search = evt.target.value;
        this.setState({
            search: evt.target.value
        });
    }

    async update() {
        await this.populateData(persistedState.skip, persistedState.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={persistedState.sort}
                    direction={persistedState.direction}
                    columns={[
                        { name: "", sort: "", static: true },
                        { name: "#", sort: "parcelNumber" },
                        { name: "Location", sort: "location" },
                        { name: "Type", sort: "parcelType" },
                        { name: "Space", sort: "apartment.name" },
                        { name: "Status", sort: "status" },
                        { name: "Requested For", sort: "requestedHandoverDate" },
                        { name: "Created By", sort: "createdBy.name" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => {
                        persistedState.sort = sort;
                        persistedState.direction = direction;
                        this.update();
                    }} />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                <label>
                                    <input
                                        name="parcelSelect"
                                        type="checkbox"
                                        value={item.id}
                                        checked={this.state.selectedParcels.includes(item.id)}
                                        onChange={e => {

                                            var selectedParcels = this.state.selectedParcels;

                                            if (e.target.checked && selectedParcels.indexOf(item.id) === -1) {
                                                selectedParcels.push(item.id);
                                            } else {
                                                const idx = selectedParcels.indexOf(item.id);
                                                selectedParcels.splice(idx, 1);
                                                this.setState({
                                                    selectAll: false
                                                });
                                            }

                                            this.setState({
                                                selectedParcels: selectedParcels
                                            });

                                            this.handleSelectionUpdate();
                                        }}
                                    />
                                </label>
                            </td>
                            <td>
                                #{item.parcelNumber}
                            </td>
                            <td>
                                {item.location && item.location}
                                {!item.location && <i>Not specified</i>}
                            </td>
                            <td style={{ textTransform: 'capitalize' }}>
                                {item.parcelType && item.parcelType}
                                {!item.parcelType && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.apartment && item.apartment.name}
                                {!item.apartment && <i>Not specified</i>}
                            </td>
                            <td className="clickable" onClick={e => {

                                var selectedParcels = this.state.selectedParcels;

                                for (var i = 0; i < this.state.data.length; i++) {
                                    if (this.state.data[i].status === item.status) {
                                        if (selectedParcels.indexOf(this.state.data[i].id) === -1) {
                                            selectedParcels.push(this.state.data[i].id);
                                        }
                                    }
                                }

                                this.setState({
                                    selectedParcels: selectedParcels
                                });

                                this.handleSelectionUpdate();
                            }}>
                                {item.status === "ready" && <Badge color="primary">Ready for collection</Badge>}
                                {item.status === "requestedDropOff" && <Badge color="info">Take to apartment</Badge>}
                                {item.status === "requestedPickUp" && <Badge color="warning">Resident will collect</Badge>}
                                {item.status === "collected" && <Badge color="secondary">Collected</Badge>}
                                {item.status === "delivered" && <Badge color="secondary">Delivered</Badge>}
                                {item.status === "invalidated" && <Badge color="danger">Invalidated</Badge>}
                                {item.status === "inTransit" && <Badge color="success">In transit</Badge>}
                            </td>
                            <td>
                                {item.requestedHandoverDate &&
                                    <>
                                        <Moment utc local format="ddd DD MMM YY">{item.requestedHandoverDate}</Moment> &nbsp;

                                            {item.requestedHandoverDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                        {item.requestedHandoverDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                        {item.requestedHandoverDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}

                                    </>
                                }
                                {!item.requestedHandoverDate && <i>-</i>}
                            </td>
                            <td>
                                {item.createdBy && <UserLink hideApartments={true} user={item.createdBy} />}
                                {!item.createdBy && <i>System generated</i>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/parcels/" + item.id}>Details</Link>
                                {item.status !== "invalidated" && item.status !== "handedOver" && <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/parcels/edit/" + item.id}>Edit</Link>}
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No deliveries to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <userContext.Consumer>
                            {({ user, logoutUser }) => {
                                return (<CardHeader>
                                    <Row>
                                        <Col>
                                            <h5> Parcels ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                            </h5>
                                            <span className="d-block m-t-5 text-muted">List of parcels delivered to concierge</span>
                                        </Col>
                                        <Col>
                                            <div style={{ paddingRight: "10px" }}>
                                                <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (persistedState.skip + persistedState.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (persistedState.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                                {user.permissions && user.permissions.includes("parcels.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">

                                                    {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                                    {this.state.exporting && <Spinner animation="border" />}

                                                </button>}

                                                <Link style={{ float: "right" }} to="/parcels/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                            </div>
                                        </Col>
                                    </Row>

                                </CardHeader>);
                            }}
                        </userContext.Consumer>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <ApartmentSelector
                                        placeholder="Space"
                                        value={persistedState.apartmentId}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="ready">Ready for collection</option>
                                            <option value="requestedDropOff">Take to my space</option>
                                            <option value="requestedPickUp">Resident will collect</option>
                                            <option value="inTransit">In transit</option>
                                            {!persistedState.hideCollected && <option value="collected">Collected</option>}
                                            {!persistedState.hideCollected && <option value="delivered">Delivered</option>}
                                            {persistedState.showInvalidated && <option value="invalidated">Invalidated</option>}
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.type} onChange={this.handleTypeChange} >
                                            <option value="">All types</option>
                                            <option value="mail">Mail</option>
                                            <option value="parcel">Parcel</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <InputGroup>
                                            <DatePicker
                                                id="date"
                                                onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                                placeholderText="Filter to a date"
                                                selected={persistedState.filterDate}
                                                onChange={this.handleFilterByDateChange}
                                                dateFormat='dd/MM/yyyy'
                                                className="form-control"
                                                autoComplete="off" />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={persistedState.deliverySlot} onChange={this.handleDeliverySlotChange} >
                                            <option value="">All delivery slots</option>
                                            <option value="morning">Morning</option>
                                            <option value="afternoon">Afternoon</option>
                                            <option value="evening">Evening</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardHeader>
                            <Row>
                                <Col className="p-0">
                                    <label style={{ margin: "0" }}>
                                        <input name="selectAll"
                                            type="checkbox"
                                            checked={this.state.selectAll}
                                            onChange={(e) => {
                                                this.setState({
                                                    selectAll: e.target.checked
                                                });

                                                var x = document.getElementsByName("parcelSelect");
                                                var selectedParcels = this.state.selectedParcels;

                                                for (var i = 0; i < x.length; i++) {
                                                    if (x[i].type === "checkbox") {
                                                        if (e.target.checked) {
                                                            if (selectedParcels.indexOf(x[i].value) === -1)
                                                                selectedParcels.push(x[i].value);
                                                        } else {
                                                            if (selectedParcels.indexOf(x[i].value) !== -1) {
                                                                const idx = selectedParcels.indexOf(x[i].value);
                                                                selectedParcels.splice(idx, 1);
                                                            }
                                                        }
                                                    }
                                                }

                                                this.setState({ selectedParcels: selectedParcels });

                                                this.handleSelectionUpdate();

                                            }} /> &nbsp;Select all
                                    </label>
                                </Col>
                                <Col md="5">
                                    <div className="form-group">
                                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="hideCollected"
                                                        type="checkbox"
                                                        checked={persistedState.indicatedPreference}
                                                        onChange={this.handleIndicatedPreferenceChange} />  User responded
                                        </label>
                                            </li>

                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="hideCollected"
                                                        type="checkbox"
                                                        checked={persistedState.hideCollected}
                                                        onChange={this.handleHideCollected} />  Hide processed
                                        </label>
                                            </li> <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="showInvalidated"
                                                        type="checkbox"
                                                        checked={persistedState.showInvalidated}
                                                        onChange={this.handleShowInvalidated} />  Show invalidated
                                        </label>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append" style={{ zIndex: 0 }}>
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>

                                            <ButtonGroup>
                                                {(persistedState.search || persistedState.apartmentId || persistedState.status || persistedState.type || persistedState.filterDate || persistedState.indicatedPreference) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardHeader>
                            <Row>
                                <Col>
                                    {this.state.panelToDisplay === "update-location" &&

                                        <Formik
                                            initialValues={{
                                                location: ""
                                            }}
                                            onSubmit={async (fields, { setErrors, setFieldError }) => {

                                                if (!fields.location) {
                                                    setFieldError("location", "Please specify a location");
                                                    return;
                                                }

                                                this.setState({
                                                    panelToDisplay: "processing"
                                                });

                                                for (var i = 0; i < this.state.data.length; i++) {

                                                    var item = this.state.data[i];

                                                    if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                                                        if (item.status === "ready" || item.status === "inTransit" || item.status === "requestedPickUp" || item.status === "requestedDropOff") {
                                                            await HengyiParcelClient.UpdateLocation(item.id, fields.location);
                                                        }
                                                    }

                                                }

                                                await this.update();
                                                this.setState({
                                                    selectedParcels: [],
                                                    actionToApply: ""
                                                }, () => this.handleSelectionUpdate());
                                            }}
                                        >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="location">Location*</label>
                                                            <select type="select" name="location"
                                                                value={values.location}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                                                                <option value="Front Desk">Front Desk</option>
                                                                <option value="Storage">Storage</option>
                                                            </select>
                                                            <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col md="1">
                                                        <button type="submit" style={{ float: "right", marginTop: "31px" }} className="btn btn-primary pull-right" disabled={isSubmitting}>
                                                            {!isSubmitting && "Save"}
                                                            {isSubmitting && <Spinner animation="border" />}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}</Formik>}

                                    {this.state.panelToDisplay === "drop-off" &&
                                        <Formik
                                            initialValues={{
                                                chargeUser: true,
                                                amountToCharge: this.state.chargeDefault,
                                                notes: ""
                                            }}
                                            onSubmit={async (fields, { setErrors, setFieldError }) => {

                                                if (!fields.chargeUser || !fields.amountToCharge || parseInt(fields.amountToCharge) === 0) {
                                                    if (!fields.notes) {
                                                        setFieldError("notes", "Please specify why these deliveries are not charged");
                                                        return;
                                                    }
                                                }

                                                if (parseInt(fields.amountToCharge) < 0) {
                                                    setFieldError("amountToCharge", "Please specify a positive number");
                                                    return;
                                                }

                                                this.setState({
                                                    panelToDisplay: "processing"
                                                });

                                                for (var i = 0; i < this.state.data.length; i++) {

                                                    var item = this.state.data[i];

                                                    if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                                                        if (item.status === "ready" || item.status === "inTransit" || item.status === "requestedPickUp" || item.status === "requestedDropOff") {
                                                            await HengyiParcelClient.SetParcelAsDroppedOff(item.id, fields.chargeUser, Math.round(fields.amountToCharge * 100), fields.notes);
                                                        }
                                                    }

                                                }

                                                await this.update();
                                                this.setState({
                                                    selectedParcels: [],
                                                    actionToApply: ""
                                                }, () => this.handleSelectionUpdate());
                                            }}
                                        >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <label><Field name="chargeUser" type="checkbox" checked={values.chargeUser} /> Charge delivery fee</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {values.chargeUser && <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="amountToCharge">Amount to charge each</label>
                                                            <div class="input-group"><div class="input-group-prepend"><span class="input-group-text">$</span></div>
                                                                <Field name="name" type="text" value={values.amountToCharge} onChange={c => setFieldValue("amountToCharge", c.target.value)} className={'form-control' + (errors.amountToCharge && touched.amountToCharge ? ' is-invalid' : '')} />
                                                            </div>
                                                            <ErrorMessage name="amountToCharge" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>}
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="notes">Note</label>
                                                            <Field name="notes" type="text" value={values.notes} onChange={c => setFieldValue("notes", c.target.value)} className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </Col>
                                                    <Col md="1">
                                                        <button type="submit" style={{ float: "right", marginTop: "31px" }} className="btn btn-primary pull-right" disabled={isSubmitting}>
                                                            {!isSubmitting && "Save"}
                                                            {isSubmitting && <Spinner animation="border" />}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}</Formik>}
                                    {this.state.panelToDisplay === "collected" && <Row>
                                        <Col>
                                            <Input type="text" placeholder="Message" value={this.state.collectedMessage} onChange={this.handleCollectedMessageChange} />
                                        </Col>
                                        <Col md="2">
                                            <button style={{ float: "right" }} className="btn btn-primary pull-right" onClick={async () => {
                                                if (!this.state.collectedMessage) {
                                                    alert("When bulk marking parcels as collected, please specify who collected the parcels");
                                                } else {

                                                    this.setState({
                                                        panelToDisplay: "processing"
                                                    });

                                                    for (var i = 0; i < this.state.data.length; i++) {

                                                        var item = this.state.data[i];

                                                        if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                                                            if (item.status === "ready" || item.status === "inTransit" || item.status === "requestedPickUp" || item.status === "requestedDropOff") {
                                                                await HengyiParcelClient.SetParcelAsCollected(item.id, this.state.collectedMessage);

                                                            }
                                                        }

                                                    }

                                                    await this.update();
                                                    this.setState({
                                                        selectedParcels: [],
                                                        actionToApply: ""
                                                    }, () => this.handleSelectionUpdate());
                                                }
                                            }}>Save</button>
                                        </Col>
                                    </Row>}
                                    {this.state.panelToDisplay === "will-collect" && <Row>
                                        <Col>
                                            <div className="form-group">
                                                <InputGroup>
                                                    <DatePicker
                                                        id="date"
                                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                                        placeholderText="When will they collect?"
                                                        selected={this.state.whenToTakeUp}
                                                        onChange={this.handleWhenToTakeUp}
                                                        dateFormat='dd/MM/yyyy'
                                                        className="form-control"
                                                        autoComplete="off" />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <Input type="select" name="select" value={this.state.whenToTakeUpDeliverySlot} onChange={this.handleWhenToTakeUpDeliverySlot} >
                                                    <option value="">Choose delivery slot</option>
                                                    <option value="morning">Morning</option>
                                                    <option value="afternoon">Afternoon</option>
                                                    <option value="evening">Evening</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col md="2">
                                            <button style={{ float: "right" }} className="btn btn-primary pull-right" onClick={async () => {
                                                if (!this.state.whenToTakeUp || !this.state.whenToTakeUpDeliverySlot) {
                                                    alert("Please specify both date and timeslot");
                                                } else {

                                                    this.setState({
                                                        panelToDisplay: "processing"
                                                    });

                                                    for (var i = 0; i < this.state.data.length; i++) {

                                                        var item = this.state.data[i];

                                                        if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                                                            if (item.status === "ready" || item.status === "inTransit" || item.status === "requestedDropOff") {
                                                                await HengyiParcelClient.SetParcelAsResidentWillCollect(item.id, this.state.whenToTakeUp.toISOString(), this.state.whenToTakeUpDeliverySlot);

                                                            }
                                                        }

                                                    }

                                                    await this.update();
                                                    this.setState({
                                                        selectedParcels: [],
                                                        actionToApply: ""
                                                    }, () => this.handleSelectionUpdate());
                                                }
                                            }}>Save</button>
                                        </Col>
                                    </Row>}
                                    {this.state.panelToDisplay === "please-take-up" && <Row>
                                        <Col>
                                            <div className="form-group">
                                                <InputGroup>
                                                    <DatePicker
                                                        id="date"
                                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                                        placeholderText="When to take up"
                                                        selected={this.state.whenToTakeUp}
                                                        onChange={this.handleWhenToTakeUp}
                                                        dateFormat='dd/MM/yyyy'
                                                        className="form-control"
                                                        autoComplete="off" />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <Input type="select" name="select" value={this.state.whenToTakeUpDeliverySlot} onChange={this.handleWhenToTakeUpDeliverySlot} >
                                                    <option value="">Choose delivery slot</option>
                                                    <option value="morning">Morning</option>
                                                    <option value="afternoon">Afternoon</option>
                                                    <option value="evening">Evening</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col md="2">
                                            <button style={{ float: "right" }} className="btn btn-primary pull-right" onClick={async () => {
                                                if (!this.state.whenToTakeUp || !this.state.whenToTakeUpDeliverySlot) {
                                                    alert("Please specify both date and timeslot");
                                                } else {

                                                    this.setState({
                                                        panelToDisplay: "processing"
                                                    });

                                                    for (var i = 0; i < this.state.data.length; i++) {

                                                        var item = this.state.data[i];

                                                        if (this.state.selectedParcels.indexOf(item.id) !== -1) {

                                                            if (item.status === "ready" || item.status === "inTransit" || item.status === "requestedPickUp") {
                                                                await HengyiParcelClient.SetParcelAsTakeUpToApartment(item.id, this.state.whenToTakeUp.toISOString(), this.state.whenToTakeUpDeliverySlot);

                                                            }
                                                        }
                                                    }

                                                    await this.update();
                                                    this.setState({
                                                        selectedParcels: [],
                                                        actionToApply: ""
                                                    }, () => this.handleSelectionUpdate());
                                                }
                                            }}>Save</button>
                                        </Col>
                                    </Row>}
                                    {this.state.panelToDisplay === "none" && <h4 className="text-muted"><i>Select a parcel to get started</i></h4>}
                                    {this.state.panelToDisplay === "processing" && <h4 className="text-muted"><i>Applying changes</i> <Spinner animation="border" /></h4>}
                                    {this.state.panelToDisplay === "select-action" && <Row>
                                        <Col>
                                            <h4 className="text-muted"><i>{this.state.selectedParcels.length} parcel{this.state.selectedParcels.length === 1 ? "" : "s"} selected </i></h4>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <select name="action"
                                                        value={this.state.actionToApply}
                                                        onChange={e => this.setState({
                                                            actionToApply: e.target.value
                                                        })}
                                                        className="form-control">
                                                        {this.state.possibleActions.map(action => <option key={action.value} value={action.value} label={action.label} />)}
                                                    </select>
                                                </Col>
                                                <Col md="2">
                                                    <button style={{ float: "right" }} className="btn btn-primary pull-right" onClick={this.applyAction}>Apply</button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.panelToDisplay === "none" && <span><i>Status updates will only apply eligible selected parcels. Click on a status to select all other parcels of that status.</i></span>}
                                    {this.state.panelToDisplay === "select-action" &&
                                        this.state.selectedStatuses.map(status => <span key={status} className="clickable" onClick={e => {

                                            var selectedParcels = this.state.selectedParcels;

                                            for (var i = 0; i < this.state.data.length; i++) {
                                                if (this.state.data[i].status === status) {
                                                    if (selectedParcels.indexOf(this.state.data[i].id) !== -1) {
                                                        const idx = selectedParcels.indexOf(this.state.data[i].id);
                                                        selectedParcels.splice(idx, 1);

                                                        this.setState({
                                                            selectAll: false
                                                        });
                                                    }
                                                }
                                            }

                                            this.setState({
                                                selectedParcels: selectedParcels
                                            });

                                            this.handleSelectionUpdate();
                                        }}>{status === "ready" && <Badge color="primary">Ready for collection</Badge>}
                                            {status === "requestedDropOff" && <Badge color="info">Take to my space</Badge>}
                                            {status === "requestedPickUp" && <Badge color="warning">Resident will collect</Badge>}
                                            {status === "collected" && <Badge color="secondary">Collected</Badge>}
                                            {status === "delivered" && <Badge color="secondary">Delivered</Badge>}
                                            {status === "invalidated" && <Badge color="danger">Invalidated</Badge>}
                                            {status === "inTransit" && <Badge color="success">In transit</Badge>}&nbsp;</span>)}
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(persistedState.skip - persistedState.take, persistedState.take);
    }

    async next() {
        await this.populateData(persistedState.skip + persistedState.take, persistedState.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            persistedState.skip = skip;
            persistedState.take = take;

            this.setState({
                loading: true,
                selectedParcels: [],
                selectAll: false,
                panelToDisplay: "none",
                selectedStatuses: [],
                possibleActions: []
            });

            var date = persistedState.filterDate ? persistedState.filterDate.toISOString() : null;

            var apartmentId = "";
            if (persistedState.apartmentId)
                apartmentId = persistedState.apartmentId.value;

            var response = await HengyiParcelClient.List(new ParcelQueryParams()
                .WithHideCollected(persistedState.hideCollected ? "true" : "false")
                .WithShowInvalidated(persistedState.showInvalidated ? "true" : "false")
                .WithParcelType(persistedState.type)
                .WithStatus(persistedState.status)
                .WithApartment(apartmentId)
                .WithDeliverySlot(persistedState.deliverySlot)
                .WithDate(date)
                .WithPreferenceInidcation(persistedState.indicatedPreference ? "true" : null)
                .Sort(persistedState.sort, persistedState.direction)
                .Paginate(skip, take)
                .Search(persistedState.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }


    async exportToCSV() {

        this.setState({ exporting: true });


        var date = persistedState.filterDate ? persistedState.filterDate.toISOString() : null;

        var apartmentId = "";
        if (persistedState.apartmentId)
            apartmentId = persistedState.apartmentId.value;

        var blob = await HengyiParcelClient.Export(new ParcelQueryParams()
            .WithHideCollected(persistedState.hideCollected ? "true" : "false")
            .WithShowInvalidated(persistedState.showInvalidated ? "true" : "false")
            .WithParcelType(persistedState.type)
            .WithStatus(persistedState.status)
            .WithApartment(apartmentId)
            .WithDeliverySlot(persistedState.deliverySlot)
            .WithDate(date)
            .WithPreferenceInidcation(persistedState.indicatedPreference ? "true" : null)
            .Sort(persistedState.sort, persistedState.direction)
            .Paginate(0, 1)
            .Search(persistedState.search));

        FileSaver.saveAs(blob, "parcels.csv");

        this.setState({ exporting: false });
    }

}

const Parcels = (props) => {
    return view(<ParcelComponent location={props.location} />);
};

export { Parcels };

Parcels.contextType = userContext;