import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiEventClient } from '../infrastructure/HengyiClient/Events';
import { GetAPIDomain } from '../infrastructure/Helper';

export class EventsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: ""
        };


        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                buildingId: this.state.buildingOptions[0]
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.buildingId) {
                    setFieldError("buildingId", "Please select a building");
                    return;
                }

                var imageId = null;

                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await HengyiEventClient.Create(
                    this.state.name,
                    this.state.description,
                    this.state.location,
                    fields.buildingId.value,
                    fields.startTime,
                    fields.endTime,
                    imageId
                );

                if (!response.successful) {
                    response.data.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                        <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                        <textarea name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} ></textarea>
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                        <input name="location" type="text" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} onChange={this.handleChange}/>
                                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <SelectField
                                    id="buildingId"
                                    name="buildingId"
                                    label="Building"
                                    placeholder="Select building"
                                    options={this.state.buildingOptions}
                                    value={values.buildingId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.buildingId}
                                    error={errors.buildingId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <div className="form-group">
                                    <label htmlFor="startTime">Start time</label>

                                        <DatePicker id="startTime"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.startTime}
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy hh:mm a'
                                        onChange={(v, f) => {
                                            setFieldValue("startTime", v);
                                            }} />

                                        {errors.startTime && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.startTime}</div>}

                                    </div>
                            </Col>

                            <Col>
                                <div className="form-group">
                                    <label htmlFor="endTime">End time</label>

                                        <DatePicker id="endTime"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.endTime}
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy hh:mm a'
                                        onChange={(v, f) => {
                                            setFieldValue("endTime", v);
                                            }} />


                                        {errors.endTime && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.endTime}</div>}

                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col md="3" className="text-center">
                        <div className="form-group">
                            <h6 className="text-left">Background image</h6>

                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                        </div>
                    </Col>
                </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/events/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>New event
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List a new event for the building</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/events" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.buildingOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
