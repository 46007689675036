import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class CarParkTypeQueryParams extends ListQueryParams {

}

//******************************
// Car park types
//******************************
export class HengyiCarParkTypeClient {

    static async List(params: CarParkTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/car-park-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-park-types/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, description: string, buildingId?: string, valetProvider?: string, externalId?: string, externalName?: string) {

        var request: any = {
            "name": name,
            "description": description,
            "buildingId": buildingId,
            "valetProvider": valetProvider,
            "externalId": externalId,
            "externalName": externalName
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-park-types/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, name: string, description: string, buildingId?: string, valetProvider?: string, externalId?: string, externalName?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/car-park-types/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "buildingId",
                "value": buildingId
            }, {
                "op": "replace",
                "path": "valetProvider",
                "value": valetProvider
            }, {
                "op": "replace",
                "path": "externalId",
                "value": externalId
            }, {
                "op": "replace",
                "path": "externalName",
                "value": externalName
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/car-park-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

}