import React, { Component } from 'react';
import { Spinner, Card, CardHeader, Row, Col, CardBody, ListGroup, ListGroupItem, ListGroupItemHeading, InputGroup } from 'reactstrap';
import { HengyiSchedulesClient, SchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { HengyiBookingSlotsClient, BookingSlotsQueryParams } from '../infrastructure/HengyiClient/BookingSlots';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';

class AvailabilityView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            init: false,
            loading: false,
            authenticated: true,
            next: false
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleScheduleChange = this.handleScheduleChange.bind(this);
    }


    handleDateChange = (date) => {

        console.log("Clearing availability");

        this.setState({
            date: date,
            scheduleId: "",
            availability: []
        }, () => this.populateData());
    }

    handleScheduleChange = (evt, value) => {

        console.log("Clearing availability");

        this.setState({
            scheduleId: value,
            availability: []
        }, () => this.populateData());
    }


    componentDidMount() {
        this.populateData();
    }

    render() {

        const {
            title,
            description,
            facilityId,
            eventId,

        } = this.props;

        return (<div>

            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h5>{title && title} {!title && "Availability"}
                                {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                            </h5>
                            <span className="d-block m-t-5 text-muted">{description && description} {!description && "Availability for this resource"}</span>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <InputGroup>
                                    <DatePicker
                                        id="date"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        placeholderText="Choose a date"
                                        selected={this.state.date}
                                        onChange={this.handleDateChange}
                                        dateFormat='dd/MM/yyyy'
                                        className="form-control" />
                                </InputGroup>
                            </div>
                        </Col>
                        <Col>
                            <SelectField
                                isDisabled={!this.state.scheduleOptions || this.state.scheduleOptions.length < 2}
                                id="scheduleId"
                                name="scheduleId"
                                placeholder="Schedule"
                                options={this.state.scheduleOptions}
                                value={this.state.scheduleId}
                                isMulti={false}
                                onChange={this.handleScheduleChange}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                    </Row>
                </CardHeader>
            </Card>

            {!this.state.date &&
                <div className="alert alert-info" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>Please select the date you'd like to view the availability for</p>
                </div>}

            {!this.state.loading && this.state.date && this.state.scheduleOptions && this.state.scheduleOptions.length === 0 &&
                <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>No schedules:</strong> No valid schedule for this date, bookings cannot be made</p>
                </div>}

            {!this.state.loading && this.state.date && this.state.scheduleOptions && !this.state.scheduleId && this.state.scheduleOptions.length > 0 &&
                <div className="alert alert-info" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>Please select the schedule you'd like to view the availability for</p>
                </div>}

            {this.state.availability && this.state.availability.length > 0 && <Card>
                <CardBody>
                    <h5>Availability</h5>
                    <hr />
                    <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap" }}>
                        {this.state.availability.map((item, index) => (
                            <ListGroupItem key={item.start}
                                style={{
                                    flexWrap: "wrap", "width": "120px"
                                }}
                                disabled={!item.available}>

                                <ListGroupItemHeading style={{ textAlign: "center" }}>{item.start.substring(0, item.start.length - 3)}</ListGroupItemHeading>
                                <small>{item.capacity - item.booked} remaining</small>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </CardBody>
            </Card>}

        </div>);
    }

    async populateData() {

        const {
            facilityId,
            eventId,

        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true });

            var scheduleId = this.state.scheduleId;

            if (this.state.date) {
                var result = await HengyiSchedulesClient.List(new SchedulesQueryParams()
                    .WithFacility(facilityId)
                    .WithEvent(eventId)
                    .WithDate(this.state.date).Paginate(0, 1000))

                var scheduleOptions = result.data.data.map(i => { return { value: i.id, label: i.name }; });
                this.setState({
                    scheduleOptions: scheduleOptions
                });

                if (result.data.data.length === 1) {
                    this.setState({
                        scheduleId: scheduleOptions[0]
                    });
                    scheduleId = scheduleOptions[0];
                }
            }

            if (scheduleId && scheduleId.value && this.state.date) {
                var response = await HengyiBookingSlotsClient.List(new BookingSlotsQueryParams()
                    .WithScheduleId(scheduleId.value)
                    .WithBookingDate(this.state.date));

                console.log("Setting availability");
                console.log(response.data);

                this.setState({
                    availability: response.data
                });
            }

            this.setState({ loading: false });
        }
    }
}

export { AvailabilityView };