import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { HengyiSwipeCardClient } from '../infrastructure/HengyiClient/SwipeCards';
import { UserLink } from '../Users/UserLink';
import { Formik, Field, Form } from 'formik';
import { HengyiApi, UserQueryParams, ListQueryParams } from '../infrastructure/fetchHengy';
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';
import { SelectField } from "../infrastructure/SelectField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { UserSelector } from '../infrastructure/UserSelector';

export class SwipeCardsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1',
            mode: "none"
        };

        this.toggle = this.toggle.bind(this);
        this.resetCard = this.resetCard.bind(this);
        this.disableCard = this.disableCard.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/swipe-cards" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/swipe-cards" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Swipe Card</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/swipe-cards/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}

                                        {this.state.data && this.state.data.status === "available" && <button style={{ float: "right" }} onClick={() => { this.setState({ mode: "assign" }); }} className="btn btn-outline-dark mt-2">Assign</button>}
                                        {this.state.data && this.state.data.status !== "available" && <button style={{ float: "right" }} onClick={() => { this.setState({ mode: "reset" }); }} className="btn btn-outline-dark mt-2">Reset</button>}
                                        {this.state.data && this.state.data.status !== "disabled" && <button style={{ float: "right" }} onClick={() => { this.setState({ mode: "disable" }); }} className="btn btn-outline-dark mt-2">Disable</button>}

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.mode === "assign" && <Card><CardBody><Formik
                        initialValues={{
                            cardAssignment: "resident",
                            userId: "",
                            contractorId: ""
                        }}
                        onSubmit={async (fields, { setErrors, setFieldError }) => {

                            if (fields.cardAssignment === "resident" && !fields.userId) {
                                setFieldError("userId", "Please select a resident");
                                return;
                            }

                            if (fields.cardAssignment === "contractor" && !fields.contractorId) {
                                setFieldError("contractorId", "Please select a contractor");
                                return;
                            }

                            var response = {};

                            if (fields.cardAssignment === "resident")
                                response = await HengyiSwipeCardClient.AssignToResident(this.props.match.params.id, fields.userId.value);

                            if (fields.cardAssignment === "contractor")
                                response = await HengyiSwipeCardClient.AssignToContractor(this.props.match.params.id, fields.contractorId.value);

                            if (!response.successful) {

                                response.validationErrors.map(error => {
                                    setFieldError(error.key, error.message);
                                    return {
                                        [error.key]: error.message
                                    };
                                });

                            } else {
                                this.populateData(this.props.match.params.id)
                                this.setState({ mode: "none" });
                            }

                        }}
                    >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <div class="form-check">
                                        <Field class="form-check-input" type="radio" name="cardAssignment" id="resident" value="resident" />
                                        <label class="form-check-label" for="resident">Assigned to resident</label>
                                    </div>
                                    <div class="form-check">
                                        <Field class="form-check-input" type="radio" name="cardAssignment" id="contractor" value="contractor" />
                                        <label class="form-check-label" for="contractor">Assigned to contractor</label>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {values.cardAssignment && values.cardAssignment === "resident" && <Row>
                                <Col>

                                    <UserSelector
                                        value={values.userId}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.userId}
                                        error={errors.userId} />
                                    </Col>

                            </Row>}

                            {values.cardAssignment && values.cardAssignment === "contractor" && <Row>
                                <Col>
                                    <SelectField
                                        id="contractorId"
                                        name="contractorId"
                                        label="Contractor"
                                        placeholder="Select contractor"
                                        options={this.state.contractorOptions}
                                        value={values.contractorId}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.contractorId}
                                        error={errors.contractorId}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                    /></Col>
                            </Row>}

                            <div className="form-group mt-3">
                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                    {!isSubmitting && "Assign"}
                                    {isSubmitting && <Spinner animation="border" />}
                                </button>
                                <button onClick={() => { this.setState({ mode: "none" }); }} className="btn btn-outline-dark mt-2">Cancel</button>
                            </div>
                        </Form>
                    )}</Formik></CardBody></Card>}

                    {this.state.mode === "reset" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> This will remove the assigned user/ contractor and mark the card as available for reuse.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/" onClick={this.resetCard} className="btn mr-2 mt-2 mb-2 btn-danger">Reset card</Button>
                                    <Button style={{ float: "right" }} to="/" onClick={() => { this.setState({ mode: "none" }); }} className="btn mr-2 mt-2 mb-2 btn-default">Cancel</Button>
                                </Col>
                            </Row>
                        </div>}

                    {this.state.mode === "disable" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> This will remove the disable the card to indicate that it should no longer be used.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/" onClick={this.disableCard} className="btn mr-2 mt-2 mb-2 btn-danger">Disable card</Button>
                                    <Button style={{ float: "right" }} to="/" onClick={() => { this.setState({ mode: "none" }); }} className="btn mr-2 mt-2 mb-2 btn-default">Cancel</Button>
                                </Col>
                            </Row>
                        </div>}
                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Identifier:</td>
                                                <td>
                                                    {this.state.data.cardIdentifier && this.state.data.cardIdentifier}
                                                    {!this.state.data.cardIdentifier && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>
                                                    {this.state.data.type === "accessCard" && <Badge color="primary">Access</Badge>}
                                                    {this.state.data.type === "evChargeCard" && <Badge color="success">EV Charge</Badge>}
                                                    {this.state.data.type === "key" && <Badge color="info">Key</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">User:</td>
                                                <td>
                                                    {this.state.data.user && <UserLink user={this.state.data.user} />}
                                                    {!this.state.data.user && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Contractor:</td>
                                                <td>
                                                    {this.state.data.contractor && <span>{this.state.data.contractor.name} {this.state.data.contractor.surname} {this.state.data.contractor.company && <span>-  {this.state.data.contractor.company}</span>}</span>}
                                                    {!this.state.data.contractor && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "available" && <Badge color="success">Available</Badge>}
                                                    {this.state.data.status === "resident" && <Badge color="success">Assigned to resident</Badge>}
                                                    {this.state.data.status === "contractor" && <Badge color="success">Assigned to contractor</Badge>}
                                                    {this.state.data.status === "lost" && <Badge color="secondary">Lost</Badge>}
                                                    {this.state.data.status === "disabled" && <Badge color="secondary">Disabled</Badge>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Card activity" description="List all card activity" entityId={this.props.match.params.id} />}
                        </TabPane>

                    </TabContent>

                </div>
            );
        }
    }


    async resetCard() {
        await HengyiSwipeCardClient.ResetCard(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
        this.setState({ mode: "none" });
    }

    async disableCard() {
        await HengyiSwipeCardClient.DisableCard(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
        this.setState({ mode: "none" });
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiSwipeCardClient.Get(id);

            var contractors = (await HengyiContractorClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({
                    data: response.data,
                    contractorOptions: contractors.map(function (item) { return { value: item.id, label: item.name + " " + item.surname + " - " + item.company }; }),
                    loading: false
                });
            }
        }
    }
}
