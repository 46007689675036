import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class HandoverNoteParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithIsAssigned(value: string) {
        this.WithParam("isAssigned", value);
        return this;
    }

    ShowOnlyMyTickets() {
        this.WithParam("showOnlyMyTickets", true);
        return this;
    }

    WithHideClosed(value: Boolean) {
        this.WithParam("hideClosed", value);
        return this;
    }


    WithAssignedTo(value: string) {
        this.WithParam("assignedToId", value);
        return this;
    }

}

export class HandoverNoteCommentParams extends ListQueryParams {

    WithIncludeDeleted(includeDeleted: Boolean) {
        this.WithParam("includeDeleted", includeDeleted);
        return this;
    }

}

//******************************
// Handover notes
//******************************
export class HengyiHandoverNoteClient {

    static async List(params: HandoverNoteParams) {

        var query = (await GetAPIDomain()) + "/handover-notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Export(params: HandoverNoteParams) {

        var query = (await GetAPIDomain()) + "/handover-notes/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async Update(id: string, title: string, note: string, apartmentId?: string, facilityId?: string, assignedToId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "message",
                "value": note
            },
            {
                "op": "replace",
                "path": "title",
                "value": title
            },
            {
                "op": "replace",
                "path": "apartmentId",
                "value": apartmentId
            },
            {
                "op": "replace",
                "path": "facilityId",
                "value": facilityId
            }])
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(title: string, note: string, apartmentId?: string, facilityId?: string, assignedToId?: string) {

        var request: any = {
            note: note,
            title: title,
            assignedToId: assignedToId,
            apartmentId: apartmentId,
            facilityId: facilityId
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async SetImages(id: string, imageIds?: string[]) {

        var request: any = {
            mode: "replace",
            imageIds: imageIds
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id + "/set-images", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async CloseHandoverNote(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id + "/close", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async OpenHandoverNote(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id + "/open", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async AssignHandoverNote(id: string, userId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id + "/assign", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId
            })
        }));
    }

    static async AddComment(id: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/handover-notes/" + id + "/comments", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        }));
    }

    static async ListComments(id: string, params: HandoverNoteCommentParams) {

        var query = (await GetAPIDomain()) + "/handover-notes/" + id + "/comments" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/handover-notes/" + id + "/comments/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}