import React, { Component } from "react";
import { Link } from 'react-router-dom';

class UserLink extends Component {

    render() {
        const {
            user,
            missingText,
            hideApartments
        } = this.props;

        return (
            <div>
                {user && <Link key={user.id + "-user-component-view"} to={"/users/" + user.id}>
                    {user.name + " " + user.surname}
                    {user.apartments && user.apartments.length !== 0 && !hideApartments && <p>
                        {user.apartments && user.apartments.length !== 0 && <small>{user.apartments.map((item, index, array) => <>{item.apartment && <span key={item.apartment.id}>{item.apartment.name}{((index + 1) < array.length) && ", "}</span>}</>
                        )}</small>}</p>}
                </Link>}
                {!user && !missingText && <i> Not specified</i>}
                {!user && missingText && <i>{missingText}</i>}
            </div>
        );
    }
}

export { UserLink };
