import React, { Component } from 'react';
import { HengyiApi, TicketCategoryQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class TicketTypeDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            categoryData: [], categoryLoading: false, categoryTotal: 0, categorySkip: 0, categoryTake: 50, categorySearch: "",
            activeTab: '1'
        };
        this.delete = this.delete.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.handleCategorySearchChange = this.handleCategorySearchChange.bind(this);

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
        await this.updateCategoryData();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }


    render() {

        if (this.state.next) {
            return (<Redirect to="/ticket-types" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/ticket-types" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Ticket Type</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/ticket-types/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Categories
                            </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardBody>
                                        <h6>Details</h6>
                                        <hr />
                                        <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && <span>{this.state.data.name}</span>}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Instructions:</td>
                                                <td>
                                                    {this.state.data.instructions && <span>{this.state.data.instructions}</span>}
                                                    {!this.state.data.instructions && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td>
                                                        {this.state.data.status && <span>{this.state.data.status}</span>}
                                                        {!this.state.data.status && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Categories ({this.state.categoryTotal})
                                     {this.state.categoryLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">List of categories for this type</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>
                                                    <button style={{ float: "right" }} onClick={() => { this.categoryNext(); }} disabled={this.state.categoryLoading || (this.state.categorySkip + this.state.categoryTake >= this.state.categoryTotal)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                    <button style={{ float: "right" }} onClick={() => { this.categoryPrevious(); }} disabled={this.state.categoryLoading || (this.state.categorySkip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                                    <Link style={{ float: "right" }} to={"/ticket-types/" + this.props.match.params.id + "/categories/new"} className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Filter</h5>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <InputGroup>
                                                        <Input type="text" placeholder="Search" value={this.state.categorySearch} onChange={this.handleCategorySearchChange} onKeyPress={e => e.key === 'Enter' && this.updateCategoryData()}/>
                                                        <ButtonGroup className="input-group-append">
                                                            <button onClick={() => { this.updateCategoryData(); }} disabled={this.state.categoryLoading} className="btn btn-outline-dark" >
                                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                            </button>
                                                        </ButtonGroup>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody className="p-0">
                                        {this.state.categoryData &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>Name</th>
                                                        <th style={{ borderTop: "none" }}>Status</th>
                                                        <th style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.categoryData.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td style={{ textTransform: 'capitalize' }}>{item.status}</td>
                                                            <td>
                                                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/ticket-types/" + this.props.match.params.id + "/categories/edit/" + item.id}>Edit</Link>
                                                                <Button style={{ float: "right" }} to="/ticket-types" onClick={() => { this.deleteCategory(item.id); }} className="btn mr-2 mt-2 mb-2 btn-danger">Delete</Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.categoryData.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No categories to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>

                            </div>}
                        </TabPane>
                    </TabContent>

                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this type, doing so will permanently remove it. Please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete type</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This type has categories, please delete all categories first</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetTicketType(id);
            console.log(response);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }


        }
    }

    handleCategorySearchChange(evt) {
        this.setState({
            categorySearch: evt.target.value
        });
    }

    async delete() {
        var success = await HengyiApi.DeleteTicketType(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async deleteCategory(id) {
        await HengyiApi.DeleteTicketCategory(id);
        await this.updateCategoryData();
    }


    async categoryPrevious() {
        await this.populateCategoryData(this.state.categorySkip - this.state.categoryTake, this.state.categoryTake);
    }

    async categoryNext() {
        await this.populateCategoryData(this.state.categorySkip + this.state.categoryTake, this.state.categoryTake);
    }

    async updateCategoryData() {
        await this.populateCategoryData(this.state.categorySkip, this.state.categoryTake);
    }


    async populateCategoryData(skip, take) {

        if (!this.state.loading) {
            this.setState({ categoryLoading: true, categorySkip: skip, categoryTake: take });

            var response = await HengyiApi.GetTicketCategories(new TicketCategoryQueryParams()
                .WithTicketType(this.props.match.params.id)
                .Paginate(skip, take).Search(this.state.categorySearch));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ categoryData: response.data.data, categoryLoading: false, categoryTotal: response.data.total });
            }
        }
    }
}
