import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class ParcelNew extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, authenticated: true, next: false, origin: "parcels" };


        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("origin") === "dashboard")
            this.setState({
                origin: "dashboard"
            });

        this.populateData();
    }


    renderForm() {
        return (<Formik
            initialValues={{
                parcelType: "parcel",
                location: "Storage",
                userId: "",
                apartmentId: "",
                numberOfPackages: 1,
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.apartmentId) {
                    setFieldError("apartmentId", "Please select the space this parcel is being delivered to");
                    return;
                }

                if (!fields.userId) {
                    setFieldError("userId", "Please select a contact to bill/ notify about this parcel");
                    return;
                }

                var submission = {
                    residentNote: this.state.residentNote,
                    location: fields.location,
                    description: this.state.description,
                    numberOfPackages: fields.numberOfPackages,
                    parcelType: fields.parcelType
                };

                if (fields.apartmentId)
                    submission.apartmentId = fields.apartmentId.value;

                if (fields.userId)
                    submission.userId = fields.userId.value;

                const response = await fetch((await GetAPIDomain()) + "/parcels", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(submission)
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    const data = await response.json();
                    this.setState({ next: true, id: data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <Row>
                        <Col>
                            <ApartmentSelector
                                label="Space*"
                                value={values.apartmentId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId}
                                filterUser={values.userId ? values.userId.value : null} />
                        </Col>
                        <Col>
                            <UserSelector
                                label="Primary contact*"
                                value={values.userId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.userId}
                                error={errors.userId}
                                filterApartment={values.apartmentId ? values.apartmentId.value : null } />
                        </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <div className="form-group">

                            <label htmlFor="location">Location*</label>
                            <select type="select" name="location"
                                    value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                                <option value="Front Desk">Front Desk</option>
                                <option value="Storage">Storage</option>
                            </select>

                            <ErrorMessage name="location" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="parcelType">Type</label>
                            <select type="select" name="parcelType"
                                value={values.parcelType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                    className={'form-control' + (errors.parcelType && touched.parcelType ? ' is-invalid' : '')}>
                                    <option value="mail">Mail</option>
                                <option value="parcel">Parcel</option>
                            </select>
                            <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                                <input name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="form-group">
                                <label htmlFor="residentNote">Note to display to resident</label>
                                <input name="residentNote" type="text" className={'form-control' + (errors.residentNote && touched.residentNote ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="residentNote" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="numberOfPackages">Number of packages</label>
                            <Field name="numberOfPackages" type="number" className={'form-control' + (errors.numberOfPackages && touched.numberOfPackages ? ' is-invalid' : '')} />
                            <ErrorMessage name="numberOfPackages" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            if (this.state.origin === "dashboard") {
                return (<Redirect to="/" />);
            } else {
                return (<Redirect to={"/parcels?reset=true"} />);
            }
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New parcel
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a new parcel which has been dropped off</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.origin === "dashboard" &&
                                            <Link style={{ float: "right" }} to="/" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }
                                        {this.state.origin !== "dashboard" &&
                                            <Link style={{ float: "right" }} to="/parcels" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
