import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class CampaignQueryParams extends ListQueryParams {

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithHideSent(value: string) {
        this.WithParam("hideSentCampaigns", value);
        return this;
    }

    WithHideCancelled(value: string) {
        this.WithParam("hideCancelledCampaigns", value);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiCampaignClient {

    static async List(params: CampaignQueryParams) {

        var query = (await GetAPIDomain()) + "/campaigns" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async ListRecipients(id: any, params: CampaignQueryParams) {

        var query = (await GetAPIDomain()) + "/campaigns/"+id+"/recipients" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Create(title: string, content: string, scheduledSend?: string, audiences?: any, partnerOfferId?: string) {

        var request: any = {
            title: title,
            content: content,
            partnerOfferId: partnerOfferId,
            audiences: audiences
        }

        if (scheduledSend)
            request.scheduledSend = scheduledSend;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, title: string, content: string, scheduledSend?: string, audiences?: any, partnerOfferId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "title",
                "value": title
            }, {
                "op": "replace",
                "path": "content",
                "value": content
            }, {
                "op": "replace",
                "path": "audiences",
                "value": audiences
                }, {
                    "op": "replace",
                    "path": "scheduledSend",
                    "value": scheduledSend
                }, {
                    "op": "replace",
                "path": "partnerOfferId",
                "value": partnerOfferId
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/campaigns/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Schedule(id: string, scheduledSend?: string) {

        var request: any = { }

        if (scheduledSend)
            request.scheduledSend = scheduledSend;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/" + id + "/schedule", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Cancel(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async MakeDraft(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/campaigns/" + id + "/set-as-draft", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
}