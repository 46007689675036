import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { userContext } from '../../userContext';
import { ActivityTable } from '../Activity/ActivityTable';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiParcelClient } from '../infrastructure/HengyiClient/Parcels';

export class ParcelDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, showForm: "none", includeDeleted: false,
            activeTab: '1',
            showAddNote: false
        };

        this.toggle = this.toggle.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    async deleteNote(id) {
        await HengyiParcelClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/parcels" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/parcels" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Parcel</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                        {this.state.data && this.state.data.status === "ready" && <Badge color="primary" className="ml-2">Ready for collection</Badge>}
                                        {this.state.data && this.state.data.status === "requestedDropOff" && <Badge color="info" className="ml-2">Take to apartment</Badge>}
                                        {this.state.data && this.state.data.status === "requestedPickUp" && <Badge color="info" className="ml-2">Resident will collect</Badge>}
                                        {this.state.data && this.state.data.status === "collected" && <Badge color="secondary" className="ml-2">Collected</Badge>}
                                        {this.state.data && this.state.data.status === "delivered" && <Badge color="secondary" className="ml-2">Delivered</Badge>}
                                        {this.state.data && this.state.data.status === "invalidated" && <Badge color="danger" className="ml-2">Invalidated</Badge>}
                                        {this.state.data && this.state.data.status === "inTransit" && <Badge color="success" className="ml-2">In transit</Badge>}

                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.location}</span>}</span>

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && this.state.data.status !== "invalidated" && this.state.data.status !== "handedOver" && <Link style={{ float: "right" }} to={"/parcels/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                        {this.state.data && this.state.data.status !== "invalidated" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "invalidate" }); }} className="btn mr-2 btn-outline-dark mt-2 mb-2">Invalidate</button>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.showForm === "invalidate" && <Card>
                        <CardBody>
                            <Formik
                                initialValues={{
                                    message: ""
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                    if (!fields.message) {
                                        setFieldError("message", "Please specify why you are invalidating this parcel");
                                        return;
                                    }

                                    var response = await HengyiParcelClient.InvalidateParcel(this.props.match.params.id, fields.message);

                                    if (!response.successful) {
                                        response.data.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });

                                    } else {
                                        this.setState({ showForm: "none" });
                                        setFieldValue("message", "");
                                        await this.populateData(this.props.match.params.id);
                                    }
                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="message">Reason for invalidating</label>
                                        <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="mt-4">
                                        <button style={{ padding: "6px", float: "right" }} onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                        <button style={{ padding: "6px", float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                            {!isSubmitting && "Invalidate"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </button>
                                    </div>
                                </Form>
                            )}</Formik>
                        </CardBody>
                    </Card>}

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Audit log
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    {!this.state.loading && this.state.data && this.state.data.status === "ready" &&
                                        <div>
                                            <div className="alert alert-info" style={{ marginTop: "30px", marginBottom: "30px" }}>
                                                <p className="mt-2"><strong>Resident has not indicated collection preference</strong></p>
                                                <p>If they do not collect the parcel soon, or indicate when they will collect, please get in touch with apartment {this.state.data.apartment.name}</p>
                                            </div>
                                            <hr />
                                        </div>
                                    }

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Parcel Number:</td>
                                                <td>
                                                    #{this.state.data.parcelNumber}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Location:</td>
                                                <td>
                                                    {this.state.data.location && this.state.data.location}
                                                    {!this.state.data.location && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Parcel Type</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    {this.state.data.parcelType && this.state.data.parcelType}
                                                    {!this.state.data.parcelType && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Resident note:</td>
                                                <td>
                                                    {this.state.data.residentNote && this.state.data.residentNote}
                                                    {!this.state.data.residentNote && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Number of packages:</td>
                                                <td>
                                                    {this.state.data.numberOfPackages && this.state.data.numberOfPackages}
                                                    {!this.state.data.numberOfPackages && this.state.data.numberOfPackages !== 0 && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Space:</td>
                                                <td>
                                                    {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                    {!this.state.data.apartment && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Contact:</td>
                                                <td>
                                                    {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/" + this.state.data.user.id}>{this.state.data.user.name} {this.state.data.user.surname}</Link>}
                                                    {!this.state.data.user && <i>All residents</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created by:</td>
                                                <td>
                                                    {this.state.data.createdBy && <Link key={this.state.data.createdBy.id + "-view"} to={"/users/" + this.state.data.createdBy.id}>{this.state.data.createdBy.name} {this.state.data.createdBy.surname}</Link>}
                                                    {!this.state.data.createdBy && <i>System generated</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created:</td>
                                                <td>
                                                    <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.created}</Moment>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Requested for
                                                </td>
                                                <td>
                                                    {this.state.data.requestedHandoverDate &&
                                                        <>
                                                            <Moment utc local format="ddd DD MMM YY">{this.state.data.requestedHandoverDate}</Moment> &nbsp;

                                            {this.state.data.requestedHandoverDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                                            {this.state.data.requestedHandoverDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                                            {this.state.data.requestedHandoverDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}

                                                        </>
                                                    }
                                                    {!this.state.data.requestedHandoverDate && <i>-</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>

                                                    {this.state.data && this.state.data.status === "ready" && <Badge color="primary" className="ml-2">Ready for collection</Badge>}
                                                    {this.state.data && this.state.data.status === "requestedDropOff" && <Badge color="info" className="ml-2">Take to apartment</Badge>}
                                                    {this.state.data && this.state.data.status === "requestedPickUp" && <Badge color="info" className="ml-2">Resident will collect</Badge>}
                                                    {this.state.data && this.state.data.status === "collected" && <Badge color="secondary" className="ml-2">Collected</Badge>}
                                                    {this.state.data && this.state.data.status === "delivered" && <Badge color="secondary" className="ml-2">Delivered</Badge>}
                                                    {this.state.data && this.state.data.status === "invalidated" && <Badge color="danger" className="ml-2">Invalidated</Badge>}
                                                    {this.state.data && this.state.data.status === "inTransit" && <Badge color="success" className="ml-2">In transit</Badge>}
                                                </td>
                                            </tr>
                                            {this.state.data.status === "invalidated" && <tr>
                                                <td className="pr-2">Invalidation note:</td>
                                                <td>
                                                    {this.state.data.invalidationNote && <span>{this.state.data.invalidationNote}</span>}
                                                    {!this.state.data.invalidationNote && <i>Not specified</i>}
                                                </td>
                                            </tr>}
                                            {(this.state.data.status === "collected" || this.state.data.status === "delivered") && <tr>
                                                <td className="pr-2">Note:</td>
                                                <td>
                                                    {this.state.data.handoverNotes && <span>{this.state.data.handoverNotes}</span>}
                                                    {!this.state.data.handoverNotes && <i>Not specified</i>}
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Parcel audit" description="List all parcel activity" entityId={this.props.match.params.id} />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <div>

                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this parcel</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        if (!fields.message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiParcelClient.AddNote(this.props.match.params.id, fields.message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                            <Field as="textarea" name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("parcel.modify") && item.status === "active"&& <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                    </TabContent>
                </div>
            );
        }
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiParcelClient.Get(id);

            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiParcelClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false,
                    notes: notes.data
                });
            }
        }
    }
}
