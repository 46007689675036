import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiCarParkClient } from '../infrastructure/HengyiClient/CarParks';
import { HengyiCampaignClient } from '../infrastructure/HengyiClient/Campaigns';
import DatePicker from 'react-datepicker';
import { AudienceSelector } from '../Audience/AudienceSelector';
import { PartnerOfferSelector } from '../infrastructure/PartnerOfferSelector';

export class CampaignsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", audiences: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    sendBehaviour: this.state.sendBehaviour,
                    scheduledSend: this.state.scheduledSend,
                    partnerOfferId: "",
                    campaignType: this.state.campaignType
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    console.log(fields);

                    var response = await HengyiCampaignClient.Update(this.props.match.params.id,
                        this.state.title,
                        this.state.content,
                        fields.scheduledSend,
                        this.state.audiences,
                        fields.campaignType === "offer" && fields.partnerOfferId ? fields.partnerOfferId.value : null);

                    if (fields.sendBehaviour === "now") {
                        await HengyiCampaignClient.Schedule(response.data.id, (new Date()).toISOString());
                    } else if (fields.sendBehaviour === "schedule") {
                        await HengyiCampaignClient.Schedule(response.data.id, fields.scheduledSend);
                    } else if (fields.sendBehaviour === "draft") {
                        await HengyiCampaignClient.MakeDraft(response.data.id);
                    }

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>
                        <Card>
                            <CardBody>

                                <h6>Details</h6>
                                <hr />
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input id="title" name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} onChange={this.handleChange} />
                                            <ErrorMessage name="title" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="content">Content</label>
                                            <textarea id="content" name="content" type="text" component="textarea" className="form-control" onChange={this.handleChange}></textarea>
                                            <ErrorMessage name="content" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="campaignType">Type</label>
                                            <Field as="select" name="campaignType"
                                                className={'form-control' + (errors.campaignType && touched.campaignType ? ' is-invalid' : '')}>
                                                <option value="general" label="General" />
                                                <option value="offer" label="Offer" />
                                            </Field>
                                            <ErrorMessage name="campaignType" component="div" className="invalid-feedback" />
                                        </div>

                                        {values.campaignType === "offer" && <div className="form-group">
                                            <PartnerOfferSelector
                                                value={values.partnerOfferId}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                touched={touched.partnerOfferId}
                                                error={errors.partnerOfferId}
                                                initialPartnerOfferId={this.state.partnerOfferId}
                                                filterStatus="published"  />                                          
                                        </div>}

                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>

                                <div className="form-group">
                                    <h6>Audience</h6>
                                    <hr />

                                    <Row className="mb-2">
                                        <Col>Type</Col>
                                        <Col>Entity</Col>
                                        <Col md="1"></Col>
                                    </Row>
                                    <hr />
                                    {(!this.state.audiences || this.state.audiences.length === 0) && <i>No audiences added yet</i>}
                                    {this.state.audiences && this.state.audiences.length > 0 &&
                                        <div>

                                        {this.state.audiences.map((audience, index) =>
                                            <Row key={"audience-"+index}>
                                                    <Col style={{ textTransform: 'capitalize' }}>
                                                        {audience.type}
                                                    </Col>
                                                    <Col style={{ textTransform: 'capitalize' }}>
                                                        {audience.entityName && <span>{audience.entityName}</span>}
                                                        {!audience.entityName && <i>Everything</i>}
                                                    </Col>
                                                    <Col md="1">
                                                        <button className="btn mr-2 btn-outline-dark" onClick={() => {
                                                            console.log("Remove index: " + index);

                                                            this.state.audiences.splice(index, 1);
                                                            this.setState({
                                                                audiences: this.state.audiences
                                                            });

                                                            return false;
                                                        }}>REMOVE</button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>}

                                    <hr />

                                    <AudienceSelector hideUserType={true} hideLabels={true} initialValues={{
                                        audience: "all",
                                        userType: "any"
                                    }} onSubmit={async (fields, { setErrors, setFieldError }) => {

                                        var audiences = this.state.audiences;

                                        var audience = {
                                            type: fields.audience
                                        };

                                        if (fields.userType && fields.userType !== "any")
                                            audience.userType = fields.userType;


                                        if (fields.entityId) {
                                            audience.entityId = fields.entityId.value;
                                            audience.entityName = fields.entityId.label;
                                        }

                                        audiences.push(audience);

                                        this.setState({
                                            audiences: audiences
                                        });

                                    }} />

                                </div>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h6>Scheduling</h6>
                                <hr />

                                <Row>
                                    <Col>
                                        <div className="form-check">
                                            <Field className="form-check-input" type="radio" name="sendBehaviour" id="draft" value="draft" />
                                            <label className="form-check-label" htmlFor="draft">Save as draft</label>
                                        </div>
                                        <div className="form-check">
                                            <Field className="form-check-input" type="radio" name="sendBehaviour" id="now" value="now" />
                                            <label className="form-check-label" htmlFor="now">Send right away</label>
                                        </div>
                                        <div className="form-check">
                                            <Field className="form-check-input" type="radio" name="sendBehaviour" id="schedule" value="schedule" />
                                            <label className="form-check-label" htmlFor="schedule">Schedule a time to send</label>
                                        </div>
                                    </Col>
                                </Row>
                                {values.sendBehaviour && values.sendBehaviour === "schedule" && <Row className="mt-2">
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="scheduledSend">Scheduled for</label>

                                            <DatePicker id="scheduledSend"
                                                onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                                autoComplete="off"
                                                className="form-control"
                                                selected={values.scheduledSend}
                                                showTimeSelect
                                                dateFormat='dd/MM/yyyy hh:mm a'
                                                onChange={(v, f) => {
                                                    setFieldValue("scheduledSend", v);
                                                }} />
                                        </div>
                                    </Col>
                                </Row>}
                            </CardBody>
                        </Card>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && <span>
                                    {!values.sendBehaviour && "Save"}
                                    {values.sendBehaviour && values.sendBehaviour === "draft" && "Save"}
                                    {values.sendBehaviour && values.sendBehaviour === "now" && "Send"}
                                    {values.sendBehaviour && values.sendBehaviour === "schedule" && "Schedule"}
                                </span>}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/campaigns/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update campaign
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/campaigns/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    {this.state.title && this.renderForm()}

                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiCampaignClient.Get(this.props.match.params.id);

            var audiences = [];

            if (response.data && response.data.audiences) {
                audiences = response.data.audiences.map(item => {
                    if (item.apartment)
                        return {
                            entityName: item.apartment.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.apartment.id
                        };
                    if (item.apartmentType)
                        return {
                            entityName: item.apartmentType.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.apartmentType.id
                        };
                    if (item.building)
                        return {
                            entityName: item.building.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.building.id
                        };
                    if (item.floor)
                        return {
                            entityName: item.floor.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.floor.id
                        };

                    if (item.user)
                        return {
                            entityName: item.user.name + " "+item.user.surname,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.user.id
                        };


                    if (item.type === "all")
                        return {
                            entityName: "Everyone",
                            type: item.type,
                            userType: item.userType,
                            entityId: null
                        };

                    return {
                        entityName: "Unknown type: " + item.type,
                        type: item.type,
                        userType: item.userType,
                        entityId: ""
                    };
                });
            }

            console.log(response.data.scheduledSend);
            console.log("Send: " + (response.data.status === "scheduled" ? "schedule" : "draft"));

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.scheduledSend) {
                    this.setState({ scheduledSend: new Date(response.data.scheduledSend) });
                }

                if (response.data.partnerOffer)
                    this.setState({ partnerOfferId: response.data.partnerOffer.id });

                this.setState({
                    loading: false,
                    title: response.data.title,
                    content: response.data.content,
                    audiences: audiences,
                    sendBehaviour: response.data.status === "scheduled" ? "schedule" : "draft",
                    campaignType: response.data.partnerOffer ? "offer" : "general"
                }, () => {
                    document.getElementById("title").value = response.data.title;
                    document.getElementById("content").value = response.data.content;
                });
            }
        }
    }
}
