import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardBody, CardHeader, Row, Col, Button, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { userContext } from '../../userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiChargeClient } from '../infrastructure/HengyiClient/Charges';
import NumberFormat from 'react-number-format';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import classnames from 'classnames';

export class ChargesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, canEdit: false,
            activeTab: '1',
            showAddNote: false, includeDeleted: false };
        this.delete = this.delete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiChargeClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/charges" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/charges" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Charges</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.canEdit && <Link style={{ float: "right" }} to={"/charges/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    {!this.state.canEdit && <div className="alert alert-info">
                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This charge cannot be edited or deleted as it has been invoiced</p>
                    </div>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardHeader>
                                        <h6>Charge details</h6>
                                        <hr />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td>
                                                        {this.state.data.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                        {this.state.data.status === "invoiced" && <Badge color="success">Invoiced</Badge>}
                                                        {this.state.data.status === "voided" && <Badge color="secondary">Voided</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Description:</td>
                                                    <td>
                                                        {this.state.data.description && this.state.data.description}
                                                        {!this.state.data.description && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Amount:</td>
                                                    <td>
                                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(this.state.data.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Invoice:</td>
                                                    <td>
                                                        {!this.state.data.invoice && <i>Not invoiced yet</i>}

                                                        {this.state.data.invoice &&
                                                            <>
                                                                {this.state.data.invoice.status === "draft" && <Badge color="primary">Draft</Badge>}
                                                                {this.state.data.invoice.status === "open" && <Badge color="primary">Open</Badge>}
                                                                {this.state.data.invoice.status === "paid" && <Badge color="success">Paid</Badge>}
                                                                {this.state.data.invoice.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                                                {this.state.data.invoice.status === "void" && <Badge color="secondary">Voided</Badge>}
                                                                {this.state.data.invoice.status === "deleted" && <Badge color="secondary">Deleted</Badge>}
                                                                <Link key={this.state.data.invoice.id + "-invoice-view"} to={"/invoices/" + this.state.data.invoice.id}> &nbsp;
                                                        {!this.state.data.invoice.invoiceNumber && <i>No invoice number</i>}
                                                                    {this.state.data.invoice.invoiceNumber && this.state.data.invoice.invoiceNumber}
                                                                </Link>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">User:</td>
                                                    <td>
                                                        {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/" + this.state.data.user.id}>{this.state.data.user.name} {this.state.data.user.surname}</Link>}
                                                        {!this.state.data.user && <i>Anyone at apartment</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Billing code:</td>
                                                    <td>
                                                        {this.state.data.billingCode && <Link key={this.state.data.billingCode.id + "-view"} to={"/billing-codes/" + this.state.data.billingCode.id}>{this.state.data.billingCode.name}</Link>}
                                                        {!this.state.data.billingCode && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Created by:</td>
                                                    <td>
                                                        {this.state.data.createdBy && <Link key={this.state.data.createdBy.id + "-view"} to={"/users/" + this.state.data.createdBy.id}>{this.state.data.createdBy.name} {this.state.data.createdBy.surname}</Link>}
                                                        {!this.state.data.createdBy && <i>System generated</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Created:</td>
                                                    <td>
                                                        <Moment utc local format="ddd, DD/MM h:mma" date={this.state.data.created} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardHeader>
                                </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this charge</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        var message = document.getElementById("message").value;

                                                        if (!message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }

                                                        var response = await HengyiChargeClient.AddNote(this.props.match.params.id, message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            setFieldValue("message", "");
                                                            document.getElementById("message").value = "";
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                                <textarea as="textarea" name="message" id="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("charges.modify") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                    </TabContent>

                    {!this.state.loading && this.state.canEdit &&
                        <div className="alert alert-danger">
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>You can void this charge, doing so will permanently cancel it, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/pets" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Void charge</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This charge cannot be voided</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiChargeClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiChargeClient.Get(id);
            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiChargeClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data, loading: false,
                    notes: notes.data });

                if (response.data.status === "pending")
                    this.setState({ canEdit: true });

                if (response.data.status === "invoiced" && response.data.invoice && response.data.invoice === "draft")
                    this.setState({ canEdit: true });

            }
        }
    }
}
