import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiAutomatedChargeTypeClient } from '../infrastructure/HengyiClient/AutomatedChargeTypes';
import { HengyiBillingCodeClient } from '../infrastructure/HengyiClient/BillingCodes';

export class AutomatedChargeTypesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", buildingId:""
        };
       
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialBillingCode = this.state.billingCodeOptions.filter(type => type.value === this.state.billingCodeId)[0];

            return (<Formik
                initialValues={{
                    billingCodeId: initialBillingCode
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiAutomatedChargeTypeClient.SetBillingCode(this.props.match.params.id, fields.billingCodeId ? fields.billingCodeId.value : null);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>

                        <Row>
                            <Col>
                                <SelectField
                                    id="billingCodeId"
                                    name="billingCodeId"
                                    label="Billing code"
                                    placeholder="Select billing code"
                                    options={this.state.billingCodeOptions}
                                    value={values.billingCodeId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.billingCodeId}
                                    error={errors.billingCodeId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/automated-charge-types/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update charge type
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/automated-charge-types/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.billingCodeOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiAutomatedChargeTypeClient.Get(this.props.match.params.id);
            var billingCodes = (await HengyiBillingCodeClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.billingCode)
                    this.setState({ billingCodeId: response.data.billingCode.id });

                this.setState({
                    loading: false,
                    billingCodeOptions: billingCodes.map(function (item) { return { value: item.id, label: item.name }; })
                });
            }
        }
    }
}
