import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class NoticeQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithHideArchived(value: Boolean) {
        this.WithParam("hideArchived", value);
        return this;
    }

    WithHideExpired(value: Boolean) {
        this.WithParam("hideExpired", value);
        return this;
    }
}

//******************************
// Notices
//******************************
export class HengyiNoticeClient {

    static async List(params: NoticeQueryParams) {

        var query = (await GetAPIDomain()) + "/notices" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Create(title: string, description: string, buildingId: string, expiry?: string, type?:string) {

        var request: any = {
            title: title,
            description: description,
            buildingId: buildingId,
            type: type
        }

        if (expiry)
            request.expiry = expiry;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, title: string, description: string, buildingId: string, expiry?: string, notes?: string, type?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "title",
                "value": title
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                    "path": "buildingId",
                    "value": buildingId
                }, {
                    "op": "replace",
                    "path": "expiry",
                    "value": expiry
                }, {
                    "op": "replace",
                    "path": "notes",
                    "value": notes
                }, {
                    "op": "replace",
                    "path": "type",
                    "value": type
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/notices/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Restore(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/" + id + "/restore", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Archive(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/notices/" + id + "/archive", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
}