import React, { Component } from 'react';
import { HengyiCarParkClient } from '../infrastructure/HengyiClient/CarParks';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'


export class CarParksDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false
        };

        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/car-parks" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/car-parks" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Car park</span>}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Details</span>
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/car-parks/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <h6>Details</h6>
                            <hr />

                            <Row>
                                <Col>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Park Number:</td>
                                                <td>
                                                    {this.state.data.parkNumber && this.state.data.parkNumber}
                                                    {!this.state.data.parkNumber && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>
                                                    {this.state.data.type && <Link key={this.state.data.type.id + "-view"} to={"/car-park-types/" + this.state.data.type.id}>{this.state.data.type.name}</Link>}
                                                    {!this.state.data.type && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Parking Bank:</td>
                                                <td>
                                                    {this.state.data.parkingBank && <Link key={this.state.data.parkingBank.id + "-view"} to={"/parking-banks/" + this.state.data.parkingBank.id}>{this.state.data.parkingBank.name}</Link>}
                                                    {!this.state.data.parkingBank && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Space:</td>
                                                <td>
                                                    {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                    {!this.state.data.apartment && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Owned by:</td>
                                                <td>
                                                    {this.state.data.apartmentOwnedBy && <Link key={this.state.data.apartmentOwnedBy.id + "-view"} to={"/apartments/" + this.state.data.apartmentOwnedBy.id}>{this.state.data.apartmentOwnedBy.name}</Link>}
                                                    {!this.state.data.apartmentOwnedBy && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Leased to:</td>
                                                <td>
                                                    {this.state.data.apartmentLeasedBy && <Link key={this.state.data.apartmentLeasedBy.id + "-view"} to={"/apartments/" + this.state.data.apartmentLeasedBy.id}>{this.state.data.apartmentLeasedBy.name}</Link>}
                                                    {!this.state.data.apartmentLeasedBy && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>


                        </CardBody>
                    </Card>}

                    {!this.state.loading && this.state.data && this.state.data.status !== "handedOver" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this car park, doing so will permanently remove it. Please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete car park</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This car park has tickets assigned, please remove the parked vehicle references first</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiCarParkClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async delete() {
        var response = await HengyiCarParkClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }
}
