import React, { Component } from 'react';
import { HengyiApi, DocumentEventQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { GetResidentDomain, GetAssetDomain } from '../infrastructure/Helper';
import classnames from 'classnames';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';
import { UserLink } from '../Users/UserLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class FolderDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            eventData: [], eventLoading: false, eventTotal: 0, eventSkip: 0, eventTake: 50, eventSearch: "",
            activeTab: '1'
        };
        this.delete = this.delete.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.handleEventSearchChange = this.handleEventSearchChange.bind(this);

        this.toggle = this.toggle.bind(this);

    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain()
        });

        this.populateData(this.props.match.params.id);
        await this.updateEventData();
        this.setState({
            residentDomain: await GetResidentDomain()
        });
    }

    async approve() {
        await HengyiDocumentClient.Approve(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async reject() {
        await HengyiDocumentClient.Reject(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/folders" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/folders" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Folder</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/folders/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Events
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardBody>
                                        <Row><Col>
                                            <h6>Folder details</h6>
                                            <hr />
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Name:</td>
                                                        <td>
                                                            {this.state.data.name && <span>{this.state.data.name}</span>}</td>
                                                    </tr> <tr>
                                                        <td className="pr-2">Weight:</td>
                                                        <td>
                                                            {this.state.data.weight && <span>{this.state.data.weight}</span>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Status:</td>
                                                        <td>
                                                            {this.state.data.status === "pending" && <Badge color="secondary">Hidden</Badge>}
                                                            {this.state.data.status === "approved" && <Badge color="success">Active</Badge>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"><strong>Description</strong></td></tr>
                                                    <tr>
                                                        <td colSpan="2"> {this.state.data.description && <span>{this.state.data.description}</span>}
                                                            {!this.state.data.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan="2" className="pt-4"><strong>Audience</strong></td></tr>
                                                    {this.state.data && this.state.data.visibility === "public" &&
                                                        <tr>
                                                            <td colSpan="2" className="pt-4">Visible to all users</td></tr>}
                                                    {this.state.data && this.state.data.visibility === "restricted" && this.state.data.audiences && this.state.data.audiences.map(item =>
                                                        <tr>
                                                            <td style={{ textTransform: 'capitalize' }}>{item.type} {item.userType && " - " + item.userType}</td>
                                                            <td>
                                                                {item.type === "user" && item.user.name + " " + item.user.surname}
                                                                {item.type === "apartment" && item.apartment.name}
                                                                {item.type === "apartmentType" && item.apartmentType.name}
                                                                {item.type === "building" && item.building.name}
                                                                {item.type === "floor" && item.floor.name}
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </Col>

                                            <Col md="3" className="text-center">
                                                <h6 className="text-left">Cover image</h6>
                                                <hr />
                                                {!this.state.data.imageId && <h4 className="text-muted mt-3"><i>No cover image</i></h4>}
                                                {this.state.data.imageId && <img alt="Folder cover" src={this.state.assetUrl + "/image/" + this.state.data.imageId + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Folder events ({this.state.eventTotal})
                                     {this.state.eventLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">List of events for this folder</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>
                                                    <button style={{ float: "right" }} onClick={() => { this.eventNext(); }} disabled={this.state.eventLoading || (this.state.eventSkip + this.state.eventTake >= this.state.eventTotal)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                    <button style={{ float: "right" }} onClick={() => { this.eventPrevious(); }} disabled={this.state.eventLoading || (this.state.eventSkip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Filter</h5>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <InputGroup>
                                                        <Input type="text" placeholder="Search" value={this.state.eventSearch} onChange={this.handleEventSearchChange} onKeyPress={e => e.key === 'Enter' && this.updateEventData()} />
                                                        <ButtonGroup className="input-group-append">
                                                            <button onClick={() => { this.updateEventData(); }} disabled={this.state.eventLoading} className="btn btn-outline-dark" >
                                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                            </button>
                                                        </ButtonGroup>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody className="p-0">
                                        {this.state.eventData &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>Date</th>
                                                        <th style={{ borderTop: "none" }}>User</th>
                                                        <th style={{ borderTop: "none" }}>Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.eventData.map(item =>
                                                        <tr key={item.id}>
                                                            <td><Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></td>
                                                            <td>
                                                                <Link key={item.id + item.user.id + "-view"} to={"/users/" + item.user.id}>{item.user.name} {item.user.surname}</Link>
                                                            </td>
                                                            <td style={{ textTransform: 'capitalize' }}>{item.type}</td>
                                                        </tr>
                                                    )}
                                                    {this.state.eventData.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No events to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }

                                    </CardBody>
                                </Card>

                            </div>}
                        </TabPane>
                    </TabContent>


                    {!this.state.loading && this.state.data && this.state.data.status === "pending" &&
                        <div className="alert alert-primary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Hidden:</strong> Publish this folder to make it visible to eligible non-admins</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/folders" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-seconday">Approve</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "approved" &&
                        <div className="alert alert-success" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Approved:</strong> Hide this folder to remove it from the resident dashboard.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/folders" onClick={this.reject} className="btn mr-2 mt-2 mb-2 btn-seconday">Hide</Button>
                                </Col>
                            </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.status === "pending" &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this folder, doing so will permanently remove it and all associated events, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/folders" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete folder</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This folder is protected and cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var response = await HengyiApi.DeleteDocument(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiDocumentClient.GetDocument(id);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }


        }
    }


    handleEventSearchChange(evt) {
        this.setState({
            eventSearch: evt.target.value
        });
    }

    async eventPrevious() {
        await this.populateEventData(this.state.eventSkip - this.state.eventTake, this.state.eventTake);
    }

    async eventNext() {
        await this.populateEventData(this.state.eventSkip + this.state.eventTake, this.state.eventTake);
    }

    async updateEventData() {
        await this.populateEventData(this.state.eventSkip, this.state.eventTake);
    }

    async populateEventData(skip, take) {

        if (!this.state.loading) {
            this.setState({ eventLoading: true, eventSkip: skip, eventTake: take });

            var response = await HengyiApi.GetDocumentEvents(new DocumentEventQueryParams()
                .WithDocument(this.props.match.params.id)
                .Search(this.state.eventSearch)
                .Paginate(skip, take)
                .Sort("created")
                .Descending());

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ eventData: response.data.data, eventLoading: false, eventTotal: response.data.total });
            }
        }
    }
}
