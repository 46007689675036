import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { HengyiNoticeClient } from '../infrastructure/HengyiClient/Notices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class NoticesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.delete = this.delete.bind(this);
        this.approve = this.approve.bind(this);
        this.restore = this.restore.bind(this);
        this.archive = this.archive.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/notices" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/notices" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Notice</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.title}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/notices/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Title:</td>
                                                <td>
                                                    {this.state.data.title && this.state.data.title}
                                                    {!this.state.data.title && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>

                                                    {this.state.data.type === "info" && <Badge color="info">Information</Badge>}
                                                    {this.state.data.type === "warning" && <Badge color="warning">Warning</Badge>}
                                                    {this.state.data.type === "alert" && <Badge color="danger">Alert</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Building:</td>
                                                <td>
                                                    {this.state.data.building && <Link key={this.state.data.building.id + "-view"} to={"/buildings/" + this.state.data.building.id}>{this.state.data.building.name}</Link>}
                                                    {!this.state.data.building && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Expiry:</td>
                                                <td>
                                                    {this.state.data.expiry && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.expiry}</Moment>}
                                                    {!this.state.data.expiry && <i>Never</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Notes:</td>
                                                <td>
                                                    {this.state.data.notes && this.state.data.notes}
                                                    {!this.state.data.notes && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created by:</td>
                                                <td>
                                                    {this.state.data.createdBy && <Link key={this.state.data.createdBy.id + "-view"} to={"/users/" + this.state.data.createdBy.id}>{this.state.data.createdBy.name} {this.state.data.createdBy.surname}</Link>}
                                                    {!this.state.data.createdBy && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                    {this.state.data.status === "approved" && <Badge color="success">Approved</Badge>}
                                                    {this.state.data.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Notice activity" description="List all notice activity" entityId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>

                    {!this.state.loading && this.state.data && this.state.data.status === "pending" &&
                        <div className="alert alert-primary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This notice is <strong>Pending.</strong> Approve this notice to make it visible to all residents, or archive it if it is not relevent.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/notices" onClick={this.archive} className="btn mr-2 mt-2 mb-2 btn-seconday">Archive</Button>
                                    <Button style={{ float: "right" }} to="/notices" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-seconday">Approve</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "approved" &&
                        <div className="alert alert-success" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This notice is <strong>Approved.</strong> Archive this notice when it is no longer relevent.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/notices" onClick={this.archive} className="btn mr-2 mt-2 mb-2 btn-seconday">Archive</Button>
                                </Col>
                            </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.status === "archived" &&
                        <div className="alert alert-secondary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This notice is <strong>Archived</strong> and not currently visible. If you want to display the notice again it can be restored to an approved state.</p>
                                </Col>
                                <Col md="2">
                                <Button style={{ float: "right" }} to="/notices" onClick={this.restore} className="btn mr-2 mt-2 mb-2 btn-seconday">Restore</Button>
                                </Col>
                            </Row>
                        </div>}

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiNoticeClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async delete() {
        var success = await HengyiNoticeClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async approve() {
        await HengyiNoticeClient.Approve(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async restore() {
        await HengyiNoticeClient.Restore(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async archive() {
        await HengyiNoticeClient.Archive(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }
}
