import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiChargeClient } from '../infrastructure/HengyiClient/Charges';
import { HengyiBillingCodeClient, BillingCodeQueryParams } from '../infrastructure/HengyiClient/BillingCodes';
import { HengyiTicketClient } from '../infrastructure/HengyiClient/Tickets';
import { HengyiApi, ListQueryParams, TicketCategoryQueryParams } from '../infrastructure/fetchHengy';

export class TicketsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialTicketType = this.state.ticketTypeOptions.filter(type => type.value === this.state.ticketTypeId)[0];
            var initialTicketCategory = this.state.ticketCategoryOptions.filter(type => type.value === this.state.ticketCategoryId)[0];

            return (<Formik
                initialValues={{
                    ticketTypeId: initialTicketType,
                    ticketCategoryId: initialTicketCategory
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.ticketTypeId) {
                        setFieldError("ticketTypeId", "Please select the type of ticket");
                        return;
                    }

                    if (!fields.ticketCategoryId) {
                        setFieldError("ticketCategoryId", "Please select the category of ticket");
                        return;
                    }

                    const response = await HengyiTicketClient.Update(
                        this.props.match.params.id,
                        fields.ticketTypeId ? fields.ticketTypeId.value : null,
                        fields.ticketCategoryId ? fields.ticketCategoryId.value : null
                    );

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <Row>
                        <Col>
                            <SelectField
                                id="ticketTypeId"
                                name="ticketTypeId"
                                label="Ticket type"
                                placeholder="Select type of ticket"
                                options={this.state.ticketTypeOptions}
                                value={values.ticketTypeId}
                                isMulti={false}
                                onChange={async (key, item) => {
                                    setFieldValue(key, item);
                                    setFieldValue("ticketCategoryId", "");

                                    this.setState({
                                        ticketCategoryOptions: item.value ? (await HengyiApi.GetTicketCategories(new TicketCategoryQueryParams()
                                            .WithTicketType(item.value)
                                            .Paginate(0, 1000))).data.data.map(function (item) { return { value: item.id, label: item.name }; }) : []
                                    });

                                }}
                                onBlur={setFieldTouched}
                                touched={touched.ticketTypeId}
                                error={errors.ticketTypeId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        </Row>
                        <Row className="mt-4">
                        <Col>
                            <SelectField
                                id="ticketCategoryId"
                                name="ticketCategoryId"
                                label="Ticket category"
                                placeholder="Select category of ticket"
                                options={this.state.ticketCategoryOptions}
                                value={values.ticketCategoryId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.ticketCategoryId}
                                error={errors.ticketCategoryId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                    </Row>
                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/tickets/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update ticket
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the ticket type and category</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/tickets/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
           
            var ticket = await HengyiTicketClient.Get(this.props.match.params.id)
            var responseTypes = await HengyiApi.GetTicketTypes(new ListQueryParams().Paginate(0, 1000));

            if (!responseTypes.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false,
                    ticketCategoryOptions: []
                });
            } else {

                this.setState({
                    init: true,
                    loading: false,
                    ticketCategoryId: ticket.data.category ? ticket.data.category.id : null,
                    ticketTypeId: ticket.data.type ? ticket.data.type.id : null,
                    ticketTypeOptions: responseTypes.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                    ticketCategoryOptions: ticket.data.type ? (await HengyiApi.GetTicketCategories(new TicketCategoryQueryParams()
                        .WithTicketType(ticket.data.type.id)
                        .Paginate(0, 1000))).data.data.map(function (item) { return { value: item.id, label: item.name }; }) : []
                });
            }
        }
    }
}
