import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';
import { HengyiInvitationClient } from '../infrastructure/HengyiClient/Invitations';

export class InvitationEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, name: "", surname: "", mobile: "", trust: "", email: "", loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    surname: this.state.surname,
                    mobile: this.state.mobile,
                    trust: this.state.trust
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    console.log(fields);

                    const response = await fetch((await GetAPIDomain()) + "/resident-invitations/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "name",
                            "value": this.state.name
                        }, {
                            "op": "replace",
                            "path": "surname",
                            "value": this.state.surname
                        }, {
                            "op": "replace",
                            "path": "mobile",
                            "value": this.state.mobile
                        }, {
                            "op": "replace",
                            "path": "trust",
                            "value": this.state.trust
                        }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                    <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div></Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="surname">Surname</label>
                                    <input name="surname" id="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                    <input name="mobile" id="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="trust">Trust</label>
                                    <input name="trust" id="trust" type="text" className={'form-control' + (errors.trust && touched.trust ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="trust" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/invitations/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update invitation details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details of the user being invited to access the portal</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/invitations/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var invite = await HengyiInvitationClient.Get(this.props.match.params.id);

            if (!invite.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    name: invite.data.name,
                    surname: invite.data.surname,
                    mobile: invite.data.mobile,
                    trust: invite.data.trust,
                    loading: false,
                    init: true
                }, () => {
                    document.getElementById("name").value = invite.data.name ? invite.data.name : "";
                    document.getElementById("surname").value = invite.data.surname ? invite.data.surname : "";
                    document.getElementById("mobile").value = invite.data.mobile ? invite.data.mobile : "";
                    document.getElementById("trust").value = invite.data.trust ? invite.data.trust : "";
                });
            }
        }
    }
}
