import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { UserLink } from "../Users/UserLink";
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import { ChargeQueryParams } from "../infrastructure/HengyiClient/Charges";
import { HengyiChargeClient } from "../infrastructure/HengyiClient/Charges";
import NumberFormat from "react-number-format";
import { HengyiBillingCodeClient, BillingCodeQueryParams } from "../infrastructure/HengyiClient/BillingCodes";
import { UserSelector } from "../infrastructure/UserSelector";
import { TableHeader } from "../infrastructure/TableHeader";
import { ApartmentSelector } from "../infrastructure/ApartmentSelector";
import FileSaver from 'file-saver';
import { userContext } from '../../userContext';

class ChargesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, showVoided: false,
            search: "", exporting: false,
            status: "", sort: "created", direction: "desc"
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowVoided = this.handleShowVoided.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);

        this.handleBillingCodeIdChange = this.handleBillingCodeIdChange.bind(this);
        this.handleCreateUserIdChange = this.handleCreateUserIdChange.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    handleShowVoided = (evt) => {
        this.setState({
            showVoided: evt.target.checked
        }, () => this.update());
    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            skip: 0,
            startFilter: "",
            apartmentId: "",
            endFilter: ""
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
        this.populateFilters();
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleBillingCodeIdChange(name, value) {

        this.setState({
            billingCodeId: value
        }, () => this.update());
    }

    handleUserIdChange(name, value) {

        this.setState({
            userFilterId: value
        }, () => this.update());
    }

    handleCreateUserIdChange(name, value) {

        this.setState({
            userCreatedById: value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateFilters() {

        var billingCodes = (await HengyiBillingCodeClient.List(new BillingCodeQueryParams().Paginate(0, 10000))).data.data;

        this.setState({
            billingCodeOptions: billingCodes.map(function (item) { return { value: item.id, label: item.name }; })
        });
    }

    async populateData(skip, take) {

        const {
            userId,
            invoiceId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var userFilterId = "";
            if (this.state.userFilterId)
                userFilterId = this.state.userFilterId.value;

            var userCreatedById = "";
            if (this.state.userCreatedById)
                userCreatedById = this.state.userCreatedById.value;

            var billingCodeId = "";
            if (this.state.billingCodeId)
                billingCodeId = this.state.billingCodeId.value;

            var params = new ChargeQueryParams()
                .WithUser(userFilterId)
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
                .WithCreatedByUser(userCreatedById)
                .WithBillingCode(billingCodeId)
                .WithStatus(this.state.status)
                .WithShowVoided(this.state.showVoided)
                .WithStart(this.state.startFilter)
                .WithEnd(this.state.endFilter)
                .Sort(this.state.sort, this.state.direction);

            if (userId)
                params = params.WithUser(userId);

            if (invoiceId)
                params = params.WithInvoice(invoiceId);

            var response = await HengyiChargeClient.List(params.Paginate(skip, take)
                .Search(this.state.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }
        }
    }


    async exportToCSV() {
        const {
            userId,
            invoiceId
        } = this.props;
        this.setState({ exporting: true });


        var userFilterId = "";
        if (this.state.userFilterId)
            userFilterId = this.state.userFilterId.value;

        var userCreatedById = "";
        if (this.state.userCreatedById)
            userCreatedById = this.state.userCreatedById.value;

        var billingCodeId = "";
        if (this.state.billingCodeId)
            billingCodeId = this.state.billingCodeId.value;

        var params = new ChargeQueryParams()
            .WithUser(userFilterId)
            .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
            .WithCreatedByUser(userCreatedById)
            .WithBillingCode(billingCodeId)
            .WithStatus(this.state.status)
            .WithShowVoided(this.state.showVoided)
            .WithStart(this.state.startFilter)
            .WithEnd(this.state.endFilter)
            .Sort(this.state.sort, this.state.direction);

        if (userId)
            params = params.WithUser(userId);

        if (invoiceId)
            params = params.WithInvoice(invoiceId);

        var blob = await HengyiChargeClient.Export(params.Paginate(0, 1)
            .Search(this.state.search));

        FileSaver.saveAs(blob, "charges.csv");

        this.setState({ exporting: false });
    }

    render() {
        const {
            title,
            description,
            enableEditControls,
            userId,
            invoiceId
        } = this.props;

        return (
            <div>

                <Card>
                    <userContext.Consumer>
                        {({ user, logoutUser }) => {
                            return (<CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Charges"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all charges"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                            <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                            {user.permissions && user.permissions.includes("charges.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">

                                                {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                                {this.state.exporting && <Spinner animation="border" />}

                                            </button>}

                                    {enableEditControls && < Link style={{ float: "right" }} to="/charges/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                </div>
                            </Col>
                        </Row>

                            </CardHeader>);
                        }}
                    </userContext.Consumer>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            <Col>
                                <ApartmentSelector
                                    placeholder="Space"
                                    value={this.state.apartmentId}
                                    onChange={this.handleApartmentIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <SelectField
                                    id="billingCodeId"
                                    name="billingCodeId"
                                    placeholder="Billing code"
                                    options={this.state.billingCodeOptions}
                                    value={this.state.billingCodeId}
                                    isMulti={false}
                                    onChange={this.handleBillingCodeIdChange}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>


                            <Col>

                                <UserSelector
                                    id="userCreatedById"
                                    name="userCreatedById"
                                    placeholder="Created by"
                                    value={this.state.userCreatedById}
                                    onChange={this.handleCreateUserIdChange}
                                    hideApartmentSuffix={true}
                                    filterPermission="charges.modify"
                                    hideLabel={true} />

                            </Col>

                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.startFilter || this.state.endFilter || this.state.apartmentId) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>

                        </Row>
                    </CardHeader>

                    {!invoiceId && <CardHeader>
                        <Row>
                            <Col>
                            </Col>

                            <Col>
                                <UserSelector
                                    id="userFilterId"
                                    name="userFilterId"
                                    placeholder="Charged to"
                                    value={this.state.userFilterId}
                                    onChange={this.handleUserIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="pending">Pending</option>
                                        <option value="invoiced">Invoiced</option>
                                        {this.state.showVoided && <option value="voided">Voided</option>}
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <DatePicker id="start"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholderText="Start date"
                                    selected={this.state.startFilter}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        this.setState({
                                            startFilter: v
                                        }, () => this.update());
                                    }} />
                            </Col>
                            <Col>
                                <DatePicker id="end"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholderText="End date"
                                    selected={this.state.endFilter}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        this.setState({
                                            endFilter: v
                                        }, () => this.update());
                                    }} />
                            </Col>
                        </Row>
                    </CardHeader>}

                    <CardHeader>

                        <div className="form-group">
                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideCancellations"
                                            type="checkbox"
                                            checked={this.state.showVoided}
                                            onChange={this.handleShowVoided} />  Show voided charges
                                        </label>
                                </li>
                            </ul>
                        </div>
                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "Status", sort: "status"},
                                        { name: "Description", sort: "description", className:"d-sm-none"  },
                                        { name: "Billing Code", sort: "billingCode.name" },
                                        { name: "Amount", sort: "amount" },
                                        { name: "Invoice", sort: "invoice.invoiceNumber", disabled: invoiceId != null },
                                        { name: "User", sort: "user.name", disabled: userId != null },
                                        { name: "Created By", sort: "createdBy.name", className: "d-sm-none"  },
                                        { name: "Created", sort: "created"},
                                        { name: "", sort: "", static: true }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />

                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                {item.status === "invoiced" && <Badge color="success">Invoiced</Badge>}
                                                {item.status === "voided" && <Badge color="secondary">Voided</Badge>}
                                            </td>
                                            <td className="d-sm-none">
                                                {item.description && item.description}
                                                {!item.description && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {item.billingCode && <Link key={item.billingCode.id + "-view"} to={"/billing-codes/" + item.billingCode.id}>{item.billingCode.name}</Link>}
                                                {!item.billingCode && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                            </td>
                                            {!invoiceId && <td>
                                                {!item.invoice && <i>Not invoiced yet</i>}

                                                {item.invoice &&
                                                    <>
                                                        {item.invoice.status === "draft" && <Badge color="primary">Draft</Badge>}
                                                        {item.invoice.status === "open" && <Badge color="primary">Open</Badge>}
                                                        {item.invoice.status === "paid" && <Badge color="success">Paid</Badge>}
                                                        {item.invoice.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                                        {item.invoice.status === "void" && <Badge color="secondary">Voided</Badge>}
                                                        {item.invoice.status === "deleted" && <Badge color="secondary">Deleted</Badge>}
                                                        <Link key={item.invoice.id + "-invoice-view"} to={"/invoices/" + item.invoice.id}> &nbsp;
                                                        {!item.invoice.invoiceNumber && <i>No invoice number</i>}
                                                            {item.invoice.invoiceNumber && item.invoice.invoiceNumber}
                                                        </Link>
                                                    </>
                                                }

                                            </td>}
                                            {!userId && <td>
                                                {item.user && <UserLink user={item.user} />}
                                                {!item.user && <i>Not specified</i>}
                                            </td>}
                                            <td className="d-sm-none">
                                                {item.createdBy && <UserLink user={item.createdBy} />}
                                                {!item.createdBy && <i>System generated</i>}
                                            </td>
                                            <td>
                                                <Moment utc local format="ddd, DD/MM h:mma" date={item.created} />
                                            </td>
                                            <td>
                                                {item.status === "pending" && <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invoices/new?userId=" + item.user.id + "&chargeId=" + item.id}>Invoice</Link>}
                                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/charges/" + item.id}>Details</Link>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No charges to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { ChargesTable };

ChargesTable.contextType = userContext;
