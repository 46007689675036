import React, { Component } from 'react';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiInvitationClient } from '../infrastructure/HengyiClient/Invitations';

export class InvitationsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                surname: "",
                email: "",
                mobile: "",
                trust: "",
                apartments: "",
                apartmentLivingIn: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var response = await HengyiInvitationClient.Create(
                    this.state.name,
                    this.state.surname,
                    this.state.email,
                    this.state.mobile,
                    this.state.trust,
                    fields.apartments ? fields.apartments.map(a => a.value) : [],
                    fields.apartmentLivingIn ? fields.apartmentLivingIn.value : null
                );

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                                <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div></Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="surname">Surname</label>
                                <input name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                                <input name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div></Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                                <input name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <div className="form-group">
                    <label htmlFor="trust">Trust</label>
                        <input name="trust" type="text" className={'form-control' + (errors.trust && touched.trust ? ' is-invalid' : '')} onChange={this.handleChange} />
                    <ErrorMessage name="trust" component="div" className="invalid-feedback" />
                </div>

                <Row>
                    <Col>
                        <div className="form-group">
                            <SelectField
                                id="apartments"
                                name="apartments"
                                label="Apartments"
                                placeholder="Select spaces"
                                options={this.state.apartmentOptions}
                                value={values.apartments}
                                isMulti={true}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartments}
                                error={errors.apartments}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </div>
                    </Col>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartmentLivingIn"
                                    name="apartmentLivingIn"
                                    label="Apartment living in"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentLivingIn}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentLivingIn}
                                    error={errors.apartmentLivingIn}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                </Row>
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SEND INVITE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to="/invitations" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invite
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Send a new invite for a resident or owner</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApartmentClient.List(new ApartmentQueryParams().Paginate(0, 500));

            this.setState({ init: true });

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    apartmentOptions: response.data.data.map(function (apartment) { return { value: apartment.id, label: apartment.name }; }), loading: false
                });
            }
        }
    }
}
