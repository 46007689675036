import React, { Component } from "react";
import { Button, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiBookingTypesClient, BookingTypesQueryParams } from "../infrastructure/HengyiClient/BookingTypes";
import { HengyiSchedulesClient, SchedulesQueryParams } from "../infrastructure/HengyiClient/Schedules";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { AudienceSelector } from '../Audience/AudienceSelector';
import { ExivoComponentSelector } from "../infrastructure/ExivoComponentSelector";
import { userContext } from '../../userContext';

class BookingTypesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: "",
            showForm: "none",
            scheduleOptions: [],
            selectedSchedule: null,
            scheduleId: "",
            currentBookingTypeId: "",
            audiences: []
        };

        this.handleComponentChange = this.handleComponentChange.bind(this);
        this.handleScheduleChange = this.handleScheduleChange.bind(this);
        this.updateCurrentBookingType = this.updateCurrentBookingType.bind(this);
        this.editBookingType = this.editBookingType.bind(this);


        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    async componentDidMount() {
        await this.update();
    }

    handleScheduleChange(item, value) {
        this.setState({
            selectedSchedule: value,
            scheduleId: value.value
        }, () => this.update());
    }

    handleComponentChange(item, value) {
        console.log(item);
        console.log(value);

        this.setState({
            componentId: value.value,
            componentName: value.label
        }, () => this.update());

    }

    async removeBookingType(typeId) {
        var response = await HengyiBookingTypesClient.Remove(typeId);

        if (!response.authenticated) {
            this.setState({
                authenticated: false
            });
        } else {
            await this.update();
        }

        this.setState({ showForm: "none" });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            facilityId,
            eventId,
            buildingId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiBookingTypesClient.List(new BookingTypesQueryParams()
                .WithFacility(facilityId)
                .WithEvent(eventId)
                .WithScheduleId(this.state.scheduleId)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort("created").Descending());

            var schedules = (await HengyiSchedulesClient.List(new SchedulesQueryParams()
                .WithFacility(facilityId)
                .WithEvent(eventId)
                .Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total,
                    scheduleOptions: schedules.map(function (item) { return { value: item.id, label: item.name }; })
                });
            }
        }
    }

    async editBookingType(bookingTypeId) {
        this.setState({ showForm: "none" });
        await this.updateCurrentBookingType(bookingTypeId);
        this.setState({ showForm: "edit-booking-type" }, () => {
            document.getElementById("name").value = this.state.name;
            document.getElementById("numberOfSlots").value = this.state.numberOfSlots;
            if (document.getElementById("concurrencyLimit"))
                document.getElementById("concurrencyLimit").value = this.state.concurrencyLimit;
        });
    }

    async updateCurrentBookingType(bookingTypeId) {
        this.setState({
            currentBookingTypeId: bookingTypeId
        });

        if (bookingTypeId) {
            //Set the state to form
            var response = await HengyiBookingTypesClient.Get(bookingTypeId);

            var audiences = [];

            if (response.data && response.data.audiences) {
                audiences = response.data.audiences.map(item => {
                    if (item.apartment)
                        return {
                            entityName: item.apartment.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.apartment.id
                        };
                    if (item.apartmentType)
                        return {
                            entityName: item.apartmentType.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.apartmentType.id
                        };
                    if (item.building)
                        return {
                            entityName: item.building.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.building.id
                        };
                    if (item.floor)
                        return {
                            entityName: item.floor.name,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.floor.id
                        };

                    if (item.user)
                        return {
                            entityName: item.user.name + " " + item.user.surname,
                            type: item.type,
                            userType: item.userType,
                            entityId: item.user.id
                        };


                    if (item.type === "all")
                        return {
                            entityName: "Everyone",
                            type: item.type,
                            userType: item.userType,
                            entityId: null
                        };
                    return {
                        entityName: "Unknown type: " + item.type,
                        type: item.type,
                        userType: item.userType,
                        entityId: ""
                    };
                });
            }

            this.setState({
                loading: false,
                name: response.data.name,
                isMultiple: response.data.isMultiple,
                numberOfSlots: response.data.maximumBookingLength / response.data.timeslot,
                scheduleId: response.data.scheduleId,
                audiences: audiences,
                doesNotRequireApproval: response.data.doesNotRequireApproval,
                concurrencyLimit: response.data.concurrencyLimit,
                exivoEnabled: response.data.exivoEnabled,
                componentId: response.data.componentId,
                componentName: response.data.componentName
            });
        }
    }

    render() {
        const {
            title,
            description,
            buildingId
        } = this.props;

        console.log("BTBID: " + buildingId);

        return (

            <userContext.Consumer>
                {({ user, logoutUser, features }) => {

                    return (<div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Booking types"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all booking types"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                    {this.state.showForm !== "add-booking-type" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "add-booking-type" }); }} className="btn btn-outline-dark mt-2">Add</button>}
                                    {this.state.showForm === "add-booking-type" && <button style={{ float: "right" }} onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Cancel</button>}

                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                {(this.state.showForm === "add-booking-type" || this.state.showForm === "edit-booking-type") && <Card>
                    <CardBody>
                        <Formik
                            initialValues={{
                                exivoHoldingValue: {
                                    value: this.state.componentId,
                                    label: this.state.componentName
                                },
                                isMultiple: this.state.showForm === "add-booking-type" ? false : this.state.isMultiple,
                                doesNotRequireApproval: this.state.showForm === "add-booking-type" ? false : this.state.doesNotRequireApproval,
                                exivoEnabled: this.state.showForm === "add-booking-type" ? false : this.state.exivoEnabled,
                                scheduleId: this.state.showForm === "add-booking-type" ? null : this.state.scheduleOptions.filter(item => item.value === this.state.scheduleId)[0]
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                console.log(fields.scheduleId);

                                if (!fields.scheduleId) {
                                    setFieldError("scheduleId", "Please choose a schedule");
                                    return;
                                }

                                var schedule = await HengyiSchedulesClient.Get(fields.scheduleId.value);
                                var response = {};

                                if (this.state.showForm === "add-booking-type") {
                                    response = await HengyiBookingTypesClient.Create(
                                        this.state.name,
                                        fields.isMultiple,
                                        fields.doesNotRequireApproval,
                                        this.state.numberOfSlots * schedule.data.timeslot,
                                        parseInt(this.state.concurrencyLimit+""),
                                        fields.scheduleId.value,
                                        this.state.audiences,
                                        fields.exivoEnabled,
                                        this.state.componentId,
                                        this.state.componentName);
                                } else {
                                    response = await HengyiBookingTypesClient.Update(this.state.currentBookingTypeId,
                                        this.state.name,
                                        fields.isMultiple,
                                        fields.doesNotRequireApproval,
                                        this.state.numberOfSlots * schedule.data.timeslot,
                                        this.state.concurrencyLimit,
                                        fields.scheduleId.value,
                                        this.state.audiences,
                                        fields.exivoEnabled,
                                        this.state.componentId,
                                        this.state.componentName)
                                }

                                if (!response.successful) {

                                    response.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    await this.update();
                                    this.setState({
                                        showForm: "none",
                                        audiences: [],
                                        name: "",
                                        numberOfSlots: 1,
                                        concurrencyLimit: 1,
                                        exivoEnabled: false,
                                        componentId: "",
                                        componentName: ""
                                    });
                                    setFieldValue("componentId", "");
                                    setFieldValue("componentName", "");
                                    setFieldValue("exivoEnabled", false);
                                    setFieldValue("name", "");
                                    setFieldValue("numberOfSlots", 1);
                                    setFieldValue("concurrencyLimit", 1);
                                    setFieldValue("isMultiple", false);
                                }
                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Purpose</label>
                                                <Field id="name" name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                            <small>This is only displayed if the resident needs to choose between two different actions, e.g. Large item delivery vs. moving in.</small>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label htmlFor="scheduleId">Schedule this applies to</label>
                                        <SelectField
                                            id="scheduleId"
                                            name="scheduleId"
                                            placeholder="Select schedule"

                                            value={values.scheduleId}
                                            onChange={setFieldValue}
                                            options={this.state.scheduleOptions}
                                            touched={touched.scheduleId}
                                            error={errors.scheduleId} />

                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="numberOfSlots">Max slots for one booking</label>
                                                <Field id="numberOfSlots" name="numberOfSlots" type="number" className={'form-control' + (errors.numberOfSlots && touched.numberOfSlots ? ' is-invalid' : '')} onChange={this.handleChange} />
                                            <ErrorMessage name="numberOfSlots" component="div" className="invalid-feedback" />
                                        </div>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <label><Field name="isMultiple" type="checkbox" checked={values.isMultiple} /> Resident can have unlimited active bookings</label>
                                                </li>
                                                <li>
                                                    <label><Field name="doesNotRequireApproval" type="checkbox" checked={values.doesNotRequireApproval} /> Does not require approval</label>
                                                </li>
                                            </ul>
                                        </div>
                                        </Col>
                                        {!values.isMultiple && < Col >
                                            <div className="form-group">
                                                <label htmlFor="concurrencyLimit">Maximum number of concurrent bookings</label>
                                                <Field id="concurrencyLimit" name="concurrencyLimit" type="number" className={'form-control' + (errors.concurrencyLimit && touched.concurrencyLimit ? ' is-invalid' : '')} onChange={this.handleChange} />
                                                <ErrorMessage name="concurrencyLimit" component="div" className="invalid-feedback" />
                                            </div>
                                        </Col>}
                                    </Row>
                                            {features && features.includes("exivo") && <div className="form-group">
                                                <h6>Exivo integration</h6>
                                                <hr />
                                            </div>}
                                            {features && features.includes("exivo") && <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <label><Field name="exivoEnabled" type="checkbox" checked={values.exivoEnabled} /> Exivo integraton enabled</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>}
                                            {features && features.includes("exivo") && values.exivoEnabled  && <Row style={{marginBottom: "20px"}}>
                                        <Col>
                                            <ExivoComponentSelector
                                                initialComponentId={this.state.componentId}
                                                buildingId={buildingId}
                                                value={values.exivoHoldingValue}
                                                onChange={(item, value) => {
                                                    this.handleComponentChange(item, value);
                                                    setFieldValue("exivoHoldingValue", value);
                                                    
                                                }}
                                                placeholder="Component"
                                                hideLabel={true} />
                                        </Col>
                                    </Row>}
                                <div className="form-group">
                                    <h6>Audience</h6>
                                    <hr />

                                    <Row className="mb-2">
                                        <Col>Type</Col>
                                        <Col>Entity</Col>
                                        <Col>User Type</Col>
                                        <Col md="1"></Col>
                                    </Row>
                                    <hr />

                                    {this.state.audiences && this.state.audiences.length > 0 &&
                                        <div>

                                            {this.state.audiences.map((audience, index) =>
                                                <Row>
                                                    <Col style={{ textTransform: 'capitalize' }}>
                                                        {audience.type}
                                                    </Col>
                                                    <Col style={{ textTransform: 'capitalize' }}>
                                                        {audience.entityName && <span>{audience.entityName}</span>}
                                                        {!audience.entityName && <i>Everything</i>}
                                                    </Col>
                                                    <Col md="1">
                                                        <button className="btn mr-2 btn-outline-dark" onClick={() => {
                                                            console.log("Remove index: " + index);

                                                            this.state.audiences.splice(index, 1);
                                                            this.setState({
                                                                audiences: this.state.audiences
                                                            });

                                                            return false;
                                                        }}>REMOVE</button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>}

                                    <hr />

                                    <AudienceSelector hideUserType={true} hideLabels={true} initialValues={{
                                        audience: "all",
                                        userType: "any"
                                    }} onSubmit={async (fields, { setErrors, setFieldError }) => {

                                        var audiences = this.state.audiences;

                                        var audience = {
                                            type: fields.audience
                                        };

                                        if (fields.userType && fields.userType !== "any")
                                            audience.userType = fields.userType;


                                        if (fields.entityId) {
                                            audience.entityId = fields.entityId.value;
                                            audience.entityName = fields.entityId.label;
                                        }

                                        audiences.push(audience);

                                        this.setState({
                                            audiences: audiences
                                        });

                                    }} />

                                </div>

                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                    {!isSubmitting && <span>{this.state.showForm === "add-booking-type" ? "Add booking type" : "Update booking type"}</span>}
                                    {isSubmitting && <Spinner animation="border" />}
                                </button>
                                <button onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Cancel</button>
                            </Form>
                        )}</Formik>
                    </CardBody>
                </Card>}

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <SelectField
                                        clearOption="Filter by schedule"
                                        id="filterScheduleId"
                                        name="filterScheduleId"
                                        value={this.state.selectedSchedule}
                                        onChange={this.handleScheduleChange}
                                        options={this.state.scheduleOptions} />

                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                        <th style={{ borderTop: "none" }}>Name</th>
                                        <th style={{ borderTop: "none" }}>Schedule</th>
                                        <th style={{ borderTop: "none" }}>Concurrent bookings</th>
                                        <th style={{ borderTop: "none" }}>Approval Required</th>
                                        <th style={{ borderTop: "none" }}>Maximum slots</th>
                                        {features && features.includes("exivo") && <th style={{ borderTop: "none" }}>Exivo</th>}
                                        <th style={{ borderTop: "none" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map(type =>
                                        <tr key={type.id}>
                                            <td style={{ textTransform: 'capitalize' }}>{type.name}</td>
                                            <td style={{ textTransform: 'capitalize' }}>{type.scheduleName}</td>
                                            <td style={{ textTransform: 'capitalize' }}>
                                                {type.isMultiple && <i>unlimited</i>}
                                                {!type.isMultiple && type.concurrencyLimit}
                                            </td>
                                            <td style={{ textTransform: 'capitalize' }}>
                                                {type.doesNotRequireApproval && "No"}
                                                {!type.doesNotRequireApproval && "Yes"}
                                            </td>
                                            <td>{type.maximumBookingLength / type.timeslot}</td>
                                            {features && features.includes("exivo") && <td>{!type.exivoEnabled && "No"}
                                                {type.exivoEnabled && "Yes"}</td>}
                                            <td>
                                                <Button onClick={() => { this.removeBookingType(type.id); }} key={type.id + "-delete"} style={{ float: "right" }} className="btn mr-2 btn-danger mt-2"><FontAwesomeIcon icon={faTrash} /></Button>
                                                <Button onClick={() => { this.editBookingType(type.id); }} key={type.id + "-edit"} style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark mt-2">Edit</Button>
                                            </td>
                                        </tr>
                                    )}

                                    {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No booking types to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

                    </div>)
                }}
            </userContext.Consumer>
        );
    }
}

export { BookingTypesTable };