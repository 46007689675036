import React, { Component } from 'react';
import { HengyiParkedVehicleClient, ParkedVehicleQueryParams } from '../infrastructure/HengyiClient/ParkedVehicles';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import Moment from 'react-moment';
import { TableHeader } from '../infrastructure/TableHeader';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class ParkedVehicles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true,
            hideCancelled: true, hideCollected: true, sort: "parked", direction: "desc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handlePaidStatusChange = this.handlePaidStatusChange.bind(this);
        this.handleHideCollected = this.handleHideCollected.bind(this);
        this.handleHideCancelled = this.handleHideCancelled.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    handleHideCollected = (evt) => {
        this.setState({
            hideCollected: evt.target.checked
        }, () => this.update());
    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    handleHideCancelled = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            paidStatus: "",
            apartmentId: "",
            skip: 0
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handlePaidStatusChange(evt) {
        this.setState({
            paidStatus: evt.target.value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Ticket", sort: "ticketNumber" },
                        { name: "Parked", sort: "parked" },
                        { name: "Collected", sort: "pickedUp" },
                        { name: "Status", sort: "status" },
                        { name: "Vehicle", sort: "vehicle.licensePlate" },
                        { name: "Space", sort: "vehicle.apartment.name" },
                        { name: "Location", sort: "location" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.ticketNumber && item.ticketNumber}
                                {!item.ticketNumber && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.parked && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.parked}</Moment>}
                                {!item.parked && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.pickedUp && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.pickedUp}</Moment>}
                                {!item.pickedUp && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.status === "parked" && <Badge color="primary">Parked</Badge>}
                                {item.status === "readyForPickup" && <Badge color="info">Ready for collection</Badge>}
                                {item.status === "collected" && <Badge color="success">Collected</Badge>}
                                {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                            </td>
                            <td>
                                {item.vehicle && <Link key={item.vehicle.id + "-view"} to={"/vehicles/" + item.vehicle.id}>{item.vehicle.licensePlate} ({item.vehicle.model})</Link>}
                                {!item.vehicle && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.vehicle.apartment && <Link key={item.vehicle.apartment.id + "-view"} to={"/apartments/" + item.vehicle.apartment.id}>{item.vehicle.apartment.name}</Link>}
                                {!item.vehicle.apartment && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.location && item.location}
                                {!item.location && <i>Not specified</i>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/parked-vehicles/" + item.id}>Details</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No parked vehicles to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Parked Vehicles ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of vehicles with Valet</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/parked-vehicles/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <ApartmentSelector
                                        placeholder="Space"
                                        value={this.state.apartmentId}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="parked">Parked</option>
                                            <option value="readyForPickup">Ready for user to pick up</option>
                                            {!this.state.hideCollected && <option value="collected">Collected</option>}
                                            {!this.state.hideCancelled && <option value="cancelled">Cancelled</option>}
                                        </Input>
                                    </InputGroup>
                                </Col><Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.paidStatus} onChange={this.handlePaidStatusChange} >
                                            <option value="">Billing status</option>
                                            <option value="True">Paid</option>
                                            <option value="False">Unpaid</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()}/>
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.status || this.state.paidStatus || this.state.apartmentId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardHeader>
                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideCompleted"
                                                type="checkbox"
                                                checked={this.state.hideCollected}
                                                onChange={this.handleHideCollected} />  Hide collected
                                        </label>
                                    </li>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideCancelled"
                                                type="checkbox"
                                                checked={this.state.hideCancelled}
                                                onChange={this.handleHideCancelled} />  Hide cancelled
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiParkedVehicleClient.List(new ParkedVehicleQueryParams()
                .WithStatus(this.state.status)
                .IsPaid(this.state.paidStatus)
                .WithHideCollected(this.state.hideCollected ? "true" : "false")
                .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
