import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiCarParkTypeClient } from '../infrastructure/HengyiClient/CarParkTypes';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';
import { SwiftParkCarParkTypeSelector } from '../infrastructure/SwiftParkCarParkTypeSelector';

export class CarParkTypesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false, buildingId: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }


    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialBuilding = this.state.buildingOptions.filter(type => type.value === this.state.buildingId)[0];

            return (<Formik
                initialValues={{
                    name: this.state.data.name,
                    description: this.state.description,
                    valetProvider: this.state.valetProvider,
                    externalId: this.state.externalId,
                    buildingId: initialBuilding
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiCarParkTypeClient.Update(this.props.match.params.id, this.state.name, this.state.description, fields.buildingId?.value, fields.valetProvider ?? "none", fields.externalId ? fields.externalId.value : null, fields.externalId ? fields.externalId.label : null );

                    if (!response.successful) {

                        response.data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }
                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                    <input id="name" name="name" type="text" className="form-control" onChange={this.handleChange} />

                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                    <input id="description" name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                        <Row>
                            <Col>
                                <SelectField
                                    id="buildingId"
                                    name="buildingId"
                                    label="Building"
                                    placeholder="Select building"
                                    options={this.state.buildingOptions}
                                    value={values.buildingId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.buildingId}
                                    error={errors.buildingId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>

                        {this.state.data.building?.valetProvider == "swiftPark" && <>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="valetProvider">Valet Integration</label>
                                    <select name="valetProvider"
                                        value={values.valetProvider}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.valetProvider && touched.valetProvider ? ' is-invalid' : '')}>
                                        <option value="none" label="None" />
                                        <option value="swiftPark" label="SwiftPark" />
                                    </select>
                                    <ErrorMessage name="valetProvider" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                            {values.valetProvider == "swiftPark" && <Row>
                                <Col>
                                    <SwiftParkCarParkTypeSelector
                                        id="externalId"
                                        name="externalId"
                                        buildingId={this.state.data.building.id}
                                        label="Default car park type"
                                        initialId={this.state.externalId}
                                        value={values.externalId}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.externalId}
                                        error={errors.externalId}
                                    />
                                </Col>
                            </Row>}

                        </>}

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/car-park-types/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update car park type details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details of this car park type</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/car-park-types/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var carParkType = await HengyiCarParkTypeClient.Get(this.props.match.params.id);
            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!carParkType.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                if (carParkType.data.building)
                    this.setState({ buildingId: carParkType.data.building.id });

                this.setState({
                    data: carParkType.data,
                    name: carParkType.data.name,
                    description: carParkType.data.description,
                    externalId: carParkType.data.externalId,
                    valetProvider: carParkType.data.valetProvider,
                    buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                    loading: false,
                    init: true
                }, () => {
                    document.getElementById("name").value = carParkType.data.name;
                    document.getElementById("description").value = carParkType.data.description;
                });

            }
        }
    }
}
