import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ParcelQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithDeliverySlot(value: string) {
        this.WithParam("deliverySlot", value);
        return this;
    }

    WithParcelType(value: string) {
        this.WithParam("type", value);
        return this;
    }

    WithHideCollected(value: Boolean) {
        this.WithParam("hideCollected", value);
        return this;
    }

    WithShowInvalidated(value: Boolean) {
        this.WithParam("showInvalidatedParcels", value);
        return this;
    }

    WithPreferenceInidcation(value: Boolean) {
        this.WithParam("hasIndicatedPreference", value);
        return this;
    }

    WithDate(value: string) {
        this.WithParam("requestedDate", value);
        return this;
    }
}

//******************************
// PETS
//******************************
export class HengyiParcelClient {

    static async List(params: ParcelQueryParams) {

        var query = (await GetAPIDomain()) + "/parcels" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }


    static async UpdateLocation(id: string, location: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "location",
                "value": location
            }])
        }));
    }

    static async Export(params: ParcelQueryParams) {

        var query = (await GetAPIDomain()) + "/parcels/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/parcels/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    //Invalidate parcel
    static async InvalidateParcel(id: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + id + "/invalidate", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        }));
    }

    //SET PARCEL AS Dropped off
    static async SetParcelAsDroppedOff(parcelId: string, chargeUser: Boolean, amountToCharge: number, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/confirm/drop-off", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "chargeUser": chargeUser,
                "notes": notes,
                "amountToCharge": amountToCharge
            })
        }));
    }

    //SET PARCEL AS Dropped off
    static async SetParcelAsInTransit(parcelId: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/in-transit", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async SetParcelAsResidentWillCollect(parcelId: string, date: string, deliverySlot: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/notify-pick-up", {
            method: 'post',
            headers: await GetAPIHeaders(), body: JSON.stringify({
                requestedHandoverDate: date,
                requestedHandoverDeliverySlot: deliverySlot
            })
        }));
    }

    static async SetParcelAsTakeUpToApartment(parcelId: string, date: string, deliverySlot: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/request-drop-off", {
            method: 'post',
            headers: await GetAPIHeaders(), body: JSON.stringify({
                requestedHandoverDate: date,
                requestedHandoverDeliverySlot: deliverySlot
            })
        }));
    }

    //SET PARCEL AS COLLECTED
    static async SetParcelAsCollected(parcelId: string, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/confirm/collected", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "notes": notes
            })
        }));
    }

    static async SetExpectedHandover(parcelId: string, requestedHandoverDate: string, requestedHandoverDeliverySlot: string, status: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/set-expected-handover", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "requestedHandoverDate": requestedHandoverDate,
                "deliveryStatusOverride": status,
                "requestedHandoverDeliverySlot": requestedHandoverDeliverySlot
            })
        }));
    }


    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/parcels/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/parcels/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/parcels/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

}