import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class StorageUnitQueryParams extends ListQueryParams {

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

}

//******************************
// STORAGE UNITS
//******************************
export class HengyiStorageUnitClient {

    static async List(params: StorageUnitQueryParams) {

        var query = (await GetAPIDomain()) + "/storage-units" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/storage-units/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, description: string, apartmentId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/storage-units/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "apartmentId": apartmentId
            })
        }));
    }

    static async Update(id: string, name: string, description: string, status: string, apartmentId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/storage-units/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "status",
                "value": status
            }, {
                "op": "replace",
                "path": "apartmentId",
                "value": apartmentId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/storage-units/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}