import React, { Component } from 'react';
import { ScheduleDetails } from './ScheduleDetails';

export class EventScheduleDetails extends Component {

    render() {
        return (
            <ScheduleDetails eventId={this.props.match.params.id} scheduleId={this.props.match.params.sid}/>
        );
    }

}
