import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { HengyiApi, ActivityQueryParams } from '../infrastructure/fetchHengy';

class ActivityTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: ""
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            entityId,
            userId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiApi.GetActivities(new ActivityQueryParams()
                .AssignedToUser(false)
                .WithEntity(entityId)
                .WithUser(userId)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort("created").Descending());

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }

    render() {
        const {
            title,
            description
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Activity"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all activity"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input id="search" type="text" placeholder="Search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                        <th style={{ borderTop: "none" }}>Title</th>
                                        <th style={{ borderTop: "none" }}>Description</th>
                                        <th style={{ borderTop: "none" }}>Date</th>
                                        <th style={{ borderTop: "none" }}>User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.title && item.title}
                                                {!item.title && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {item.description && item.description}
                                                {!item.description && <i>Not specified</i>}
                                            </td>
                                            <td><Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></td>
                                            <td>
                                                {item.user && <Link key={item.user.id + "-view"} to={"/users/" + item.user.id}>{item.user.name} {item.user.surname}</Link>}
                                                {!item.user && <i>System generated</i>}
                                            </td>
                                        </tr>
                                    )}

                                    {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No activity to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { ActivityTable };
