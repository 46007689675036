import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';
import { WorkOrderTable } from '../WorkOrders/WorkOrderTable';
import { WorkOrderAssignmentTable } from '../WorkOrderAssignments/WorkOrderAssignmentTable';
import { SwipeCardTable } from '../SwipeCards/SwipeCardTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class ContractorsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.approve = this.approve.bind(this);
        this.cancel = this.cancel.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/contractors" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/contractors" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Contractor</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name} {this.state.data.surname}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/contractors/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Assigned work
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Work order history
                            </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}>
                                        Swipe Cards
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name + " " + this.state.data.surname}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Email:</td>
                                                <td>
                                                    {this.state.data.email && this.state.data.email}
                                                    {!this.state.data.email && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Mobile:</td>
                                                <td>
                                                    {this.state.data.mobile && this.state.data.mobile}
                                                    {!this.state.data.mobile && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Company:</td>
                                                <td>
                                                    {this.state.data.company && this.state.data.company}
                                                    {!this.state.data.company && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Provider:</td>
                                                <td>
                                                    {this.state.data.provider === "none" && <Badge color="primary">None</Badge>}
                                                    {this.state.data.provider === "iviva" && <Badge color="info">IVIVA</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "pending" && <Badge color="secondary">Pending</Badge>}
                                                    {this.state.data.status === "approved" && <Badge color="primary">Approved</Badge>}
                                                    {this.state.data.status === "cancelled" && <Badge color="info">Cancelled</Badge>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <WorkOrderTable contractorId={this.props.match.params.id} title="Assigned work orders" description={"Open work orders currently assigned to " + this.state.data.name +" "+this.state.data.surname} status="open" />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <WorkOrderAssignmentTable contractorId={this.props.match.params.id} />}
                        </TabPane>
                        <TabPane tabId="4">
                            {this.state.activeTab === '4' && <SwipeCardTable title="Contractor cards" description="List all swipe cards for this contractor" userId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>


                    {!this.state.loading && this.state.data && this.state.data.status === "pending" &&
                        <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Contractor Pending:</strong> Do you want to approve or cancel this contractor?</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.cancel} className="btn mr-2 mt-2 mb-2 btn-seconday">Cancel</Button>
                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-primary">Approve</Button>
                                    </Col>
                                </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.status === "approved" &&
                        <div className="alert alert-success" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Contractor Approved:</strong> Do you want cancel this contractor?</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/ticket-types" onClick={this.cancel} className="btn mr-2 mt-2 mb-2 btn-seconday">Cancel</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "cancelled" &&
                        <div className="alert alert-secondary" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Contractor Cancelled:</strong> Do you want re-enable this contractor?</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/ticket-types" onClick={this.approve} className="btn mr-2 mt-2 mb-2 btn-primary">Restore</Button>
                                </Col>
                            </Row>
                        </div>}

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiContractorClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async approve() {
        await HengyiContractorClient.Approve(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async cancel() {
        await HengyiContractorClient.Cancel(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }
}
