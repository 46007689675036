import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class EventQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithShowCancelled(value: string) {
        this.WithParam("showCancelledEvents", value);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiEventClient {

    static async List(params: EventQueryParams) {

        var query = (await GetAPIDomain()) + "/events" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Create(
        name: string,
        description: string,
        location: string,
        buildingId: string,
        startTime?: string,
        endTime?: string,
        imageId?: string) {

        var request: any = {
            name: name,
            description: description,
            location: location,
            buildingId: buildingId,
            "backgroundImageId": imageId
        }

        if (endTime)
            request.endTime = endTime;

        if (startTime)
            request.startTime = startTime;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string,
        name: string,
        description: string,
        location: string,
        buildingId: string,
        startTime?: string,
        endTime?: string,
        imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "buildingId",
                "value": buildingId
            }, {
                "op": "replace",
                "path": "location",
                "value": location
            }, {
                "op": "replace",
                "path": "startTime",
                "value": startTime
            }, {
                "op": "replace",
                "path": "endTime",
                "value": endTime
            }, {
                "op": "replace",
                "path": "backgroundImageId",
                "value": imageId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/events/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Reject(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/" + id + "/reject", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Cancel(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/events/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
}