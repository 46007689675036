import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { HengyiTicketClient } from '../infrastructure/HengyiClient/Tickets';
import { GetAssetDomain } from '../infrastructure/Helper';
import { HengyiWorkOrderClient } from '../infrastructure/HengyiClient/WorkOrders';
import { SelectField } from '../infrastructure/SelectField';

function required(value) {
    console.log(value);
    if (!value) {
        console.log("Error: " + value);
        return 'This is a required field';
    }
}

export class ConvertTicketToWorkRequest extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], categoryOptions: [], apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };

    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var ticketDetails = await HengyiTicketClient.Get(this.props.match.params.id);

            if (ticketDetails.data.submittedBy)
                this.setState({ userId: ticketDetails.data.submittedBy.id });

            this.setState({
                init: true,
                location: "",
                data: ticketDetails.data,
                loading: false,
                serviceCategoryOptions: (await HengyiWorkOrderClient.GetServiceCategories()).data.data.map(function (item) { return { value: item.value, label: item.display }; }),
                problemTypeOptions: (await HengyiWorkOrderClient.GetProblemTypes()).data.data.map(function (item) { return { value: item.value, label: item.display }; }),
                locationOptions: (await HengyiWorkOrderClient.GetLocations()).data.data.map(function (item) { return { value: item.value, label: item.display }; })
            });
        }
    }

    renderForm() {

        var title = "";
        if (this.state.data.type)
            title = title + this.state.data.type.name;
        if (this.state.data.category) {
            if (this.state.data.type)
                title = title + " - ";
            title = title + this.state.data.category.name;
        }


        return (<Formik
            initialValues={{
                orderNumber: "",
                description: this.state.data.description,
                subject: title,
                problemType: "",
                serviceCategoryName: "",
                priorityName: "",
                locationFullName: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.subject) {
                    setFieldError("subject", "Please set the subject");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please enter a description");
                    return;
                }

                if (!fields.serviceCategoryName) {
                    setFieldError("serviceCategoryName", "Please choose a category");
                    return;
                }

                if (!fields.problemType) {
                    setFieldError("problemType", "Please choose a problem type");
                    return;
                }

                if (!fields.priorityName) {
                    setFieldError("priorityName", "Please choose a priority");
                    return;
                }

                if (!fields.locationFullName) {
                    setFieldError("locationFullName", "Please select the location");
                    return;
                }

                const params = {
                    subject: fields.subject,
                    description: fields.description,
                    locationFullName: fields.locationFullName ? fields.locationFullName.value : null,
                    problemType: fields.problemType ? fields.problemType.value : null,
                    serviceCategoryName: fields.serviceCategoryName ? fields.serviceCategoryName.value : null,
                    priorityName: fields.priorityName,
                    orderNumber: fields.orderNumber,
                    ticketId: this.props.match.params.id
                };

                const response = await fetch((await GetAPIDomain()) + "/work-orders", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {


                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="orderNumber">Work order number (leave blank to auto-generate)</label>
                            <Field name="orderNumber" type="text" className={'form-control' + (errors.orderNumber && touched.orderNumber ? ' is-invalid' : '')} />
                            <ErrorMessage name="orderNumber" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <Field validate={required} name="subject" type="text" className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')} />
                            <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <SelectField
                                id="serviceCategoryName"
                                name="serviceCategoryName"
                                label="Service Category"
                                placeholder="Select service category"
                                options={this.state.serviceCategoryOptions}
                                value={values.serviceCategoryName}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.serviceCategoryName}
                                error={errors.serviceCategoryName}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </div>
                    </Col>
                    <Col>
                            <div className="form-group">

                                <SelectField
                                    id="problemType"
                                    name="problemType"
                                    label="Problem Type"
                                    placeholder="Please choose type"
                                    options={this.state.problemTypeOptions}
                                    value={values.problemType}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.problemType}
                                    error={errors.problemType}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                        </div>
                    </Col>

                </Row>

                <Row>
                    <Col>
                            <div className="form-group">

                                <SelectField
                                    id="locationFullName"
                                    name="locationFullName"
                                    label="Location"
                                    placeholder="Please choose location"
                                    options={this.state.locationOptions}
                                    value={values.locationFullName}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.locationFullName}
                                    error={errors.locationFullName}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="priorityName">Priority</label>
                            <select type="select" name="priorityName"
                                placeholder="Please choose priority"
                                value={values.priorityName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.priorityName && touched.priorityName ? ' is-invalid' : '')}>
                                    <option value="">Please choose priority</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Low">Low</option>
                                    <option value="Urgent">Urgent</option>
                            </select>
                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                        </div>
                    </Col>

                </Row>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>

                <hr />

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "CREATE WORK ORDER"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/tickets/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Create a work order
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Use this form to create a work order linked to this ticket</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/tickets/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>


                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}
