import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, Error } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiChargeClient } from '../infrastructure/HengyiClient/Charges';
import { HengyiBillingCodeClient, BillingCodeQueryParams } from '../infrastructure/HengyiClient/BillingCodes';
import { UserSelector } from '../infrastructure/UserSelector';
import NumberFormat from 'react-number-format';

export class ChargesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, origin: "parcels", screen: "form"
        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("origin") === "dashboard")
            this.setState({
                origin: "dashboard"
            });

        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                description: this.state.description,
                amount: this.state.description,
                userId: "",
                billingCodeId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!this.state.description) {
                    setFieldError("description", "Please enter a description so the resident knows what this charge is for");
                    return;
                }

                if (!fields.userId) {
                    setFieldError("userId", "Please select a resident");
                    return;
                }

                var amount = 0;
                if (this.state.amount)
                    amount = parseFloat(this.state.amount);


                const response = await HengyiChargeClient.Create(
                    fields.userId ? fields.userId.value : null,
                    Math.round(amount * 100),
                    this.state.description,
                    fields.billingCodeId ? fields.billingCodeId.value : null);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, setFieldError, handleChange, handleBlur }) => (
            <Form>
                {this.state.screen === "form" && <>
                    {this.state.errors && <div className="alert alert-danger">{this.state.errors}</div>}
                    <Row>
                        <Col>

                            <UserSelector
                                value={values.userId}
                                label="User to charge"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.status}
                                error={errors.type}
                                includeCardSuffix={true} />

                        </Col>

                        <Col>
                            <div className="form-group">
                                <label htmlFor="amount">Amount to charge</label>
                                    <input name="amount" type="text" className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="amount" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>

                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                    <input name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <SelectField
                                id="billingCodeId"
                                name="billingCodeId"
                                label="Billing code"
                                placeholder="Select billing code"
                                options={this.state.billingCodeOptions}
                                value={values.billingCodeId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.billingCodeId}
                                error={errors.billingCodeId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                    </Row>
                    <div className="form-group mt-3">
                        <button type="button" className="btn mr-2 btn-outline-dark mt-2" onClick={() => {


                                if (!this.state.description) {
                                this.setState({ errors: "Please enter a description so the resident knows what this charge is for" });
                                return;
                            }

                                if (!values.userId) {
                                this.setState({ errors: "Please select a resident to bill" });
                                return;
                            }

                                if (!(this.state.amount > 0)) {
                                this.setState({ errors: "Please enther the amount to bill (must be greater than 0)" });
                                return;
                            }

                            this.setState({ errors: "", screen: "confirm" });

                        }}>CONFIRM</button>
                    </div>
                </>}

                {this.state.screen === "confirm" && <>
                    <h3>Please confirm the amount to charge</h3>
                    <hr />

                    <table>
                        <tbody>
                            <tr>
                                <td className="pr-2">Resident to bill:</td>
                                <td>
                                    {values.userId.label}
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-2">Billing code:</td>
                                <td>
                                    {values.billingCodeId && values.billingCodeId.label}
                                    {!values.billingCodeId && <i>Not specified</i>}
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-2">Amount:</td>
                                <td>
                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(this.state.amount)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                </td>
                            </tr>
                            <tr>
                                <td className="pr-2">Description:</td>
                                <td>
                                        {this.state.description}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "CHARGE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                        <button type="button" className="btn mr-2 btn-outline-dark mt-2" onClick={() => {

                            this.setState({ errors: "", screen: "form" });

                        }}>CHANGE DETAILS</button>
                    </div></>}
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {

            if (this.state.origin === "dashboard") {
                return (<Redirect to="/" />);
            } else {
                return (<Redirect to={"/charges/" + this.state.id} />);
            }

        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Charge a user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a new manual charge to a user</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.origin === "dashboard" &&
                                            <Link style={{ float: "right" }} to="/" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }
                                        {this.state.origin !== "dashboard" &&
                                            <Link style={{ float: "right" }} to="/charges" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                billingCodeOptions: (await HengyiBillingCodeClient.List(new BillingCodeQueryParams().Paginate(0, 10000))).data.data.map(function (item) { return { value: item.id, label: item.name + (item.description ? " (" + item.description + ")" : "") }; }),
                loading: false
            });
        }
    }
}
