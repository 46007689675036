import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { userContext } from '../../userContext';

export class BuildingsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/buildings" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/buildings" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Building</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/buildings/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <userContext.Consumer>
                        {({ features }) => {
                            return (<Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h6>Building details</h6>
                                            <hr />
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Name:</td>
                                                        <td>
                                                            <span>{this.state.data.name}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        {features && features.includes("valet") && <Col>
                                            <h6>Valet service</h6>
                                            <hr />
                                            {this.state.data.parkingEnabled &&
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Provider:</td>
                                                            <td>
                                                                {this.state.data.valetProvider === "none" && <span>Manual</span>}
                                                                {this.state.data.valetProvider === "oValet" && <span>O-Valet</span>}
                                                                {this.state.data.valetProvider === "swiftPark" && <span>SwiftPark</span>}
                                                            </td>
                                                        </tr>
                                                        {this.state.data.valetProvider === "oValet" &&
                                                            <tr>
                                                                <td className="pr-2">API Key:</td>
                                                                <td>
                                                                    {this.state.data.valetProviderAPIKey && <span>Configured</span>}
                                                                    {!this.state.data.valetProviderAPIKey && <i>Not set</i>}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {this.state.data.valetProvider === "swiftPark" &&
                                                            <tr>
                                                                <td className="pr-2">Username:</td>
                                                                <td>
                                                                    {this.state.data.valetUserName && <span>{this.state.data.valetUserName}</span>}
                                                                    {!this.state.data.valetUserName && <i>Not set</i>}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {this.state.data.valetProvider === "swiftPark" &&
                                                            <tr>
                                                                <td className="pr-2">Password:</td>
                                                                <td>
                                                                    {this.state.data.valetPassword && <span>Configured</span>}
                                                                    {!this.state.data.valetPassword && <i>Not set</i>}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {this.state.data.valetProvider === "swiftPark" &&
                                                            <tr>
                                                                <td className="pr-2">Default Park:</td>
                                                                <td>
                                                                    {this.state.data.valetDefaultParkTypeName && <span>{this.state.data.valetDefaultParkTypeName}</span>}
                                                                    {!this.state.data.valetDefaultParkTypeName && <i>Not set</i>}
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="pr-2">Request item cost:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={this.state.data.valetRequestItemCost / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Valet park car cost:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={this.state.data.valetParkCarCost / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
                                            {!this.state.data.parkingEnabled && <i>Parking disabled</i>}
                                        </Col>}
                                        {features && features.includes("parcels") && <Col>
                                            <h6>Delivery service</h6>
                                            <hr />
                                            {this.state.data.parcelEnabled &&
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Space delivery cost:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={this.state.data.parcelDeliveryCost / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Parcel hold time:</td>
                                                            <td>{this.state.data.parcelHoldingLength} hours</td>
                                                        </tr>
                                                    </tbody>
                                                </table>}
                                            {!this.state.data.parcelEnabled && <i>Parcel delivery disabled</i>}
                                        </Col>}
                                        <hr />

                                    </Row>
                                    <Row className="mt-5">
                                        {features && features.includes("inductions") && <Col>
                                            <h6>Resident induction</h6>
                                            <hr />
                                            {this.state.data.inductionEnabled &&
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Document:</td>
                                                            <td>{this.state.data.inductionDocument.name}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>}
                                            {!this.state.data.inductionEnabled && <i>Resident induction disabled</i>}
                                        </Col>}
                                        {features && features.includes("ev-charging") && <Col>
                                            <h6>EV Charging</h6>
                                            <hr />
                                            {this.state.data.evChargerEnabled &&
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Provider:</td>
                                                            <td>{this.state.data.evChargeProvider}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Fee per charge:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={this.state.data.fixedChargeFee / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Power cost per kW:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={+(Math.round((this.state.data.evPowerCostPerWatt / 100 * 1000) + "e+2") + "e-2")} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>}
                                            {!this.state.data.evChargerEnabled && <i>EV Charging disabled</i>}
                                        </Col>}
                                        {features && features.includes("exivo") && <Col>
                                            <h6>Exivo access control</h6>
                                            <hr />
                                            {this.state.data.exivoEnabled &&
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Site Id:</td>
                                                            <td>{this.state.data.exivoSiteId}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">API Key:</td>
                                                            <td>{this.state.data.exivoApiKey}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">API Secret:</td>
                                                            <td>********************</td>
                                                        </tr>
                                                    </tbody>
                                                </table>}
                                            {!this.state.data.exivoEnabled && <i>Integration disabled</i>}
                                        </Col>}
                                    </Row>
                                </CardHeader>
                            </Card>)
                        }}
                    </userContext.Consumer>

                </div>
            );
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiBuildingClient.Get(id);
            console.log(response);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
