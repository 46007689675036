import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiBookingsClient, BookingQueryParams } from "../infrastructure/HengyiClient/Bookings";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendar } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { userContext } from '../../userContext';
import { TableHeader } from "../infrastructure/TableHeader";
import { ApartmentSelector } from "../infrastructure/ApartmentSelector";
import { UserLink } from "../Users/UserLink";
import { FacilitySelector } from "../infrastructure/FacilitySelector";
import { EventSelector } from "../infrastructure/EventSelector";
import FileSaver from 'file-saver';

class BookingsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, exporting: false, total: 0, skip: 0, take: 50, search: "", futureOnly: true, hideCancellations: true, sort: "start", direction: "desc"
        };

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleFilterByDateChange = this.handleFilterByDateChange.bind(this);
        this.handleHideCancellations = this.handleHideCancellations.bind(this);
        this.handleFutureOnlyChange = this.handleFutureOnlyChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleTargetChange = this.handleTargetChange.bind(this);
        this.approve = this.approve.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
        this.handleFacilityIdChange = this.handleFacilityIdChange.bind(this);
        this.handleEventIdChange = this.handleEventIdChange.bind(this);
    }

    async approve(id) {
        await HengyiBookingsClient.Approve(id);
        await this.update();
    }

    handleFacilityIdChange(name, value) {

        this.setState({
            facilityId: value
        }, () => this.update());
    }

    handleEventIdChange(name, value) {

        this.setState({
            eventId: value
        }, () => this.update());
    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleTargetChange(evt) {
        this.setState({
            targetType: evt.target.value
        }, () => this.update());
    }

    handleFilterByDateChange = (date) => {
        this.setState({
            filterDate: date
        }, () => this.update());
    }

    handleHideCancellations = (evt) => {
        this.setState({
            hideCancellations: evt.target.checked
        }, () => this.update());
    }

    handleFutureOnlyChange = (evt) => {
        this.setState({
            futureOnly: evt.target.checked
        }, () => this.update());
    }

    handleSearchChange = (evt) => {
        this.setState({
            search: evt.target.value
        });
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            filterDate: "",
            targetType: "",
            apartmentId: "",
            facilityId: "",
            eventId: "",
            skip: 0
        }, () => this.update());

    }

    async componentDidMount() {

        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);

            this.setState({
                search: urlParams.get("q") ? urlParams.get("q") : "",
                status: urlParams.get("status"),
                futureOnly: urlParams.get("futureOnly") === "false" ? false : true,
                skip: 0
            }, () => this.update());
        } else {
            await this.update();
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            facilityId,
            eventId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var date = this.state.filterDate ? this.state.filterDate.toISOString() : null;
            var futureOnly = this.state.futureOnly ? true : null;

            var facilityFilter = facilityId;
            if (!facilityFilter && this.state.facilityId)
                facilityFilter = this.state.facilityId.value;

            var eventFilter = eventId;
            if (!eventFilter && this.state.eventId)
                eventFilter = this.state.eventId.value;

            var params = new BookingQueryParams()
                .WithFacility(facilityFilter)
                .WithEvent(eventFilter)
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
                .WithHideCancellation(this.state.hideCancellations ? "true" : "false")
                .WithStatus(this.state.status)
                .WithTarget(this.state.targetType)
                .WithLimitToFutureBookings(futureOnly)
                .WithDate(date)
                .Search(this.state.search)
                .Paginate(skip, take)
                .Sort(this.state.sort, this.state.direction);

            var response = await HengyiBookingsClient.List(params);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }
        }
    }


    async exportToCSV() {

        const {
            facilityId,
            eventId
        } = this.props;

        this.setState({ exporting: true });


        var date = this.state.filterDate ? this.state.filterDate.toISOString() : null;
        var futureOnly = this.state.futureOnly ? true : null;

        var facilityFilter = facilityId;
        if (!facilityFilter && this.state.facilityId)
            facilityFilter = this.state.facilityId.value;

        var eventFilter = eventId;
        if (!eventFilter && this.state.eventId)
            eventFilter = this.state.eventId.value;

        var params = new BookingQueryParams()
            .WithFacility(facilityFilter)
            .WithEvent(eventFilter)
            .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
            .WithHideCancellation(this.state.hideCancellations ? "true" : "false")
            .WithStatus(this.state.status)
            .WithTarget(this.state.targetType)
            .WithLimitToFutureBookings(futureOnly)
            .WithDate(date)
            .Search(this.state.search)
            .Paginate(0, 1)
            .Sort(this.state.sort, this.state.direction);

        var blob = await HengyiBookingsClient.Export(params);

        FileSaver.saveAs(blob, "bookings.csv");

        this.setState({ exporting: false });
    }

    render() {
        const {
            title,
            description,
            facilityId,
            eventId
        } = this.props;

        return (
            <div>

                <Card>
                    <userContext.Consumer>
                        {({ user, logoutUser, features }) => {
                    return (<CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Bookings"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List of bookings in the building."}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                    {user.permissions && user.permissions.includes("bookings.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">

                                        {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                        {this.state.exporting && <Spinner animation="border" />}

                                    </button>}

                                    {!facilityId && !eventId && <Link style={{ float: "right" }} to="/bookings/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                </div>
                            </Col>
                        </Row>

                            </CardHeader>);
                        }}
                    </userContext.Consumer>
                </Card>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            {(facilityId || eventId) && <Col>
                                <ApartmentSelector
                                    placeholder="Space"
                                    value={this.state.apartmentId}
                                    onChange={this.handleApartmentIdChange}
                                    hideLabel={true} />
                            </Col>}
                            <Col>
                                <div className="form-group">
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="pending">Pending</option>
                                            <option value="confirmed">Confirmed</option>
                                            <option value="cancelled">Cancelled</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            {!facilityId && !eventId && <Col>
                                <div className="form-group">
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.targetType} onChange={this.handleTargetChange} >
                                            <option value="">All types</option>
                                            <option value="event">Events</option>
                                            <option value="facility">Facilities</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>}

                            <Col>
                                <div className="form-group">
                                    <InputGroup>
                                        <DatePicker
                                            id="date"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                            placeholderText="Filter to a date"
                                            selected={this.state.filterDate}
                                            onChange={this.handleFilterByDateChange}
                                            dateFormat='dd/MM/yyyy'
                                            className="form-control"
                                            autoComplete="off" />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.targetType || this.state.filterDate || this.state.apartmentId) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        {!facilityId && !eventId && <Row>
                            <Col></Col>
                            <Col>
                                <ApartmentSelector
                                    placeholder="Space"
                                    value={this.state.apartmentId}
                                    onChange={this.handleApartmentIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <FacilitySelector
                                    placeholder="Facility"
                                    value={this.state.facilityId}
                                    onChange={this.handleFacilityIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <EventSelector
                                    placeholder="Event"
                                    value={this.state.eventId}
                                    onChange={this.handleEventIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col></Col>
                        </Row>}
                    </CardHeader>
                    <CardHeader>

                        <div className="form-group">
                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideCancellations"
                                            type="checkbox"
                                            checked={this.state.hideCancellations}
                                            onChange={this.handleHideCancellations} />  Hide cancelled bookings
                                        </label>
                                </li>   <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="futureOnly"
                                            type="checkbox"
                                            checked={this.state.futureOnly}
                                            onChange={this.handleFutureOnlyChange} />  Future bookings only
                                        </label>
                                </li>
                            </ul>
                        </div>
                    </CardHeader>
                    <userContext.Consumer>
                        {({ user, logoutUser, features }) => {
                            return (<CardBody className="p-0">
                        <div className="table-responsive">
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "Booked", static: true },
                                        { name: "User", sort: "user.name" },
                                        { name: "Time", sort: "start" },
                                        { name: "Status", sort: "status" },
                                        { name: "Booking Type", sort: "bookingType.name" },
                                        { disabled: features && !features.includes("exivo"), name: "Exivo", sort: "exivoEnabled"},
                                        { name: "", sort: "", static: true }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />
                                <tbody>
                                    {this.state.data && this.state.data.map(booking =>
                                        <tr key={booking.id}>
                                            <td>
                                                {booking.schedule.facility && <span><FontAwesomeIcon icon={faBuilding} /> {booking.schedule.facility.name}</span>}
                                                {booking.schedule.event && <span><FontAwesomeIcon icon={faCalendar} /> {booking.schedule.event.name}</span>}
                                            </td>
                                            <td><UserLink user={booking.user} /></td>
                                            <td><Moment utc local format="ddd, DD/MM" date={booking.start} />: <Moment utc local format="h:mma" date={booking.start} /> - <Moment utc local format="h:mma" date={booking.end} /></td>
                                            <td>
                                                {booking.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                {booking.status === "confirmed" && <Badge color="success">Confirmed</Badge>}
                                                {booking.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                {!booking.status && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {booking.bookingType.name}
                                            </td>{features && features.includes("exivo") && <td>{!booking.exivoEnabled && "No"}
                                                {booking.exivoEnabled && "Yes"}</td>}
                                            <td>
                                                <Link key={booking.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/bookings/" + booking.id}>Details</Link>
                                                {booking.status === "pending" && <Button key={booking.id + "-approve"} style={{ float: "right", backgroundColor: "white" }} to="/bookings" onClick={() => { this.approve(booking.id); }} className="btn mr-2 mt-2 mb-2 btn-outline-dark">Approve</Button>}
                                            </td>
                                        </tr>
                                    )}

                                    {this.state.data && this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No bookings to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        </div>
                            </CardBody>);
                        }}
                    </userContext.Consumer>
                </Card>
            </div>
        );
    }
}

export { BookingsTable };

BookingsTable.contextType = userContext;