import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { WorkOrderAssignmentQueryParams, HengyiWorkOrderAssignmentClient } from "../infrastructure/HengyiClient/WorkOrderAssignments";
import Moment from 'react-moment';

class WorkOrderAssignmentTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: ""
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async clear() {
        this.setState({
            skip: 0,
            status: "",
            search: "",
            provider: ""
        }, async () => {
            await this.update();
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new WorkOrderAssignmentQueryParams()
                .Paginate(skip, take)
                .Sort("created")
                .Descending()
                .Search(this.state.search);

            if (this.props.contractorId != null)
                params.WithContractor(this.props.contractorId);

            if (this.props.workOrderId != null)
                params.WithWorkOrder(this.props.workOrderId);

            var response = await HengyiWorkOrderAssignmentClient.List(params);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }

    render() {
        const {
            title,
            description
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Work order assignments"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "History of work order assignments"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()}/>
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {this.state.search &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <div className="table-responsive">
                                <table className='table' aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th style={{ borderTop: "none" }}>Work Order</th>
                                            <th style={{ borderTop: "none" }}>Assignment</th>
                                            <th style={{ borderTop: "none" }}>Reason</th>
                                            <th style={{ borderTop: "none" }}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map(item =>
                                            <tr key={item.id}>
                                                <td>
                                                    {item.workOrder && <Link key={item.workOrder.id + "-view"} to={"/work-orders/" + item.workOrder.id}>{item.workOrder.orderNumber}</Link>}
                                                    {!item.workOrder && <i>Not specified</i>}
                                                </td>
                                                <td>
                                                    {item.assignedTo && <Link key={item.assignedTo.id + "-view"} to={"/contractors/" + item.assignedTo.id}>{item.assignedTo.name} {item.assignedTo.surname}</Link>}
                                                    {!item.assignedTo && <i>Not assigned</i>}
                                                </td>
                                                <td>
                                                    {item.reason && item.reason}
                                                    {!item.reason && <i>Not specified</i>}
                                                </td>
                                                <td>
                                                    <Moment utc local format="ddd DD MMM YY, yyyy hh:MM A">{item.created}</Moment>
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No history to display</i></h4></td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { WorkOrderAssignmentTable };
