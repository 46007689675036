import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain } from '../infrastructure/Helper';

export class TicketTypesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, name: "", description: "", loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    status: this.state.status,
                    instructions: this.state.instructions
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    fields.name = this.state.name;
                    fields.instructions = this.state.instructions;

                    const response = await fetch((await GetAPIDomain()) + "/ticket-types/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "name",
                            "value": fields.name
                        }, {
                                "op": "replace",
                                "path": "status",
                                "value": fields.status
                            }, {
                                "op": "replace",
                                "path": "instructions",
                                "value": fields.instructions
                            }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values }) => (
                <Form>
                    <Row className="mt-3">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                    <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <select name="status"
                                    value={values.status}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
                                    <option value="available" label="Active" />
                                    <option value="archived" label="Archived" />
                                </select>
                                <ErrorMessage name="status" component="div" className="invalid-feedback" />
                            </div>

                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="instructions">Tip/ Instructions</label>
                                    <textarea name="instructions" id="instructions" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="instructions" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/ticket-types/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update ticket type
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the name or status</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/ticket-types/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiApi.GetTicketType(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    name: response.data.name,
                    status: response.data.status,
                    instructions: response.data.instructions
                }, () => {
                    document.getElementById("name").value = response.data.name ? response.data.name : "";
                    document.getElementById("instructions").value = response.data.instructions ? response.data.instructions : "";
                });
            }
        }
    }
}
