import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Spinner, Card, CardHeader, Row, Col, Button, Badge } from 'reactstrap';
import classnames from 'classnames';
import { userContext } from '../../userContext';
import { HengyiPetClient } from '../infrastructure/HengyiClient/Pets';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Formik, Field, Form, ErrorMessage } from 'formik';

export class PetsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [], loading: false,
            activeTab: '1',
            showAddNote: false, includeDeleted: false
        };

        this.delete = this.delete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiPetClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/pets" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/pets" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Pet</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/pets/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h6>Pet details</h6>
                                                <hr />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Name:</td>
                                                            <td>
                                                                <span>{this.state.data.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Type:</td>
                                                            <td>
                                                                {this.state.data.type === "dog" && "Dog"}
                                                                {this.state.data.type === "cat" && "Cat"}
                                                                {this.state.data.type === "bird" && "Bird"}
                                                                {this.state.data.type === "other" && this.state.data.petType}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Breed:</td>
                                                            <td>
                                                                {this.state.data.breed && this.state.data.breed}
                                                                {!this.state.data.breed && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Description:</td>
                                                            <td>
                                                                {this.state.data.description && this.state.data.description}
                                                                {!this.state.data.description && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Space:</td>
                                                            <td>
                                                                {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                                {!this.state.data.apartment && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Primary contact:</td>
                                                            <td>
                                                                {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/" + this.state.data.user.id}>{this.state.data.user.name} {this.state.data.user.surname}</Link>}
                                                                {!this.state.data.user && <i>Anyone at the space</i>}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>

                                            <Col md="3" className="text-center">
                                                <h6 className="text-left">Profile image</h6>
                                                <hr />
                                                {!this.state.data.profileImageId && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                                {this.state.data.profileImageId && <img alt="Uploaded by submitted" className="rounded-circle" src={this.state.assetUrl + "/image/" + this.state.data.profileImageId + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}
                                            </Col>
                                            <hr />

                                        </Row>
                                    </CardHeader>
                                </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this pet</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        var message = document.getElementById("message").value;

                                                        if (!message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiPetClient.AddNote(this.props.match.params.id, message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            document.getElementById("message").value = "";
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                            <input id="message" as="textarea" name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("pet.modify") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                    </TabContent>


                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger">
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this pet, doing so will permanently remove it, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/pets" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete pet</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This pet cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiPetClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiPetClient.Get(id);
            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiPetClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data, loading: false,
                    notes: notes.data });
            }
        }
    }
}
