import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class BookingQueryParams extends ListQueryParams {
    WithDate(value: string) {
        this.WithParam("date", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithTarget(value: string) {
        this.WithParam("target", value);
        return this;
    }

    WithHideCancellation(value: boolean) {
        this.WithParam("hideCancellations", value);
        return this;
    }

    WithLimitToFutureBookings(value: boolean) {
        this.WithParam("limitToFutureBookings", value);
        return this;
    }

    WithFacility(value: boolean) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithEvent(value: boolean) {
        this.WithParam("eventId", value);
        return this;
    }
}

export class HengyiBookingsClient {

    static async List(params: BookingQueryParams) {

        var query = (await GetAPIDomain()) + "/bookings" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async Export(params: BookingQueryParams) {

        var query = (await GetAPIDomain()) + "/bookings/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            headers: await GetAPIHeaders()
        }));
    }

    static async Create(
        notes: string,
        contactName: string,
        contactNumber: string,
        userId: string,
        bookingTypeId: string,
        scheduleId: string,
        start: string,
        end: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "notes": notes,
                "contactName": contactName,
                "contactNumber": contactNumber,
                "userId": userId,
                "bookingTypeId": bookingTypeId,
                "scheduleId": scheduleId,
                "start": start,
                "end": end,
            })
        }));
    }

    static async UpdateNotes(
        id: string,
        notes: string,
        contactName: string,
        contactNumber: string) {

        var updates = [{
            "op": "replace",
            "path": "notes",
            "value": notes
        }, {
            "op": "replace",
            "path": "contactName",
            "value": contactName
        }, {
            "op": "replace",
            "path": "contactNumber",
            "value": contactNumber
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Update(
        id: string,
        notes: string,
        contactName: string,
        contactNumber: string,
        userId: string,
        bookingTypeId: string,
        scheduleId: string,
        approvedById: string,
        start: string,
        end: string,
        status: number) {

        var updates = [{
                "op": "replace",
                "path": "notes",
                "value": notes
            }, {
                "op": "replace",
                "path": "contactName",
                "value": contactName
            }, {
                "op": "replace",
                "path": "contactNumber",
                "value": contactNumber
            }, {
                "op": "replace",
                "path": "userId",
                "value": userId
            }, {
                "op": "replace",
                "path": "bookingTypeId",
                "value": bookingTypeId
            }, {
                "op": "replace",
                "path": "scheduleId",
                "value": scheduleId
            }, {
                "op": "replace",
                "path": "approvedById",
                "value": approvedById
            }, {
                "op": "replace",
                "path": "start",
                "value": start
            }, {
                "op": "replace",
                "path": "end",
                "value": end
            }, {
                "op": "replace",
                "path": "status",
                "value": status
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }


    static async UpdateBookingDate(
        id: string,
        start: string,
        end: string) {

        var updates = [{
            "op": "replace",
            "path": "start",
            "value": start
        }, {
            "op": "replace",
            "path": "end",
            "value": end
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }


    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Cancel(id: string, reason: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "reasonCancelled": reason
            })
        }));
    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/bookings/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/bookings/" + id + "/notes" + params.GenerateQueryString();
        
        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/bookings/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}