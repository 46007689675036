import React, { Component } from 'react';
import { InvoicesTable } from './InvoicesTable';

export class Invoices extends Component {

    render() {
        return (
            <InvoicesTable enableEditControls={true} />
        );
    }
}
