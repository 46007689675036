import React, { Component } from 'react';
import { Container, Spinner, Alert, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';

export class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false };
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });
        this.setState({
            data: await HengyiApi.GetCurrentUser(),
            actions: (await HengyiApi.GetUserActions("current")).data,
            loading: false
        });
    }

    renderContent() {

        return (<Formik
            initialValues={{
                existingPassword: "",
                newPassword: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {
                var response = await HengyiApi.SetUserPassword(fields.existingPassword, fields.newPassword);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ success: true, id: response.data.id });
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <Row>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="existingPassword">Existing password</label>
                                <Field name="existingPassword" type="password" className={'form-control' + (errors.existingPassword && touched.existingPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="existingPassword" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword">New password</label>
                                <Field name="newPassword" type="password" className={'form-control' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5>Success</h5>
            <strong>Password changed</strong>. Please use your new password from now on.
                    </Alert>
            <Link className="btn mr-2 btn-outline-dark mt-2" to="/">CONTINUE</Link></div>);
    }

    render() {

        if (this.state.success) {
            return (<Redirect to="/" />);
        } else {
            return (

                <div>
                    <Container style={{ "paddingTop": "50px" }}>

                        {this.state.actions && this.state.actions.requireTempPasswordReset &&
                            <div className="alert alert-danger">
                                <p className="mt-2"><strong>Secure your account</strong>. You have a temporary password set, this is only to be used to help set up your account.</p>
                                <p className="mb-2">Please choose a new secure permanent password.</p>
                            </div>}

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Change password
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Use the form below to choose a new password</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right" }} to="/" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderContent()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }
}
