import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class IncidentQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

}

//******************************
// INCIDENTS
//******************************
export class HengyiIncidentClient {

    static async List(params: IncidentQueryParams) {

        var query = (await GetAPIDomain()) + "/incidents" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/incidents/" + id, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Sync(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/incidents/" + id + "/sync", {
            headers: await GetAPIHeaders(),
            method: "POST"
        }));
    }
}