import React, { Component } from 'react';
import { ScheduleDetails } from './ScheduleDetails';

export class FacilityScheduleDetails extends Component {

    render() {
        return (
            <ScheduleDetails facilityId={this.props.match.params.id} scheduleId={this.props.match.params.sid} />
        );
    }

}
