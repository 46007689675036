import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { AudienceSelector } from '../Audience/AudienceSelector';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';
import { HengyiTenantImageClient } from '../infrastructure/HengyiClient/TenantImages';

export class BrandingImageEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false, fileName: "", type: "" };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    type: this.state.type,
                    fileName: this.state.fileName,
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiTenantImageClient.Update(this.props.match.params.id, fields.type, this.state.fileName);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleBlur, handleChange }) => (
                <Form>

                    <Row>
                        <Col>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="fileName">Filename*</label>
                                            <input name="fileName" id="fileName" type="text" className={'form-control' + (errors.fileName && touched.fileName ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="fileName" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="type">Image Type</label>
                                        <select name="type"
                                            value={values.type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                            <option value="logo" label="Logo" />
                                            <option value="logoAlt" label="Logo (footer)" />
                                            <option value="logoEmail" label="Logo (email)" />
                                            <option value="emailBanner" label="Banner (email)" />
                                            <option value="residentBanner" label="Resident banner image" />
                                            <option value="appBackground" label="Mobile app background image" />
                                        </select>
                                        <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>



                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/branding/images/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update image details
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details for this image</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/branding/images/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var item = await HengyiTenantImageClient.Get(this.props.match.params.id);

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    fileName: item.data.fileName,
                    type: item.data.type,
                    loading: false
                }, () => {
                    document.getElementById("fileName").value = item.data.fileName ? item.data.fileName : "";
                });

            }
        }
    }
}
