import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class FeatureQueryParams extends ListQueryParams {

}

//******************************
// Features
//******************************
export class HengyiFeatureClient {

    static async List(params: FeatureQueryParams) {

        var query = (await GetAPIDomain()) + "/features" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Toggle(id: string, enabled: boolean) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/features/toggle", {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "featureId": id,
                "enabled": enabled
            })
        }));

    }

}