import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class InductionQueryParams extends ListQueryParams {

    WithDocument(value: string) {
        this.WithParam("documentId", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

}

export class HengyiInductionClient {

    static async List(params: InductionQueryParams) {

        var query = (await GetAPIDomain()) + "/induction-documents" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string, documentId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/induction-documents/" + documentId, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(userId?: string, documentId?:string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/induction-documents", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId,
                "documentId": documentId
            })
        }));
    }

    static async Delete(id: string, documentId: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/induction-documents/" + documentId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}