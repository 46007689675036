import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiCarParkTypeClient } from '../infrastructure/HengyiClient/CarParkTypes';

export class CarParkTypesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/car-park-types" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/car-park-types" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Car Park Type</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/car-park-types/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                                <h6>Details</h6>
                                <hr />
                                    <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">Name:</td>
                                            <td>
                                                {this.state.data.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Building:</td>
                                            <td>
                                                {this.state.data.building && this.state.data.building.name}
                                                {!this.state.data.building && <i>Not linked</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Description:</td>
                                            <td>
                                                {this.state.data.description}
                                            </td>
                                        </tr>
                                            <tr>
                                                <td className="pr-2">Provider:</td>
                                                <td>
                                                    {this.state.data.valetProvider === "none" && <span>Manual</span>}
                                                    {this.state.data.valetProvider === "swiftPark" && <span>SwiftPark</span>}
                                                </td>
                                            </tr>
                                            {this.state.data.valetProvider === "swiftPark" &&
                                                <tr>
                                                    <td className="pr-2">Linked Park Type:</td>
                                                    <td>
                                                    {this.state.data.externalName && <span>{this.state.data.externalName}</span>}
                                                    {!this.state.data.externalName && <i>Not set</i>}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                        </CardHeader>
                    </Card>

                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger">
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this car park type, doing so will permanently remove it, please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                <Button style={{ float: "right" }} to="/car-park-types" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete car park type</Button>
                                    </Col>
                                </Row>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This car park type is in use and cannot be deleted</p>
                                </div>}
                        </div>}

                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiCarParkTypeClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiCarParkTypeClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
