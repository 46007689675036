import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Input, InputGroup, ButtonGroup  } from 'reactstrap';
import { UserLink } from '../Users/UserLink';
import { TableHeader } from '../infrastructure/TableHeader';
import { PartnerOfferClient } from '../infrastructure/HengyiClient/PartnerOffers';
import { PartnerOfferCategorySelector } from '../infrastructure/PartnerOfferCategorySelector';
import FileSaver from 'file-saver';
import { userContext } from '../../userContext';

export class PartnerOffers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, search: "", authenticated: true,
            userStatus: "", folderId: "",
            sort: "name", direction: "asc",
            categoryId: "", showExpired: false,
            exporting: false
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleCategoryIdChange = this.handleCategoryIdChange.bind(this);
        this.handleShowExpired = this.handleShowExpired.bind(this);
    }

    handleShowExpired = (evt) => {
        this.setState({
            showExpired: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }



    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }


    handleCategoryIdChange(name, value) {
        this.setState({
            categoryId: value
        }, () => this.update());
    }

    async clear() {

        this.setState({
            status: "",
            search: "",
            categoryId: "",
            skip: 0
        }, () => this.populateData(this.state.skip, this.state.take));

    }


    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Name", sort: "name" },
                        { name: "Status", sort: "status" },
                        { name: "Uploaded By", sort: "createdBy.name" },
                        { name: "Weight", sort: "weight" },
                        { name: "Category", sort: "partnerOfferCategory.name" },
                        { name: "Downloads", sort: "downloads" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(doc =>
                        <tr key={doc.id}>
                            <td>{doc.name}</td>
                            <td>
                                {doc.status === "draft" && <Badge color="primary">Draft</Badge>}
                                {doc.status === "published" && <Badge color="success">Published</Badge>}
                                {!doc.status && <i>Not specified</i>}
                            </td>
                            <td>
                                {doc.createdBy && <UserLink user={doc.createdBy} />}
                                {!doc.createdBy && <i>Not specified</i>}
                            </td>
                            <td>{doc.weight}</td>
                            <td>{!doc.partnerOfferCategory && <i>None</i>}
                                {doc.partnerOfferCategory && <span>{doc.partnerOfferCategory.name}</span>}
                            </td>
                            <td>{doc.downloads ? doc.downloads : 0} ({doc.uniqueDownloads ? doc.uniqueDownloads : 0} unique)</td>
                            <td>
                                <Link key={doc.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/partner-offers/" + doc.id}>Details</Link>
                                <Link key={doc.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/partner-offers/edit/" + doc.id}>Edit</Link>
                                {doc.fileUploaded && <button style={{ float: "right" }} onClick={() => { this.downloadFile(doc.id, doc.name + "." + doc.fileType); return false; }} className="btn mr-2 btn-outline-dark mt-2 mb-2" ><span className="pcoded-micon"><i className="feather icon-download"></i></span></button>}
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No offers to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }


    async downloadFile(id, name) {

        var data = await PartnerOfferClient.GetDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        await this.update();

    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <userContext.Consumer>
                            {({ user, logoutUser }) => {
                                return (<CardHeader>
                            <Row>
                                <Col>
                                    <h5>Partner offers ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of offers.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                        {user.permissions && user.permissions.includes("partner-offer.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">

                                            {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                            {this.state.exporting && <Spinner animation="border" />}

                                        </button>}

                                        <Link style={{ float: "right" }} to="/partner-offers/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Add</Link>
                                    </div>
                                </Col>
                            </Row>

                                </CardHeader>);
                            }}
                        </userContext.Consumer>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <PartnerOfferCategorySelector
                                        placeholder="Category"
                                        value={this.state.categoryId}
                                        onChange={this.handleCategoryIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="draft">Unpublished</option>
                                            <option value="published">Published</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.status || this.state.categoryId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardHeader>
                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="showExpired"
                                                type="checkbox"
                                                checked={this.state.showExpired}
                                                onChange={this.handleShowExpired} />  Show expired
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new ListQueryParams()
                .Paginate(skip, take).Sort(this.state.sort, this.state.direction);

            if (this.state.status) {
                params.WithParam("status", this.state.status);
            }

            if (this.state.categoryId) {
                params.WithParam("categoryId", this.state.categoryId.value);
            }

            if (this.state.search) {
                params.WithParam("q", this.state.search);
            }

            if (this.state.showExpired) {
                params.WithParam("includeExpiredOffers", this.state.showExpired);
            }

            var response = await PartnerOfferClient.List(params);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }



    async exportToCSV() {

        this.setState({ exporting: true });
        var params = new ListQueryParams().Sort(this.state.sort, this.state.direction);

        if (this.state.status) {
            params.WithParam("status", this.state.status);
        }

        if (this.state.categoryId) {
            params.WithParam("categoryId", this.state.categoryId.value);
        }

        if (this.state.search) {
            params.WithParam("q", this.state.search);
        }

        if (this.state.showExpired) {
            params.WithParam("includeExpiredOffers", this.state.showExpired);
        }

        var blob = await PartnerOfferClient.Export(params.Paginate(0, 1)
            .Search(this.state.search));

        FileSaver.saveAs(blob, "partner-offers.csv");

        this.setState({ exporting: false });
    }
}
