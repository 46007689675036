import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

//******************************
// BUILDINGS
//******************************
export class HengyiBuildingClient {

    static async List(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/buildings" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, parcelEnabled: Boolean, parcelDeliveryCost: number, parkingEnabled: Boolean, valetProvider: string, valetProviderAPIKey: string, valetRequestItemCost: number, valetParkCarCost: number) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "parcelEnabled": parcelEnabled,
                "parcelDeliveryCost": parcelDeliveryCost,
                "parkingEnabled": parkingEnabled,
                "valetProvider": valetProvider,
                "valetProviderAPIKey": valetProviderAPIKey,
                "valetRequestItemCost": valetRequestItemCost,
                "valetParkCarCost": valetParkCarCost
            })
        }));
    }

    static async Update(id: string, name: string,
        parcelEnabled: Boolean, parcelDeliveryCost: number, parkingEnabled: Boolean,
        valetProvider: string, valetProviderAPIKey: string, valetRequestItemCost: number,
        valetParkCarCost: number, parcelHoldingLength: number) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "parcelEnabled",
                "value": parcelEnabled
            }, {
                "op": "replace",
                "path": "parcelDeliveryCost",
                "value": parcelDeliveryCost
            }, {
                "op": "replace",
                "path": "parkingEnabled",
                "value": parkingEnabled
            }, {
                "op": "replace",
                "path": "valetProvider",
                "value": valetProvider
            }, {
                "op": "replace",
                "path": "valetProviderAPIKey",
                "value": valetProviderAPIKey
            }, {
                "op": "replace",
                "path": "valetRequestItemCost",
                "value": valetRequestItemCost
            }, {
                "op": "replace",
                "path": "valetParkCarCost",
                "value": valetParkCarCost
            }, {
                "op": "replace",
                "path": "parcelHoldingLength",
                "value": parcelHoldingLength
            }])
        }));
    }

    static async UpdateInductionDocument(id: string, inductionEnabled: Boolean, inductionDocumentId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "inductionEnabled",
                "value": inductionEnabled
            }, {
                "op": "replace",
                "path": "inductionDocumentId",
                "value": inductionDocumentId
            }])
        }));
    }

    static async UpdateEVChargeSettings(id: string, evChargerEnabled: Boolean, evChargeProvider: string, fixedChargeFee: number, evPowerCostPerUnit: number) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "evChargerEnabled",
                "value": evChargerEnabled
            }, {
                "op": "replace",
                "path": "evChargeProvider",
                "value": evChargeProvider
            }, {
                "op": "replace",
                "path": "fixedChargeFee",
                "value": fixedChargeFee
            }, {
                "op": "replace",
                "path": "evPowerCostPerUnit",
                "value": evPowerCostPerUnit
            }])
        }));
    }

    static async UpdateExivoSettings(id: string, exivoEnabled: Boolean, exivoSiteId: string, exivoApiKey: string, exivoApiSecret: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "exivoEnabled",
                "value": exivoEnabled
            }, {
                "op": "replace",
                "path": "exivoSiteId",
                "value": exivoSiteId
            }, {
                "op": "replace",
                "path": "exivoApiKey",
                "value": exivoApiKey
            }, {
                "op": "replace",
                "path": "exivoApiSecret",
                "value": exivoApiSecret
            }])
        }));
    }

    static async UpdateValetProviderSettings(id: string, valetProvider: string, valetProviderAPIKey: string, valetPassword: string, valetUserName: string, valetDefaultParkTypeId?: string, valetDefaultParkTypeName?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/buildings/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "valetProvider",
                "value": valetProvider
            }, {
                "op": "replace",
                "path": "valetProviderAPIKey",
                "value": valetProviderAPIKey
            }, {
                "op": "replace",
                "path": "valetPassword",
                "value": valetPassword
            }, {
                "op": "replace",
                "path": "valetUserName",
                "value": valetUserName
            }, {
                "op": "replace",
                "path": "valetDefaultParkTypeId",
                "value": valetDefaultParkTypeId
            }, {
                "op": "replace",
                "path": "valetDefaultParkTypeName",
                "value": valetDefaultParkTypeName
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/buildings/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}