import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Nav, NavItem, NavLink, Badge, TabPane, TabContent, CardBody, Col, Row, InputGroup, Input, ButtonGroup, Button, FormGroup } from 'reactstrap';
import Moment from 'react-moment';
import { userContext } from '../../userContext';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiChargeClient } from '../infrastructure/HengyiClient/Charges';
import NumberFormat from 'react-number-format';
import { HengyiInvoiceClient } from '../infrastructure/HengyiClient/Invoices';
import classnames from 'classnames';
import { ChargesTable } from '../Charges/ChargesTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class InvoicesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, activeTab: '1',
            showAddNote: false, includeDeleted: false };
        this.toggle = this.toggle.bind(this);
        this.notifySuccessCopy = this.notifySuccessCopy.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiInvoiceClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    notifySuccessCopy() {
        toast("📋 Link copied to clipboard");
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/invoices" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <h5>
                                <Link to="/invoices" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                {this.state.data && <span>Invoice</span>}
                                {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                            </h5>
                        </CardHeader>
                    </Card>


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Charges
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h6>Invoice details</h6>
                                                <hr />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pr-2">Invoice #:</td>
                                                            <td>
                                                                {this.state.data.invoiceNumber && this.state.data.invoiceNumber}
                                                                {!this.state.data.invoiceNumber && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Status:</td>
                                                            <td>
                                                                {this.state.data.status === "draft" && <Badge color="primary">Draft</Badge>}
                                                                {this.state.data.status === "open" && <Badge color="primary">Open</Badge>}
                                                                {this.state.data.status === "paid" && <Badge color="success">Paid</Badge>}
                                                                {this.state.data.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                                                {this.state.data.status === "void" && <Badge color="secondary">Voided</Badge>}
                                                                {this.state.data.status === "deleted" && <Badge color="secondary">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Amount:</td>
                                                            <td>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(this.state.data.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">User:</td>
                                                            <td>
                                                                {this.state.data.user && <Link key={this.state.data.user.id + "-view"} to={"/users/" + this.state.data.user.id}>{this.state.data.user.name} {this.state.data.user.surname}</Link>}
                                                                {!this.state.data.user && <i>Anyone at the space</i>}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pr-2">Created:</td>
                                                            <td>
                                                                <Moment utc local format="ddd, DD/MM h:mma" date={this.state.data.created} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                            <Col>
                                                <h6>Links</h6>
                                                <hr />
                                                {this.state.links &&
                                                    <FormGroup>
                                                        <strong>Hosted invoice</strong>
                                                        <InputGroup>
                                                            <Input type="text" disabled value={this.state.links.hostedInvoice} />
                                                            <ButtonGroup className="input-group-append">
                                                                <CopyToClipboard
                                                                    onCopy={this.notifySuccessCopy}
                                                            text={this.state.links.hostedInvoice}>
                                                                    <button disabled={this.state.loading} className="btn btn-outline-dark" >
                                                                        <span className="pcoded-micon"><i className="feather icon-copy"></i></span>
                                                                    </button>
                                                                </CopyToClipboard>
                                                            </ButtonGroup>
                                                        </InputGroup>
                                                    </FormGroup>}
                                                {this.state.links && <FormGroup>
                                                    <strong>PDF Download</strong>
                                                    <InputGroup>
                                                        <Input type="text" disabled value={this.state.links.pdfLink} />
                                                        <ButtonGroup className="input-group-append">

                                                            <CopyToClipboard
                                                                onCopy={this.notifySuccessCopy}
                                                                text={this.state.links.pdfLink}>
                                                                <button disabled={this.state.loading} className="btn btn-outline-dark" >
                                                                    <span className="pcoded-micon"><i className="feather icon-copy"></i></span>
                                                                </button>
                                                            </CopyToClipboard>
                                                        </ButtonGroup>
                                                    </InputGroup> </FormGroup>}

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>}
                        </TabPane>

                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ChargesTable title="Invoice charges" description="List all charges this invoice covers" invoiceId={this.props.match.params.id} />}
                        </TabPane>

                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <div>

                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this invoice</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        if (!fields.message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }

                                                        var response = await HengyiInvoiceClient.AddNote(this.props.match.params.id, fields.message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                            <Field as="textarea" name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("invoices.modify") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>
                    </TabContent>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiInvoiceClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiInvoiceClient.Get(id);
            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiInvoiceClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data, loading: false, links: (await HengyiInvoiceClient.GetLinks(id)).data,
                    notes: notes.data });
            }
        }
    }
}
