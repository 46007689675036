import React, { Component } from 'react';
import { HengyiParkedVehicleClient } from '../infrastructure/HengyiClient/ParkedVehicles';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { ValetRequestsTable } from '../ValetRequests/ValetRequestsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export class ParkedVehiclesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/parked-vehicles" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/parked-vehicles" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Parked Vehicle</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                        {this.state.data.status === "parked" && <Badge color="primary" className="ml-2">Parked</Badge>}
                                        {this.state.data.status === "readyForPickup" && <Badge color="info" className="ml-2">Ready for collection</Badge>}
                                        {this.state.data.status === "collected" && <Badge color="success" className="ml-2">Collected</Badge>}
                                        {this.state.data.status === "cancelled" && <Badge color="secondary" className="ml-2">Cancelled</Badge>}

                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.vehicle.licensePlate}</span>}</span>

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/parked-vehicles/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    {!this.state.loading && this.state.data && this.state.data.status === "parked" &&
                        <div>
                            <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Has this vehicle been collected?</strong> Mark this parked vehicle as collected and no longer with valet</p>
                                    </Col>
                                    <Col md="2">
                                        <Link style={{ float: "right" }} to={"/parked-vehicles/" + this.state.data.id + "/collected"} className="btn mr-2 mt-2 mb-2 btn-info">Vehicle collected</Link>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        </div>
                    }

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Valet Requests
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Ticket number:</td>
                                                <td>
                                                    {this.state.data.ticketNumber && this.state.data.ticketNumber}
                                                    {!this.state.data.ticketNumber && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Provider:</td>
                                                <td>
                                                    {this.state.data.provider && this.state.data.provider}
                                                    {!this.state.data.provider && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Location:</td>
                                                <td>
                                                    {this.state.data.location && this.state.data.location}
                                                    {!this.state.data.location && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Billing:</td>
                                                <td>
                                                    ${this.state.data.balanceRemaining + this.state.data.balancePaid}
                                                    {this.state.data.balanceRemaining + this.state.data.balancePaid > 0 && ("($" + this.state.data.balancePaid + ")")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Parked:</td>
                                                <td>
                                                    {this.state.data.parked && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.parked}</Moment>}
                                                    {!this.state.data.parked && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Picked up:</td>
                                                <td>
                                                    {this.state.data.pickedUp && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.pickedUp}</Moment>}
                                                    {!this.state.data.pickedUp && <i>Not picked up</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "parked" && <Badge color="primary">Parked</Badge>}
                                                    {this.state.data.status === "readyForPickup" && <Badge color="info">Ready for collection</Badge>}
                                                    {this.state.data.status === "collected" && <Badge color="success">Collected</Badge>}
                                                    {this.state.data.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Vehicle:</td>
                                                <td>
                                                    {this.state.data.vehicle && <Link key={this.state.data.vehicle.id + "-view"} to={"/vehicles/" + this.state.data.vehicle.id}>{this.state.data.vehicle.licensePlate} - {this.state.data.vehicle.colour} {this.state.data.vehicle.make} {this.state.data.vehicle.model}</Link>}
                                                    {!this.state.data.vehicle && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Building:</td>
                                                <td>
                                                    {this.state.data.building && <Link key={this.state.data.building.id + "-view"} to={"/buildings/" + this.state.data.building.id}>{this.state.data.building.name}</Link>}
                                                    {!this.state.data.building && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {this.state.data.notes && <div>
                                        <h6 style={{marginTop: "30px"}}>Notes</h6>
                                        <hr />
                                        <p>{this.state.data.notes}</p>
                                    </div>}
                                </CardBody>
                            </Card>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <ActivityTable title="Parked vehicle activity" description="List all activity for this parked vehicle" entityId={this.props.match.params.id} />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <ValetRequestsTable title="Valet request activity" description="List all requests for this parked vehicle" parkedVehicleId={this.props.match.params.id} />}
                        </TabPane>
                    </TabContent>


                    {!this.state.loading && this.state.data && this.state.data.status === "parked" &&
                        <div className="alert alert-info" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Made a mistake?</strong> Cancel this parked car request.</p>
                                </Col>
                                <Col md="2">
                                    <Link style={{ float: "right" }} to={"/parked-vehicles/" + this.props.match.params.id + "/cancel"} className="btn mr-2 mt-2 mb-2 btn-info">Cancel parked vehicle</Link>
                                </Col>
                            </Row>

                        </div>}
                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiParkedVehicleClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
