import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Input, InputGroup, ButtonGroup  } from 'reactstrap';
import { UserLink } from '../Users/UserLink';
import { TableHeader } from '../infrastructure/TableHeader';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';
import { FolderSelector } from '../infrastructure/FolderSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class Documents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, search: "", authenticated: true,
            userStatus: "", folderId: "",
            sort: "name", direction: "asc",
            apartmentId: ""
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFolderIdChange = this.handleFolderIdChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }


    handleFolderIdChange(name, value) {

        this.setState({
            folderId: value
        }, () => this.update());
    }


    handleApartmentIdChange(name, value) {
        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    async clear() {

        this.setState({
            folderId: "",
            search: "",
            apartmentId: "",
            skip: 0
        }, () => this.populateData(this.state.skip, this.state.take));

    }


    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Name", sort: "name" },
                        { name: "Status", sort: "status" },
                        { name: "Uploaded By", sort: "createdBy.name" },
                        { name: "Folder", sort: "parent.name" },
                        { name: "Downloads", sort: "downloads" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(doc =>
                        <tr key={doc.id}>
                            <td>{doc.name}</td>
                            <td>
                                {doc.status === "pending" && <Badge color="primary">Pending</Badge>}
                                {doc.status === "approved" && <Badge color="success">Active</Badge>}
                                {!doc.status && <i>Not specified</i>}
                            </td>
                            <td>
                                {doc.createdBy && <UserLink user={doc.createdBy} />}
                                {!doc.createdBy && <i>Not specified</i>}
                            </td>
                            <td>{!doc.parentId && <i>None</i>}
                                {doc.parentId && <span>{doc.parentName}</span>}
                            </td>
                            <td>{doc.downloads}</td>
                            <td>
                                <Link key={doc.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/documents/" + doc.id}>Details</Link>
                                <Link key={doc.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/documents/edit/" + doc.id}>Edit</Link>
                                <button style={{ float: "right" }} onClick={() => { this.downloadFile(doc.id, doc.name + "." + doc.fileType); return false; }} className="btn mr-2 btn-outline-dark mt-2 mb-2" ><span className="pcoded-micon"><i className="feather icon-download"></i></span></button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        );
    }


    async downloadFile(id, name) {

        var data = await HengyiDocumentClient.GetDocumentDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        await this.update();
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Documents ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of uploaded documents.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/documents/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Upload document</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <ApartmentSelector
                                        placeholder="Space"
                                        value={this.state.apartmentId}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <FolderSelector
                                        placeholder="Folder"
                                        value={this.state.folderId}
                                        onChange={this.handleFolderIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.status || this.state.folderId || this.state.apartmentId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new ListQueryParams()
                .Paginate(skip, take).Sort(this.state.sort, this.state.direction);

            if (this.state.folderId) {
                params.WithParam("parentId", this.state.folderId.value);
            }

            if (this.state.apartmentId) {
                params.WithParam("apartmentId", this.state.apartmentId.value);
            }

            if (this.state.search) {
                params.WithParam("q", this.state.search);
            }

            var response = await HengyiApi.GetListFiles(params);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }


        }
    }
}
