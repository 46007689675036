import React, { Component } from 'react';
import { BookingsTable } from './BookingsTable';

export class Bookings extends Component {

    render() {

        return (<BookingsTable location={this.props.location} />);
    }

}
