import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class MessageThreadQueryParams extends ListQueryParams {

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithCampaign(value: string) {
        this.WithParam("campaignId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithUnread(value: string) {
        this.WithParam("unreadFor", value);
        return this;
    }

    WithNoneOfStatus(value: string) {
        this.WithParam("noneOfstatus", value);
        return this;
    }

    ExcludeOutboundCampaignsWithNoReply() {
        this.WithParam("ExcludeOutboundCampaignsWithNoReply", true);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiMessageThreadClient {

    static async List(params: MessageThreadQueryParams) {

        var query = (await GetAPIDomain()) + "/message-threads" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async MarkAsSeen(id: string) {

        var query = (await GetAPIDomain()) + "/message-threads/" + id + "/mark-as-seen";

        return await ApiResponse.Create(await fetch(query, {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                direction: "toConcierge"
            })
        }));
    }

    static async MarkAsUnread(id: string) {


        var query = (await GetAPIDomain()) + "/message-threads/" + id + "/mark-as-unread";

        return await ApiResponse.Create(await fetch(query, {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                direction: "toConcierge"
            })
        }));
    }


    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/message-threads/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/message-threads/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

}