import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiFloorClient } from '../infrastructure/HengyiClient/Floors';

export class FloorsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    ordinal: this.state.ordinal
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiFloorClient.Update(this.props.match.params.id, this.state.name, this.state.ordinal);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.data.validationErrors.map(error => {
                                console.log(error);
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }
                }}
            >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="ordinal">Ordinal number</label>
                                    <input name="ordinal" id="ordinal" type="number" className={'form-control' + (errors.ordinal && touched.ordinal ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="ordinal" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                    </Row>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/floors/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update floor details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Floor details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/floors/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var item = await HengyiFloorClient.Get(this.props.match.params.id);


            console.log(item);

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    data: item.data,
                    name: item.data.name,
                    ordinal: item.data.ordinal,
                    loading: false,
                    init: true
                }, () => {
                    document.getElementById("name").value = item.data.name;
                    document.getElementById("ordinal").value = item.data.ordinal;
                });

            }
        }
    }
}
