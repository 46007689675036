import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { HengyiPetClient, PetQueryParams } from '../infrastructure/HengyiClient/Pets';
import { UserLink } from '../Users/UserLink';
import { TableHeader } from '../infrastructure/TableHeader';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class Pets extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", petType: "", sort: "name", direction: "asc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePetTypeChange = this.handlePetTypeChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    async clear() {

        this.setState({
            search: "",
            petType: "",
            apartmentId: "",
            skip: 0
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handlePetTypeChange(evt) {
        this.setState({
            petType: evt.target.value
        }, () => this.update());
    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Type", sort: "type" },
                        { name: "Name", sort: "name" },
                        { name: "Breed", sort: "breed" },
                        { name: "Space", sort: "apartment.name" },
                        { name: "Primary contact", sort: "user.name" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />

                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.type === "dog" && "Dog"}
                                {item.type === "cat" && "Cat"}
                                {item.type === "bird" && "Bird"}
                                {item.type === "other" && item.petType}</td>
                            <td>
                                {item.name && item.name}
                                {!item.name && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.breed && item.breed}
                                {!item.breed && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.apartment && <Link key={item.apartment.id + "-view"} to={"/apartments/" + item.apartment.id}>{item.apartment.name}</Link>}
                                {!item.apartment && <i>Not specified</i>}
                            </td>
                            <td>
                                <UserLink user={item.user} missingText="Anyone at apartment" hideApartments={true} />
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/pets/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/pets/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No pets to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Pets ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of pets in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/pets/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <ApartmentSelector
                                        placeholder="Space"
                                        value={this.state.apartmentId}
                                        onChange={this.handleApartmentIdChange}
                                        hideLabel={true} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.petType} onChange={this.handlePetTypeChange} >
                                            <option value="">All pet types</option>
                                            <option value="dog">Dogs</option>
                                            <option value="cat">Cats</option>
                                            <option value="bird">Birds</option>
                                            <option value="other">Other</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.petType || this.state.apartmentId) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiPetClient.List(new PetQueryParams()
                .WithPetType(this.state.petType)
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value:null)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
