import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class TenantImageQueryParams extends ListQueryParams {

    WithType(value: string) {
        this.WithParam("type", value);
        return this;
    }

}

//******************************
// PETS
//******************************
export class HengyiTenantImageClient {

    static async List(params: TenantImageQueryParams) {

        var query = (await GetAPIDomain()) + "/tenants/current/images" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tenants/current/images/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(imageId: string, type: string, filename: string) {

        var request: any = {
            "imageId": imageId,
            "type": type,
            "filename": filename
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tenants/current/images/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, type: string, fileName: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tenants/current/images/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "type",
                "value": type
            }, {
                    "op": "replace",
                    "path": "fileName",
                "value": fileName
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/tenants/current/images/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

}