import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ApartmentQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithAccessibility(value: Boolean) {
        this.WithParam("hasAccessibilityRequirements", value);
        return this;
    }
}



export class HengyiApartmentClient {

    static async List(params: ApartmentQueryParams) {

        var query = (await GetAPIDomain()) + "/apartments" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Export(params: ApartmentQueryParams) {

        var query = (await GetAPIDomain()) + "/apartments/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async AddUser(id: string, userId: string, relationship: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id + "/add-user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId,
                "relationship": relationship
            })
        }));
    }

    static async RemoveUser(id: string, userId: string, relationship: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id + "/remove-user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId
            })
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            headers: await GetAPIHeaders()
        }));

    }


    static async GetOValetTenants(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id +"/ovalet/tenants", {
            headers: await GetAPIHeaders()
        }));

    }


    static async UpdateAccessibilityRequirements(id: string, hasAccessibilityRequirements?: Boolean, accessibilityRequirement?: string,
        accessibilityOrientationRequired?: Boolean, accessibilityMedicalRequired?: string) {

        var updates = [{
            "op": "replace",
            "path": "hasAccessibilityRequirements",
            "value": hasAccessibilityRequirements
        }, {
            "op": "replace",
            "path": "accessibilityRequirement",
            "value": accessibilityRequirement
        }, {
            "op": "replace",
            "path": "accessibilityOrientationRequired",
            "value": accessibilityOrientationRequired
        }, {
            "op": "replace",
            "path": "accessibilityMedicalRequired",
            "value": accessibilityMedicalRequired
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));

    }

    static async Update(id: string,
        name: string, description: string, apartmentTypeId?: string,
        powerMeterNumber?: string, waterMeterNumber?: string,
        lettingAgentName?: string, lettingAgentBusiness?: string, lettingAgentMobile?: string, lettingAgentEmail?: string,
        propertyTitleNumber?: string, settled?: Boolean,
        hasAccessibilityRequirements?: Boolean, accessibilityRequirement?: string,
        accessibilityOrientationRequired?: Boolean, accessibilityMedicalRequired?: string, userId?: string) {

        var updates = [{
            "op": "replace",
            "path": "propertyTitleNumber",
            "value": propertyTitleNumber
        }, {
            "op": "replace",
            "path": "hasAccessibilityRequirements",
            "value": hasAccessibilityRequirements
        }, {
            "op": "replace",
            "path": "accessibilityRequirement",
            "value": accessibilityRequirement
        }, {
            "op": "replace",
            "path": "accessibilityOrientationRequired",
            "value": accessibilityOrientationRequired
        }, {
            "op": "replace",
            "path": "accessibilityMedicalRequired",
            "value": accessibilityMedicalRequired
        }, {
            "op": "replace",
            "path": "settled",
            "value": settled
        }, {
            "op": "replace",
            "path": "description",
            "value": description
        }, {
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
            "path": "powerMeterNumber",
            "value": powerMeterNumber
        }, {
            "op": "replace",
            "path": "waterMeterNumber",
            "value": waterMeterNumber
        }, {
            "op": "replace",
            "path": "lettingAgentName",
            "value": lettingAgentName
        }, {
            "op": "replace",
            "path": "lettingAgentBusiness",
            "value": lettingAgentBusiness
        }, {
            "op": "replace",
            "path": "lettingAgentMobile",
            "value": lettingAgentMobile
            }, {
                "op": "replace",
                "path": "lettingAgentEmail",
                "value": lettingAgentEmail
            }, {
                "op": "replace",
                "path": "userId",
                "value": userId
            }];

        if (apartmentTypeId != null) {
            updates.push({
                "op": "replace",
                "path": "typeId",
                "value": apartmentTypeId
            });
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }


    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/apartments/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/apartments/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/apartments/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}