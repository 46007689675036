import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiParcelClient } from '../infrastructure/HengyiClient/Parcels';

export class ParcelSetCollected extends Component {

    constructor(props) {
        super(props);
        this.state = { init: true, loading: false, authenticated: true, next: false, handoverNotes:"" };
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    handoverNotes: this.state.handoverNotes
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiParcelClient.SetParcelAsCollected(this.props.match.params.id, fields.handoverNotes);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                console.log(error);
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }
                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                    <label htmlFor="handoverNotes">Handover notes (e.g. who collected)</label>
                                    <Field name="handoverNotes" type="text" component="textarea"  className="form-control" />
                                    <ErrorMessage name="handoverNotes" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SET COLLECTED"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/parcels/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Set parcel as collected
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Enter any notes about the handover</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/parcels/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}
