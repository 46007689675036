import React, { Component } from 'react';
import { Collapse, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';
import { userContext } from '../../userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faBarcode, faDollarSign, faCar, faParking, faCubes, faCube, faBuilding, faThList, faPaw, faClipboardList, faCarBattery, faCalendarAlt, faReceipt, faBook } from '@fortawesome/free-solid-svg-icons'

export class SideBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {

        return (
            <userContext.Consumer>
                {({ user, logoutUser, features }) => {

                    return (<div>
                        <NavbarToggler className="d-block d-md-none bg-dark sidebar" onClick={this.toggleNavbar}>
                            <span style={{ color: "white" }}>View menu</span>
                        </NavbarToggler>

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar style={{ width: "100%", minWidth: "200px" }}>

                            <nav className="sidebar pcoded-navbar menu-light" style={{ paddingTop: "10px" }}>
                                <div className="sidebar-sticky" style={{ width: "100%", minWidth: "200px" }}>
                                    <ul className="nav flex-column pcoded-inner-navbar">
                                       
                                        {user.permissions && user.permissions.includes("dashboard.view") && <Route exact path="/">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/">
                                                    <span className="pcoded-micon"><i className="feather icon-pie-chart"></i></span>
                                                    <span className="pcoded-mtext">Dashboard</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("campaigns.view.all") && <li className="nav-item pcoded-menu-caption">
                                            <label>Message center</label>
                                        </li>}

                                        {user.permissions && user.permissions.includes("campaigns.view.all") && <Route path="/campaigns">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/campaigns">
                                                    <span className="pcoded-micon"><i className="feather icon-volume-1"></i></span>
                                                    <span className="pcoded-mtext">Campaigns</span>
                                                </Link>
                                            </li>}
                                        </Route>}
                                        {features && features.includes("conversations") && user.permissions && user.permissions.includes("message-threads.view.all") && <Route path="/conversations">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/conversations">
                                                    <span className="pcoded-micon"><i className="feather icon-message-circle"></i></span>
                                                    <span className="pcoded-mtext">Conversations</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        <li className="nav-item pcoded-menu-caption">
                                            <label>CRM</label>
                                        </li>

                                        {features && features.includes("parcels") && user.permissions && user.permissions.includes("parcels.view.all") && <Route path="/parcels">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/parcels">
                                                    <span className="pcoded-micon"><i className="feather icon-package"></i></span>
                                                    <span className="pcoded-mtext">Parcels</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("tickets") && user.permissions && user.permissions.includes("tickets.view.all") && <Route path="/tickets">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/tickets">
                                                    <span className="pcoded-micon"><i className="feather icon-file"></i></span>
                                                    <span className="pcoded-mtext">Tickets</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("incidents") && user.permissions && user.permissions.includes("incidents.view.all") && <Route path="/incidents">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/incidents">
                                                    <span className="pcoded-micon"><i className="feather icon-alert-triangle"></i></span>
                                                    <span className="pcoded-mtext">Incidents</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("hazards") && user.permissions && user.permissions.includes("hazards.view.all") && <Route path="/hazards">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/hazards">
                                                    <span className="pcoded-micon"><i className="feather icon-alert-octagon"></i></span>
                                                    <span className="pcoded-mtext">Hazards</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("valet") && user.permissions && user.permissions.includes("valet.requests.view.all") && <Route path="/valet-requests">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/valet-requests">
                                                    <span className="pcoded-micon"><i className="feather icon-tag"></i></span>
                                                    <span className="pcoded-mtext">Valet Requests</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("handover") && user.permissions && user.permissions.includes("handover.notes.use") && <Route path="/handover-notes">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/handover-notes">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faBook} /></span>
                                                    <span className="pcoded-mtext">Handover notes</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("bookings") && user.permissions && user.permissions.includes("booking.view.all") && <Route path="/bookings">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/bookings">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                    <span className="pcoded-mtext">Bookings</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("notices") && user.permissions && user.permissions.includes("notices.view.all") && <Route path="/notices">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/notices">
                                                    <span className="pcoded-micon"><i className="feather icon-bookmark"></i></span>
                                                    <span className="pcoded-mtext">Notices</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("partner-offers") && user.permissions && user.permissions.includes("partner-offer.view.all") && <Route path="/partner-offers">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/partner-offers">
                                                    <span className="pcoded-micon"><i className="feather icon-aperture"></i></span>
                                                    <span className="pcoded-mtext">Partner offers</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("ev-charging") && user.permissions && user.permissions.includes("ev.charge.view.all") && <Route path="/ev-charge-sessions">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/ev-charge-sessions">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faCarBattery} /></span>
                                                    <span className="pcoded-mtext">EV Charge Sessions</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("valet") && user.permissions && user.permissions.includes("parked.vehicles.view.all") && <Route path="/parked-vehicles">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/parked-vehicles">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faParking} /></span>
                                                    <span className="pcoded-mtext">Parked Vehicles</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("events") && user.permissions && user.permissions.includes("events.view.all") && <Route path="/events">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/events">
                                                    <span className="pcoded-micon"><i className="feather icon-calendar"></i></span>
                                                    <span className="pcoded-mtext">Events</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("facilities") && user.permissions && user.permissions.includes("facility.view.all") && <Route path="/facilities">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/facilities">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faBuilding} /></span>
                                                    <span className="pcoded-mtext">Facilities</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("apartment.view.owners") && <Route path="/apartments">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/apartments">
                                                    <span className="pcoded-micon"><i className="feather icon-home"></i></span>
                                                    <span className="pcoded-mtext">Spaces</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("documents") && user.permissions && user.permissions.includes("document.view.all") && <Route path="/documents">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/documents">
                                                    <span className="pcoded-micon"><i className="feather icon-file-text"></i></span>
                                                    <span className="pcoded-mtext">Documents</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("documents") && user.permissions && user.permissions.includes("document.view.all") && <Route path="/folders">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/folders">
                                                    <span className="pcoded-micon"><i className="feather icon-folder"></i></span>
                                                    <span className="pcoded-mtext">Folders</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("inductions") && user.permissions && user.permissions.includes("users.inductions.view.all") && <Route path="/inductions">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/inductions">
                                                    <span className="pcoded-micon"><i className="feather icon-feather"></i></span>
                                                    <span className="pcoded-mtext">Completed inductions</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        <li className="nav-item pcoded-menu-caption">
                                            <label>User profile</label>
                                        </li>

                                        {features && features.includes("billing") && user.permissions && user.permissions.includes("charges.view.all") && <Route path="/charges">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/charges">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                    <span className="pcoded-mtext">Charges</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("billing") && user.permissions && user.permissions && user.permissions.includes("invoices.view.all") && <Route path="/invoices">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/invoices">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faReceipt} /></span>
                                                    <span className="pcoded-mtext">Invoices</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("swipe-cards") && user.permissions && user.permissions.includes("swipe.cards.view.all") && <Route path="/swipe-cards">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/swipe-cards">
                                                    <span className="pcoded-micon"><i className="feather icon-credit-card"></i></span>
                                                    <span className="pcoded-mtext">Swipe Cards</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("users.view.all") && <Route path="/users">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/users">
                                                    <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                                                    <span className="pcoded-mtext">Users</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("pets") && user.permissions && user.permissions.includes("pet.view.all") && <Route path="/pets">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/pets">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faPaw} /></span>
                                                    <span className="pcoded-mtext">Pets</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("vehicles") && user.permissions && user.permissions.includes("vehicle.view.all") && <Route path="/vehicles">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/vehicles">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faCar} /></span>
                                                    <span className="pcoded-mtext">Vehicles</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("invitations.view") && <Route path="/invitations">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/invitations">
                                                    <span className="pcoded-micon"><i className="feather icon-user-plus"></i></span>
                                                    <span className="pcoded-mtext">Invitations</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && (features.includes("contractors") || features.includes("work-orders")) && <li className="nav-item pcoded-menu-caption">
                                            <label>Contractors</label>
                                        </li>}

                                        {features && features.includes("contractors") && user.permissions && user.permissions.includes("contractors.view.all") && <Route path="/contractors">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/contractors">
                                                    <span className="pcoded-micon"><i className="feather icon-users"></i></span>
                                                    <span className="pcoded-mtext">Contractors</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("work-orders") && user.permissions && user.permissions.includes("work.orders.view.all") && <Route path="/work-orders">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/work-orders">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faClipboardList} /></span>
                                                    <span className="pcoded-mtext">Work Orders</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        <li className="nav-item pcoded-menu-caption">
                                            <label>Settings</label>
                                        </li>

                                        {features && features.includes("billing") && user.permissions && user.permissions && user.permissions.includes("charges.view.all") && <Route path="/billing-codes">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/billing-codes">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faBarcode} /></span>
                                                    <span className="pcoded-mtext">Billing codes</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("billing") && user.permissions && user.permissions && user.permissions.includes("automated.charge.types.view.all") && <Route path="/automated-charge-types">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/automated-charge-types">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faClipboardCheck} /></span>
                                                    <span className="pcoded-mtext">Charge types</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("tickets") && user.permissions && user.permissions.includes("tickets.types.modify") && <Route path="/ticket-types">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/ticket-types">
                                                    <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                                                    <span className="pcoded-mtext">Ticket types</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("roles.view.all") && <Route path="/roles">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/roles">
                                                    <span className="pcoded-micon"><i className="feather icon-lock"></i></span>
                                                    <span className="pcoded-mtext">Roles</span>
                                                </Link>
                                            </li>}
                                        </Route>}


                                        {user.permissions && user.permissions.includes("features.modify") && <Route path="/features">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/features">
                                                    <span className="pcoded-micon"><i className="feather icon-check-circle"></i></span>
                                                    <span className="pcoded-mtext">Features</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("branding.modify") && <Route path="/branding">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/branding">
                                                    <span className="pcoded-micon"><i className="feather icon-feather"></i></span>
                                                    <span className="pcoded-mtext">Branding</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        <li className="nav-item pcoded-menu-caption">
                                            <label>Building</label>
                                        </li>

                                        {user.permissions && user.permissions.includes("apartment.view.owners") && <Route path="/apartment-types">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/apartment-types">
                                                    <span className="pcoded-micon"><i className="feather icon-grid"></i></span>
                                                    <span className="pcoded-mtext">Space Types</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("partner-offers") && user.permissions && user.permissions.includes("partner-offer-categories.modify") && <Route path="/partner-offer-categories">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/partner-offer-categories">
                                                    <span className="pcoded-micon"><i className="feather icon-align-justify"></i></span>
                                                    <span className="pcoded-mtext">Partner Offer Categories</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("storage-units") && user.permissions && user.permissions.includes("storage.units.modify") && <Route path="/storage-units">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/storage-units">
                                                    <span className="pcoded-micon"><i className="feather icon-lock"></i></span>
                                                    <span className="pcoded-mtext">Storage Cages</span>
                                                </Link>
                                            </li>}
                                        </Route>}
                                       

                                        {features && features.includes("car-parks") && user.permissions && user.permissions.includes("car.parks.modify") && <Route path="/car-parks">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/car-parks">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faCube} /></span>
                                                    <span className="pcoded-mtext">Car parks</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("car-parks") && user.permissions && user.permissions.includes("car.parks.modify") && <Route path="/car-park-types">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/car-park-types">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faParking} /></span>
                                                    <span className="pcoded-mtext">Car park types</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {features && features.includes("car-parks") && user.permissions && user.permissions.includes("parking.banks.modify") && <Route path="/parking-banks">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/parking-banks">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faCubes} /></span>
                                                    <span className="pcoded-mtext">Parking banks</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("floor.modify") && <Route path="/floors">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/floors">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faThList} /></span>
                                                    <span className="pcoded-mtext">Floors</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                        {user.permissions && user.permissions.includes("building.modify") && <Route path="/buildings">
                                            {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                <Link className="nav-link" to="/buildings">
                                                    <span className="pcoded-micon"><FontAwesomeIcon icon={faBuilding} /></span>
                                                    <span className="pcoded-mtext">Buildings</span>
                                                </Link>
                                            </li>}
                                        </Route>}

                                    </ul>
                                </div>
                            </nav>
                        </Collapse>
                    </div>)
                }}
            </userContext.Consumer>
        );
    }
}
