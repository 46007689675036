import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiCarParkTypeClient } from '../infrastructure/HengyiClient/CarParkTypes';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { ListQueryParams } from '../infrastructure/fetchHengy';

export class CarParkTypesNew extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: false, authenticated: true, next: false, id: "" };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                description: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var response = await HengyiCarParkTypeClient.Create(this.state.name, this.state.description, fields.buildingId?.value, fields.valetProvider ?? "none");

                if (!response.successful) {

                    response.data.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>
                    <Row>
                        <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                                <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                            </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                                <input name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <SelectField
                        id="buildingId"
                        name="buildingId"
                        label="Building"
                        placeholder="Select building"
                        options={this.state.buildingOptions}
                        value={values.buildingId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.buildingId}
                        error={errors.buildingId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/car-park-types/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New car park type
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new car park classification</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/car-park-types" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
