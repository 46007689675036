import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SchedulesQueryParams, HengyiSchedulesClient } from "../infrastructure/HengyiClient/Schedules";
import Moment from "react-moment";

class ScheduleTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: ""
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        const {
            facilityId,
            eventId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiSchedulesClient.List(new SchedulesQueryParams()
                .WithFacility(facilityId)
                .WithEvent(eventId)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort("created").Descending());

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total
                });
            }
        }
    }

    render() {
        const {
            title,
            description,
            facilityId,
            eventId
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Schedules"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List of schedules for this facility"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                    {facilityId && <Link style={{ float: "right" }} to={"/facilities/" + facilityId + "/schedules/new/"} className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                    {eventId && <Link style={{ float: "right" }} to={"/events/" + eventId + "/schedules/new/"} className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}

                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>

                    {(!facilityId && !eventId) && <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>}

                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                        <th style={{ borderTop: "none" }}>Name</th>
                                        <th style={{ borderTop: "none" }}>Timeslot</th>
                                        <th style={{ borderTop: "none" }}>Capacity</th>
                                        <th style={{ borderTop: "none" }}>Date/ Day</th>
                                        <th style={{ borderTop: "none" }}>Open</th>
                                        <th style={{ borderTop: "none" }}>Close</th>
                                        <th style={{ borderTop: "none" }}>Reminder</th>
                                        <th style={{ borderTop: "none" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map(schedule =>
                                    <tr key={schedule.id}>
                                        <td style={{ textTransform: 'capitalize' }}>{schedule.name}</td>
                                        <td>{schedule.timeslot} mins</td>
                                        <td>{schedule.capacity}</td>
                                        {!schedule.specificDates && <td>
                                            {schedule.daysOfWeek.length === 0 && <i>None</i>}
                                            {schedule.daysOfWeek.map((item, index) =>
                                                <span key={"span-"+item} style={{ textTransform: 'capitalize' }}>{item}{index < (schedule.daysOfWeek.length - 1) && <span>, </span>}</span>)}
                                        </td>}

                                        {schedule.specificDates && <td>
                                            {(!schedule.dates || schedule.dates.length === 0) && <i>None</i>}
                                            {schedule.dates && schedule.dates.map((item, index) =>
                                                <span><Moment utc local format="ddd DD MMM YY" date={item} />{index < (schedule.daysOfWeek.length - 1) && <span>, </span>}</span>)}
                                        </td>}

                                        <td>{schedule.open}</td>
                                        <td>{schedule.close}</td>
                                        <td style={{ textTransform: 'capitalize' }}>{schedule.reminderEnabled.toString()}</td>
                                        <td>
                                            {facilityId && <Link key={schedule.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/facilities/" + facilityId + "/schedules/" + schedule.id}>Details</Link>}
                                            {facilityId && <Link key={schedule.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/facilities/" + facilityId + "/schedules/edit/" + schedule.id}>Edit</Link>}

                                            {eventId && <Link key={schedule.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/events/" + eventId + "/schedules/" + schedule.id}>Details</Link>}
                                            {eventId && <Link key={schedule.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/events/" + eventId + "/schedules/edit/" + schedule.id}>Edit</Link>}
                                        </td>
                                    </tr>
                                    )}

                                {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No schedules to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { ScheduleTable };
