import React, { Component } from "react";
import { ListQueryParams } from "./fetchHengy";
import { PartnerOfferClient } from "./HengyiClient/PartnerOffers";
import { PartnerOfferQueryParams } from "./HengyiClient/PartnerOffers";
import { SelectField } from "./SelectField";

class PartnerOfferSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        const {
            filterCategory,
            name
        } = this.props;

        if (prevProps.filterCategory !== filterCategory)
            await this.loadData();

        if (prevProps.filterCategory !== filterCategory) {
            if (this.state.inputValue && this.state.inputValue.value) {
                var existingPartnerOffer = this.state.partnerOfferOptions.filter(i => i.value === this.state.inputValue.value)[0];
                this.handleChange(name ? name : "partnerOfferId", existingPartnerOffer ? existingPartnerOffer : this.state.partnerOfferOptions[0]);
            } else {
                this.handleChange(name ? name : "partnerOfferId", this.state.partnerOfferOptions[0]);
            }
        }
    }

    async loadData() {

        const {
            filterCategory,
            initialPartnerOfferId,
            displayFormat,
            name,
            initComplete,
            filterStatus
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new PartnerOfferQueryParams();
        if (filterCategory)
            query = query.WithCategory(filterCategory);

        if (filterStatus)
            query = query.WithStatus(filterStatus);

        var offers = (await PartnerOfferClient.List(query.Paginate(0, 10000))).data;

        if (offers) {
            this.setState({
                partnerOfferOptions: offers.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                if (initialPartnerOfferId && !this.state.init) {
                    this.handleChange(name ? name : "partnerOfferId", this.state.partnerOfferOptions.filter(i => i.value === initialPartnerOfferId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }


        if (initComplete)
            initComplete();
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : "partnerOfferId"}
            name={name ? name : "partnerOfferId"}
            label={label ? label : hideLabel ? null : "Offer"}
            placeholder={placeholder ? placeholder : "Select offer"}
            options={this.state.partnerOfferOptions}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}

export { PartnerOfferSelector };
