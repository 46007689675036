import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiBillingCodeClient } from '../infrastructure/HengyiClient/BillingCodes';

export class BillingCodesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false };
        this.archive = this.archive.bind(this);
        this.restore = this.restore.bind(this);
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/billing-codes" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/billing-codes" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Billing code</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name}</span>}</span>
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/billing-codes/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h6>{this.state.data.name} &nbsp;
                                        {this.state.data.status === "active" && <Badge color="primary">Active</Badge>}
                                        {this.state.data.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                    </h6>
                                    <hr />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "active" && <Badge color="primary">Active</Badge>}
                                                    {this.state.data.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">External Reference:</td>
                                                <td>
                                                    {this.state.data.externalReference && this.state.data.externalReference}
                                                    {!this.state.data.externalReference && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    {!this.state.loading && this.state.data && this.state.data.status === "active" &&
                        <div className="alert alert-danger">
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Code no longer in use?</strong> Archive it to prevent it been applied to any future charges</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/billing-codes" onClick={this.archive} className="btn mr-2 mt-2 mb-2 btn-danger">Archive code</Button>
                                </Col>
                            </Row>
                        </div>}


                    {!this.state.loading && this.state.data && this.state.data.status === "archived" &&
                        <div className="alert alert-info">
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Code still in use?</strong> Restore it to allow this code to be used again</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/billing-codes" onClick={this.restore} className="btn mr-2 mt-2 mb-2 btn-info">Restore code</Button>
                                </Col>
                            </Row>
                        </div>}

                </div>
            );
        }
    }

    async archive() {
        await HengyiBillingCodeClient.Archive(this.props.match.params.id);
        this.setState({ next: true });
    }

    async restore() {
        await HengyiBillingCodeClient.Restore(this.props.match.params.id);
        this.setState({ next: true });
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiBillingCodeClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }
}
