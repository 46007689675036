import React, { Component } from "react";
import { HengyiApi, UserQueryParams } from "./fetchHengy";
import { SelectField } from "./SelectField";

class UserSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        const {
            filterUninvoiced,
            filterPermission,
            filterVehicle,
            filterApartment,
            value,
            name
        } = this.props;

        if (prevProps.filterUninvoiced !== filterUninvoiced ||
            prevProps.filterPermission !== filterPermission ||
            prevProps.filterVehicle !== filterVehicle ||
            prevProps.filterApartment !== filterApartment)
            await this.loadData();

        if (prevProps.filterApartment !== filterApartment) {
            if (this.state.inputValue && this.state.inputValue.value) {
                var existingUser = this.state.userOptions.filter(i => i.value === this.state.inputValue.value)[0];
                this.handleChange(name ? name : "userId", existingUser ? existingUser : this.state.userOptions[0]);
            } else {
                this.handleChange(name ? name : "userId", this.state.userOptions[0]);
            }
        }
    }

    async loadData() {

        const {
            filterUninvoiced,
            filterPermission,
            filterVehicle,
            filterApartment,
            initialUserId,
            displayFormat,
            hideApartmentSuffix,
            name,
            initComplete,
            includeCardSuffix
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;
            var suffix = () => "";

            if (!displayFormat)
                display = (item) => item.name + " " + item.surname;

           
            suffix = (item) => {

                var suffix = "";

                if (!hideApartmentSuffix) {

                    if (item.apartments && item.apartments.length > 0) {

                        suffix = suffix + " - " + item.apartments[0].apartment.name;

                        if (item.apartments.length > 1) {
                            suffix = suffix + " + " + (item.apartments.length - 1) + " more";
                        }

                    }
                }

                if (includeCardSuffix) {
                    suffix = suffix + " (" + (item.hasCreditCardLoaded ? "Card" : "Invoice") + ")"
                }

                return suffix;
            }

            return display(item) + suffix(item);

        }

        var query = new UserQueryParams();
        if (filterUninvoiced)
            query = query.WithUninvoicedCharges(true);
        if (filterPermission)
            query = query.WithPermission(filterPermission);
        if (filterVehicle)
            query = query.WithVehicle(filterVehicle);
        if (filterApartment)
            query = query.WithApartment(filterApartment);

        var userResponse = (await HengyiApi.GetUsers(query.Paginate(0, 10000)));

        if (userResponse.authenticated) {
            var users = userResponse.data;

            if (users) {
                this.setState({
                    userOptions: users.data.map(function (item) {
                        return {
                            value: item.id,
                            label: displayFormatter(item)
                        };
                    })
                }, () => {
                    if (initialUserId && !this.state.init) {

                        this.handleChange(name ? name : "userId", this.state.userOptions.filter(i => i.value === initialUserId)[0]);
                    }
                    this.setState({
                        init: true
                    });
                });
            }

            if (initComplete)
                initComplete();
        }
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : "userId"}
            name={name ? name : "userId"}
            label={label ? label : hideLabel ? null : "User"}
            placeholder={placeholder ? placeholder : "Select user"}
            options={this.state.userOptions}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}

export { UserSelector };
