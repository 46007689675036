import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import { GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiTenantImageClient } from '../infrastructure/HengyiClient/TenantImages';

export class BrandingImageUpload extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                imageType: "logo",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                console.log(fields);

                var imageId = null;

                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await HengyiTenantImageClient.Create(imageId, fields.imageType, fields.file.name);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleBlur, handleChange }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="imageType">Image Type</label>
                            <select name="imageType"
                                style={{ maxWidth: "250px" }}
                                value={values.imageType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.imageType && touched.imageType ? ' is-invalid' : '')}>
                                <option value="logo" label="Logo" />
                                <option value="logoAlt" label="Logo (footer)" />
                                <option value="logoEmail" label="Logo (email)" />
                                <option value="emailBanner" label="Banner (email)" />
                                <option value="residentBanner" label="Resident banner image" />
                                <option value="appBackground" label="Mobile app background image" />
                            </select>
                            <ErrorMessage name="imageType" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="file">Image to upload</label>
                                <input style={{ "overflow": "hidden" }} className="form-control-file" id="file" name="file" type="file" accept={(values.imageType == "logo" || values.imageType == "logoAlt" || values.imageType == "logoEmail") ? "image/png" : "image/jpeg"} onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="form-group">
                            <h6 className="text-left">Image</h6>
                            {!this.state.file && <i>Nothing selected</i>}
                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ minWidth: "250px", width: "100%", marginTop: "20px", objectFit: "contain" }} />
                            </div>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="alert alert-info text-left">
                            <h6>Image tips</h6>
                            <p>
                                Logo
                            </p>
                            <p>
                                A good logo
                                <ul>
                                    <li>Is PNG with a transparent background</li>
                                    <li>High resolution</li>
                                    <li>Logo - Suitable to be used in the top banner</li>
                                    <li>Footer - Suitable to be used in the bottom footer</li>
                                </ul>
                            </p>

                            <p>
                                Banner & background
                            </p>
                            <p>
                                A good background image is one that
                                <ul>
                                    <li>Is JPG format</li>
                                    <li>Does not contain any text</li>
                                    <li>Can be cropped on the edges without impacting the visual quality of the image</li>
                                    <li>Avoids sharp contrast contours which makes the text harder to read</li>
                                </ul>
                            </p>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "UPLOAD"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/branding"} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Upload branding image
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Upoad a new image for the tenant</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/branding" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {

            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
