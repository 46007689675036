import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiSwipeCardClient } from '../infrastructure/HengyiClient/SwipeCards';
import { HengyiApi, UserQueryParams, ListQueryParams } from '../infrastructure/fetchHengy';
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';
import { UserSelector } from '../infrastructure/UserSelector';

export class SwipeCardsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                cardIdentifier: "",
                type: "accessCard",
                userId: "",
                contractorId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.type) {
                    setFieldError("type", "Please specify the type of card");
                    return;
                }

                if (fields.cardAssignment === "resident" && !fields.userId) {
                    setFieldError("userId", "Please select a resident");
                    return;
                }

                if (fields.cardAssignment === "contractor" && !fields.contractorId) {
                    setFieldError("contractorId", "Please select a contractor");
                    return;
                }

                const response = await HengyiSwipeCardClient.Create(
                    this.state.name,
                    this.state.cardIdentifier,
                    fields.type,
                    fields.userId ? fields.userId.value : null,
                    fields.contractorId ? fields.contractorId.value : null);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                                <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>

                        <div className="form-group">
                            <label htmlFor="type">Type</label>
                            <select name="type"
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                <option value="accessCard" label="Access Card" />
                                    <option value="evChargeCard" label="EV Charge Card" />
                                    <option value="key" label="Key" />
                            </select>
                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                        </div>

                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="cardIdentifier">Identifier</label>
                                <input name="cardIdentifier" type="text" className={'form-control' + (errors.cardIdentifier && touched.cardIdentifier ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="cardIdentifier" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <h6>Card assignment</h6>
                <hr />
                <Row>
                    <Col>
                        <div class="form-check">
                            <Field class="form-check-input" type="radio" name="cardAssignment" id="none" value="none" />
                            <label class="form-check-label" for="none">Assiged to no one</label>
                        </div>
                        <div class="form-check">
                            <Field class="form-check-input" type="radio" name="cardAssignment" id="resident" value="resident" />
                            <label class="form-check-label" for="resident">Assigned to resident</label>
                        </div>
                        <div class="form-check">
                            <Field class="form-check-input" type="radio" name="cardAssignment" id="contractor" value="contractor" />
                            <label class="form-check-label" for="contractor">Assigned to contractor</label>
                        </div>
                    </Col>
                </Row>
                <hr />
                {values.cardAssignment && values.cardAssignment === "resident" && <Row>
                    <Col>
                        <UserSelector
                            value={values.userId}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.userId}
                            error={errors.userId} />
                    </Col>
                </Row>}

                {values.cardAssignment && values.cardAssignment === "contractor" && <Row>
                    <Col>
                        <SelectField
                            id="contractorId"
                            name="contractorId"
                            label="Contractor"
                            placeholder="Select contractor"
                            options={this.state.contractorOptions}
                            value={values.contractorId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.contractorId}
                            error={errors.contractorId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        /></Col>
                </Row>}

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/swipe-cards/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New swipe card
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a swipe card in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/swipe-cards" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var contractors = (await HengyiContractorClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                contractorOptions: contractors.map(function (item) { return { value: item.id, label: item.name + " " + item.surname + " - " + item.company }; }),
                loading: false
            });
        }
    }
}
