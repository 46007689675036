import React, { Component } from "react";
import { SelectField } from "./SelectField";
import { ApartmentQueryParams, HengyiApartmentClient } from "./HengyiClient/Apartments";

class ApartmentSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        const {
            filterUser,
            name
        } = this.props;

        if (prevProps.filterUser !== filterUser)
            await this.loadData();

        if (prevProps.filterUser !== filterUser) {
            if (this.state.inputValue && this.state.inputValue.value) {
                var existingApartment = this.state.apartmentOptions.filter(i => i.value === this.state.inputValue.value)[0];
                this.handleChange(name ? name : "apartmentId", existingApartment ? existingApartment : this.state.apartmentOptions[0]);
            } else {
                this.handleChange(name ? name : "apartmentId", this.state.apartmentOptions[0]);
            }
        }
    }

    async loadData() {

        const {
            filterUser,
            initialApartmentId,
            displayFormat,
            name,
            initComplete
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new ApartmentQueryParams();
        if (filterUser)
            query = query.WithUser(filterUser);

        var apartments = (await HengyiApartmentClient.List(query.Paginate(0, 10000))).data;

        if (apartments) {
            this.setState({
                apartmentOptions: apartments.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                if (initialApartmentId && !this.state.init) {
                    this.handleChange(name ? name : "apartmentId", this.state.apartmentOptions.filter(i => i.value === initialApartmentId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }


        if (initComplete)
            initComplete();
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : "apartmentId"}
            name={name ? name : "apartmentId"}
            label={label ? label : hideLabel ? null : "Space"}
            placeholder={placeholder ? placeholder : "Select space"}
            options={this.state.apartmentOptions}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}

export { ApartmentSelector };
