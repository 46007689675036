import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { HengyiCampaignClient } from '../infrastructure/HengyiClient/Campaigns';
import Moment from 'react-moment';
import classnames from 'classnames';
import { CampaignRecipientsTable } from './CampaignRecipientsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'



export class CampaignsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async cancel() {
        await HengyiCampaignClient.Cancel(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }
    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/campaigns" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/campaigns" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Campaign</span>}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Campaign details</span>
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && (this.state.data.status === "draft" || this.state.data.status === "scheduled") && <Link style={{ float: "right" }} to={"/campaigns/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Recipients
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <div>

                                {!this.state.loading && this.state.data &&
                                    (this.state.data.status === "draft" || this.state.data.status === "scheduled" || this.state.data.status === "processing") &&
                                    <div className="alert alert-primary">
                                        <Row>
                                            <Col>
                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>Cancel this campaign to prevent it from being sent, once it is cancelled it cannot be restored.</p>
                                            </Col>
                                            <Col md="2">
                                                <Button style={{ float: "right" }} to="/campaigns" onClick={this.cancel} className="btn mr-2 mt-2 mb-2 btn-info">Cancel</Button>
                                            </Col>
                                        </Row>
                                    </div>}

                                <Card>
                        <CardBody>
                            <h6>Details</h6>
                            <hr />

                            <table>
                                <tbody>
                                    <tr>
                                        <td className="pr-2">Title:</td>
                                        <td>
                                            {this.state.data.title && this.state.data.title}
                                            {!this.state.data.title && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Status:</td>
                                        <td>
                                            {this.state.data.status === "draft" && <Badge color="secondary">Draft</Badge>}
                                            {this.state.data.status === "scheduled" && <Badge color="info">Scheduled</Badge>}
                                            {this.state.data.status === "processing" && <Badge color="info">Processing</Badge>}
                                            {this.state.data.status === "sent" && <Badge color="success">Sent</Badge>}
                                            {this.state.data.status === "cancelled" && <Badge color="danger">Cancelled</Badge>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Scheduled send:</td>
                                        <td>
                                            {this.state.data.scheduledSend && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.scheduledSend}</Moment>}
                                            {!this.state.data.scheduledSend && <i>Not sent</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Sent:</td>
                                        <td>
                                            {this.state.data.sent && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.sent}</Moment>}
                                            {!this.state.data.sent && <i>Not sent</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Content:</td>
                                        <td>
                                            {this.state.data.content && this.state.data.content}
                                            {!this.state.data.content && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="pt-4"><strong>Audience</strong></td></tr>
                                    {this.state.data && this.state.data.audiences && this.state.data.audiences.map(item =>
                                        <tr>
                                            <td style={{ textTransform: 'capitalize' }}>{item.type} {item.userType && " - " + item.userType}</td>
                                            <td>
                                                {item.type === "user" && item.user.name + " " + item.user.surname}
                                                {item.type === "apartment" && item.apartment.name}
                                                {item.type === "apartmentType" && item.apartmentType.name}
                                                {item.type === "building" && item.building.name}
                                                {item.type === "floor" && item.floor.name}
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                                        </table>

                                        {!this.state.loading && this.state.data && this.state.data.partnerOffer && <div className="alert alert-primary" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Attached offer: </strong>{this.state.data.partnerOffer.name}</p>
                                                    </Col>
                                                    <Col md="2">
                                                        <Link style={{ float: "right" }} to={"/partner-offers/" + this.state.data.partnerOffer.id} className="btn mr-2 mt-2 mb-2 btn-primary">View details</Link>
                                                    </Col>
                                                </Row>
                                            </div>}
                        </CardBody>
                                </Card>
                                
                                </div>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <CampaignRecipientsTable entityId={this.props.match.params.id} />}

                        </TabPane>
                    </TabContent>

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiCampaignClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async delete() {
        var response = await HengyiCampaignClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }
}
