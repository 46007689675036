import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ValetRequestQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithParkedVehicle(value: string) {
        this.WithParam("parkedVehicleId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithType(value: string) {
        this.WithParam("type", value);
        return this;
    }

    WithDate(value: string) {
        this.WithParam("date", value);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiValetRequestClient {

    static async List(params: ValetRequestQueryParams) {

        var query = (await GetAPIDomain()) + "/valet-requests" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Complete(id: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/" + id + "/complete", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                message: message
            })
        }));
    }

    static async Cancel(id: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/" + id + "/cancel", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                message: message
            })
        }));
    }

    static async RequestCar(parkedCarId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/request-car", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                parkedCarId: parkedCarId
            })
        }));
    }

    static async RequestItem(parkedCarId: string, message: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/request-car-item", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                message: message,
                parkedCarId: parkedCarId
            })
        }));
    }

    static async RequestDriver() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/valet-requests/request-driver", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
}