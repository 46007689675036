import React, { Component } from 'react';
import { HengyiApartmentClient } from '../infrastructure/HengyiClient/Apartments';
import { Link, Redirect } from 'react-router-dom';
import { Button, InputGroup, Input, ButtonGroup, Badge, Nav, NavItem, NavLink, TabContent, TabPane, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faMinus, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Formik, Field,Form, ErrorMessage } from 'formik';
import { UserSelector } from '../infrastructure/UserSelector';
import { HengyiInvitationClient, InvitationQueryParams } from '../infrastructure/HengyiClient/Invitations';
import { UserLink } from '../Users/UserLink';
import Moment from 'react-moment';
import { userContext } from '../../userContext';


export class ApartmentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addUserVisible: false,
            data: null, loading: false, authenticated: true, next: false,
            invitationData: [], invitationLoading: false, invitationTotal: 0, invitationSkip: 0, invitationTake: 50, invitationSearch: "", invitationStatus: "",
            activeTab: '1',
            showAddNote: false, includeDeleted: false
        };
        this.toggle = this.toggle.bind(this);
        this.removeUserFromApartment = this.removeUserFromApartment;
        this.handleInvitationSearchChange = this.handleInvitationSearchChange.bind(this);
        this.handleInvitationStatusChange = this.handleInvitationStatusChange.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }


    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiApartmentClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
        await this.updateInvitationData();

        this.setState({
            tenants: (await HengyiApartmentClient.GetOValetTenants(this.props.match.params.id)).data
        });
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/apartments" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/apartments" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>{this.state.data.name}</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Space details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/apartments/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Residents & Owners
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Invitations
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '5' })}
                                        onClick={() => { this.toggle('5'); }}>
                                        Notes
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h6>Details</h6>
                                                <hr />
                                                {this.state.data &&
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pr-2">Number:</td>
                                                                <td>
                                                                    {this.state.data.number}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Property title number:</td>
                                                                <td>
                                                                    {this.state.data.propertyTitleNumber}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Settled:</td>
                                                                <td>
                                                                    {this.state.data.settled && "Yes"}
                                                                    {!this.state.data.settled && "No"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Parks:</td>
                                                                <td>
                                                                    {this.state.data.carParks}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Floor:</td>
                                                                <td>
                                                                    {this.state.data.floor.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Type:</td>
                                                                <td>
                                                                    {this.state.data.type && this.state.data.type.name}
                                                                    {!this.state.data.type && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Primary contact:</td>
                                                                <td>
                                                                    {this.state.data.primaryContact && <UserLink user={this.state.data.primaryContact} />}
                                                                    {!this.state.data.primaryContact && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Description:</td>
                                                                <td>
                                                                    {this.state.data.description}
                                                                    {!this.state.data.description && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Building:</td>
                                                                <td>
                                                                    {this.state.data.floor.building.name}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </Col>
                                            <Col>
                                                <h6>Utilities & Accessibility</h6>
                                                <hr />
                                                {this.state.data &&
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pr-2">Power meter (ICP):</td>
                                                                <td>
                                                                    {this.state.data.powerMeterNumber}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Water meter:</td>
                                                                <td>
                                                                    {this.state.data.waterMeterNumber}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Has accessibility requirements:</td>
                                                                <td>
                                                                    {this.state.data.hasAccessibilityRequirements ? "Yes" : "No"}
                                                                </td>
                                                            </tr>


                                                            {this.state.data.hasAccessibilityRequirements && <tr>
                                                                <td className="pr-2">Requirements:</td>
                                                                <td>
                                                                    {this.state.data.accessibilityRequirement && this.state.data.accessibilityRequirement}
                                                                    {!this.state.data.accessibilityRequirement && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}

                                                            {this.state.data.hasAccessibilityRequirements && <tr>
                                                                <td className="pr-2">Medical Requirements:</td>
                                                                <td>

                                                                    {this.state.data.accessibilityMedicalRequired && this.state.data.accessibilityMedicalRequired}
                                                                    {!this.state.data.accessibilityMedicalRequired && <i>Not specified</i>}

                                                                </td>
                                                            </tr>}

                                                            {this.state.data.hasAccessibilityRequirements && <tr>
                                                                <td className="pr-2">Orientation required:</td>
                                                                <td>
                                                                    {this.state.data.accessibilityOrientationRequired ? "Yes" : "No"}
                                                                </td>
                                                            </tr>}

                                                        </tbody>
                                                    </table>
                                                }
                                            </Col>


                                            <Col>
                                                <h6>Letting agent</h6>
                                                <hr />
                                                {this.state.data &&
                                                    <table>
                                                        <tbody>

                                                            <tr>
                                                                <td className="pr-2">Name:</td>
                                                                <td>
                                                                    {!this.state.data.lettingAgentName && <i>Not specified</i>}
                                                                    {this.state.data.lettingAgentName && this.state.data.lettingAgentName}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Business:</td>
                                                                <td>
                                                                    {!this.state.data.lettingAgentBusiness && <i>Not specified</i>}
                                                                    {this.state.data.lettingAgentBusiness && this.state.data.lettingAgentBusiness}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Mobile:</td>
                                                                <td>
                                                                    {!this.state.data.lettingAgentMobile && <i>Not specified</i>}
                                                                    {this.state.data.lettingAgentMobile && this.state.data.lettingAgentMobile}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Email:</td>
                                                                <td>
                                                                    {!this.state.data.lettingAgentEmail && <i>Not specified</i>}
                                                                    {this.state.data.lettingAgentEmail && this.state.data.lettingAgentEmail}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </Col>
                                            <Col>
                                                <h6>Valet details</h6>
                                                <hr />
                                                {this.state.data && this.state.data.valetProvider === "none" &&
                                                    <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                                        <Row>
                                                            <Col>
                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>No tenant set up in O-Valet</strong></p>
                                                                <p>To enable Valet services, create a new tenant in O-Valet and assign the Unit Number <strong>{this.state.data.externalUnitNumberId}</strong>.</p>
                                                                <p>O-Valet is checked for new tenants every hour, it may take upto one hour for changes in O-Valet are reflected here.</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }

                                                {this.state.data && this.state.data.valetProvider === "oValet" &&
                                                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                        <Row>
                                                            <Col>
                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Linked to O-Valet</strong></p>
                                                                <p>O-Valet user_id: <strong>{this.state.data.valetTenantId}</strong></p>
                                                                <p>Space External Unit Number: <strong>{this.state.data.externalUnitNumberId}</strong></p>
                                                                <p>Vehicles are sync'd <strong>every hour</strong></p>
                                                                <p>Vehicle status is checked <strong>every minute</strong></p>

                                                        <a className="btn btn-success" target="_blank" href={"https://www.o-valet.com/admin/permanent-users-edit/" + this.state.data.valetTenantId}>View in O-Valet</a>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                            }


                                                {this.state.data && this.state.data.valetProvider === "swiftPark" &&
                                                    <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                        <Row>
                                                            <Col>
                                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Linked to SwiftPark</strong></p>
                                                                <p>SwiftPark Allocation Id: <strong>{this.state.data.valetTenantId}</strong></p>
                                                                <p>SwiftPark User Id: <strong>{this.state.data.valetUserId}</strong></p>
                                                                <p>Vehicles are sync'd <strong>every hour</strong></p>
                                                                <p>Vehicle status is checked <strong>every minute</strong></p>

                                                                <a className="btn btn-success" target="_blank" href={"https://swiftpark.codemagic.app/parking_allocations/details/" + this.state.data.valetTenantId}>View in SwiftPark</a>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }

                                            {this.state.tenants && this.state.tenants.length > 1 && <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Multiple O-Valet tenants detected</strong></p>
                                                        <p>The O-Valet tenant with the highest Id will be linked. If you wish the space to be linked to a different tenant please ensure the "Unit Number" field is populated for only the tenant to be linked.</p>
                                                        {this.state.tenants.map(item => <a className="btn btn-danger" target="_blank" href={"https://www.o-valet.com/admin/permanent-users-edit/" + item.user_id}>{item.user_first_name} {item.user_last_name} - {item.user_id}</a>)}
                                                    </Col>
                                                </Row>
                                            </div>}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' &&
                                <div>
                                    {this.state.addUserVisible && <Card>
                                        <CardBody>
                                            <Formik
                                                initialValues={{
                                                    userId: "",
                                                    relationship: "resident"
                                                }}
                                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                                    if (fields.userId) {
                                                        await HengyiApartmentClient.AddUser(this.props.match.params.id, fields.userId.value, fields.relationship);
                                                        await this.populateData(this.props.match.params.id);
                                                        this.setState({
                                                            addUserVisible: false
                                                        });
                                                    } else {
                                                        setFieldError("userId", "Please choose a user to add");
                                                    }
                                                }}
                                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                <Form>
                                                    <Row>
                                                        <Col>
                                                            <UserSelector
                                                                value={values.userId}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                touched={touched.userId}
                                                                error={errors.userId}
                                                            />

                                                            <ErrorMessage name="userId" component="div" className="invalid-feedback" />
                                                        </Col>
                                                        <Col>
                                                            <label htmlFor="relationship">Type</label>
                                                            <select name="relationship"
                                                                value={values.relationship}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={'form-control' + (errors.relationship && touched.relationship ? ' is-invalid' : '')}>
                                                                <option value="resident" label="Resident" />
                                                                <option value="owner" label="Owner" />
                                                                <option value="ownerOccupier" label="Owner occupier" />
                                                            </select>
                                                        </Col>
                                                        <Col md="1">
                                                            <div className="mt-4">
                                                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                    {!isSubmitting && "ADD"}
                                                                    {isSubmitting && <Spinner animation="border" />}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}</Formik>
                                        </CardBody>
                                    </Card>}

                                    <Card>
                                        <CardBody>
                                            <table className='table' aria-labelledby="tabelLabel">

                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>Name</th>
                                                        <th style={{ borderTop: "none" }}>Email</th>
                                                        <th style={{ borderTop: "none" }}>Mobile</th>
                                                        <th style={{ borderTop: "none" }}>Status</th>
                                                        <th style={{ borderTop: "none" }}>
                                                            {!this.state.addUserVisible && <button style={{ float: "right" }} onClick={() => { this.setState({ addUserVisible: true }); }} className="btn btn-outline-dark" ><FontAwesomeIcon icon={faPlus} /></button>}
                                                            {this.state.addUserVisible && <button style={{ float: "right" }} onClick={() => { this.setState({ addUserVisible: false }); }} className="btn btn-outline-dark" ><FontAwesomeIcon icon={faMinus} /></button>}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data && this.state.data.users && this.state.data.users.map(item =>
                                                        <tr key={item.user.id}>
                                                            <td>
                                                                <Link key={item.user.id + "-view"} to={"/users/" + item.user.id}>{item.user.name} {item.user.surname}</Link>
                                                                <Badge color="primary" className="ml-2" style={{ textTransform: 'capitalize' }} >
                                                                    {item.relationship === "ownerOccupier" && "Owner occupier"}
                                                                    {item.relationship !== "ownerOccupier" && item.relationship}</Badge>
                                                            </td>
                                                            <td>{item.user.email}</td>
                                                            <td>{item.user.mobile}</td>
                                                            <td style={{ textTransform: 'capitalize' }} >{item.user.status}</td>
                                                            <td>
                                                                <Button onClick={() => { this.removeUserFromApartment(item.user.id); }} key={item.user.id + "-delete"} style={{ float: "right" }} className="btn mr-2 btn-danger mt-2"><FontAwesomeIcon icon={faTrash} /></Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.data && this.state.data.users.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No users to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        </CardBody></Card>
                                </div>}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' &&
                                <div>
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col>
                                                    <h5>Invitations ({this.state.invitationTotal})
                                     {this.state.invitationLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                    </h5>
                                                    <span className="d-block m-t-5 text-muted">List of invitations for this space</span>
                                                </Col>
                                                <Col>
                                                    <div style={{ paddingRight: "10px" }}>
                                                        <button style={{ float: "right" }} onClick={() => { this.invitationNext(); }} disabled={this.state.invitationLoading || (this.state.invitationSkip + this.state.invitationTake >= this.state.invitationTotal)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                                        <button style={{ float: "right" }} onClick={() => { this.invitationPrevious(); }} disabled={this.state.invitationLoading || (this.state.invitationSkip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardHeader>
                                    </Card>

                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col>
                                                    <h5>Filter</h5>
                                                </Col>
                                                <Col>
                                                    <InputGroup>
                                                        <Input type="select" name="select" value={this.state.invitationStatus} onChange={this.handleInvitationStatusChange} >
                                                            <option value="">All statuses</option>
                                                            <option value="active">Active</option>
                                                            <option value="used">Used</option>
                                                            <option value="expired">Expired</option>
                                                        </Input>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <div style={{ paddingRight: "10px" }}>

                                                        <InputGroup>
                                                            <Input type="text" placeholder="Search" value={this.state.invitationSearch} onChange={this.handleInvitationSearchChange} onKeyPress={e => e.key === 'Enter' && this.updateInvitationData()} />
                                                            <ButtonGroup className="input-group-append">
                                                                <button onClick={() => { this.updateInvitationData(); }} disabled={this.state.invitationLoading} className="btn btn-outline-dark" >
                                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                                </button>
                                                            </ButtonGroup>
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardHeader>
                                        <CardBody className="p-0">
                                            {this.state.invitationData &&
                                                <table className='table' aria-labelledby="tabelLabel">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ borderTop: "none" }}>Details</th>
                                                            <th style={{ borderTop: "none" }}>User</th>
                                                            <th style={{ borderTop: "none" }}>Status</th>
                                                            <th style={{ borderTop: "none" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.invitationData.map(invite =>
                                                            <tr key={invite.id}>
                                                                <td>
                                                                    {invite.name} {invite.surname}
                                                                    <p className="muted">{invite.email}</p>
                                                                </td>
                                                                <td>
                                                                    {invite.user && <Link key={invite.user.id + "-view"} to={"/users/" + invite.user.id}>{invite.user.name} {invite.user.surname}</Link>}
                                                                    {!invite.user && <i>Invite not used</i>}
                                                                </td>
                                                                <td style={{ textTransform: 'capitalize' }}>{invite.status}</td>
                                                                <td>
                                                                    <Link key={invite.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invitations/" + invite.id}>Details</Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {this.state.invitationData && this.state.invitationData.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No invitations to display</i></h4></td></tr>}
                                                    </tbody>
                                                </table>
                                            }
                                        </CardBody>
                                    </Card>

                                </div>}
                        </TabPane>

                        <TabPane tabId="4">
                            {this.state.activeTab === '4' && <ActivityTable title="Space activity" description="List all activity for this space" entityId={this.props.match.params.id} />}
                        </TabPane>

                        <TabPane tabId="5">
                            {this.state.activeTab === '5' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this space</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        var message = document.getElementById("message").value;

                                                        if (!message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiApartmentClient.AddNote(this.props.match.params.id, message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            setFieldValue("message", "");
                                                            document.getElementById("message").value = "";
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                                <textarea as="textarea" id="message" name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("apartment.modify") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                    </TabContent>


                </div>
            );
        }
    }

    async removeUserFromApartment(userApartmentId) {
        await HengyiApartmentClient.RemoveUser(this.props.match.params.id, userApartmentId);
        this.populateData(this.props.match.params.id);
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApartmentClient.Get(id);

            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiApartmentClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false,
                    notes: notes.data
                });
            }


        }
    }


    handleInvitationSearchChange(evt) {
        this.setState({
            invitationSearch: evt.target.value
        });
    }


    handleInvitationStatusChange(evt) {
        this.setState({
            invitationStatus: evt.target.value
        }, () => {
            this.updateInvitationData();
        });
    }

    async invitationPrevious() {
        await this.populateInvitationData(this.state.invitationSkip - this.state.invitationTake, this.state.invitationTake);
    }

    async  invitationNext() {
        await this.populateInvitationData(this.state.invitationSkip + this.state.invitationTake, this.state.invitationTake);
    }

    async updateInvitationData() {
        await this.populateInvitationData(this.state.invitationSkip, this.state.invitationTake);
    }


    async populateInvitationData(skip, take) {

        if (!this.state.loading) {
            this.setState({ invitationLoading: true, invitationSkip: skip, invitationTake: take });

            var response = await HengyiInvitationClient.List(new InvitationQueryParams()
                .WithApartment(this.props.match.params.id)
                .WithStatus(this.state.invitationStatus)
                .Search(this.state.invitationSearch)
                .Paginate(skip, take));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ invitationData: response.data.data, invitationLoading: false, invitationTotal: response.data.total });
            }
        }
    }

}
