import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup } from 'reactstrap';
import { HengyiCampaignClient, CampaignQueryParams } from '../infrastructure/HengyiClient/Campaigns';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import { TableHeader } from '../infrastructure/TableHeader';

export class Campaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", inUseFilter: "", statusFilter: "",
            hideSent: true, hideCancelled: true, sort: "sent", direction: "desc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleHideSentChanged = this.handleHideSentChanged.bind(this);
        this.handleHideCancelledChanged = this.handleHideCancelledChanged.bind(this);
    }

    handleHideCancelledChanged = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    handleHideSentChanged = (evt) => {
        this.setState({
            hideSent: evt.target.checked,
            skip: 0
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            statusFilter: "",
            startFilter: "",
            endFilter: "",
            skip: 0
        }, () => this.update());

    }

    handleStatusChange(evt) {
        this.setState({
            statusFilter: evt.target.value
        }, () => this.update());
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">
                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Title", sort: "title" },
                        { name: "Scheduled", sort: "scheduledSend" },
                        { name: "Status", sort: "status" },
                        { name: "User", sort: "from.name" },
                        { name: "Sent", sort: "sent" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.title && item.title}
                                {!item.title && <i>Not specified</i>}
                            </td>
                            <td>
                                {(item.status !== "cancelled" && item.scheduledSend) && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.scheduledSend}</Moment>}
                                {(!item.scheduledSend || item.status === "cancelled") && <i>Not scheduled</i>}
                            </td>
                            <td>
                                {item.status === "draft" && <Badge color="secondary">Draft</Badge>}
                                {item.status === "scheduled" && <Badge color="info">Scheduled</Badge>}
                                {item.status === "processing" && <Badge color="info">Processing</Badge>}
                                {item.status === "sent" && <Badge color="success">Sent</Badge>}
                                {item.status === "cancelled" && <Badge color="danger">Cancelled</Badge>}
                            </td>
                            <td>
                                {item.from && item.from.name + " " +item.from.surname}
                                {!item.from && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.sent && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.sent}</Moment>}
                                {!item.sent && <i>Not sent</i>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/campaigns/" + item.id}>Details</Link>
                                {(item.status === "draft" || item.status === "scheduled") && <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/campaigns/edit/" + item.id}>Edit</Link>}
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No campaigns to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Campaigns ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of campaigns</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/campaigns/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <DatePicker id="start"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="Start date"
                                        selected={this.state.startFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                startFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <DatePicker id="end"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                        autoComplete="off"
                                        className="form-control"
                                        placeholderText="End date"
                                        selected={this.state.endFilter}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            this.setState({
                                                endFilter: v
                                            }, () => this.update());
                                        }} />
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.statusFilter} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="draft">Draft</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="processing">Processing</option>
                                            {!this.state.hideSent && <option value="sent">Sent</option>}
                                            {!this.state.hideCancelled && <option value="cancelled">Cancelled</option>}
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <InputGroup>
                                            <Input id="search" type="text" placeholder="Search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()}/>
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.statusFilter || this.state.startFilter || this.state.endFilter) &&
                                                    <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardHeader>
                            <div className="form-group">
                                <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideSent"
                                                type="checkbox"
                                                checked={this.state.hideSent}
                                                onChange={this.handleHideSentChanged} />  Hide sent campaigns
                                        </label>
                                    </li>
                                    <li className="list-inline-item">
                                        <label style={{ margin: "0" }}>
                                            <input name="hideCancelled"
                                                type="checkbox"
                                                checked={this.state.hideCancelled}
                                                onChange={this.handleHideCancelledChanged} />  Hide cancelled campaigns
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>


                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiCampaignClient.List(new CampaignQueryParams()
                .WithStatus(this.state.statusFilter)
                .WithHideSent(this.state.hideSent ? "true" : "false")
                .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
                .WithStart(this.state.startFilter)
                .WithEnd(this.state.endFilter)
                .Sort(this.state.sort, this.state.direction)
                .Search(this.state.search)
                .Paginate(skip, take));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }

        }
    }
}
