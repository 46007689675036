import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { PartnerOfferCategoryClient, PartnerOfferCategoryQueryParams } from '../infrastructure/HengyiClient/PartnerOfferCategories';
import { PartnerOfferClient } from '../infrastructure/HengyiClient/PartnerOffers';
import DatePicker from 'react-datepicker';

export class PartnerOffersNew extends Component {

    constructor(props) {
        super(props);
        this.state = { categoryOptions: [], init: false, loading: false, authenticated: true, next: false, id: "", name:"", weight: 0 };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                expiry: "",
                categoryId: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                console.log(fields);

                var imageId = null;

                if (fields.thumbnail) {

                    let formData = new FormData();
                    formData.append("file", fields.thumbnail);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await PartnerOfferClient.Create(this.state.name, this.state.description, fields.expiry, this.state.weight ? this.state.weight : 0, fields.categoryId ? fields.categoryId.value : null, this.state.weblink, imageId);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {

                    var nextId = response.data.id;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var uploadResponse = await fetch((await GetAPIDomain()) + "/partner-offers/" + response.data.id + "/upload-file", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                    }

                    this.setState({ id: nextId, next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange }) => (
            <Form>
                    <Row>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="expiry">Expiry</label>

                                <DatePicker id="expiry"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.expiry}
                                    showTimeSelect
                                    dateFormat='dd/MM/yyyy hh:mm a'
                                    onChange={(v, f) => {
                                        setFieldValue("expiry", v);
                                    }} />


                                {errors.expiry && <div name="end" style={{ display: "block" }} className="invalid-feedback">{errors.expiry}</div>}

                            </div>
                            <div className="form-group">
                                <label htmlFor="weight">Weight</label>
                                <Field name="weight" type="number" className={'form-control' + (errors.weight && touched.weight ? ' is-invalid' : '')} onChange={this.handleChange}/>
                                <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="weblink">Weblink</label>
                                <Field name="weblink" type="text" className={'form-control' + (errors.weblink && touched.weblink ? ' is-invalid' : '')} onChange={this.handleChange}/>
                                <ErrorMessage name="weblink" component="div" className="invalid-feedback" />
                            </div>

                <div className="form-group">
                    <SelectField
                        id="categoryId"
                        name="categoryId"
                        label="Category"
                        placeholder="Select category"
                        options={this.state.categoryOptions}
                        value={values.categoryId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.categoryId}
                        error={errors.categoryId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                </div>

                    <div className="pt-3">
                <div className="form-group">
                    <label htmlFor="file">Downloadable document about the offer</label>
                    <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                    }} />
                        </div>
                            </div>

                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Offer thumbnail</h6>

                                {this.state.thumbnail && <div>
                                    <img alt="Uploaded supporting file" src={this.state.thumbnail} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="thumbnail" name="thumbnail" type="file" onChange={(event) => {

                                    this.setState({
                                        thumbnail: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("thumbnail", event.currentTarget.files[0]);
                                }} />

                            </div>
                        </Col>
                    </Row>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Save"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/partner-offers/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New partner offer
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a new partner offer to the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/partner-offers" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await PartnerOfferCategoryClient.List((new PartnerOfferCategoryQueryParams()).Paginate(0, 1000));

            var categoryOptions = response.data.data.map(function (item) { return { value: item.id, label: item.name }; });

            this.setState({
                init: true,
                categoryOptions: categoryOptions,
                loading: false
            });
        }
    }
}
