import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeadersPatch, GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { AudienceSelector } from '../Audience/AudienceSelector';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';

export class FolderEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    description: this.state.description,
                    weight: this.state.weight,
                    name: this.state.data.name,
                    visibility: this.state.data.visibility,
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var imageId = this.state.imageId;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var weight = 0;
                    if (this.state.weight)
                        weight = parseInt(this.state.weight);

                    const response = await fetch((await GetAPIDomain()) + "/documents/" + this.props.match.params.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "description",
                            "value": this.state.description
                        }, {
                            "op": "replace",
                            "path": "weight",
                            "value": weight
                        }, {
                            "op": "replace",
                            "path": "audiences",
                            "value": this.state.audiences
                        }, {
                            "op": "replace",
                            "path": "visibility",
                            "value": fields.visibility
                        }, {
                            "op": "replace",
                            "path": "name",
                            "value": this.state.name
                        }, {
                            "op": "replace",
                            "path": "imageId",
                            "value": imageId
                        }])
                    });

                    if (!response.ok) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            const data = await response.json();

                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="name">Name*</label>
                                            <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="weight">Weight</label>
                                            <input name="weight" id="weight" type="number" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                        <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                            <textarea name="description" id="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                        {touched.description && errors.description ? <p className="error-text">{errors.description}</p> : null}
                                    </div>
                                </Col>
                            </Row>
                            <h6 className="mt-2">Visibility</h6>
                            <hr />
                            <Row>
                                <Col>
                                    <div class="form-check">
                                        <Field class="form-check-input" type="radio" name="visibility" id="public" value="public" />
                                        <label class="form-check-label" for="public">Public</label>
                                    </div>
                                    <div class="form-check">
                                        <Field class="form-check-input" type="radio" name="visibility" id="restricted" value="restricted" />
                                        <label class="form-check-label" for="restricted">Restricted</label>
                                    </div>
                                </Col>
                            </Row>

                            {values.visibility === "restricted" && <div className="form-group">
                                <h6 className="mt-2">Audience</h6>
                                <hr />

                                <Row className="mb-2">
                                    <Col>Type</Col>
                                    <Col>Entity</Col>
                                    <Col>User Type</Col>
                                    <Col md="1"></Col>
                                </Row>
                                <hr />
                                {(!this.state.audiences || this.state.audiences.length === 0) && <i>No audiences added yet</i>}
                                {this.state.audiences && this.state.audiences.length > 0 &&
                                    <div>

                                        {this.state.audiences.map((audience, index) =>
                                            <Row>
                                                <Col style={{ textTransform: 'capitalize' }}>
                                                    {audience.type}
                                                </Col>
                                                <Col style={{ textTransform: 'capitalize' }}>
                                                    {audience.entityName && <span>{audience.entityName}</span>}
                                                    {!audience.entityName && <i>Everything</i>}
                                                </Col>
                                                <Col md="1">
                                                    <button className="btn mr-2 btn-outline-dark" onClick={() => {
                                                        console.log("Remove index: " + index);

                                                        this.state.audiences.splice(index, 1);
                                                        this.setState({
                                                            audiences: this.state.audiences
                                                        });

                                                        return false;
                                                    }}>REMOVE</button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>}

                                <hr />

                                <AudienceSelector hideUserType={true} hideLabels={true} initialValues={{
                                    audience: "all",
                                    userType: "any"
                                }} onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    var audiences = this.state.audiences;

                                    var audience = {
                                        type: fields.audience
                                    };

                                    if (fields.userType && fields.userType !== "any")
                                        audience.userType = fields.userType;


                                    if (fields.entityId) {
                                        audience.entityId = fields.entityId.value;
                                        audience.entityName = fields.entityId.label;
                                    }

                                    audiences.push(audience);

                                    this.setState({
                                        audiences: audiences
                                    });

                                }} />

                            </div>}

                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Cover image</h6>

                                    {this.state.file && <div>
                                        <img alt="Uploaded supporting file" src={this.state.file} style={{ minWidth: "250px", width: "100%", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                    this.setState({
                                        file: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />

                            </div>

                            <div className="alert alert-info text-left">
                                <h6>Cover image tips</h6>
                                <p>
                                    A cover image is used for the image of the folder tile, this tile can be different sizes depending on the device used.

                                </p>
                                <p>
                                    A good cover image is one that
                                        <ul>
                                        <li>Does not contain any text</li>
                                        <li>Can be cropped on the edges without impacting the visual quality of the image</li>
                                        <li>Avoids sharp contrast contours which makes the text harder to read</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>



                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/folders/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update folder details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update the details this folder</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/folders/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var item = await HengyiDocumentClient.GetDocument(this.props.match.params.id);

            if (item.data.imageId) {
                this.setState({
                    file: (await GetAssetDomain()) + "/image/" + item.data.imageId + ".jpg"
                });
            }

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                var audiences = [];

                if (item.data && item.data.audiences) {
                    audiences = item.data.audiences.map(item => {
                        if (item.apartment)
                            return {
                                entityName: item.apartment.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.apartment.id
                            };
                        if (item.apartmentType)
                            return {
                                entityName: item.apartmentType.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.apartmentType.id
                            };
                        if (item.building)
                            return {
                                entityName: item.building.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.building.id
                            };
                        if (item.floor)
                            return {
                                entityName: item.floor.name,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.floor.id
                            };

                        if (item.user)
                            return {
                                entityName: item.user.name + " " + item.user.surname,
                                type: item.type,
                                userType: item.userType,
                                entityId: item.user.id
                            };


                        if (item.type === "all")
                            return {
                                entityName: "Everyone",
                                type: item.type,
                                userType: item.userType,
                                entityId: null
                            };

                        return {
                            entityName: "Unknown type: " + item.type,
                            type: item.type,
                            userType: item.userType,
                            entityId: ""
                        };
                    });
                }
                this.setState({
                    data: item.data,
                    description: item.data.description,
                    name: item.data.name,
                    weight: item.data.weight,
                    visibility: item.data.visibility,
                    loading: false,
                    init: true,
                    audiences: audiences,
                    folderOptions: (await HengyiApi.GetDocuments()).data.data.map(function (item) { return { value: item.id, label: item.name }; })
                }, () => {

                    document.getElementById("name").value = item.data.name ? item.data.name : "";
                    document.getElementById("description").value = item.data.description ? item.data.description : "";
                    document.getElementById("weight").value = item.data.weight ? item.data.weight : "";
                });

            }
        }
    }
}
