import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class HazardQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

}

//******************************
// INCIDENTS
//******************************
export class HengyiHazardClient {

    static async List(params: HazardQueryParams) {

        var query = (await GetAPIDomain()) + "/hazards" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/hazards/" + id, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Sync(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/hazards/" + id + "/sync", {
            headers: await GetAPIHeaders(),
            method: "POST"
        }));
    }
}