import React, { Component } from 'react';
import { HengyiApi, DocumentEventQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { GetResidentDomain, GetAssetDomain } from '../infrastructure/Helper';
import classnames from 'classnames';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';
import { UserLink } from '../Users/UserLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiTenantImageClient } from '../infrastructure/HengyiClient/TenantImages';

export class BrandingImageDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false
        };

        this.delete = this.delete.bind(this);

    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain()
        });

        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/branding" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/branding" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Uploaded image</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/branding/images/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    {this.state.data && <Card>
                        <CardBody>
                            <Row><Col>
                                <h6>Image details</h6>
                                <hr />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">Filename:</td>
                                            <td>
                                                {this.state.data.fileName && <span>{this.state.data.fileName}</span>}</td>
                                        </tr> <tr>
                                            <td className="pr-2">Type:</td>
                                            <td>
                                                {this.state.data.type === "logo" && "Logo"}
                                                {this.state.data.type === "logoAlt" && "Logo (footer)"}
                                                {this.state.data.type === "logoEmail" && "Logo (email)"}
                                                {this.state.data.type === "emailBanner" && "Email banner image"}
                                                {this.state.data.type === "residentBanner" && "Resident portal banner image"}
                                                {this.state.data.type === "appBackground" && "Mobile application background image"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>

                                <Col md="3" className="text-center">
                                    <h6 className="text-left">Image</h6>
                                    <hr />
                                    {!this.state.data.id && <h4 className="text-muted mt-3"><i>No image uploaded</i></h4>}
                                    {this.state.data.id && <img alt="Folder cover" src={this.state.assetUrl + "/image/" + this.state.data.id + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "contain" }} />}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}

                    <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                        {!this.state.deleteFail &&
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this image, doing so will permanently remove it, please proceed with caution</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/folders" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete image</Button>
                                </Col>
                            </Row>}

                        {this.state.deleteFail &&
                            <div>
                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed:</strong> This image cannot be deleted</p>
                            </div>}
                    </div>

                </div>
            );
        }
    }

    async delete() {
        var response = await HengyiTenantImageClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiTenantImageClient.Get(id);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }


        }
    }

}
