import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { HengyiFloorClient } from '../infrastructure/HengyiClient/Floors';

function required(value) {
    console.log(value);
    if (!value) {
        console.log("Error: " + value);
        return 'This is a required field';
    }
}

export class TicketsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], categoryOptions: [], apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };


        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }


    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = (await HengyiApi.GetTicketTypes(new ListQueryParams().Paginate(0, 50)));

            var apartmentResponse = await HengyiApartmentClient.List(new ApartmentQueryParams()
                .Paginate(0, 10000));

            var floorResponse = await HengyiFloorClient.List(new ListQueryParams()
                .Sort("ordinal","asc")
                .Paginate(0, 10000));

            this.setState({
                init: true,
                location: "",
                types: response.data.data,
                typeOptions: response.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                apartmentOptions: apartmentResponse.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                floorOptions: floorResponse.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }

    renderForm() {
        return (<Formik
            initialValues={{
                ticketTypeId: "",
                ticketCategoryId: "",
                floorId: "",
                apartmentId: "",
                location: "",
                description: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.ticketTypeId || !fields.ticketTypeId.value) {
                    setFieldError("ticketTypeId", 'This is a required field');
                    return;
                }
                if (!fields.ticketCategoryId || !fields.ticketCategoryId.value) {
                    setFieldError("ticketCategoryId", 'This is a required field');
                    return;
                }

                const params = {
                    ticketTypeId: fields.ticketTypeId.value,
                    ticketCategoryId: fields.ticketCategoryId.value,
                    description: this.state.description,
                    location: this.state.location
                };

                if (fields.apartmentId && fields.apartmentId.value)
                    params.apartmentId = fields.apartmentId.value;

                if (fields.floorId && fields.floorId.value)
                    params.floorId = fields.floorId.value;

                if (this.state.apartmentOptions.length == 1)
                    params.apartmentId = this.state.apartmentOptions[0].value;

                const response = await fetch((await GetAPIDomain()) + "/tickets", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {


                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }

                } else {

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        await fetch((await GetAPIDomain()) + "/tickets/" + data.id + "/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });
                    }

                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <SelectField
                                id="ticketTypeId"
                                name="ticketTypeId"
                                label="Type"
                                placeholder="Select a type"
                                options={this.state.typeOptions}
                                value={values.ticketTypeId}
                                isMulti={false}
                                onChange={(key, item) => {
                                    setFieldValue(key, item);
                                    if (item) {
                                        var selectedType = this.state.types.filter(type => type.id === item.value)[0];
                                        this.setState({ categoryOptions: selectedType.categories.map(function (item) { return { value: item.id, label: item.name }; }) });
                                    } else {
                                        this.setState({ categoryOptions: [] });
                                    }
                                    setFieldValue("ticketCategoryId", null);
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.ticketTypeId}
                                error={errors.ticketTypeId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                            <ErrorMessage name="ticketTypeId" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <SelectField
                                id="ticketCategoryId"
                                name="ticketCategoryId"
                                label="Category"
                                placeholder="Select a category"
                                options={this.state.categoryOptions}
                                value={values.ticketCategoryId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.ticketCategoryId}
                                error={errors.ticketCategoryId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                            <ErrorMessage name="ticketCategoryId" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <Row>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="floorId"
                                    name="floorId"
                                    label="Floor"
                                    placeholder="Which floor is this about?"
                                    options={this.state.floorOptions}
                                    value={values.floorId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.floorId}
                                    error={errors.floorId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                                <ErrorMessage name="floorId" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    <Col>
                        <div className="form-group">
                            <SelectField
                                id="apartmentId"
                                name="apartmentId"
                                label="Space"
                                placeholder="Which space is this about?"
                                options={this.state.apartmentOptions}
                                value={values.apartmentId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                            <ErrorMessage name="apartmentId" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <div className="form-group">
                    <label htmlFor="location">Location</label>
                        <input validate={required} name="location" type="text" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} onChange={this.handleChange}/>
                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                        <textarea name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} onChange={this.handleChange}></textarea>
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="file">Photo</label>
                    <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                        this.setState({
                            file: URL.createObjectURL(event.target.files[0])
                        })
                        setFieldValue("file", event.currentTarget.files[0]);
                    }} />

                    {this.state.file && <div>
                        <h6 style={{ marginTop: "25px" }}>Preview</h6>
                        <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "100%", marginTop: "20px" }} />
                    </div>}
                </div>

                <hr />
                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/tickets/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New ticket
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Lodge a new ticket</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/tickets" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}
