import React, { Component } from 'react';
import { HengyiTenantImageClient, TenantImageQueryParams } from '../infrastructure/HengyiClient/TenantImages';
import { HengyiTenantClient } from '../infrastructure/HengyiClient/Tenants';
import { Link, Redirect } from 'react-router-dom';
import { GetAssetDomain } from '../infrastructure/Helper';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { TableHeader } from '../infrastructure/TableHeader';
import { userContext } from '../../userContext';

export class Tenants extends Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "type", direction: "asc", tenant: null };
    }

    async clear() {

        this.setState({
            search: "",
            skip: 0
        }, () => this.update());

    }

    async componentDidMount() {
        this.populateData(this.state.skip, this.state.take);

        this.setState({
            assetUrl: await GetAssetDomain()
        });
    }

    renderTable(data) {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">

                    <TableHeader
                        sort={this.state.sort}
                        direction={this.state.direction}
                        columns={[
                            { name: "Image", sort: "filename" },
                            { name: "Name", sort: "filename" },
                            { name: "Type", sort: "type" },
                            { name: "", sort: "", static: true }]}
                        onUpdate={(sort, direction) => this.setState({
                            sort: sort,
                            direction: direction
                        }, () => this.update())}
                    />
                    <tbody>
                        {data.map(image =>
                            <>
                                <tr key={image.id}>
                                    <td><img src={this.state.assetUrl + "/image/" + image.id + ".jpg"} style={{ maxWidth: "200px", maxHeight: "150px", objectFit: "contain" }} /></td>
                                    <td style={{ verticalAlign: "middle" }}>{image.fileName}</td>
                                    <td style={{ verticalAlign: "middle" }}>
                                        {image.type === "logo" && "Logo"}
                                        {image.type === "logoAlt" && "Logo (footer)"}
                                        {image.type === "logoEmail" && "Logo (email)"}
                                        {image.type === "emailBanner" && "Email banner image"}
                                        {image.type === "residentBanner" && "Resident portal banner image"}
                                        {image.type === "appBackground" && "Mobile application background image"}
                                    </td>
                                    <td>
                                        <Link key={image.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/branding/images/" + image.id}>Details</Link>
                                        <Link key={image.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/branding/images/edit/" + image.id}>Edit</Link>
                                    </td>
                                </tr>
                            </>
                        )}

                        {this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No images to display</i></h4></td></tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>


                    {this.state.tenant && <Card>
                        <CardHeader>
                            <Row>
                                <Col style={{display:"flex", alignItems:"center"}}>
                                    <h6 style={{margin: "0"}}>{this.state.tenant.name}</h6>
                                </Col>
                                <Col>
                                    <Link style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/branding/edit"}>Edit</Link>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="pr-2">Colour:</td>
                                        <td>
                                            {this.state.tenant.colour && <><div style={{ backgroundColor: this.state.tenant.colour, width: "20px", height: "20px", display: "inline-block", marginRight:"10px", position:"relative", top:"3px"}}></div> {this.state.tenant.colour}</>}
                                            {!this.state.tenant.colour && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Domain:</td>
                                        <td>
                                            {this.state.tenant.domain && this.state.tenant.domain}
                                            {!this.state.tenant.domain && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </CardBody>

                    </Card>}


                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Images ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of images uploaded.</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/branding/images/upload" className="btn mr-2 btn-outline-dark mt-2 mb-2">Upload</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()}/>
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response =
                await HengyiTenantImageClient.List(new TenantImageQueryParams()
                .Search(this.state.search)
                .Paginate(skip, take)
                .Sort(this.state.sort, this.state.direction));

            var tenantData = await HengyiTenantClient.Get();

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total, tenant: tenantData.data });
            }
        }
    }
}


Tenants.contextType = userContext;