import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'


class TableHeader extends Component {

    constructor(props) {
        super(props);
        const {
            sort,
            direction
        } = this.props;

        this.state = { sort: sort ? sort : "created", direction: direction ? direction:"desc" };
    }

    headerClicked(sort) {
        const {
            onUpdate
        } = this.props;

        var newDirection = this.state.direction === "desc" ? "asc" : "desc";
            this.setState({
                direction: newDirection,
                sort: sort
            });

        if (onUpdate)
            onUpdate(sort, newDirection);
    }

    render() {

        const {
            columns
        } = this.props;

        return (<thead>
            <tr>
                {columns.filter(f => !f.disabled).map((item, idx) =>
                    <th key={item.name + "-" + idx} className={(item.static ? "" : "clickable ") + item.className} style={{ borderTop: "none" }} onClick={() => { if (!item.static) this.headerClicked(item.sort); }}>
                        {item.name}
                        {!item.static && item.sort === this.state.sort && <span>&nbsp;
                            {this.state.direction === "desc" && <FontAwesomeIcon icon={faChevronDown} />}
                            {this.state.direction !== "desc" && <FontAwesomeIcon icon={faChevronUp} />}
                        </span>}
                    </th>)}
            </tr>
        </thead>);
    }
}

export { TableHeader };
