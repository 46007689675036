import React, { Component } from 'react';
import { HengyiApi, ApiResponse } from '../infrastructure/fetchHengy';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { Link, Redirect } from 'react-router-dom';
import { Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { ActivityTable } from '../Activity/ActivityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faMinus, faChevronLeft, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import { GetAPIDomain, GetAPIHeaders, GetAssetDomain } from '../infrastructure/Helper';
import { HengyiUserClient } from '../infrastructure/HengyiClient/Users';
import { SwipeCardTable } from '../SwipeCards/SwipeCardTable';
import { ChargesTable } from '../Charges/ChargesTable';
import { InductionsTable } from '../Induction/InductionsTable';
import Moment from 'react-moment';
import { userContext } from '../../userContext';

export class UsersDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addTempPassVisible: false,
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1',
            tempPassLoading: false,
            showAddNote: false, includeDeleted: false
        };

        this.toggle = this.toggle.bind(this);
        this.removeUserFromApartment = this.removeUserFromApartment;

        this.revokeTempPass = this.revokeTempPass.bind(this);
        this.suspend = this.suspend.bind(this);
        this.restore = this.restore.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiUserClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/users" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/users" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>{this.state.data.name}</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>User details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/users/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                        <button style={{ float: "right" }} onClick={() => { this.setTempPass(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">Set temp password</button>
                                        {this.state.data && !this.state.data.locked && <button style={{ float: "right" }} disabled={this.state.lockedLoading} onClick={() => { this.setLockedStatus(true); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">Lock</button>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>


                    {this.state.addTempPassVisible && <div className="alert alert-success">

                        {this.state.tempPassLoading && <Spinner animation="border" />}

                        {!this.state.tempPassLoading && <>
                            <h4>New temporary password is: {this.state.tempPass}</h4>
                            <p style={{ marginBottom: "0.5rem" }}>The user will be asked to change their password when they next sign into the resident portal.</p>
                            <p>For security you will not be able to view this temporary password again once you leave this page.</p>
                        </>}
                    </div>}


                    {!this.state.loading && this.state.data && this.state.data.tempPasswordSet === true &&
                        <div className="alert alert-info" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Temporary password set:</strong> This user currently has a temp password set, for security this should be cleared if they don't use it soon.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/ticket-types" onClick={this.revokeTempPass} className="btn mr-2 mt-2 mb-2 btn-primary">Revoke Temp Password</Button>
                                </Col>
                            </Row>
                        </div>}

                    {!this.state.loading && this.state.data && this.state.data.locked != null &&
                        <div className="alert alert-warning" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Account locked:</strong> This user account has been locked, it cannot sign in to the platform.</p>
                                </Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/ticket-types" onClick={() => { this.setLockedStatus(false); }} className="btn mr-2 mt-2 mb-2 btn-warning">Unlock</Button>
                                </Col>
                            </Row>
                        </div>}

                    <Card>
                        <CardHeader>
                            <userContext.Consumer>
                                {({ features }) => {
                                    return (<Nav pills style={{ boxShadow: "none" }}>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}>
                                                Details
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }}>
                                                Spaces
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '3' })}
                                                onClick={() => { this.toggle('3'); }}>
                                                Activity
                                            </NavLink>
                                        </NavItem>
                                        {features && features.includes("swipe-cards") && <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '4' })}
                                                onClick={() => { this.toggle('4'); }}>
                                                Swipe Cards
                                            </NavLink>
                                        </NavItem>}
                                        {features && features.includes("billing") && <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '5' })}
                                                onClick={() => { this.toggle('5'); }}>
                                                Charges
                                            </NavLink>
                                        </NavItem>}
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '7' })}
                                                onClick={() => { this.toggle('7'); }}>
                                                Notes
                                            </NavLink>
                                        </NavItem>
                                        {features && features.includes("inductions") &&this.state.actions && this.state.actions.inductionEnabled && <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '6' })}
                                                onClick={() => { this.toggle('6'); }}>
                                                Induction
                                            </NavLink>
                                        </NavItem>}
                                    </Nav>);
                                }}
                            </userContext.Consumer>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h6>Details</h6>
                                                <hr />
                                                {this.state.data &&
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="pr-2">Name:</td>
                                                                <td>
                                                                    {this.state.data.name && this.state.data.name}
                                                                    {!this.state.data.name && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Surname:</td>
                                                                <td>
                                                                    {this.state.data.surname && this.state.data.surname}
                                                                    {!this.state.data.surname && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Mobile:</td>
                                                                <td>
                                                                    {this.state.data.mobile && this.state.data.mobile}
                                                                    {!this.state.data.mobile && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Email:</td>
                                                                <td>
                                                                    {this.state.data.email && this.state.data.email}
                                                                    {!this.state.data.email && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Push notifications:</td>
                                                                <td>
                                                                    {this.state.data.pushNotificationsEnabled && "Enabled"}
                                                                    {!this.state.data.pushNotificationsEnabled && <i>Disabled</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Roles:</td>
                                                                <td>
                                                                    {this.state.data.roles && this.state.data.roles.length > 0 && this.state.data.roles.map(role =>
                                                                        <Badge color="primary" className="mr-2">{role.name}</Badge>
                                                                    )}
                                                                    {!(this.state.data.roles && this.state.data.roles.length > 0) && <i>Resident or owner</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Status:</td>
                                                                <td>
                                                                    {this.state.data.status === "active" && <Badge color="primary">Active</Badge>}
                                                                    {this.state.data.status === "suspended" && <Badge color="dander">Suspended</Badge>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Parcel preference: </td>
                                                                <td>
                                                                    {this.state.data.preferredParcelDeliveryOption === "askEachTime" && <span>Ask each time</span>}
                                                                    {this.state.data.preferredParcelDeliveryOption === "bringUp" && <span>Bring up &nbsp;
                                    {!this.state.data.preferredParcelDeliverySlot && <Badge color="info">Any time</Badge>}
                                                                        {this.state.data.preferredParcelDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                                                        {this.state.data.preferredParcelDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                                                        {this.state.data.preferredParcelDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}
                                                                    </span>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td className="pr-2">Has loaded a credit card:</td>
                                                                <td>
                                                                    {this.state.data.hasCreditCardLoaded ? "Yes" : "No"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">One or more apartmetns have accessibility requirements:</td>
                                                                <td>
                                                                    {this.state.data.hasAccessibilityRequirements ? "Yes" : "No"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </Col>
                                            <Col md="3" className="text-center">
                                                <h6 className="text-left">Profile image</h6>
                                                <hr />
                                                {!this.state.data.profileImageId && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                                {this.state.data.profileImageId && <img alt="Uploaded by submitted" className="rounded-circle" src={this.state.assetUrl + "/image/" + this.state.data.profileImageId + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}
                                            </Col>
                                        </Row>

                                        {!this.state.loading && this.state.data && this.state.data.status === "active" &&
                                            <div className="alert alert-success" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>User Active:</strong> Do you want to block access for this user?</p>
                                                    </Col>
                                                    <Col md="2">
                                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.suspend} className="btn mr-2 mt-2 mb-2 btn-seconday">Suspend</Button>
                                                    </Col>
                                                </Row>
                                            </div>}


                                        {!this.state.loading && this.state.data && this.state.data.status === "suspended" &&
                                            <div className="alert alert-secondary" style={{ marginTop: "30px" }}>
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>User Suspended:</strong> Do you want allow this user to access the system again?</p>
                                                    </Col>
                                                    <Col md="2">
                                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.restore} className="btn mr-2 mt-2 mb-2 btn-primary">Restore</Button>
                                                    </Col>
                                                </Row>
                                            </div>}

                                    </CardBody>
                                </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' &&
                                <div>
                                    {this.state.addUserVisible && <Card>
                                        <CardBody>
                                            <Formik
                                                initialValues={{
                                                    apartmentId: "",
                                                    relationship: "resident"
                                                }}
                                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                                    if (fields.apartmentId) {
                                                        await HengyiApartmentClient.AddUser(fields.apartmentId.value, this.props.match.params.id, fields.relationship);
                                                        await this.populateData(this.props.match.params.id);
                                                        this.setState({
                                                            addUserVisible: false
                                                        });
                                                    } else {
                                                        setFieldError("apartmentId", "Please choose a space to add");
                                                    }
                                                }}
                                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                <Form>
                                                    <Row>
                                                        <Col>
                                                            <SelectField
                                                                id="apartmentId"
                                                                name="apartmentId"
                                                                label="Space"
                                                                placeholder="Select space"
                                                                options={this.state.apartmentOptions}
                                                                value={values.apartmentId}
                                                                isMulti={false}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                touched={touched.apartmentId}
                                                                error={errors.apartmentId}
                                                                isClearable={true}
                                                                backspaceRemovesValue={true}
                                                            />
                                                            <ErrorMessage name="apartmentId" component="div" className="invalid-feedback" />
                                                        </Col>
                                                        <Col>
                                                            <label htmlFor="relationship">Type</label>
                                                            <select name="relationship"
                                                                value={values.relationship}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={'form-control' + (errors.relationship && touched.relationship ? ' is-invalid' : '')}>
                                                                <option value="resident" label="Resident" />
                                                                <option value="owner" label="Owner" />
                                                                <option value="ownerOccupier" label="Owner occupier" />
                                                            </select>
                                                        </Col>
                                                        <Col md="1">
                                                            <div className="mt-4">
                                                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                    {!isSubmitting && "ADD"}
                                                                    {isSubmitting && <Spinner animation="border" />}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}</Formik>
                                        </CardBody>
                                    </Card>}

                                    <Card>
                                        <CardBody>
                                            <table className='table' aria-labelledby="tabelLabel">

                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}></th>
                                                        <th style={{ borderTop: "none" }}>Name</th>
                                                        <th style={{ borderTop: "none" }}>
                                                            {!this.state.addUserVisible && <button style={{ float: "right" }} onClick={() => { this.setState({ addUserVisible: true }); }} className="btn btn-outline-dark" ><FontAwesomeIcon icon={faPlus} /></button>}
                                                            {this.state.addUserVisible && <button style={{ float: "right" }} onClick={() => { this.setState({ addUserVisible: false }); }} className="btn btn-outline-dark" ><FontAwesomeIcon icon={faMinus} /></button>}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data && this.state.data.apartments && this.state.data.apartments.map(item =>
                                                        <tr key={item.apartment.id}>
                                                            <td style={{ width: "15px" }}>
                                                                {item.apartment.hasAccessibilityRequirements && <FontAwesomeIcon icon={faWheelchair} />}
                                                            </td>
                                                            <td>
                                                                <Link key={item.apartment.id + "-view"} to={"/apartments/" + item.apartment.id}>{item.apartment.name}</Link>
                                                                <Badge color="success" className="ml-2" style={{ textTransform: 'capitalize' }} >
                                                                    {item.relationship === "ownerOccupier" && "Owner occupier"}
                                                                    {item.relationship !== "ownerOccupier" && item.relationship}</Badge>
                                                            </td>
                                                            <td>
                                                                <Button onClick={() => { this.removeUserFromApartment(item.apartment.id); }} key={item.apartment.id + "-delete"} style={{ float: "right" }} className="btn mr-2 btn-danger mt-2"><FontAwesomeIcon icon={faTrash} /></Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.data && this.state.data.apartments.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No apartments to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        </CardBody></Card>
                                </div>}
                        </TabPane>

                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <ActivityTable title="User activity" description="List all activity for this user" userId={this.props.match.params.id} />}
                        </TabPane>

                        <userContext.Consumer>
                            {({ features }) => {
                                return (<>

                                    {features && features.includes("swipe-cards") && <TabPane tabId="4">
                                        {this.state.activeTab === '4' && <SwipeCardTable title="User cards" description="List all swipe cards for this user" userId={this.props.match.params.id} />}
                                    </TabPane>}

                                    {features && features.includes("billing") && <TabPane tabId="5">
                                        {this.state.activeTab === '5' && <ChargesTable title="User charges" description="List all charges to this user's account" userId={this.props.match.params.id} />}
                                    </TabPane>}

                                    {features && features.includes("inductions") &&this.state.actions && this.state.actions.inductionEnabled && <TabPane tabId="6">
                                        {this.state.activeTab === '6' && <div>

                                            <InductionsTable userId={this.props.match.params.id} refresh={this.state.actions.inductionStatus} enabledDelete={true} />

                                        </div>}
                                    </TabPane>}

                                </>)
                            }}
                        </userContext.Consumer>

                        <TabPane tabId="7">
                            {this.state.activeTab === '7' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Notes ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Notes for this user</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        var message = document.getElementById("message").value;

                                                        if (!message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiUserClient.AddNote(this.props.match.params.id, message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            document.getElementById("message").value = "";
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                                <textarea as="textarea" name="message" id="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                <userContext.Consumer>
                                                                    {({ user, logoutUser }) => {
                                                                        return (<>{user.permissions && user.permissions.includes("users.view.all") && item.status === "active" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}</>);
                                                                    }}
                                                                </userContext.Consumer>
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                        
                    </TabContent>



                </div>
            );
        }
    }


    async suspend() {
        await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + this.props.match.params.id + "/set-status", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                status: "suspended"
            })
        }));
        this.populateData(this.props.match.params.id);
    }

    async restore() {
        await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + this.props.match.params.id + "/set-status", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                status: "active"
            })
        }));
        this.populateData(this.props.match.params.id);
    }

    async revokeTempPass() {
        await HengyiUserClient.ClearTempPassword(this.props.match.params.id);
        this.populateData(this.props.match.params.id);
    }

    randomString(length) {

        var chars = '23456789ABCDEFGHIJKLMNPQRSTUVWXYZ'

        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    async setLockedStatus(locked) {
        this.setState({
            lockedLoading: true,
        })

        await HengyiUserClient.SetLockedState(this.props.match.params.id, locked);
        await this.populateData(this.props.match.params.id);
        this.setState({
            lockedLoading: false
        });
    }

    async setTempPass() {

        var pass = this.randomString(8);

        this.setState({
            tempPassLoading: true,
            addTempPassVisible: true,
            tempPass: pass
        });

        await HengyiUserClient.SetTempPassword(this.props.match.params.id, pass);
        await this.populateData(this.props.match.params.id);
        this.setState({
            tempPassLoading: false
        });
    }

    async removeUserFromApartment(userApartmentId) {
        await HengyiApartmentClient.RemoveUser(userApartmentId, this.props.match.params.id);
        this.populateData(this.props.match.params.id);
    }

    async update() {
        this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetUser(id);
            var actions = await HengyiApi.GetUserActions(id);

            var includeDeleted = this.state.includeDeleted ? true : null;
            var notes = (await HengyiUserClient.ListNotes(id, includeDeleted)).data;

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                var apartments = (await HengyiApartmentClient.List(new ApartmentQueryParams().Paginate(0, 10000))).data.data;
                this.setState({
                    data: response.data,
                    apartmentOptions: apartments.map(function (item) { return { value: item.id, label: item.name }; }),
                    loading: false,
                    actions: actions.data,
                    notes: notes.data
                });
            }
        }
    }
}
