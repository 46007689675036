import React, { Component } from 'react';
import { ScheduleEdit } from './ScheduleEdit';

export class EventScheduleEdit extends Component {

    render() {
        return (
            <ScheduleEdit eventId={this.props.match.params.id} scheduleId={this.props.match.params.sid}/>
        );
    }

}
