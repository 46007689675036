import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiHandoverNoteClient } from '../infrastructure/HengyiClient/HandoverNotes';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';
import { FacilitySelector } from '../infrastructure/FacilitySelector';

export class HandoverNotesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, note: ""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    note: this.state.note,
                    title: this.state.title,
                    apartmentId: "",
                    facilityId: ""
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    var response = await HengyiHandoverNoteClient.Update(this.props.match.params.id, this.state.title, this.state.note, fields.apartmentId ? fields.apartmentId.value : null, fields.facilityId ? fields.facilityId.value : null, null);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input name="title" id="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="title" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="note">Note</label>
                                    <textarea name="note" id="note" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    {touched.note && errors.note ? <p className="error-text">{errors.note}</p> : null}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <ApartmentSelector
                                    label="Space"
                                    initialApartmentId={this.state.apartmentId}
                                    value={values.apartmentId}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentId}
                                    error={errors.apartmentId} />
                            </Col>
                            <Col>
                                <FacilitySelector
                                    label="Facility"
                                    initialFacilityId={this.state.facilityId}
                                    value={values.facilityId}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.facilityId}
                                    error={errors.facilityId} />
                            </Col>
                        </Row>

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/handover-notes/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update handover note
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update note</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/handover-notes/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        var response = await HengyiHandoverNoteClient.Get(this.props.match.params.id);

        if (!response.authenticated) {
            this.setState({
                loading: false,
                authenticated: false,
                init: true
            });
        } else {

            if (response.data.apartment)
                this.setState({ apartmentId: response.data.apartment.id });
            if (response.data.facility)
                this.setState({ facilityId: response.data.facility.id });


            this.setState({
                loading: false,
                note: response.data.note,
                title: response.data.title,
                init: true
            }, () => {
                document.getElementById("note").value = response.data.note ? response.data.note : "";
                document.getElementById("title").value = response.data.title ? response.data.title : "";
            });
        }
    }
}
