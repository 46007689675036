import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { SideBar } from './SideBar';
import { NavMenu } from './NavMenu';
import { userContext } from '../../userContext';
import { HengyiApi } from '../infrastructure/fetchHengy';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        this.iniMenu();
    }

    render() {

        return (<userContext.Consumer>
                {({ user, logoutUser }) => {
                    if (HengyiApi.IsAuthenticated()) {
                        return (
                            <div className="bg-light">
                                <NavMenu />
                                <Row style={{marginRight: 0}}>
                                    <div className="col-md-2">
                                        <SideBar />
                                    </div>
                                    <div className="col-md-10">
                                        <div className="container-fluid" style={{ paddingTop: "20px" }}>
                                            {this.props.children}
                                        </div>
                                    </div>
                                </Row>

                            </div>);
                    } else {
                        return (
                            <div className="auth-wrapper aut-bg-img" style={{ backgroundImage: "url('assets/images/bg-images/bg3.jpg')" }}>
                                {this.props.children}
                            </div>
                        );
                    }
                }}
            </userContext.Consumer>

        );
    }


    iniMenu() {

    }

}
