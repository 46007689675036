import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Button } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { HengyiValetRequestClient, ValetRequestQueryParams } from "../infrastructure/HengyiClient/ValetRequests";
import NumberFormat from "react-number-format";
import { UserLink } from "../Users/UserLink";
import DatePicker from 'react-datepicker';
import { TableHeader } from "../infrastructure/TableHeader";
import { ApartmentSelector } from "../infrastructure/ApartmentSelector";

class ValetRequestsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false, total: 0, skip: 0, take: 50, search: "", sort: "created", direction: "desc"
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleFilterByDateChange = this.handleFilterByDateChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            type: "",
            skip: 0,
            apartmentId: "",
            filterDate: ""
        }, () => this.update());

    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    async componentDidMount() {

        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search);

            this.setState({
                search: urlParams.get("q") ? urlParams.get("q") : "",
                status: urlParams.get("status"),
                type: urlParams.get("type"),
                skip: 0
            }, () => this.update());
        } else {
            await this.update();
        }
    }

    handleFilterByDateChange = (date) => {
        this.setState({
            filterDate: date
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleTypeChange(evt) {
        this.setState({
            type: evt.target.value
        }, () => this.update());
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    async completeRequest(id) {
        var response = await HengyiValetRequestClient.Complete(id);

        if (!response.authenticated) {
            this.setState({
                authenticated: false
            });
        } else {
            await this.update();
        }
    }

    async cancelRequest(id) {
        var response = await HengyiValetRequestClient.Cancel(id);

        if (!response.authenticated) {
            this.setState({
                authenticated: false
            });
        } else {
            await this.update();
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    async populateData(skip, take) {

        var date = this.state.filterDate ? this.state.filterDate.toISOString() : null;

        const {
            parkedVehicleId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var response = await HengyiValetRequestClient.List(new ValetRequestQueryParams()
                .WithParkedVehicle(parkedVehicleId)
                .WithStatus(this.state.status)
                .WithType(this.state.type)
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
                .WithDate(date)
                .Paginate(skip, take)
                .Search(this.state.search)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }

    render() {
        const {
            title,
            description,
            parkedVehicleId,
            includeNewControl
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Valet requests"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all valet requests"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                    {includeNewControl && <Link style={{ float: "right" }} to="/valet-requests/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            <Col>
                                <ApartmentSelector
                                    placeholder="Space"
                                    value={this.state.apartmentId}
                                    onChange={this.handleApartmentIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="open">Open</option>
                                        <option value="completed">Completed</option>
                                        <option value="cancelled">Cancelled</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <InputGroup>
                                        <DatePicker
                                            id="date"
                                            onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                            placeholderText="Filter to a date"
                                            selected={this.state.filterDate}
                                            onChange={this.handleFilterByDateChange}
                                            dateFormat='dd/MM/yyyy'
                                            className="form-control"
                                            autoComplete="off" />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.type} onChange={this.handleTypeChange} >
                                        <option value="">All types</option>
                                        {!parkedVehicleId && <option value="requestDriver">Driver</option>}
                                        <option value="requestCar">Vehicle</option>
                                        <option value="requestItem">Item from car</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.type || this.state.filterDate || this.state.apartmentId) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "Type", sort: "type" },
                                        { name: "Status", sort: "status" },
                                        { name: "Message", sort: "message" },
                                        { name: "User", sort: "user.name" },
                                        { name: "Parked Vehicle", sort: "parkedVehicle.vehicle.licensePlate" },
                                        { name: "Total", sort: "total", static: true },
                                        { name: "Created", sort: "created" },
                                        { name: "", sort: "", static: true }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />
                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.type === "requestDriver" && <Badge color="primary">Driver</Badge>}
                                                {item.type === "requestCar" && <Badge color="success">Vehicle</Badge>}
                                                {item.type === "requestItem" && <Badge color="info">Item</Badge>}
                                            </td>
                                            <td>
                                                {item.status === "open" && <Badge color="primary">Open</Badge>}
                                                {item.status === "completed" && <Badge color="success">Completed</Badge>}
                                                {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                            </td>
                                            <td style={{whiteSpace: "pre-wrap"}}>
                                                {item.message && item.message}
                                                {!item.message && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                <UserLink user={item.user} />
                                            </td>
                                            {!parkedVehicleId && <td>
                                                {!item.parkedVehicle && <i>None</i>}
                                                {item.parkedVehicle && <span>{item.parkedVehicle.ticketNumber} {item.parkedVehicle.vehicle.make} {item.parkedVehicle.vehicle.model}  {item.parkedVehicle.vehicle.licensePlate}</span>}
                                            </td>}
                                            <td>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={item.total / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                            </td>
                                            <td><Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></td>
                                            <td>
                                                {item.status === "open" && <Button onClick={() => { this.completeRequest(item.id); }} key={item.id + "-complete"} style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark mt-2">Complete</Button>}
                                                {item.status === "open" && <Button onClick={() => { this.cancelRequest(item.id); }} key={item.id + "-complete"} style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark mt-2">Cancel</Button>}
                                            </td>
                                        </tr>
                                    )}

                                    {this.state.data.length === 0 && <tr><td colSpan="7"><h4 className="text-muted text-center mt-3"><i>No requests to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { ValetRequestsTable };
