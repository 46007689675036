import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ChargeQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithCreatedByUser(value: string) {
        this.WithParam("createdById", value);
        return this;
    }

    WithBillingCode(value: string) {
        this.WithParam("billingCodeId", value);
        return this;
    }

    WithInvoice(value: string) {
        this.WithParam("invoiceId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithShowVoided(value: Boolean) {
        this.WithParam("showVoided", value);
        return this;
    }
}

//******************************
// CHARGES
//******************************
export class HengyiChargeClient {

    static async List(params: ChargeQueryParams) {

        var query = (await GetAPIDomain()) + "/charges" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Export(params: ChargeQueryParams) {

        var query = (await GetAPIDomain()) + "/charges/export.csv" + params.GenerateQueryString();

        var response = await fetch(query, {
            headers: await GetAPIHeaders()
        });

        return await response.blob();
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/charges/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(userId: string, amount: number, description: string, billingCodeId?: string,) {

        var request: any = {
            "amount": amount,
            "userId": userId,
            "description": description,
            "billingCodeId": billingCodeId
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/charges/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, amount: number, description: string, billingCodeId?:string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/charges/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "amount",
                "value": amount
            }, {
                    "op": "replace",
                    "path": "description",
                    "value": description
                }, {
                    "op": "replace",
                    "path": "billingCodeId",
                    "value": billingCodeId
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/charges/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/charges/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/charges/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/charges/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}