import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiBillingCodeClient } from '../infrastructure/HengyiClient/BillingCodes';

export class BillingCodesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: false, loading: false, authenticated: true, next: false, id: "", errors: {}, isSubmitting: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    async handleSubmit(event) {

        this.setState({
            errors: {},
            isSubmitting: true
        });

        HengyiBillingCodeClient.Create(this.state.name, this.state.description, this.state.externalReference).then((response) => {

            if (!response.successful) {

                var errors = {};

                response.data.validationErrors.map(error => {
                    errors[error.key] = error.message;
                    return {
                        [error.key]: error.message
                    };
                });

                this.setState({
                    errors: errors
                });

            } else {
                this.setState({ next: true, id: response.data.id });
            }


            this.setState({
                isSubmitting: false
            });

        });

        return false;
    }

    renderForm() {

        return (<form onSubmit={() => false}>
            <Row>
                <Col>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" name="name" type="text" className={'form-control' + (this.state.errors.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                        {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.name}</div>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label htmlFor="externalReference">External Reference</label>
                        <input name="externalReference" type="text" className="form-control" onChange={this.handleChange} />
                        {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.externalReference}</div>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <input name="description" type="text" component="textarea" className={'form-control' + (this.state.errors.description ? ' is-invalid' : '')} onChange={this.handleChange} />
                        {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.description}</div>}
                    </div>
                </Col>
            </Row>

            <div className="form-group">
                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={this.state.isSubmitting} onClick={this.handleSubmit}>
                    {!this.state.isSubmitting && "SAVE"}
                    {this.state.isSubmitting && <Spinner animation="border" />}
                </button>
            </div>
        </form>);

    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/billing-codes/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New billing code
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new billing code to classify charges</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/billing-codes" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false,
                next: false
            });
        }
    }
}
