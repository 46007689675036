import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SwipeCardQueryParams } from "../infrastructure/HengyiClient/SwipeCards";
import { HengyiSwipeCardClient } from "../infrastructure/HengyiClient/SwipeCards";
import { UserLink } from "../Users/UserLink";
import { TableHeader } from "../infrastructure/TableHeader";

class SwipeCardTable extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", type: "", status: "", assignedFilter: "", sort: "name", direction: "asc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleAssignedChange = this.handleAssignedChange.bind(this);
    }

    async clear() {

        this.setState({
            search: "",
            assignedFilter: "",
            status: "",
            type: "",
            skip: 0
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleAssignedChange(evt) {
        this.setState({
            assignedFilter: evt.target.value
        }, () => this.update());
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleTypeChange(evt) {
        this.setState({
            type: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            contractorId,
            userId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new SwipeCardQueryParams()
                .WithType(this.state.type)
                .WithStatus(this.state.status)
                .Sort(this.state.sort, this.state.direction);

            if (contractorId)
                params = params.WithContractor(contractorId);

            if (userId)
                params = params.WithUser(userId);

            if (this.state.assignedFilter)
                params = params.IsAssigned(this.state.assignedFilter);

            var response = await HengyiSwipeCardClient.List(params.Paginate(skip, take)
                .Search(this.state.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }

    render() {
        const {
            title,
            description,
            enableEditControls,
            userId,
            contractorId
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Swipe Cards"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all swipe cards"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                    {enableEditControls && < Link style={{ float: "right" }} to="/swipe-cards/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>}
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.type} onChange={this.handleTypeChange} >
                                        <option value="">All types</option>
                                        <option value="accessCard">Access</option>
                                        <option value="evChargeCard">EV Charge</option>
                                        <option value="key">Key</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            {(!userId && !contractorId) && <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="available">Available</option>
                                        <option value="resident">Assigned to resident</option>
                                        <option value="contractor">Assigned to contractor</option>
                                        <option value="lost">Lost</option>
                                        <option value="disabled">Disabled</option>
                                    </Input>
                                </InputGroup>
                            </Col>}
                            {(!userId && !contractorId) && <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.assignedFilter} onChange={this.handleAssignedChange} >
                                        <option value="">Assigned status</option>
                                        <option value="False">Unassigned</option>
                                        <option value="True">Assigned</option>
                                    </Input>
                                </InputGroup>
                            </Col>}
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.type || this.state.assignedFilter) &&
                                                <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">

                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "Name", sort: "name" },
                                        { name: "Identifier", sort: "cardIdentifier" },
                                        { name: "Type", sort: "type" },
                                        { name: "User", sort: "user.name", disable: userId == null && contractorId == null },
                                        { name: "Contractor", sort: "contractor.name", disable: userId == null && contractorId == null },
                                        { name: "Status", sort: "status" },
                                        { name: "", sort: "", static: true, disable: enableEditControls == null }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />

                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.name && item.name}
                                                {!item.name && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {item.cardIdentifier && item.cardIdentifier}
                                                {!item.cardIdentifier && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                {item.type === "accessCard" && <Badge color="primary">Access</Badge>}
                                                {item.type === "evChargeCard" && <Badge color="success">EV Charge</Badge>}
                                                {item.type === "key" && <Badge color="info">Key</Badge>}
                                            </td>
                                            {(!userId && !contractorId) && <td>
                                                {item.user && <UserLink user={item.user} />}
                                                {!item.user && <i>Not specified</i>}
                                            </td>}
                                            {(!userId && !contractorId) && <td>
                                                {item.contractor && <span>{item.contractor.name} {item.contractor.surname} {item.contractor.company && <span>-  {item.contractor.company}</span>}</span>}
                                                {!item.contractor && <i>Not specified</i>}
                                            </td>}
                                            <td>
                                                {item.status === "available" && <Badge color="success">Available</Badge>}
                                                {item.status === "resident" && <Badge color="success">Assigned to resident</Badge>}
                                                {item.status === "contractor" && <Badge color="success">Assigned to contractor</Badge>}
                                                {item.status === "lost" && <Badge color="secondary">Lost</Badge>}
                                                {item.status === "disabled" && <Badge color="secondary">Disabled</Badge>}
                                            </td>
                                            {enableEditControls && <td>
                                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/swipe-cards/" + item.id}>Details</Link>
                                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/swipe-cards/edit/" + item.id}>Edit</Link>
                                            </td>}
                                        </tr>
                                    )}
                                    {this.state.data.length === 0 && <tr><td colSpan="4"><h4 className="text-muted text-center mt-3"><i>No cards to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { SwipeCardTable };
