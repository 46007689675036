import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class UsersQueryParams extends ListQueryParams {

    WithUninvoicedCharges(value: Boolean) {
        this.WithParam("hasUninvoicedCharges", value);
        return this;
    }

    WithAccessibility(value: Boolean) {
        this.WithParam("hasAccessibilityRequirements", value);
        return this;
    }

    WithInductionDocumentId(value: string) {
        this.WithParam("inductionDocumentId", value);
        return this;
    }
}

export class HengyiUserClient {

    static async List(params: UsersQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async ClearTempPassword(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/clear-temp-password", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
    static async SetTempPassword(id: string, tempPassword: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/set-temp-password", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                tempPassword: tempPassword
            })
        }));
    }


    static async SetLockedState(id: string, locked: Boolean) {

        var path = "lock";
        if (!locked) {
            path = "unlock";
        }
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/" + path, {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }


    static async SetStatus(id: string, status: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                status: status
            })
        }));
    }

    static async UpdateUser(id: string, name: string, surname: string, email: string, mobile: string, roles: string[], imageId?: string, preferredParcelDeliveryOption?: string, preferredParcelDeliverySlot?: string) {

        var updates = [{
            "op": "replace",
            "path": "preferredParcelDeliveryOption",
            "value": preferredParcelDeliveryOption
        }, {
            "op": "replace",
            "path": "preferredParcelDeliverySlot",
            "value": preferredParcelDeliverySlot
        }, {
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
            "path": "surname",
            "value": surname
        }, {
            "op": "replace",
            "path": "email",
            "value": email
        }, {
            "op": "replace",
            "path": "mobile",
            "value": mobile
        }, {
            "op": "replace",
            "path": "roles",
            "value": roles
        }, {
            "op": "replace",
            "path": "profileImageId",
            "value": imageId
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }



    static async DeleteNote(id: string, noteId: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/notes/" + noteId;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async ListNotes(id: string, includeDeleted?: Boolean) {

        var params = new ListQueryParams()
            .Descending()
            .Sort("created")
            .Paginate(0, 1000);

        if (includeDeleted)
            params = params.WithParam("includeDeleted", "true");

        var query = (await GetAPIDomain()) + "/users/" + id + "/notes" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async AddNote(id: string, note: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/notes";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "message": note
            })
        }));

    }
}