import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, TabContent, TabPane, Spinner, Card, CardHeader, Row, Col, CardBody, Button, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { GetAssetDomain, GetAPIDomain, GetAPIHeaders } from '../infrastructure/Helper';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'react-moment';
import { ActivityTable } from '../Activity/ActivityTable';
import InnerImageZoom from 'react-inner-image-zoom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { UserLink } from '../Users/UserLink';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { HengyiHandoverNoteClient, HandoverNoteCommentParams } from '../infrastructure/HengyiClient/HandoverNotes';

export class HandoverNotesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, includeDeleted: false, next: false,
            activeTab: '1'
        };

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);

        this.toggle = this.toggle.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.handleIncludeDeletedChange = this.handleIncludeDeletedChange.bind(this);
    }

    handleIncludeDeletedChange = (evt) => {
        this.setState({
            includeDeleted: evt.target.checked
        }, () => this.update());
    }

    async deleteNote(id) {
        await HengyiHandoverNoteClient.DeleteNote(this.props.match.params.id, id);
        await this.update();
    }

    async close() {
        await HengyiHandoverNoteClient.CloseHandoverNote(this.props.match.params.id);
        await this.update();
    }

    async open() {
        await HengyiHandoverNoteClient.OpenHandoverNote(this.props.match.params.id);
        await this.update();
    }

    async downloadFile(id, name) {

        var link = document.createElement('a');
        link.href = await GetAssetDomain() + "/file/" + id;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        this.update();
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
        this.setState({
            assetUrl: await GetAssetDomain()
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async update() {
        await this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/handover-notes" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/handover-notes" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Handover note details</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && this.state.data.status === "open" && <Link style={{ float: "right" }} to={"/handover-notes/" + this.state.data.id + "/assign"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Assign</Link>}
                                        {this.state.data && this.state.data.status === "open" && <Link style={{ float: "right" }} to={"/handover-notes/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    {!this.state.loading && this.state.data && this.state.data.status === "open" &&
                        <div>
                            <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>This note is currently open</strong>, mark this note as closed when no longer applicable</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/handover-notes" onClick={this.close} className="btn mr-2 mt-2 mb-2 btn-info">Close note</Button>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        </div>
                    }

                    {!this.state.loading && this.state.data && this.state.data.status === "closed" &&
                        <div>
                            <div className="alert alert-info" style={{ marginTop: "30px" }}>
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>This note is currently closed</strong>, mark this note as open if it is still active</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/handover-notes" onClick={this.open} className="btn mr-2 mt-2 mb-2 btn-info">Open note</Button>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        </div>
                    }

                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Comments
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Files
                            </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}>
                                        Activity
                            </NavLink>
                                </NavItem>
                            </Nav>

                        </CardHeader>
                    </Card>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data &&
                                <div>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <h6>Created by</h6>
                                                    <hr />
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {this.state.data.submittedBy && <UserLink user={this.state.data.submittedBy} />}
                                                                    {!this.state.data.submittedBy && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.submittedBy && <tr>
                                                                <td>
                                                                    {this.state.data.submittedBy.email && this.state.data.submittedBy.email}
                                                                    {!this.state.data.submittedBy.email && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            {this.state.data.submittedBy && <tr>
                                                                <td>
                                                                    {this.state.data.submittedBy.mobile && this.state.data.submittedBy.mobile}
                                                                    {!this.state.data.submittedBy.mobile && <i>Not specified</i>}
                                                                </td>
                                                            </tr>}

                                                        </tbody>
                                                    </table>
                                                </Col>

                                                <Col>
                                                    <h6>Assigned to</h6>
                                                    <hr />
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {this.state.data.assignedTo && <UserLink user={this.state.data.assignedTo} />}
                                                                    {!this.state.data.assignedTo && <i>Not assigned</i>}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.assignedTo && <tr>
                                                                <td>
                                                                    {this.state.data.assignedTo.email && this.state.data.assignedTo.email}
                                                                    {!this.state.data.assignedTo.email && <i>Email not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            {this.state.data.assignedTo && <tr>
                                                                <td>
                                                                    {this.state.data.assignedTo.mobile && this.state.data.assignedTo.mobile}
                                                                    {!this.state.data.assignedTo.mobile && <i>Mobile not specified</i>}
                                                                </td>
                                                            </tr>}

                                                        </tbody>
                                                    </table>
                                                </Col>

                                                <Col>
                                                    <h6>Closed by</h6>
                                                    <hr />
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {this.state.data.closedBy && <UserLink user={this.state.data.closedBy} />}
                                                                    {!this.state.data.closedBy && <i>Not closed</i>}
                                                                </td>
                                                            </tr>
                                                            {this.state.data.closedBy && <tr>
                                                                <td>
                                                                    {this.state.data.closedBy.email && this.state.data.closedBy.email}
                                                                    {!this.state.data.closedBy.email && <i>Email not specified</i>}
                                                                </td>
                                                            </tr>}
                                                            {this.state.data.closedBy && <tr>
                                                                <td>
                                                                    {this.state.data.closedBy.mobile && this.state.data.closedBy.mobile}
                                                                    {!this.state.data.closedBy.mobile && <i>Mobile not specified</i>}
                                                                </td>
                                                            </tr>}

                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>

                                            <Row>
                                                <Col>
                                                    <h6>
                                                        Details &nbsp;

                                                    {this.state.data.status === "open" && <Badge color="primary">Open</Badge>}
                                                        {this.state.data.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                                    </h6>
                                                    <hr />
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Title: </td>
                                                                <td>
                                                                    {this.state.data.title}
                                                                    {!this.state.data.title && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Space:&nbsp;&nbsp;</td>
                                                                <td>
                                                                    {this.state.data.apartment && this.state.data.apartment.name}
                                                                    {!this.state.data.apartment && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Facility: </td>
                                                                <td>
                                                                    {this.state.data.facility && this.state.data.facility.name}
                                                                    {!this.state.data.facility && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <hr />
                                                    <p>
                                                        {this.state.data.note}
                                                    </p>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>

                                </div>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>
                                <Card>

                                    {this.state.notes && <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5> Comments ({this.state.notes.length})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                                </h5>
                                                <span className="d-block m-t-5 text-muted">Comments about this handover note</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>

                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": true }); }}>Add</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardHeader>

                                        <div className="form-group">
                                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                                <li className="list-inline-item">
                                                    <label style={{ margin: "0" }}>
                                                        <input name="includeDeleted"
                                                            type="checkbox"
                                                            checked={this.state.includeDeleted}
                                                            onChange={this.handleIncludeDeletedChange} />  Show deleted notes</label>
                                                </li>
                                            </ul>
                                        </div>

                                    </CardHeader>

                                    {this.state.showAddNote && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        var message = document.getElementById("message").value;

                                                        if (!message) {
                                                            setFieldError("message", "Please specify the message to send");
                                                            return;
                                                        }



                                                        var response = await HengyiHandoverNoteClient.AddComment(this.props.match.params.id, message);

                                                        if (!response.successful) {
                                                            response.data.validationErrors.map(error => {
                                                                setFieldError(error.key, error.message);
                                                                return {
                                                                    [error.key]: error.message
                                                                };
                                                            });

                                                        } else {
                                                            document.getElementById("message").value = "";
                                                            setFieldValue("message", "");
                                                            this.setState({ showAddNote: false });
                                                            await this.update();
                                                        }
                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="message">Note</label>
                                                                <textarea as="textarea" name="message" id="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Save"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddNote": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}

                                    <CardBody className="p-0">
                                        {this.state.notes &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Note</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}>Created</th>
                                                        <th className="text-right" style={{ borderTop: "none" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.notes.map(item =>
                                                        <tr key={item.id}>
                                                            <td>{item.user.name} {item.user.surname}</td>
                                                            <td style={{ whiteSpace: "pre-line" }}>{item.message}</td>
                                                            <td className="text-right">
                                                                <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>

                                                            </td>
                                                            <td>
                                                                {item.status !== "deleted" && <Button style={{ float: "right", backgroundColor: "white" }} className="btn btn-outline-dark" onClick={() => { this.deleteNote(item.id); }}>Delete</Button>}
                                                                {item.status === "deleted" && <Badge color="danger" className="ml-2">Deleted</Badge>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.notes.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>
                            </div>}
                        </TabPane>

                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <div>

                                <Card>

                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <h5>Uploaded images and files</h5>
                                                <span className="d-block m-t-5 text-muted">Images and files uploaded to this handover note</span>
                                            </Col>
                                            <Col>
                                                <div style={{ paddingRight: "10px" }}>
                                                    <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddFile": true }); }}>Upload</Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardHeader>


                                    {this.state.showAddFile && <CardHeader>
                                        <Row className="mt-2">
                                            <Col>

                                                <Formik
                                                    initialValues={{
                                                        message: ""
                                                    }}
                                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                                        if (fields.file) {

                                                            let formData = new FormData();
                                                            formData.append("file", fields.file);

                                                            await fetch((await GetAPIDomain()) + "/handover-notes/" + this.props.match.params.id + "/images", {
                                                                method: 'post',
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                                                                },
                                                                body: formData
                                                            });
                                                        }

                                                        this.setState({ showAddFile: false });
                                                        await this.update();

                                                    }}
                                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <label htmlFor="file">File</label>
                                                            <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                                                this.setState({
                                                                    file: URL.createObjectURL(event.target.files[0])
                                                                })
                                                                setFieldValue("file", event.currentTarget.files[0]);
                                                            }} />

                                                        </div>

                                                        <hr />
                                                        <div className="mt-4">
                                                            <button style={{ float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                                {!isSubmitting && "Upload"}
                                                                {isSubmitting && <Spinner animation="border" />}
                                                            </button>
                                                            <Button style={{ float: "right", backgroundColor: "white" }} className="btn mr-2 btn-outline-dark mt-2 mb-2" onClick={() => { this.setState({ "showAddFile": false }); }}>Cancel</Button>
                                                        </div>
                                                    </Form>
                                                )}</Formik>
                                            </Col>
                                        </Row>

                                    </CardHeader>}
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h6>Images</h6>
                                        <hr />

                                        <Row>
                                            {this.state.data && this.state.data.images && this.state.data.images.length > 0 && this.state.data.images.map(item =>
                                                <Col style={{ maxWidth: "300px" }}>
                                                    <InnerImageZoom src={this.state.assetUrl + "/image/" + item + ".jpg"} />
                                                </Col>
                                            )}
                                        </Row>
                                        {(!this.state.data || !this.state.data.images || !this.state.data.images.length > 0) &&
                                            <h4 className="text-muted text-center mt-3"><i>No images uploaded</i></h4>
                                        }

                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h6>Files</h6>
                                        <hr />
                                        {this.state.data && this.state.data.files && this.state.data.files.length > 0 && this.state.data.files.map(file => <Row style={{ paddingTop: "10px" }}>
                                            <Col>
                                                <h5>
                                                    {file.fileName}
                                                </h5>
                                            </Col>
                                            <Col style={{
                                                textAlign: "right"
                                            }}>
                                                <button onClick={() => { this.downloadFile(file.id, file.fileName); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                                            </Col>
                                        </Row>)}

                                        {(!this.state.data || !this.state.data.files || !this.state.data.files.length > 0) &&
                                            <h4 className="text-muted text-center mt-3"><i>No files uploaded</i></h4>
                                        }
                                    </CardBody>
                                </Card>

                            </div>}
                        </TabPane>

                        <TabPane tabId="4">
                            {this.state.activeTab === '4' && <ActivityTable title="Note activity" description="List all activity for this handover note" entityId={this.props.match.params.id} />}
                        </TabPane>

                    </TabContent>

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiHandoverNoteClient.Get(id);
            var comments = await HengyiHandoverNoteClient.ListComments(id, new HandoverNoteCommentParams().WithIncludeDeleted(this.state.includeDeleted).Paginate(0, 1000));

            console.log(response);
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false, notes: comments.data.data });
            }
        }
    }

}
