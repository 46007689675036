import React, { Component } from 'react';
import { HengyiFacilityClient } from '../infrastructure/HengyiClient/Facilities';
import { Link, Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane, Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Button } from 'reactstrap';
import classnames from 'classnames';
import { GetAssetDomain } from '../infrastructure/Helper';
import { ScheduleTable } from '../Schedules/ScheduleTable';
import { BookingTypesTable } from '../BookingTypes/BookingTypesTable';
import { BookingsTable } from '../Bookings/BookingsTable';
import { AvailabilityView } from '../Availability/AvailabilityView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { userContext } from '../../userContext';

export class FacilityDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            authenticated: true,
            next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/facilities" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/facilities" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>{this.state.data.name}</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Facility details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/facilities/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>
                            <userContext.Consumer>
                                {({ features }) => {
                                    return (<Nav pills style={{ boxShadow: "none" }}>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}>
                                                Details </NavLink>
                                        </NavItem>
                                        {features && features.includes("bookings") && <><NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }}>
                                                Schedules </NavLink>
                                        </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => { this.toggle('3'); }}>
                                                    Booking Types </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({ active: this.state.activeTab === '4' })}
                                                    onClick={() => { this.toggle('4'); }}>
                                                    Bookings </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({ active: this.state.activeTab === '5' })}
                                                    onClick={() => { this.toggle('5'); }}>
                                                    Availability </NavLink>
                                            </NavItem></>}
                                    </Nav>)
                                }}</userContext.Consumer>

                        </CardHeader>
                    </Card>
                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <div>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <h6>Details</h6>
                                                <hr />
                                                {this.state.data &&
                                                    <>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pr-2">Name:</td>
                                                                    <td>
                                                                        {this.state.data.name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pr-2">Status:</td>
                                                                    <td>
                                                                        {this.state.data.status === "available" && <Badge color="success">Available</Badge>}
                                                                        {this.state.data.status === "closed" && <Badge color="secondary">Closed</Badge>}
                                                                    </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="pr-2">Building:</td>
                                                                <td>
                                                                    {this.state.data.building && <Link key={this.state.data.building.id + "-view"} to={"/buildings/" + this.state.data.building.id}>{this.state.data.building.name}</Link>}
                                                                    {!this.state.data.building && <i>Not specified</i>}
                                                                </td>
                                                            </tr>
                                                                <tr>
                                                                    <td className="pr-2">Type:</td>
                                                                    <td>
                                                                        {this.state.data.type === "general" && <Badge color="primary">General</Badge>}
                                                                        {this.state.data.type === "evCharger" && <Badge color="primary">EV Charger</Badge>}
                                                                        {this.state.data.type === "lift" && <Badge color="primary">Lift</Badge>}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        {this.state.data.description && <>
                                                            <hr />
                                                            <p>{this.state.data.description}</p>
                                                        </>}
                                                    </>}
                                            </Col>

                                            <Col md="3" className="text-center">
                                                <h6 className="text-left">Background image</h6>
                                                <hr />
                                                {!this.state.data.backgroundImageId && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                                {this.state.data.backgroundImageId && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.backgroundImageId + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>


                                {this.state.data && this.state.data.status === "closed" &&
                                    <div className="alert alert-primary">
                                        <Row>
                                            <Col>
                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>This facility is <strong>closed</strong>. It can now be permanently deleted.</p>

                                            </Col>
                                            <Col md="2">
                                                <Button style={{ float: "right" }} to="/facilities" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-info">Delete</Button>
                                            </Col>
                                        </Row>
                                    </div>}

                                </div>
                            }
                        </TabPane>
                        <userContext.Consumer>
                        {({ features }) => {
                            return (<>

                                {features && features.includes("bookings") && <><TabPane tabId="2">
                                    {this.state.activeTab === '2' && <ScheduleTable facilityId={this.props.match.params.id} />}
                                </TabPane>
                                    <TabPane tabId="3">
                                        {this.state.activeTab === '3' && this.state.data && <BookingTypesTable facilityId={this.props.match.params.id} buildingId={this.state.data.building.id} />}
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {this.state.activeTab === '4' && <BookingsTable facilityId={this.props.match.params.id} />}
                                    </TabPane>
                                    <TabPane tabId="5">
                                        {this.state.activeTab === '5' && <AvailabilityView facilityId={this.props.match.params.id} />}
                                    </TabPane></>}
                            </>)
                            }}
                        </userContext.Consumer>
                    </TabContent>
                </div>
            );
        }
    }

    async delete() {
        var success = await HengyiFacilityClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiFacilityClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    data: response.data,
                    loading: false
                });
            }
        }
    }
}
