import { HengyiApi } from "./fetchHengy";

export async function GetAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    return domain;
}


export async function GetAssetDomain() {
    var domain = localStorage.getItem("asset-domain-2");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainAsset;
        localStorage.setItem("asset-domain-2", domain);
    }

    return domain;
}

export async function GetResidentDomain() {
    var domain = localStorage.getItem("resident-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainResident;
        localStorage.setItem("resident-domain", domain);
    }

    return domain;
}

export async function GetAPIHeaders(contentType) {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await HengyiApi.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    return new Headers({ 'content-type': contentType ? contentType : 'application/json', 'Authorization': 'Bearer ' + accessToken });
}


export async function GetAPIHeadersPatch() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await HengyiApi.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");

    return new Headers({ 'content-type': 'application/json-patch+json', 'Authorization': 'Bearer ' + accessToken });
}