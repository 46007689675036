import React, { Component } from 'react';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";

export class UsersNew extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, authenticated: true, next: false, roleOptions: [], apartmentOptions: [] };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                surname: "",
                email: "",
                mobile: "",
                temporaryPassword: "",
                apartments: [],
                roles: [],
                apartmentLivingIn: "",
                preferredParcelDeliveryOption: "askEachTime",
                preferredParcelDeliverySlot: this.state.preferredParcelDeliverySlot
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                console.log(fields);

                var request = {
                    name: this.state.name,
                    surname: this.state.surname,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    temporaryPassword: fields.temporaryPassword,
                    preferredParcelDeliveryOption: fields.preferredParcelDeliveryOption,
                    preferredParcelDeliverySlot: fields.preferredParcelDeliverySlot
                }

                if (fields.apartmentLivingIn)
                    request.apartmentLivingIn = fields.apartmentLivingIn.value;

                if (fields.apartments)
                    request.apartmentsOwned = fields.apartments.map(a => a.value);

                if (fields.roles)
                    request.roles = fields.roles;

                const response = await fetch((await GetAPIDomain()) + "/users", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(request)
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, handleChange, handleBlur,touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div></Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="surname">Surname</label>
                                <input name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div></Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <input name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="type">Parcel preference</label>
                                <select type="select" name="preferredParcelDeliveryOption"
                                    value={values.preferredParcelDeliveryOption}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.preferredParcelDeliveryOption && touched.preferredParcelDeliveryOption ? ' is-invalid' : '')}>
                                    <option value="askEachTime">Ask each time</option>
                                    <option value="bringUp">Bring up to my space (may incur small fee)</option>
                                </select>
                                <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        {values.preferredParcelDeliveryOption === "bringUp" && <Col>
                            <div className="form-group">
                                <label htmlFor="type">Preferred delivery slot</label>
                                <select type="select" name="preferredParcelDeliverySlot"
                                    value={values.preferredParcelDeliverySlot}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.preferredParcelDeliverySlot && touched.preferredParcelDeliverySlot ? ' is-invalid' : '')}>
                                    <option value="">Any delivery slot</option>
                                    <option value="morning">Morning (before 12)</option>
                                    <option value="afternoon">Afternoon (12-5)</option>
                                    <option value="evening">Evening (after 5)</option>
                                </select>
                                <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                            </div>
                        </Col>}
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartments"
                                    name="apartments"
                                    label="Spaces owned"
                                    placeholder="Select spaces"
                                    options={this.state.apartmentOptions}
                                    value={values.apartments}
                                    isMulti={true}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartments}
                                    error={errors.apartments}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartmentLivingIn"
                                    name="apartmentLivingIn"
                                    label="Space living in"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentLivingIn}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentLivingIn}
                                    error={errors.apartmentLivingIn}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="form-group">
                        <h5>Roles</h5>
                        <hr />
                        <FieldArray name="roles"
                            render={arrayHelpers => (
                                <ul className="list-unstyled">
                                    {this.state.roleOptions && this.state.roleOptions.map(role => {

                                        return (
                                            <li><label>
                                                <input
                                                    name="roles"
                                                    type="checkbox"
                                                    value={role.id}
                                                    checked={values.roles.includes(role.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) arrayHelpers.push(role.id);
                                                        else {
                                                            const idx = values.roles.indexOf(role.id);
                                                            arrayHelpers.remove(idx);
                                                        }
                                                    }}
                                                />{" "}
                                                {role.name}
                                            </label>
                                                <p className="text-muted">{role.description}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )} />
                        <ErrorMessage name="roles" component="div" className="invalid-feedback" />
                    </div>

                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to="/users" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a new user to the platform</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/users" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetRoles(new ListQueryParams().Paginate(0, 50));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({
                    loading: false,
                    roleOptions: response.data.data,
                    apartmentOptions: (await HengyiApartmentClient.List(new ApartmentQueryParams().Paginate(0, 500))).data.data.map(function (apartment) { return { value: apartment.id, label: apartment.name }; })
                });
            }
        }
    }
}
