import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from './Helper';

export class ApiResponse {

    constructor() {
        this.successful = false;
        this.authenticated = false;
    }

    static Failed() {
        var result = new ApiResponse();
        result.authenticated = true;
        result.successful = false;
        return result;
    }

    static async Create(response: Response) {
        var result = new ApiResponse();

        result.authenticated = true;

        if (!response.ok) {
            result.successful = false;

            if (response.status === 401) {
                HengyiApi.LogOut();
                result.authenticated = false;
                window.location.reload();
            }
            else if (response.status !== 500)
            {
                try {
                    var data = await response.json();
                    result.data = data;
                    result.validationErrors = data.validationErrors;
                } catch (ex) {
                    console.log("No validate errors for this request");
                }
            }
        } else {
            result.successful = true;
            try {
                result.data = await response.json();
            } catch (ex) {
                console.log(ex);
                console.log("No content for this request");
            }
        }

        return result;
    }

    successful: Boolean;
    validationErrors: any;
    authenticated: Boolean;
    data: any;
}

export class ListQueryParams {

    constructor() {
        this.skip = 0;
        this.take = 50;
        this.direction = "ascending"
        this.params = Array<QueryParameter>();
    }

    Sort(sort: string, direction?: string) {
        this.sort = sort;

        if (direction && direction === "desc")
            return this.Descending();
        if (direction && direction !== "desc")
            return this.Ascending();

        return this;
    }

    Descending() {
        this.direction = "descending";
        return this;
    }

    Ascending() {
        this.direction = "ascending";
        return this;
    }

    Paginate(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
        return this;
    }

    Search(value: string) {
        this.WithParam("q", value);
        return this;
    }

    WithStart(value: any) {
        this.WithParam("start", value);
        return this;
    }

    WithEnd(value: any) {
        this.WithParam("end", value);
        return this;
    }

    WithParam(param: string, value: any) {

        this.params.push({
            key: param,
            value: value
        });

        return this;
    }

    skip: number;
    take: number;
    direction: string;
    params: QueryParameter[];
    sort?: string;

    GenerateQueryString() {
        var query = "?skip=" + this.skip + "&take=" + this.take;
        if (this.sort)
            query = query + "&sort=" + this.sort + "&direction=" + this.direction;

        if (this.params) {
            this.params.map(param => {
                if (param.value && param.key) {
                    if (param.value instanceof Date) {
                        query = query + "&" + param.key + "=" + (<Date>param.value).toISOString();
                    } else {
                        query = query + "&" + param.key + "=" + param.value;
                    }
                }
            });
        }

        return query;
    }
}

export class QueryParameter {
    key?: string;
    value?: any;
}

export class TicketCategoryQueryParams extends ListQueryParams {
    WithTicketType(value: string) {
        this.WithParam("ticketTypeId", value);
        return this;
    }
}

export class ActivityQueryParams extends ListQueryParams {

    WithEntity(value: string) {
        this.WithParam("entityId", value) as ActivityQueryParams;
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value) as ActivityQueryParams;
        return this;
    }

    AssignedToUser(value: Boolean) {
        this.WithParam("assignedToUser", value);
        return this;
    }

}

export class DocumentEventQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithDocument(value: string) {
        this.WithParam("documentId", value);
        return this;
    }
}

export class UserQueryParams extends ListQueryParams {

    WithRole(value: string) {
        this.WithParam("roleId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("userStatus", value);
        return this;
    }

    WithShowSuspended(value: string) {
        this.WithParam("showSuspended", value);
        return this;
    }

    WithApartment(value: string) {
        this.WithParam("apartmentId", value);
        return this;
    }

    WithAccessibility(value: Boolean) {
        this.WithParam("hasAccessibilityRequirements", value);
        return this;
    }

    WithVehicle(value: string) {
        this.WithParam("vehicleId", value);
        return this;
    }

    WithPermission(value: string) {
        this.WithParam("permission", value);
        return this;
    }

    WithUninvoicedCharges(value: Boolean) {
        this.WithParam("hasUninvoicedCharges", value);
        return this;
    }
}


export class HengyiApi {

    //******************************
    // AUTHENTICATION
    //******************************

    static refresh_lock = false;


    static async SetUserPassword(existingPassword: string, newPassword: string) {
        const response = await fetch((await GetAPIDomain()) + "/reset-password/with-existing-credentials", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "existingPassword": existingPassword,
                "newPassword": newPassword
            })
        });

        return await ApiResponse.Create(response);
    }


    //SIGN IN
    static async SignIn(username: string, password: string, setError?: Function) {

        let formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('client_id', '5f3d5a23-cb78-44e1-af18-ac962fe85daf');
        formData.append('grant_type', 'password');

        const response = await fetch((await GetAPIDomain()) + "/connect/token", {
            body: formData,
            method: "post"
        });

        if (!response.ok) {

            localStorage.clear();

            if (setError) {
                const data = await response.json();
                setError(data.error_description);
            }

            return false;
        }

        const data = await response.json();

        localStorage.setItem("acess-token", data.access_token);
        localStorage.setItem("refresh-token", data.refresh_token);
        localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

        return true;
    }

    //REFRESH TOKEN
    static async RefreshToken() {

        if (!this.refresh_lock) {
            this.refresh_lock = true;

            var token = localStorage.getItem("refresh-token");

            if (!token) {
                localStorage.clear();
                return;
            }

            let formData = new URLSearchParams();
            formData.append('refresh_token', token);
            formData.append('client_id', '5f3d5a23-cb78-44e1-af18-ac962fe85daf');
            formData.append('grant_type', 'refresh_token');

            const response = await fetch((await GetAPIDomain()) + "/connect/token", {
                body: formData,
                method: "post"
            });

            if (!response.ok) {
                localStorage.clear();
                return false;
            }

            const data = await response.json();

            localStorage.setItem("acess-token", data.access_token);
            localStorage.setItem("refresh-token", data.refresh_token);
            localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

            this.refresh_lock = false;

            return true;
        }
    }

    //SIGN OUT
    static LogOut() {
        localStorage.removeItem("acess-token");
        localStorage.removeItem("refresh-token");
        localStorage.clear();
    }

    //IS AUTHENTICATED
    static IsAuthenticated() {

        if (localStorage == null)
            return false;

        if (localStorage.getItem("acess-token") == null)
            return false;

        var token = localStorage.getItem("acess-token");
        if (token != null && token.length === 0)
            return false;

        return true;
    }

    //BEGIN PASSWORD RESET
    static async BeginPasswordReset(email: string) {
        const response = await fetch((await GetAPIDomain()) + "/reset-password/begin", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "email": email,
                "isForCRM": true
            })
        });

        return (await response.json()).successful;
    }


    static async GetDocumentDownloadLink(id: string) {

        if (id === "home")
            return null;

        const response = await fetch((await GetAPIDomain()) + "/documents/" + id + "/download", {
            headers: await GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return;
        }

        return (await response.json());
    }



    //COMPLETE PASSWORD RESET
    static async CompletePasswordReset(code: string, password: string) {

        const response = await fetch((await GetAPIDomain()) + "/reset-password/complete", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": code,
                "password": password
            })
        });

        const data = await response.json();

        var details = new HengyiApiResponse();
        details.ok = response.ok;

        if (!response.ok) {
            details.validationErrors = data.validationErrors;
        } else {
            details.data = data;
        }

        return details;
    }

    //******************************
    // DOCUMENTS
    //******************************


    //GET FILES
    static async GetFiles(parentId: string) {
        var query = (await GetAPIDomain()) + "/documents?documentType=file&sort=weight";

        if (parentId) {
            if (parentId === "home") {
                query = query + "&rootLevel=true"
            } else {
                query = query + "&parentId=" + parentId

            }
        }

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    //GET PERMISSIONS
    static async GetListFiles(params: ListQueryParams) {

        params = params.WithParam("documentType", "file");

        var query = (await GetAPIDomain()) + "/documents" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    //GET DOCUMENTS
    static async GetDocuments(parentId: string) {
        var query = (await GetAPIDomain()) + "/documents?documentType=directory&sort=weight";

        if (parentId) {
            if (parentId === "home") {
                query = query + "&rootLevel=true"
            } else {
                query = query + "&parentId=" + parentId

            }
        }

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    //GET DOCUMENT EVENTS
    static async GetDocumentEvents(params: DocumentEventQueryParams) {

        var query = (await GetAPIDomain()) + "/document-events" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //DELETE DOCUMENT
    static async DeleteDocument(id: string) {

        var query = (await GetAPIDomain()) + "/documents/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    //******************************
    // CONTACT US
    //******************************

    //CONTACT US
    static async ContactUs(email: string, subject: string, content: string) {

        await fetch((await GetAPIDomain()) + "/contact-us", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "subject": subject,
                "email": email,
                "content": content
            })
        });

        return true;
    }


    //******************************
    // ACTIVITIES
    //******************************

    //GET ACTIVITIES
    static async GetActivities(params: ActivityQueryParams) {

        var query = (await GetAPIDomain()) + "/activity" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //******************************
    // TICKETS
    //******************************

    //GET TICKET TYPES
    static async GetTicketTypes(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/ticket-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //GET TICKET TYPE DETAILS
    static async GetTicketType(id: string) {

        var query = (await GetAPIDomain()) + "/ticket-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //DELETE TICKET TYPE
    static async DeleteTicketType(id: string) {

        var query = (await GetAPIDomain()) + "/ticket-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    //DELETE TICKET CATEGORY
    static async DeleteTicketCategory(id: string) {

        var query = (await GetAPIDomain()) + "/ticket-categories/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    //GET TICKET Categories
    static async GetTicketCategories(params: TicketCategoryQueryParams) {

        var query = (await GetAPIDomain()) + "/ticket-categories" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //GET TICKET TYPE DETAILS
    static async GetTicketCategory(id: string) {

        var query = (await GetAPIDomain()) + "/ticket-categories/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //******************************
    // ROLES & ERMISSIONS
    //******************************

    //GET ROLES
    static async GetRoles(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/roles?skip=" + params.skip + "&take=" + params.take;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //GET ROLE DETAILS
    static async GetRole(id: string) {

        var query = (await GetAPIDomain()) + "/roles/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //DELETE ROLE
    static async DeleteRole(id: string) {

        var query = (await GetAPIDomain()) + "/roles/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    //GET PERMISSIONS
    static async GetPermissions(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/permissions?skip=" + params.skip + "&take=" + params.take;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //GET CURRENT PERMISSIONS
    static async GetPermissionsForCurrentUser() {

        try {
            var query = (await GetAPIDomain()) + "/permissions/for/current-user";

            return await ApiResponse.Create(await fetch(query, {
                headers: await GetAPIHeaders()
            }));
        } catch {
            return ApiResponse.Failed();
        }
    }

    //GET GROUPED PERMISSIONS
    static async GetPermissionsGrouped(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/permissions/grouped?skip=" + params.skip + "&take=" + params.take;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //******************************
    // USER
    //******************************

    //GET CURRENT USER
    static async GetCurrentUser() {
        var accessToken = localStorage.getItem("acess-token");
        if (!accessToken)
            return;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/current", {
            headers: await GetAPIHeaders()
        }));
    }

    //GET USERS
    static async GetUser(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async GetUserActions(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/actions";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }


    //GET USERS
    static async GetUsers(params: UserQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //******************************
    // APARTMENT TYPES
    //******************************

    //GET APARTMENT TYPES
    static async GetApartmentTypes(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/apartment-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //GET APARTMENT TYPE
    static async GetApartmentType(id: string) {

        var query = (await GetAPIDomain()) + "/apartment-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //POST APARTMENT TYPE
    static async CreateApartmentType(name: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartment-types/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description
            })
        }));
    }

    //PATCH APARTMENT TYPE
    static async UpdateApartmentType(id: string, name: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartment-types/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }])
        }));
    }

    //DELETE APARTMENT TYPE
    static async DeleteApartmentType(id: string) {

        var query = (await GetAPIDomain()) + "/apartment-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}

export class HengyiApiResponse {

    validationErrors: any;
    ok: any;
    data: any;

}