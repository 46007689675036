import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Spinner, Alert  } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { userContext } from '../../userContext';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link } from 'react-router-dom';

export class PasswordResetBegin extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false };
    }

    componentDidMount() {
        if (HengyiApi.IsAuthenticated()) {
            HengyiApi.LogOut();
        }
    }

    renderContent() {

        return (<Formik
            initialValues={{
                email: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {
                if (!await HengyiApi.BeginPasswordReset(fields.email)) {

                    setFieldError("email", "We can't find this email, do you normally use a different one?");
                } else {
                    this.setState({ success: true });
                }
            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-light mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                            {!isSubmitting && "RESET PASSWORD"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5 style={{ color:"#299b85"}}>Success</h5>
            We have sent you an email with a link to reset your password.
                    </Alert>
            <Link className="btn mr-2 btn-outline-light mt-2 text-white" to="/sign-in" style={{ width: "100%" }}>BACK TO SIGN IN</Link></div>);
    }

    render() {

        return (

            <div>
                <Container style={{ "paddingTop": "50px" }}>
                    <h3 className="mb-4 text-white">Forgotten password</h3>
                    <hr />
                    {!this.state.success && this.renderContent()}
                    {this.state.success && this.renderSuccessScreen()}
                </Container>
            </div>
        );

    }
}

PasswordResetBegin.contextType = userContext;