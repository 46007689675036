import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { HengyiStorageUnitClient } from '../infrastructure/HengyiClient/StorageUnits';


export class StorageUnitsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, deleteFail: false
        };

        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/storage-units" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/storage-units" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Storage units</span>}
                                        <span className="d-block text-muted" style={{ marginLeft: "45px" }}>Details</span>
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/storage-units/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <h6>Details</h6>
                            <hr />

                            <table>
                                <tbody>
                                    <tr>
                                        <td className="pr-2">Name:</td>
                                        <td>
                                            {this.state.data.name && this.state.data.name}
                                            {!this.state.data.name && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Space:</td>
                                        <td>
                                            {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                            {!this.state.data.apartment && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Status:</td>
                                        <td>
                                            {this.state.data.status === "available" && <Badge color="success">Available</Badge>}
                                            {this.state.data.status === "unavailable" && <Badge color="secondary">Unavailable</Badge>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Description:</td>
                                        <td>
                                            {this.state.data.description && this.state.data.description}
                                            {!this.state.data.description && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>}

                    {!this.state.loading && this.state.data &&
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            {!this.state.deleteFail &&
                                <Row>
                                    <Col>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger:</strong> You can delete this storage unit, doing so will permanently remove it. Please proceed with caution</p>
                                    </Col>
                                    <Col md="2">
                                        <Button style={{ float: "right" }} to="/ticket-types" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete unit</Button>
                                    </Col>
                                </Row>}
                        </div>}

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiStorageUnitClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async delete() {
        var response = await HengyiStorageUnitClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }
}
