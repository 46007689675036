import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

//******************************
// Documents
//******************************

export class DocumentsQueryParams extends ListQueryParams {

    WithInductionUserId(value: string) {
        this.WithParam("inductionUserId", value);
        return this;
    }

    WithDocumentType(value: string) {
        this.WithParam("documentType", value);
        return this;
    }
}

export class HengyiDocumentClient {

    //GET PERMISSIONS
    static async List(params: DocumentsQueryParams) {

        var query = (await GetAPIDomain()) + "/documents" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    //COUNT OF NEW FILES
    static async GetNewFileCount() {

        var query = (await GetAPIDomain()) + "/documents?take=1&documentType=file&newDocuments=true";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    //REQUEST DOCUMENT INFORMATION
    static async RequestDocumentInformation(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/documents/" + id + "/request-more-info", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, weight: number, description: string, imageId?:string) {

        var query = (await GetAPIDomain()) + "/documents/create-directory";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "name": name,
                "weight": weight,
                "description": description,
                "imageId": imageId
            })
        }));

    }

    //GET ONE DOCUMENT
    static async GetDocument(id: string) {

        if (id === "home") {
            var homeResponse = new ApiResponse()
            homeResponse.successful = true;
            return homeResponse;
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/documents/" + id, {
            headers: await GetAPIHeaders()
        }));
    }

    //DOWNLOAD LINK
    static async GetDocumentDownloadLink(id: string) {

        if (id === "home")
            return null;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/documents/" + id + "/download", {
            headers: await GetAPIHeaders()
        }));
    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/documents/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Reject(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/documents/" + id + "/reject", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }
}



