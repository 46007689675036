import React, { Component } from "react";
import { InputGroup, Input, ButtonGroup, Spinner, Card, CardHeader, Row, Col, CardBody, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { UserLink } from "../Users/UserLink";
import NumberFormat from "react-number-format";
import DatePicker from 'react-datepicker';
import { InvoiceQueryParams } from "../infrastructure/HengyiClient/Invoices";
import { HengyiInvoiceClient } from "../infrastructure/HengyiClient/Invoices";
import { TableHeader } from "../infrastructure/TableHeader";
import { ApartmentSelector } from "../infrastructure/ApartmentSelector";
import FileSaver from 'file-saver';
import { userContext } from '../../userContext';

class InvoicesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, showVoided: false, showDeleted: false,
            search: "",
            status: "", sort: "created", direction: "desc"
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowVoided = this.handleShowVoided.bind(this);
        this.handleShowDeleted = this.handleShowDeleted.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleApartmentIdChange = this.handleApartmentIdChange.bind(this);
    }

    handleShowVoided = (evt) => {
        this.setState({
            showVoided: evt.target.checked
        }, () => this.update());
    }

    handleShowDeleted = (evt) => {
        this.setState({
            showDeleted: evt.target.checked
        }, () => this.update());
    }

    handleApartmentIdChange(name, value) {

        this.setState({
            apartmentId: value
        }, () => this.update());
    }

    async clear() {

        this.setState({
            search: "",
            status: "",
            skip: 0,
            startFilter: "",
            apartmentId: "",
            endFilter: ""
        }, () => this.update());

    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    handleStatusChange(evt) {
        this.setState({
            status: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }


    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            userId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var params = new InvoiceQueryParams()
                .WithStatus(this.state.status)
                .WithShowVoided(this.state.showVoided)
                .WithShowDeleted(this.state.showDeleted)
                .WithStart(this.state.startFilter)
                .WithEnd(this.state.endFilter)
                .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
                .Sort(this.state.sort, this.state.direction);

            if (userId)
                params = params.WithUser(userId);

            var response = await HengyiInvoiceClient.List(params.Paginate(skip, take)
                .Search(this.state.search));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }


    async exportToCSV() {

        const {
            userId
        } = this.props;

        this.setState({ exporting: true });


        var params = new InvoiceQueryParams()
            .WithStatus(this.state.status)
            .WithShowVoided(this.state.showVoided)
            .WithShowDeleted(this.state.showDeleted)
            .WithStart(this.state.startFilter)
            .WithEnd(this.state.endFilter)
            .WithApartment(this.state.apartmentId ? this.state.apartmentId.value : null)
            .Sort(this.state.sort, this.state.direction);

        if (userId)
            params = params.WithUser(userId);

        var blob = await HengyiInvoiceClient.Export(params.Paginate(0, 1)
            .Search(this.state.search));

        FileSaver.saveAs(blob, "invoices.csv");

        this.setState({ exporting: false });
    }

    render() {
        const {
            title,
            description,
            enableEditControls,
            userId
        } = this.props;

        return (
            <div>

                <Card>

                    <userContext.Consumer>
                        {({ user, logoutUser }) => {
                            return (<CardHeader>
                        <Row>
                            <Col>
                                <h5>{title && title} {!title && "Invoices"} ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description && description} {!description && "List all invoices"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>


                                            {user.permissions && user.permissions.includes("invoices.export") && <button style={{ float: "right" }} onClick={() => { this.exportToCSV(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">

                                                {!this.state.exporting && <span className="pcoded-micon">Export</span>}
                                                {this.state.exporting && <Spinner animation="border" />}

                                            </button>}


                                            {enableEditControls && < Link style={{ float: "right" }} to="/invoices/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">Invoice outstanding charges</Link>}
                                </div>
                            </Col>
                        </Row>

                            </CardHeader>);
                        }}
                    </userContext.Consumer>
                </Card>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>

                            <Col>
                                <ApartmentSelector
                                    placeholder="Space"
                                    value={this.state.apartmentId}
                                    onChange={this.handleApartmentIdChange}
                                    hideLabel={true} />
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type="select" name="select" value={this.state.status} onChange={this.handleStatusChange} >
                                        <option value="">All statuses</option>
                                        <option value="draft">Draft</option>
                                        <option value="open">Open</option>
                                        <option value="paid">Paid</option>
                                        <option value="uncollectable">Uncollectable</option>
                                        {this.state.showDeleted && <option value="deleted">Deleted</option>}
                                        {this.state.showVoided && <option value="voided">Voided</option>}
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col>
                                <DatePicker id="start"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholderText="Start date"
                                    selected={this.state.startFilter}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        this.setState({
                                            startFilter: v
                                        }, () => this.update());
                                    }} />
                            </Col>
                            <Col>
                                <DatePicker id="end"
                                    onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    placeholderText="End date"
                                    selected={this.state.endFilter}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        this.setState({
                                            endFilter: v
                                        }, () => this.update());
                                    }} />
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" id="search" onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search || this.state.status || this.state.startFilter || this.state.endFilter || this.state.apartmentId) &&
                                                <button onClick={() => { this.clear(); document.getElementById("search").value = ""; }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardHeader>

                        <div className="form-group">
                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideCancellations"
                                            type="checkbox"
                                            checked={this.state.showVoided}
                                            onChange={this.handleShowVoided} />  Show voided invoices
                                        </label>
                                </li> <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="hideDeleted"
                                            type="checkbox"
                                            checked={this.state.showDeleted}
                                            onChange={this.handleShowDeleted} />  Show deleted invoices
                                        </label>
                                </li>
                            </ul>
                        </div>
                    </CardHeader>
                    <CardBody className="p-0">
                        {this.state.data &&
                            <table className='table' aria-labelledby="tabelLabel">
                                <TableHeader
                                    sort={this.state.sort}
                                    direction={this.state.direction}
                                    columns={[
                                        { name: "Number", sort: "invoiceNumber" },
                                        { name: "Amount", sort: "amount" },
                                        { name: "Status", sort: "status" },
                                        { name: "User", sort: "user.name", disabled: userId != null },
                                        { name: "Created", sort: "created" },
                                        { name: "", sort: "", static: true }]}
                                    onUpdate={(sort, direction) => this.setState({
                                        sort: sort,
                                        direction: direction
                                    }, () => this.update())}
                                />
                                <tbody>
                                    {this.state.data.map(item =>
                                        <tr key={item.id}>
                                            <td>
                                                {item.invoiceNumber && item.invoiceNumber}
                                                {!item.invoiceNumber && <i>Not specified</i>}
                                            </td>
                                            <td>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                            </td>
                                            <td>
                                                {item.status === "draft" && <Badge color="primary">Draft</Badge>}
                                                {item.status === "open" && <Badge color="primary">Open</Badge>}
                                                {item.status === "paid" && <Badge color="success">Paid</Badge>}
                                                {item.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                                {item.status === "void" && <Badge color="secondary">Voided</Badge>}
                                                {item.status === "deleted" && <Badge color="secondary">Deleted</Badge>}
                                            </td>
                                            {!userId && <td>
                                                {item.user && <UserLink user={item.user} />}
                                                {!item.user && <i>Not specified</i>}
                                            </td>}
                                            <td>
                                                <Moment utc local format="ddd, DD/MM h:mma" date={item.created} />
                                            </td>
                                            <td>
                                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invoices/" + item.id}>Details</Link>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.data.length === 0 && <tr><td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No invoices to display</i></h4></td></tr>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                </Card>

            </div>
        );
    }
}

export { InvoicesTable };

InvoicesTable.contextType = userContext;