import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { userContext } from '../userContext';
import { HengyiApi } from './infrastructure/fetchHengy';
import { Link } from 'react-router-dom';

export class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }

    renderContent() {

        if (this.state.next === "next") {
            return (<Redirect to="/" />);
        } else if (this.state.next === "change-password") {
            return (<Redirect to="/change-password" />);
        } else {
            return (<Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {
                    if (await HengyiApi.SignIn(fields.email, fields.password, error => {

                        if (error === "too_many_attempts") {
                            setFieldError("email", "Too many attempts, please wait 5 minutes before trying again");
                        } else if (error === "account_locked") {
                            setFieldError("email", "Your account is locked, please contact concierge to unlock");
                        } else {
                            setFieldError("email", "Invalid email or password");
                        }

                    })) {

                        this.context.refresh();

                        console.log("Checking next step");

                        var actions = await HengyiApi.GetUserActions("current");

                        console.log(actions);

                        if (actions.data.requireTempPasswordReset) {
                            this.setState({ next: "change-password" });
                        } else {
                            this.setState({ next: "dashboard" });
                        }
                    }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <Field name="password" placeholder="Password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-light mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "SIGN IN"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>

                        <div className="form-group text-right">
                            <Link className="text-white" to="/password-reset/begin">Forgotten password?</Link>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    render() {

        return (
            <div className="auth-content">
                <div className="text-white">
                    <div className="card-body text-center">

                        <div className="mb-4">
                            <i className="feather icon-unlock auth-icon"></i>
                        </div>
                        <h3 className="mb-4 text-white">Sign in</h3>
                        <hr />
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );

    }
}

SignIn.contextType = userContext;