import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';

export class TicketTypesNew extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, authenticated: true, next: false };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                instructions: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                fields.name = this.state.name;
                fields.instructions = this.state.instructions;

                const response = await fetch((await GetAPIDomain()) + "/ticket-types", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(fields)
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    const data = await response.json();
                    this.setState({ next: true, id: data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values }) => (
            <Form>

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                        <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                </div>
                    <Row className="mt-3">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="instructions">Tip/ Instructions</label>
                                <textarea name="instructions" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                <ErrorMessage name="instructions" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/ticket-types/"+this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New ticket type
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a ticket type to the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/ticket-types" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}
