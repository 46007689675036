import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiPetClient } from '../infrastructure/HengyiClient/Pets';
import { GetAPIDomain } from '../infrastructure/Helper';
import { UserSelector } from '../infrastructure/UserSelector';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class PetsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                petType: "",
                type: "dog",
                userId: "",
                apartmentId: "",
                breed: "",
                description: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var imageId = null;

                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await HengyiPetClient.Create(this.state.name, this.state.petType, fields.type, this.state.breed, this.state.description, fields.userId ? fields.userId.value : null, imageId, fields.apartmentId ? fields.apartmentId.value : null);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name*</label>
                                        <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>

                                <div className="form-group">
                                    <label htmlFor="type">Pet type</label>
                                    <select name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                        <option value="dog" label="Dog" />
                                        <option value="cat" label="Cat" />
                                        <option value="bird" label="Bird" />
                                        <option value="other" label="Other" />
                                    </select>
                                        <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                </div>

                            </Col>
                            {values.type === "other" && <Col>
                                <div className="form-group">
                                    <label htmlFor="petType">Other</label>
                                        <input name="petType" type="text" className={'form-control' + (errors.petType && touched.petType ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="petType" component="div" className="invalid-feedback" />
                                </div>
                            </Col>}
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="breed">Breed</label>
                                        <input name="breed" type="text" className={'form-control' + (errors.breed && touched.breed ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="breed" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>

                                    <ApartmentSelector
                                        label="Space*"
                                        value={values.apartmentId}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        touched={touched.apartmentId}
                                        error={errors.apartmentId}
                                        filterUser={values.userId ? values.userId.value : null} />
                                </Col>
                            <Col>

                                <UserSelector
                                    label="Primary contact from the space"
                                    value={values.userId}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.userId}
                                    error={errors.userId}
                                    filterApartment={values.apartmentId ? values.apartmentId.value : null} />
                            </Col>
                        </Row>

                            <Row md="3" className="pt-3" >
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                        <textarea name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3" className="text-center">
                        <div className="form-group">
                            <h6 className="text-left">Profile image</h6>

                            {this.state.file && <div>
                                <img className="rounded-circle" alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                        </div>
                    </Col>
                </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/pets/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New pet
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a pet in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/pets" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
