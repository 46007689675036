import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiApi, UserQueryParams, ListQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiMessageClient } from '../infrastructure/HengyiClient/Messages';
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { UserSelector } from '../infrastructure/UserSelector';

export class ConversationsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, origin: "conversations"
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("origin") === "dashboard")
            this.setState({
                origin: "dashboard"
            });

        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                userId: "",
                vehicleId: "",
                mode: "general"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (fields.mode === "vehicle" && !fields.vehicleId) {
                    setFieldError("vehicleId", "Please select a vehicle");
                    return;
                }

                if (!fields.userId) {
                    setFieldError("userId", "Please select a user");
                    return;
                }

                if (!this.state.message) {
                    setFieldError("message", "Please enter a message to send");
                    return;
                }

                var response = (fields.mode === "vehicle") ?
                    await HengyiMessageClient.Create(this.state.message, null, fields.userId.value, fields.vehicleId.value) :
                    await HengyiMessageClient.Create(this.state.message, null, fields.userId.value);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.threadId });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <h5>Type of message</h5>
                <Row className="mt-3">
                    <Col>
                        <div class="form-check">
                            <Field class="form-check-input" type="radio" name="mode" id="general" value="general" onChange={(key, item) => {
                                setFieldValue("mode", "general");
                                setFieldValue("vehicleId", null);
                            }} />
                            <label class="form-check-label" for="general">General message</label>
                        </div>
                        <div class="form-check">
                            <Field class="form-check-input" type="radio" name="mode" id="vehicle" value="vehicle" onChange={(key, item) => {

                                setFieldValue("mode", "vehicle");
                                setFieldValue("userId", null);

                            }} />
                            <label class="form-check-label" for="vehicle">About a vehicle</label>
                        </div>
                    </Col>
                </Row>
                <hr />

                {values.mode === "vehicle" &&
                    <div className="form-group">

                        <SelectField
                            id="vehicleId"
                            name="vehicleId"
                            label="Vehicle"
                            placeholder="Select vehicle"
                            options={this.state.vehicleOptions}
                            value={values.vehicleId}
                            isMulti={false}
                            onChange={(key, item) => {
                                setFieldValue(key, item);
                                setFieldValue("userId", null);
                            }}
                            onBlur={setFieldTouched}
                            touched={touched.vehicleId}
                            error={errors.vehicleId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        />
                        <ErrorMessage name="vehicleId" component="div" className="invalid-feedback" />
                    </div>
                }

                <div className="form-group">

                    {(values.mode === "general" || values.vehicleId) && <UserSelector
                        label="Resident"
                        value={values.userId}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.userId}
                        error={errors.userId}
                        filterVehicle={values.vehicleId ? values.vehicleId.value : null} />}

                    <ErrorMessage name="userId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" type="text" rows="3" component="textarea" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} onChange={this.handleChange} ></textarea>
                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SEND"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {

            if (this.state.origin === "dashboard") {
                return (<Redirect to="/" />);
            } else {
                return (<Redirect to={"/conversations?currentThreadId=" + this.state.id} />);
            }

        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New conversation
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Start a new conversation with a resident</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.origin === "dashboard" &&
                                            <Link style={{ float: "right" }} to="/" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }
                                        {this.state.origin !== "dashboard" &&
                                            <Link style={{ float: "right" }} to="/conversations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        }

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {

            this.setState({ loading: true });
            var vehicles = (await HengyiVehicleClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                vehicleOptions: vehicles.map(function (item) { return { value: item.id, label: item.make + " " + item.model + " - " + item.licensePlate }; }),
                vehicles: vehicles,
                loading: false
            });
        }
    }
}
