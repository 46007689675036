import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ParkingBankQueryParams extends ListQueryParams {

    WithBuilding(value: string) {
        this.WithParam("buildingId", value);
        return this;
    }

}

//******************************
// PARKING BANKS
//******************************
export class HengyiParkingBankClient {

    static async List(params: ParkingBankQueryParams) {

        var query = (await GetAPIDomain()) + "/parking-banks" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parking-banks/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(buildingId: string, name: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parking-banks/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "buildingId": buildingId
            })
        }));
    }

    static async Update(id: string, name: string, description: string, buildingId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parking-banks/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "buildingId",
                "value": buildingId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/parking-banks/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}