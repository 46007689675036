import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class HengyiTenantClient {

    static async Get() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tenants/current", {
            headers: await GetAPIHeaders()
        }));

    }

    static async Update(name: string, colour: string) {

        var updates = [{
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
            "path": "colour", 
            "value": colour
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/tenants/current", {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

}