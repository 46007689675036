import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { EVChargingTable } from './EVChargingTable';

export class EVChargingSessions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated: true
        };
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <EVChargingTable />
            );
        }
    }

}
