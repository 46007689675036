import React, { Component } from 'react';
import { ScheduleNew } from './ScheduleNew';

export class EventScheduleNew extends Component {

    render() {
        return (
            <ScheduleNew eventId={this.props.match.params.id} />
        );
    }

}
