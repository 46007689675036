import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class BookingTypesQueryParams extends ListQueryParams {
    WithScheduleId(value: string) {
        this.WithParam("scheduleId", value);
        return this;
    }

    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithEvent(value: string) {
        this.WithParam("eventId", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }
}

export class HengyiBookingTypesClient {

    static async List(params: BookingTypesQueryParams) {

        var query = (await GetAPIDomain()) + "/booking-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            headers: await GetAPIHeaders()
        }));
    }

    static async Create(
        name: string,
        isMultiple: boolean,
        doesNotRequireApproval: boolean,
        maximumBookingLength: number,
        concurrencyLimit: number,
        scheduleId: string,
        audiences?: any,
        exivoEnabled?: boolean,
        componentId?: string,
        componentName?: string) {

        var request: any = {
            name: name,
            isMultiple: isMultiple,
            maximumBookingLength: maximumBookingLength,
            scheduleId: scheduleId,
            audiences: audiences,
            doesNotRequireApproval: doesNotRequireApproval,
            concurrencyLimit: concurrencyLimit,
            exivoEnabled: exivoEnabled,
            componentId: componentId,
            componentName: componentName
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(
        id: string,
        name: string,
        isMultiple: boolean,
        doesNotRequireApproval: boolean,
        maximumBookingLength: number,
        concurrencyLimit: number,
        scheduleId: string,
        audiences?: any,
        exivoEnabled?: boolean,
        componentId?: string,
        componentName?: string) {

        console.log("UPDATE");

        var updates = [{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "scheduleId",
                "value": scheduleId
            }, {
                "op": "replace",
                "path": "isMultiple",
                "value": isMultiple
            }, {
                "op": "replace",
                "path": "doesNotRequireApproval",
                "value": doesNotRequireApproval
            }, {
                "op": "replace",
                "path": "maximumBookingLength",
                "value": maximumBookingLength
            }, {
                "op": "replace",
                "path": "concurrencyLimit",
                "value": concurrencyLimit
            }, {
                "op": "replace",
                "path": "audiences",
                "value": audiences
            }, {
                "op": "replace",
            "path": "exivoEnabled",
            "value": exivoEnabled
            }, {
                "op": "replace",
            "path": "componentId",
            "value": componentId
            }, {
                "op": "replace",
            "path": "componentName",
            "value": componentName
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Remove(id: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            method: 'delete',
            headers: await GetAPIHeaders()
        }));
    }
}