import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class HengyiExivoComponentClient {

    static async List(buildingId: string, params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/integrations/exivo/"+buildingId+"/components" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}