import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { GetAPIDomain } from '../infrastructure/Helper';
import { HengyiDocumentClient } from '../infrastructure/HengyiClient/Documents';

export class FolderNew extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                parentId: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var imageId = null;

                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await HengyiDocumentClient.Create(this.state.name, this.state.weight ? parseInt(this.state.weight) : 0, this.state.description, imageId);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>
                <Row>
                    <Col>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name*</label>
                                        <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="weight">Weight</label>
                                        <input name="weight" type="number" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="weight" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                        <textarea name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    {touched.description && errors.description ? <p className="error-text">{errors.description}</p> : null}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3" className="text-center">
                        <div className="form-group">
                            <h6 className="text-left">Cover image</h6>

                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ minWidth: "250px", width: "100%", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                            </div>


                            <div className="alert alert-info text-left">
                                <h6>Cover image tips</h6>
                                <p>
                                    A cover image is used for the image of the folder tile, this tile can be different sizes depending on the device used.

                                </p>
                                <p>
                                    A good cover image is one that
                                        <ul>
                                        <li>Does not contain any text</li>
                                        <li>Can be cropped on the edges without impacting the visual quality of the image</li>
                                        <li>Avoids sharp contrast contours which makes the text harder to read</li>
                                    </ul>
                                </p>
                            </div>
                    </Col>
                </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/folders/edit/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New folder
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Upoad a new folder to organise documents on the resident dashboard</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/folders" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetDocuments();

            var folderOptions = response.data.data.map(function (item) { return { value: item.id, label: item.name }; });

            this.setState({
                init: true,
                folderOptions: folderOptions,
                loading: false
            });
        }
    }
}
