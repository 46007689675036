import { ApiResponse } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';


//******************************
// Statistics
//******************************
export class HengyiStatisticsClient {

    static async Dashboard() {

        var query = (await GetAPIDomain()) + "/statistics/dashboard";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async PartnerOffer(id: string) {

        var query = (await GetAPIDomain()) + "/statistics/partner-offer/"+id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }
}