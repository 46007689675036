import { ApiResponse, ListQueryParams, HengyiApiResponse, HengyiApi } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class AutomatedChargeTypeQueryParams extends ListQueryParams {

}

//******************************
// Charge types
//******************************
export class HengyiAutomatedChargeTypeClient {

    static async List(params: AutomatedChargeTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/automated-charge-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }


    static async SetBillingCode(id: string, billingCodeId?:string) {

        var request: any = {
            AutomatedChargeTypeId: id,
            BillingCodeId: billingCodeId,
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/automated-charge-types/set-billing-code", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/automated-charge-types/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

}