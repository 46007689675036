import React, { Component } from 'react';
import { SwipeCardTable } from './SwipeCardTable';

export class SwipeCards extends Component {

    render() {
        return (
            <SwipeCardTable enableEditControls={true} />
        );
    }
}
