import React, { Component } from 'react';
import { ValetRequestsTable } from './ValetRequestsTable';

export class ValetRequests extends Component {

    render() {
        return (
            <ValetRequestsTable includeNewControl={true} location={this.props.location} />
        );
    }
}
