import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiParkedVehicleClient } from '../infrastructure/HengyiClient/ParkedVehicles';
import { ParkedVehicleQueryParams } from '../infrastructure/HengyiClient/ParkedVehicles';
import { SelectField } from '../infrastructure/SelectField';
import { HengyiValetRequestClient } from '../infrastructure/HengyiClient/ValetRequests';


export class ValetRequestsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", audiences: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                parkedVehicleId: "",
                message: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (fields.requestType === "requestCar") {
                    await HengyiValetRequestClient.RequestCar(fields.parkedVehicleId.value);
                } else if (fields.requestType === "requestItem") {
                    await HengyiValetRequestClient.RequestItem(fields.parkedVehicleId.value, this.state.message);
                } else if (fields.requestType === "requestDriver") {
                    await HengyiValetRequestClient.RequestDriver();
                }

                this.setState({ next: true });

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Card>
                    <CardBody>
                        <h6>Type of request</h6>
                        <hr />

                        <Row>
                            <Col>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="requestType" id="requestCar" value="requestCar" />
                                    <label class="form-check-label" for="requestCar">Vehicle</label>
                                </div>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="requestType" id="requestItem" value="requestItem" />
                                    <label class="form-check-label" for="requestItem">Item from vehicle</label>
                                </div>
                                <div class="form-check">
                                    <Field class="form-check-input" type="radio" name="requestType" id="requestDriver" value="requestDriver" />
                                    <label class="form-check-label" for="requestDriver">Driver</label>
                                </div>
                            </Col>
                        </Row>
                        {values.requestType && (values.requestType === "requestCar" || values.requestType === "requestItem") && <Row className="mt-2">
                            <Col>
                                <SelectField
                                    id="parkedVehicleId"
                                    name="parkedVehicleId"
                                    label="Parked Vehicle"
                                    placeholder="Select vehicle"
                                    options={this.state.options}
                                    value={values.parkedVehicleId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.parkedVehicleId}
                                    error={errors.parkedVehicleId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                                <ErrorMessage name="parkedVehicleId" component="div" className="invalid-feedback" />
                            </Col>
                        </Row>}

                        {values.requestType && values.requestType === "requestItem" && <Row className="mt-2">
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <input name="message" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>}
                    </CardBody>
                </Card>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && <span>Send request</span>}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/valet-requests/"} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Valet requests
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a requests for valet</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/valet-requests" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>

                    {this.state.data && this.renderForm()}

                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var parkedCars = await HengyiParkedVehicleClient.List(new ParkedVehicleQueryParams().WithStatus("parked").Paginate(0, 1000));

            this.setState({
                options: parkedCars.data.data.map(function (item) {
                    return {
                        value: item.id,
                        label: item.ticketNumber + " - " + item.vehicle.make + " " + item.vehicle.model + " " + item.vehicle.licensePlate
                    }
                }),
                init: true,
                loading: false
            });
        }
    }
}
