import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class SwipeCardQueryParams extends ListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithContractor(value: string) {
        this.WithParam("contractorId", value);
        return this;
    }

    WithType(value: string) {
        this.WithParam("type", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    IsAssigned(value: Boolean) {
        this.WithParam("isAssigned", value);
        return this;
    }
}

//******************************
// PETS
//******************************
export class HengyiSwipeCardClient {

    static async List(params: SwipeCardQueryParams) {

        var query = (await GetAPIDomain()) + "/swipe-cards" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, cardIdentifier: string, type: string, userId?: string, contractorId?: string) {

        var request: any = {
            "name": name,
            "cardIdentifier": cardIdentifier,
            "type": type,
            "userId": userId,
            "contractorId": contractorId
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async AssignToResident(id: string, userId: string) {

        var request: any = {
            "userId": userId
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id + "/assign", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async ResetCard(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id + "/reset", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async DisableCard(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id + "/disable", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async AssignToContractor(id: string, contractorId: string) {

        var request: any = {
            "contractorId": contractorId
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id + "/assign", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, name: string, cardIdentifier: string, type: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/swipe-cards/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                    "path": "cardIdentifier",
                    "value": cardIdentifier
            }, {
                "op": "replace",
                "path": "type",
                "value": type
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/swipe-cards/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}