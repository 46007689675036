import React, { Component } from 'react';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import { GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";

export class DocumentsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                parentId: "",
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                console.log(fields);

                let formData = new FormData();
                formData.append("file", fields.file);

                const response = await fetch((await GetAPIDomain()) + "/documents/" + fields.parentId.value + "/upload-file", {
                    method: 'post',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                    },
                    body: formData
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    const data = await response.json();
                    this.setState({ id: data.id, next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <div className="form-group">
                    <SelectField
                        id="parentId"
                        name="parentId"
                        label="Folder"
                        placeholder="Select folder"
                        options={this.state.folderOptions}
                        value={values.parentId}
                        isMulti={false}
                            onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.parentId}
                        error={errors.parentId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="file">File to upload</label>
                    <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                    }} />
                </div>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "UPLOAD"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/documents/edit/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New document
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Upoad a new file for residents to access</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/documents" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetDocuments();

            var folderOptions = response.data.data.map(function (item) { return { value: item.id, label: item.name }; });

            this.setState({
                init: true,
                folderOptions: folderOptions,
                loading: false
            });
        }
    }
}
