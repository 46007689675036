import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form, FieldArray } from 'formik';
import Moment from 'react-moment';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiApi, UserQueryParams } from '../infrastructure/fetchHengy';
import { HengyiChargeClient, ChargeQueryParams } from '../infrastructure/HengyiClient/Charges';
import NumberFormat from 'react-number-format';
import { HengyiInvoiceClient } from '../infrastructure/HengyiClient/Invoices';
import { UserSelector } from '../infrastructure/UserSelector';

export class InvoicesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, chargeOptions: [], chargesLoaded: false, init: false
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {

        const urlParams = new URLSearchParams(this.props.location.search);
        var initialUserId = this.state.userOptions.filter(type => type.value === urlParams.get("userId"))[0];

        var initialCharges = [];
        if (urlParams.get("chargeId")) {
            initialCharges = [urlParams.get("chargeId")];
        }

        return (<Formik
            initialValues={{
                charges: initialCharges,
                userId: initialUserId
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.userId) {
                    setFieldError("userId", "Please select a resident");
                    return;
                }

                if (!fields.charges || fields.charges.length === 0) {
                    setFieldError("charges", "Please select one or more charges to invoice");
                    return;
                }

                const response = await HengyiInvoiceClient.IssueForUser(
                    fields.userId.value,
                    fields.charges);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Card>
                    <CardHeader>
                        <h6>Step one - choose resident</h6>
                        <span className="d-block m-t-5 text-muted">Select which resident you would like to invoice</span>
                    </CardHeader>
                    <CardBody>
                        <Row>
                                <Col>
                                    <UserSelector 
                                        label="User to invoice"
                                        value={values.userId}
                                        isMulti={false}
                                        onChange={async (key, item) => {

                                            this.setState({
                                                chargesLoaded: false
                                            });

                                            setFieldValue(key, item);
                                            setFieldValue("charges", []);

                                            var charges = await HengyiChargeClient.List(new ChargeQueryParams()
                                                .WithStatus("pending")
                                                .WithUser(item.value));

                                            this.setState({
                                                chargeOptions: charges.data.data,
                                                chargesLoaded: true
                                            });

                                        }}
                                        onBlur={setFieldTouched}
                                        touched={touched.userId}
                                        error={errors.userId}
                                        filterUninvoiced={true}
                                        includeCardSuffix={true} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                    {this.state.chargesLoaded &&

                        <Card>
                            <CardHeader>
                                <h6>Step two - choose charges</h6>
                                <span className="d-block m-t-5 text-muted">Select which charges to invoice</span>
                            </CardHeader>
                            <CardBody>

                                <div className="form-group">
                                    {this.state.chargeOptions.length === 0 &&
                                        <div className="alert alert-info">
                                            <p>This user has no outstanding charges to invoice.</p>
                                        </div>
                                    }
                                    {this.state.chargeOptions.length > 0 && <div className="form-group">

                                        <Row>
                                            <Col md="1"></Col>
                                            <Col><strong>Amount</strong></Col>
                                            <Col><strong>Description</strong></Col>
                                            <Col><strong>Code</strong></Col>
                                            <Col><strong>Created by</strong></Col>
                                            <Col><strong>Created</strong></Col>
                                        </Row>
                                        <hr />
                                        <FieldArray name="charges"
                                            render={arrayHelpers => (
                                                <div>
                                                    {this.state.chargeOptions && this.state.chargeOptions.map(charge => {
                                                        return (<label style={{ display: "block", width: "100%" }}>
                                                            <Row className="pb-4 clickablepor" key={charge.id}>
                                                            <Col md="1">
                                                                <input
                                                                    name="charges"
                                                                    type="checkbox"
                                                                    value={charge.id}
                                                                    checked={values.charges.includes(charge.id)}
                                                                    onChange={e => {
                                                                        if (e.target.checked) arrayHelpers.push(charge.id);
                                                                        else {
                                                                            const idx = values.charges.indexOf(charge.id);
                                                                            arrayHelpers.remove(idx);
                                                                        }
                                                                    }}
                                                                /></Col>
                                                            <Col>
                                                                <strong><NumberFormat fixedDecimalScale="true" decimalScale="2" value={(charge.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} /></strong>

                                                            </Col>
                                                            <Col>
                                                                {charge.description && charge.description}
                                                                {!charge.description && <i>Not specified</i>}
                                                            </Col>
                                                            <Col>
                                                                {charge.billingCode && charge.billingCode.name}
                                                                {!charge.billingCode && <i>Not specified</i>}
                                                            </Col>
                                                            <Col>
                                                                {charge.createdBy && <span>{charge.createdBy.name} {charge.createdBy.surname}</span>}
                                                                {!charge.createdBy && <i>System generated</i>}</Col>
                                                            <Col>
                                                                <Moment utc local format="ddd, DD/MM h:mma" date={charge.created} />
                                                            </Col>
                                                        </Row></label>);
                                                    })}
                                                </div>
                                            )} />

                                        {touched && errors.charges ? <p className="error-text">{errors.charges}</p> : null}

                                    </div>}
                                </div>

                                <div className="form-group mt-3">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting || this.state.chargeOptions.length === 0}>
                                        {!isSubmitting && "INVOICE"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </CardBody>
                        </Card>}
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/invoices/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invoice a user
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Manually invoice one or more outstanding charges for a user</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/invoices" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                    {this.state.init && this.renderForm()}

                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var users = (await HengyiApi.GetUsers(new UserQueryParams()
                .WithUninvoicedCharges(true)
                .Paginate(0, 10000))).data.data;

            const urlParams = new URLSearchParams(this.props.location.search);

            if (urlParams.get("userId")) {
                
                var charges = await HengyiChargeClient.List(new ChargeQueryParams()
                    .WithStatus("pending")
                    .WithUser(urlParams.get("userId")));

                this.setState({
                    chargeOptions: charges.data.data,
                    chargesLoaded: true
                });
            }

            this.setState({
                init: true,
                userOptions: users.map(function (item) {

                    var suffix = "";
                    if (item.apartments.length > 0) {
                        suffix = item.apartments.map(r => r.apartment.name).join(", ");
                    } else if (item.roles.length > 0) {
                        suffix = item.roles.map(r => r.name).join(", ");
                    }

                    return { value: item.id, label: item.name + " " + item.surname + " - " + suffix };
                }),
                loading: false
            });
        }
    }
}
