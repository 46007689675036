import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiWorkOrderClient } from '../infrastructure/HengyiClient/WorkOrders';
import { HengyiContractorClient, ContractorQueryParams } from '../infrastructure/HengyiClient/Contractors';

export class WorkOrdersAssign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, userId:""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialUser = this.state.userOptions.filter(type => type.value === this.state.userId)[0];

            return (<Formik
                initialValues={{
                    userId: initialUser
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiWorkOrderClient.AssignToUser(this.props.match.params.id, fields.userId ? fields.userId.value : null, this.state.message);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>

                        <Row>
                            <Col>
                        <SelectField
                            id="userId"
                            name="userId"
                            label="Contractor"
                            placeholder="Select contractor to assign to"
                            options={this.state.userOptions}
                            value={values.userId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.userId}
                            error={errors.userId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="message">Message about assignment</label>
                                    <input name="message" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "ASSIGN WORK ORDER"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/work-orders/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Assign work order
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Set the contractor that this work order is assigned to</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/work-orders/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.userOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiWorkOrderClient.Get(this.props.match.params.id);
            var users = (await HengyiContractorClient.List(new ContractorQueryParams()
                .Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.assignedTo)
                    this.setState({ userId: response.data.assignedTo.id });

                this.setState({
                    loading: false,
                    userOptions: users.map(function (item) { return { value: item.id, label: item.name + " " + item.surname }; }),
                });
            }
        }
    }
}
