import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiApi, UserQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiContractorClient } from '../infrastructure/HengyiClient/Contractors';

export class ContractorsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                name: "",
                surname: "",
                email: "",
                mobile: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                const response = await HengyiContractorClient.Create(this.state.name, this.state.surname, this.state.email, this.state.mobile, this.state.company);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                        <input name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="surname">Surname</label>
                                        <input name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="company">Company</label>
                                <input name="company" type="text" className={'form-control' + (errors.company && touched.company ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="company" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                                <input name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                                <input name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} onChange={this.handleChange} />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/contractors/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New contractor
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a contractor in the system</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/contractors" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var users = (await HengyiApi.GetUsers(new UserQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                userOptions: users.map(function (item) { return { value: item.id, label: item.name + " " + item.surname }; }),
                loading: false
            });
        }
    }
}
