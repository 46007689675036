import React, { Component } from 'react';
import { HengyiApi, ListQueryParams } from '../infrastructure/fetchHengy';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import { HengyiCarParkClient } from '../infrastructure/HengyiClient/CarParks';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiParkedVehicleClient } from '../infrastructure/HengyiClient/ParkedVehicles';

export class ParkedVehiclesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, carParkId:""
        };
       
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            var initialCarPark = this.state.carParkOptions.filter(type => type.value === this.state.carParkId)[0];

            return (<Formik
                initialValues={{
                    carParkId: initialCarPark,
                    location: this.state.location
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var carParkId = null;
                    if (fields.carParkId)
                        carParkId = fields.carParkId.value;

                    var response = await HengyiParkedVehicleClient.Update(this.props.match.params.id, carParkId, fields.location)

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>

                        <Row>
                            <Col>
                        <SelectField
                            id="carParkId"
                            name="carParkId"
                            label="Car park"
                            placeholder="Select park this vehicle is at"
                            options={this.state.carParkOptions}
                            value={values.carParkId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.carParkId}
                            error={errors.carParkId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                                />
                            </Col>

                            <Col>
                                <div className="form-group">

                                    <label htmlFor="location">Location</label>
                                    <select type="select" name="location"
                                        value={values.location}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                                        <option value="">Not specified</option>
                                        <option value="Onsite">Onsite</option>
                                        <option value="Offsite">Offsite</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "Save"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/parked-vehicles/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update parked vehicle
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Set car park the vehicle is stored at</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/parked-vehicles/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.carParkOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiParkedVehicleClient.Get(this.props.match.params.id);
            var parks = (await HengyiCarParkClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.carPark)
                    this.setState({ carParkId: response.data.carPark.id });

                if (response.data.location)
                    this.setState({ location: response.data.location });

                this.setState({
                    loading: false,
                    carParkOptions: parks.map(function (item) { return { value: item.id, label: item.name + " - " + item.parkingBank.name }; }),
                });
            }
        }
    }
}
