import React, { Component } from 'react';
import { InductionsTable } from './InductionsTable';

export class Inductions extends Component {

    render() {
        return (
            <InductionsTable />
        );
    }
}
