import { ApiResponse, ListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class WorkOrderQueryParams extends ListQueryParams {

    WithProvider(value: string) {
        this.WithParam("provider", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithTicketId(value: string) {
        this.WithParam("ticketId", value);
        return this;
    }

    WithContractor(value: string) {
        this.WithParam("contractorId", value);
        return this;
    }
}

export class HengyiWorkOrderClient {

    static async List(params: ListQueryParams) {

        var query = (await GetAPIDomain()) + "/work-orders" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-orders/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(orderNumber: string, description: string, assignedToId: string) {

        var request: any = {
            orderNumber: orderNumber,
            description: description,
            assignedToId: assignedToId,
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-orders/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async AssignToUser(id: string, assignedToId: string, reason: string) {

        var request: any = {
            assignedToId: assignedToId,
            reason: reason
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-orders/" + id + "/assign", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Close(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-orders/" + id + "/close", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Update(id: string, orderNumber: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/work-orders/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "orderNumber",
                "value": orderNumber
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/work-orders/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async GetServiceCategories() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/integrations/iviva/service-categories", {
            headers: await GetAPIHeaders()
        }));

    }

    static async GetProblemTypes() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/integrations/iviva/problem-types", {
            headers: await GetAPIHeaders()
        }));

    }

    static async GetLocations() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/integrations/iviva/locations", {
            headers: await GetAPIHeaders()
        }));

    }

}