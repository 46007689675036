import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiStorageUnitClient } from '../infrastructure/HengyiClient/StorageUnits';
import { ApartmentSelector } from '../infrastructure/ApartmentSelector';

export class StorageUnitsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: "", parkingBankId:""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    apartmentId: "",
                    status: this.state.status
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var response = await HengyiStorageUnitClient.Update(this.props.match.params.id, this.state.name,
                        this.state.description, fields.status, fields.apartmentId ? fields.apartmentId.value : null);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input name="name" id="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea name="description" id="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select name="status"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
                                <option value="available" label="Available" />
                                <option value="reserved" label="Reserved" />
                                <option value="unavailable" label="Unavailable" />
                            </select>
                            <ErrorMessage name="status" component="div" className="invalid-feedback" />
                        </div>


                        <div className="form-group mt-3">

                            <ApartmentSelector
                                initialApartmentId={this.state.apartmentId}
                                value={values.apartmentId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.apartmentId}
                                error={errors.apartmentId} />

                        </div>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/storage-units/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update storage unit
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/storage-units/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {

            var response = await HengyiStorageUnitClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.apartment) {
                    this.setState({ apartmentId: response.data.apartment.id });
                }

                this.setState({
                    loading: false,
                    name: response.data.name,
                    description: response.data.description,
                    status: response.data.status,
                    init: true
                }, () => {
                    document.getElementById("name").value = response.data.name ? response.data.name : "";
                    document.getElementById("description").value = response.data.description ? response.data.description : "";
                });
            }
        }
    }
}
