import { ListQueryParams } from '../infrastructure/fetchHengy';
import { HengyiBuildingClient } from '../infrastructure/HengyiClient/Buildings';
import { HengyiVehicleClient, VehicleQueryParams } from '../infrastructure/HengyiClient/Vehicles';

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";

export class ParkedVehiclesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
           loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                buildingId: this.state.buildingOptions[0],
                vehicleId: "",
                location: "Onsite"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                console.log(fields);

                var request = {
                    buildingId: fields.buildingId.value,
                    vehicleId: fields.vehicleId.value,
                    location: fields.location
                }

                const response = await fetch((await GetAPIDomain()) + "/parked-vehicles", {
                    method: 'post',
                    headers: await GetAPIHeaders(),
                    body: JSON.stringify(request)
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    const data = await response.json();
                    this.setState({ next: true, id: data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>


                    <Row>
                        <Col>
                            <SelectField
                                id="vehicleId"
                                name="vehicleId"
                                label="Vehicle"
                                placeholder="Select vehicle"
                                options={this.state.vehicleOptions}
                                value={values.vehicleId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.vehicleId}
                                error={errors.vehicleId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        <Col>
                            <SelectField
                                id="buildingId"
                                name="buildingId"
                                label="Building"
                                placeholder="Select building"
                                options={this.state.buildingOptions}
                                value={values.buildingId}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.buildingId}
                                error={errors.buildingId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </Col>
                        <Col>
                            <div className="form-group">

                                <label htmlFor="location">Location</label>
                                <select type="select" name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                                    <option value="">Not specified</option>
                                    <option value="Onsite">Onsite</option>
                                    <option value="Offsite">Offsite</option>
                                </select>
                            </div>
                        </Col>
                </Row>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "PARK VEHICLE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/parked-vehicles/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Create valet ticket for parked car
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a vehicle with valet</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/vehicles" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.buildingOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var vehicles = (await HengyiVehicleClient.List(new VehicleQueryParams().Paginate(0, 10000))).data.data;
            var buildings = (await HengyiBuildingClient.List(new ListQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                vehicleOptions: vehicles.map(function (item) { return { value: item.id, label: item.licensePlate + " (" + item.colour + " " + item.make + " " + item.model + ")" }; }),
                buildingOptions: buildings.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
