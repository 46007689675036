import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, ListGroup, ListGroupItem, ListGroupItemHeading, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiBookingsClient } from '../infrastructure/HengyiClient/Bookings';
import { HengyiFacilityClient, FacilityQueryParams } from '../infrastructure/HengyiClient/Facilities';
import { HengyiSchedulesClient, SchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { HengyiBookingTypesClient, BookingTypesQueryParams } from '../infrastructure/HengyiClient/BookingTypes';
import { HengyiBookingSlotsClient, BookingSlotsQueryParams } from '../infrastructure/HengyiClient/BookingSlots';
import { SelectField } from "../infrastructure/SelectField";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HengyiEventClient, EventQueryParams } from '../infrastructure/HengyiClient/Events';
import { UserSelector } from '../infrastructure/UserSelector';

export class BookingNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: false,
            loading: true,
            authenticated: true,
            next: false,

            startDate: null,
            bookingType: null,
            modal: false,
            errorMessage: "",
            booking: "",
            bookings: [],
            scheduleOptions: [],
            bookingTypeOptions: [],
            bookingSlotsOptions: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {
            return (<Formik
                initialValues={{
                    status: "",
                    scheduleId: "",
                    bookingTypeId: "",
                    userId: "",
                    bookingType: ""
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.userId) {
                        setFieldError("userId", "Please select a user to make a booking for");
                        return;
                    }

                    if (!fields.scheduleId) {
                        setFieldError("scheduleId", "Please select a schedule to make a booking for");
                        return;
                    }

                    if (!fields.bookingTypeId) {
                        setFieldError("userId", "Please select a booking type to make a booking for");
                        return;
                    }


                    var duration = this.state.bookingSlotsOptions[0].duration * this.state.bookings.length;
                    var startTime = this.state.bookingSlotsOptions[this.state.bookings[0]].start;

                    var time = moment(startTime, "HH:mm");
                    var start = moment(fields.bookingDate.toISOString())
                        .startOf('day')
                        .add(time.format("HH"), "hours")
                        .add(time.format("mm"), "minutes");

                    var end = moment(start).add(duration, "minutes");

                    var response = await HengyiBookingsClient.Create(
                        this.state.notes,
                        this.state.contactName,
                        this.state.contactNumber,
                        fields.userId.value,
                        fields.bookingTypeId.value,
                        fields.scheduleId.value,
                        start.toISOString(),
                        end.toISOString());

                    if (!response.successful) {

                        this.setState({
                            errors: response.validationErrors.map(error => error.message)
                        });

                    } else {
                        this.setState({ next: true, id: response.data.id });
                    }

                }}
            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange }) => (
                <Form>

                    <h6>Who is this booking for?</h6>
                    <hr />

                    <div className="form-group">

                        <UserSelector
                            value={values.userId}
                                onChange={(key, item) => {
                                    setFieldValue(key, item);
                                    setFieldValue("bookingDate", "");
                                    setFieldValue("eventId", "");
                                    setFieldValue("facilityId", "");
                                }}
                            onBlur={setFieldTouched}
                            touched={touched.status}
                            error={errors.type} />

                        <ErrorMessage name="userId" component="div" className="invalid-feedback" />
                    </div>

                    {values.userId && <div>
                        <label>
                            <input name="differentContactDetails"
                                type="checkbox"
                                checked={this.state.differentContactDetails}
                                onChange={evt => this.setState({ differentContactDetails: evt.target.checked })} /> Specify alternative contact details
                        </label>

                        {this.state.differentContactDetails && <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="contactName">Contact name</label>
                                        <Field name="contactName" type="text" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="contactName" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="contactNumber">Contact number</label>
                                        <Field name="contactNumber" type="text" className="form-control" onChange={this.handleChange} />
                                    <ErrorMessage name="contactNumber" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>}

                        <h6 className="mt-4">What are you booking?</h6>
                        <hr />

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item">

                                            <div className="form-check">
                                                <Field className="form-check-input" type="radio" name="bookingType" id="bookingTypeFacilities" value="facilities" />
                                                <label className="form-check-label" htmlFor="bookingTypeFacilities">A facility</label>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <label>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="radio" name="bookingType" id="bookingTypeEvents" value="events" />
                                                    <label className="form-check-label" htmlFor="bookingTypeEvents">An event</label>
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        {values.bookingType === "facilities" && <div className="form-group">
                            <SelectField
                                id="facilityId"
                                name="facilityId"
                                label="Facility"
                                placeholder="Select facility"
                                options={this.state.facilityOptions}
                                value={values.facilityId}
                                isMulti={false}
                                onChange={(key, item) => {
                                    setFieldValue(key, item);
                                    setFieldValue("bookingDate", "");
                                    setFieldValue("eventId", "");
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.facilityId}
                                error={errors.facilityId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />

                            <ErrorMessage name="facilityId" component="div" className="invalid-feedback" />
                        </div>}

                        {values.bookingType === "events" && <div className="form-group">
                            <SelectField
                                id="eventId"
                                name="eventId"
                                label="Event"
                                placeholder="Select event"
                                options={this.state.eventOptions}
                                value={values.eventId}
                                isMulti={false}
                                onChange={(key, item) => {
                                    setFieldValue(key, item);
                                    setFieldValue("bookingDate", "");
                                    setFieldValue("facilityId", "");
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.eventId}
                                error={errors.eventId}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />

                            <ErrorMessage name="eventId" component="div" className="invalid-feedback" />
                        </div>}

                        {((values.bookingType === "events" && values.eventId) || (values.bookingType === "facilities" && values.facilityId)) && <div>


                            <h6 className="mt-4">When are you booking for?</h6>
                            <hr />

                            <div className="form-group">
                                <label htmlFor="bookingDate">Booking date</label>
                                <DatePicker
                                        id="bookingDate"
                                        onChangeRaw={(e) => { var position = e.currentTarget.selectionStart; var input = document.getElementById(e.currentTarget.id); setTimeout(() => { input.selectionStart = position; input.selectionEnd = position; }, 1); }}
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.bookingDate}
                                    placeholderText="Choose date"
                                    filterDate={date => {
                                        var today = new Date();
                                        var yesterday = moment(today).subtract(1, "day");
                                        return moment(date).isAfter(yesterday, "days");
                                    }}
                                    onChange={async (v, f) => {
                                        setFieldValue("bookingDate", v);
                                        setFieldValue("scheduleId", "");
                                        setFieldValue("bookingTypeId", "");
                                        this.setState({
                                            bookings: []
                                        });

                                        var result = await HengyiSchedulesClient.List(new SchedulesQueryParams()
                                            .WithFacility(values.facilityId && values.facilityId.value)
                                            .WithEvent(values.eventId && values.eventId.value)
                                            .WithDate(v).Paginate(0, 1000));


                                        var scheduleOptions = result.data.data.map(i => { return { value: i.id, label: i.name }; });
                                        this.setState({
                                            scheduleOptions: scheduleOptions
                                        });

                                        if (result.data.data.length === 1) {
                                            setFieldValue("scheduleId", scheduleOptions[0]);

                                            var result2 = await HengyiBookingTypesClient.List(new BookingTypesQueryParams()
                                                .WithScheduleId(scheduleOptions[0].value)
                                                .Paginate(0, 1000))

                                            var bookingOptions = result2.data.data.map(i => { return { value: i.id, label: i.name, maximumBookingLength: i.maximumBookingLength }; });

                                            this.setState({
                                                bookingOptions: bookingOptions
                                            });

                                            if (result2.data.data.length > 0) {
                                                setFieldValue("bookingTypeId", bookingOptions[0]);
                                                this.setState({
                                                    maximumBookingLength: bookingOptions[0].maximumBookingLength
                                                });
                                                this.getBookingSlots(scheduleOptions[0].value, v);
                                            }
                                        }
                                    }}
                                    dateFormat='dd/MM/yyyy' />

                                <ErrorMessage name="bookingDate" component="div" className="invalid-feedback" />
                            </div>

                            {values.bookingDate && <div>

                                {this.state.scheduleOptions && this.state.scheduleOptions.length === 0 &&
                                    <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>No schedules:</strong> No valid schedule for this date, bookings cannot be made</p>
                                    </div>}

                                {this.state.scheduleOptions && this.state.scheduleOptions.length > 0 &&
                                    <div>
                                        <div className="form-group">
                                            <SelectField
                                                id="scheduleId"
                                                name="scheduleId"
                                                label="Schedule"
                                                placeholder="Select Schedule"
                                                isDisabled={!this.state.scheduleOptions || this.state.scheduleOptions.length < 2}
                                                options={this.state.scheduleOptions}
                                                value={values.scheduleId}
                                                isMulti={false}
                                                onChange={async (key, item) => {
                                                    setFieldValue(key, item);
                                                    setFieldValue("bookingTypeId", "");
                                                    this.setState({
                                                        bookings: []
                                                    });

                                                    var result = await HengyiBookingTypesClient.List(new BookingTypesQueryParams()
                                                        .WithScheduleId(values.scheduleId && values.scheduleId.value)
                                                        .WithUser(values.userId && values.userId.value).Paginate(0, 1000))

                                                    var bookingOptions = result.data.data.map(i => { return { value: i.id, label: i.name, maximumBookingLength: i.maximumBookingLength }; });
                                                   
                                                    this.setState({
                                                        bookingOptions: bookingOptions
                                                    });

                                                    if (result.data.data.length > 0) {
                                                        setFieldValue("bookingTypeId", bookingOptions[0]);
                                                        this.setState({
                                                            maximumBookingLength: bookingOptions[0].maximumBookingLength
                                                        });
                                                        this.getBookingSlots(item.value, values.bookingDate);
                                                    }

                                                }}
                                                onBlur={setFieldTouched}
                                                touched={touched.scheduleId}
                                                error={errors.scheduleId}
                                                isClearable={true}
                                                backspaceRemovesValue={true}
                                            />

                                            <ErrorMessage name="scheduleId" component="div" className="invalid-feedback" />
                                        </div>

                                        {values.scheduleId && <div>

                                            <div className="form-group">
                                                <SelectField
                                                    id="bookingTypeId"
                                                    name="bookingTypeId"
                                                    label="Booking Type"
                                                    placeholder="Select Booking Type"
                                                    isDisabled={!this.state.bookingOptions || this.state.bookingOptions.length < 2}
                                                    options={this.state.bookingOptions}
                                                    value={values.bookingTypeId}
                                                    isMulti={false}
                                                    onChange={(key, item) => {
                                                        setFieldValue(key, item);

                                                        this.setState({
                                                            maximumBookingLength: item.maximumBookingLength
                                                        });

                                                        this.setState({
                                                            bookings: []
                                                        });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    touched={touched.status}
                                                    error={errors.type}
                                                    isClearable={true}
                                                    backspaceRemovesValue={true}
                                                />

                                                <ErrorMessage name="bookingTypeId" component="div" className="invalid-feedback" />
                                            </div>


                                            {this.state.bookingOptions && this.state.bookingOptions.length === 0 &&
                                                <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>No booking options:</strong> No valid options for this date, bookings cannot be made</p>
                                                </div>}

                                            {this.state.bookingOptions && this.state.bookingOptions.length > 0 && <div>
                                                <h6>Select a booking time</h6>
                                                <hr />

                                                {this.state.errors && this.state.errors.map(error =>
                                                    <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>{error}</p>
                                                    </div>)}

                                                {this.state.bookingSlotsOptions && this.state.bookingSlotsOptions.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No bookings to display</i></h3>}
                                                <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap" }}>
                                                    {this.state.bookingSlotsOptions.map((item, index) => (
                                                        <ListGroupItem key={item.start}
                                                            style={{
                                                                flexWrap: "wrap", "width": "120px"
                                                            }}
                                                            className="clickable"
                                                            disabled={!item.available}
                                                            active={this.state.bookings.findIndex(i => i === index) !== -1}
                                                            onClick={() => { this.onBookingChange(index, item.duration); }}>

                                                            <ListGroupItemHeading style={{ textAlign: "center" }}>{item.start.substring(0, item.start.length - 3)}</ListGroupItemHeading>
                                                            <small>{item.capacity - item.booked} remaining</small>
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            </div>}

                                            <div className="form-group mt-2">
                                                <label htmlFor="notes">Notes</label>
                                                    <Field name="notes" type="text" component="textarea" className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')} onChange={this.handleChange} />
                                                <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                                            </div>

                                        </div>}
                                    </div>
                                }

                            </div>}
                        </div>}
                    </div>}

                    <Row>
                        <Col>

                            <Modal isOpen={this.state.modal}>
                                <ModalHeader toggle={() => this.toggle()}>Information</ModalHeader>
                                <ModalBody>
                                    {this.state.errorMessage}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={() => this.toggle("")}>Ok</Button>
                                </ModalFooter>
                            </Modal>

                        </Col>
                    </Row>
                    <div className="form-group tm-4">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting || !this.state.bookings || this.state.bookings.length === 0}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/bookings/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New booking
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new booking</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/bookings" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    setStartDate = date => {
        this.setState({
            startDate: date
        });

        this.getBookingSlots(this.state.scheduleId, date);
    }

    onBookingChange = (index, duration) => {

        var bookings = this.state.bookings;

        //check if user want to de-select an item
        if (this.isDeselect(index)) {
            return;
        }

        //validate selection are consecutive eg. 1,2,3 and not 1,2,5
        if (!this.isConsecutiveSelection(index)) {
            this.toggle("You can only select hours in a consecutive order.");
            return;
        }

        //validate that current selections does not exceed bookingType.maximumBookingLength.
        if (this.exceedsMaximumBookingLength(duration)) {
            this.toggle("Sorry, you have reached the maximum allowance of " + this.state.maximumBookingLength + " minutes for this booking.");
            return;
        }

        bookings.push(index);
        this.setState({ bookings: bookings });
    }

    isDeselect(index) {
        var bookings = this.state.bookings;
        var idx = bookings.findIndex(i => i === index);

        if (idx === -1) {
            return false;
        }

        var length = bookings.length;
        //its first item, no need to remove subsequent items
        if (idx === 0) {
            length = 1;
        }

        bookings.splice(idx, length);
        this.setState({ bookings: bookings });
        return true;
    }

    exceedsMaximumBookingLength(duration) {
        var bookings = this.state.bookings;
        var total = (bookings.length + 1) * duration;
        return total > this.state.maximumBookingLength;
    }

    isConsecutiveSelection(index) {
        var bookings = this.state.bookings;
        var length = bookings.length - 1;
        var lastIndex = bookings[length];
        lastIndex++;

        //No elements on array (is the first selection).
        if (isNaN(lastIndex)) {
            return true;
        }

        return index === lastIndex;
    }

    async toggle(msg) {
        this.setState({ modal: !this.state.modal, errorMessage: msg })
    }

    async getBookingSlots(scheduleId, date) {

        var response = await HengyiBookingSlotsClient.List(new BookingSlotsQueryParams()
            .WithScheduleId(scheduleId)
            .WithBookingDate(date));

        this.setState({
            bookingSlotsOptions: response.data
        });
    }

    async populateData() {
        if (!this.state.init) {

            var facilitiesResult = await HengyiFacilityClient.List(new FacilityQueryParams());
            var eventsResult = await HengyiEventClient.List(new EventQueryParams());

            if (!facilitiesResult.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    facilityOptions: facilitiesResult.data.data.map(i => { return { value: i.id, label: i.name }; }),
                    eventOptions: eventsResult.data.data.map(i => { return { value: i.id, label: i.name }; }),

                    loading: false,
                    init: true,
                });
            }
        }
    }

}
