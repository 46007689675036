import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { HengyiCarParkClient } from '../infrastructure/HengyiClient/CarParks';
import { HengyiParkingBankClient } from '../infrastructure/HengyiClient/ParkingBanks';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { ParkingBankQueryParams } from '../infrastructure/HengyiClient/ParkingBanks';
import { HengyiApartmentClient, ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';
import { CarParkTypeSelector } from '../infrastructure/CarParkTypeSelector';

export class CarParksNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false, name: "", description: ""
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                parkNumber: this.state.parkNumber,
                description: this.state.description,
                parkingBankId: this.state.parkingBankOptions[0],
                apartmentId: "",
                apartmentLeasedToId: "",
                typeId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var response = await HengyiCarParkClient.Create(fields.parkingBankId.value, this.state.description, fields.apartmentId ? fields.apartmentId.value : null, fields.apartmentLeasedToId ? fields.apartmentLeasedToId.value : null, this.state.parkNumber, fields.typeId ? fields.typeId.value : null);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="parkNumber">Park Number</label>
                                <input name="parkNumber" type="text" className={'form-control' + (errors.parkNumber && touched.parkNumber ? ' is-invalid' : '')} onChange={this.handleChange} />
                                <ErrorMessage name="parkNumber" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                                <textarea name="description" type="text" component="textarea" className="form-control" onChange={this.handleChange} />
                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                    <div className="form-group mt-3">
                        <Row>
                            <Col>

                                <SelectField
                                    id="parkingBankId"
                                    name="parkingBankId"
                                    label="Parking bank"
                                    placeholder="Select parking bank"
                                    options={this.state.parkingBankOptions}
                                    value={values.parkingBankId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.parkingBankId}
                                    error={errors.parkingBankId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />

                            </Col>
                            <Col>
                                <CarParkTypeSelector
                                    label="Type"
                                    value={values.typeId}
                                    initialId={this.state.typeId}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.typeId}
                                    error={errors.typeId} />
                            </Col>
                        </Row>
                    </div>

                <div className="form-group mt-3">

                        <Row>
                            <Col>
                                <SelectField
                                    id="apartmentId"
                                    name="apartmentId"
                                    label="Space"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentId}
                                    error={errors.apartmentId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                            <Col>
                                <SelectField
                                    id="apartmentLeasedToId"
                                    name="apartmentLeasedToId"
                                    label="Space leased to"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentLeasedToId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentLeasedToId}
                                    error={errors.apartmentLeasedToId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </Col>
                        </Row>
                </div>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SAVE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/car-parks/" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New car park
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a new car park</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/car-parks" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.parkingBankOptions && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var banks = (await HengyiParkingBankClient.List(new ParkingBankQueryParams().Paginate(0, 10000))).data.data;
            var apartments = (await HengyiApartmentClient.List(new ApartmentQueryParams().Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                parkingBankOptions: banks.map(function (item) { return { value: item.id, label: item.name }; }),
                apartmentOptions: apartments.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
