import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiTicketClient } from '../infrastructure/HengyiClient/Tickets';

export class TicketEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    handleChange(event) {
        var formValues = {};
        formValues[event.target.name] = event.target.value;
        this.setState(formValues);
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    email: ""
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    console.log(fields);

                    var response = await HengyiTicketClient.SendToEmail(this.props.match.params.id, this.state.email);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={this.handleChange} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SEND TICKET"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/tickets/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Email ticket
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Send a copy of this ticket to an email address</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/tickets/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {!this.state.loading && this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await HengyiTicketClient.Get(this.props.match.params.id);
            
            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.assignedTo)
                    this.setState({ userId: response.data.assignedTo.id });

                this.setState({
                    loading: false
                });
            }
        }
    }
}
